import { Component, Input, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
} from '@angular/material';
import { AuthService } from '../../../auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ChecklistService } from '../../../master/checklist/checklist.service';
import * as moment from 'moment';

@Component({
  selector: 'app-audit-log-checklist',
  templateUrl: './audit-log-checklist.component.html',
  styleUrls: ['./audit-log-checklist.component.css']
})
export class AuditLogChecklistComponent implements OnInit {

  selectedRow: any;
  categoryDetails: any;
  referenceList: any;

  constructor(private dialog: MatDialog, private router: Router, private authService: AuthService,
    private ChecklistService : ChecklistService, @Inject(MAT_DIALOG_DATA) private data: any) 
    {
        this.selectedRow = data;
    }


  ngOnInit() {
    this.ChecklistService.getAllChecklist().subscribe(data => {
      if (!(data && this.selectedRow)) {
        return;
      }

      data.forEach(element => {
        if(element._id == (this.selectedRow._id || this.selectedRow.id)){
          this.categoryDetails = element.categoryDetails;
          this.referenceList = element.referenceDocuments;
          element.issueDate = moment(element.issueDate).format('DD/MM/YYYY');
        }
      });
    });
  }

}
