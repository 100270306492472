import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheet
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { UserService } from '../../user/user.service';
import { RoleManagementService } from '../../role/role-management.service';

@Component({
  selector: 'app-permission-user-and-rolelist',
  templateUrl: './permission-user-and-rolelist.component.html',
  styleUrls: ['./permission-user-and-rolelist.component.css']
})
export class PermissionUserAndRolelistComponent implements OnInit {
  user: any; selectedPermission: any; userData: any; roleData: any;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PermissionUserAndRolelistComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private UserService: UserService,
    private roleService: RoleManagementService
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    if (Object.keys(this.data).length !== 0) {
      this.selectedPermission = this.data;
    }
    this.getAllUsers();   
    this.getAllRoles(); 
  }

  getAllUsers=()=>{
    this.UserService.getRecord().subscribe(res=>{
      if(!res){
        return
      }
      this.userData = res;
      /*
      if(this.userData.length > 0) {
        let uniqueRoles = [];
        this.userData.forEach(element => {
          if(element.role !== undefined && element.role.length > 0) {            
            element.role.forEach(elem=>{
              let flag = false;
              if(uniqueRoles.length === 0) {
                uniqueRoles.push(elem.name);
              } else {
                uniqueRoles.forEach(ele=>{
                  if(ele === elem.name) {
                    flag = true;
                  }
                });
                if(!flag) {
                  uniqueRoles.push(elem.name);
                }
              }
            });            
          }
        });
      }
      */
    });
  }

  getAllRoles=()=>{
    this.roleService.getRecord().subscribe(res=>{
      if(!res){
        return
      }
      this.roleData = res;
      this.showUserRolelist();
    });
  }

  showUserRolelist=()=>{
    if(this.userData.length>0 && this.roleData.length>0) {
      this.userData.forEach(element=>{
        if(element.role !== undefined && element.role.length > 0) {
          element.role.forEach(userRoleElem=>{
            this.roleData.forEach(uniqueRoleEle=>{
              if(uniqueRoleEle._id === userRoleElem.ref) {
                if(uniqueRoleEle.permission !== undefined && uniqueRoleEle.permission.length > 0) {
                  uniqueRoleEle.permission.forEach(permElement=>{
                   // if(permElement)
                  }); 
                }
              }
            });
          });
        }
      });
    }
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
