import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangePasswordService } from './change-password.service';
import { AuthService } from '../auth.service';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheet,
  MatSnackBar
} from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  user: any;
  form: FormGroup;
  isError: Boolean = false;
  errMsg: String = '';

  private formSubmitAttempt: boolean;
  constructor(
    private fb: FormBuilder,
    private changePasswordService: ChangePasswordService
    , private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      password: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      emailId: ['']
    });

    this.authService.LoggedUserDetail.subscribe(result => {
      this.user = result;

      this.form.setValue({
        password: '',
        newPassword: '',
        confirmPassword: '',
        emailId: this.user.data.emailId
      });
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    // this.authService.login(this.form.value, (msg) => {
    //   this.isError = true;
    //   this.errMsg = msg;

    // });

    this.changePasswordService.changePassword(this.form.value).subscribe(res => {
      const resu: any = res;

      this.isError = false;
      this.errMsg = '';

      if (resu.statusBool) {
        this.snackBar.open('Password changed successfully', '', { duration: 2000, verticalPosition: "bottom" });
        this.router.navigate(['/login']);


      } else {
        this.isError = true;
        this.errMsg = resu.message;
      }


    });
    this.formSubmitAttempt = true;
  }

}
