import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { GridsterItem } from 'angular-gridster2';
import { GridsterSettingsService } from '../gridster-settings/gridster-settings.service';

@Component({
  selector: 'app-dashboard-add-widgets',
  templateUrl: './dashboard-add-widgets.component.html',
  styleUrls: ['./dashboard-add-widgets.component.css']
})
export class DashboardAddWidgetsComponent implements OnInit {
  dashboardList = [];
  isselectAll: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DashboardAddWidgetsComponent>,
    public gridsterSettingsService: GridsterSettingsService) { }

  ngOnInit() {
    this.isselectAll = false;
    this.dashboardList = [];
    this.data = this.data || [];
    this.getMapData();
  }


  getMapData() {
    this.data = [...this.data] || [];
    this.dashboardList = this.data.map((e) => {
      const element = { ...e };
      return element;
    });
  }

  filterGridsterListData(data) {
    data = data || [];
    data = data.filter((element) => {
      return element.isActive && element.isActivatedUser && element.componentType;
    });
    return data;
  }

  changeWidget(widget, isvalid) {
    const data = this.dashboardList.find((element, i) => {
      if (element.module === widget.module && element.name === widget.name) {
        element.isShow = isvalid;
        this.dashboardList[i] = element;
        return true;
      }
      return false;
    });
  }
  selectAll(checked) {
    this.isselectAll = checked;
    this.dashboardList.forEach((element) => {
      if (element.isActive && element.isActivatedUser && element.componentType) {
        element.isShow = checked;
      }
    });
  }
  saveEnableddashboardDetails() {
    const verticalvalues = [0];
    const horziontalValues = [0];
    this.dashboardList.forEach((e, index, arrayList) => {
      if (!e.isShow) {
        // arrayList[index] = this.gridsterSettingsService.resetRowValues(e);
      }
    });
    this.dialogRef.close(this.dashboardList);
  }

}
