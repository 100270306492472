import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Chart } from 'chart.js';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { UserService } from '../../admin/user/user.service';
import { RfiInspectionService } from '../../master/rfi-inspection/rfi-inspection.service';
import { Observable } from 'rxjs';
import {MatDatepicker} from '@angular/material/datepicker';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {Moment} from 'moment';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL', 
  },
  display: {
    dateInput: 'YYYY', // this is the format showing on the input element
    monthYearLabel: 'YYYY', // this is showing on the calendar
  },
};

@Component({
  selector: 'app-total-equipment-inspected',
  templateUrl: './total-equipment-inspected.component.html',
  styleUrls: ['./total-equipment-inspected.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class TotalEquipmentInspectedComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;
  title = 'angular8chartjs';
  toSearch: any;
  isTEShow = true;
  isTEShowY = true;
  isTEShowW =false;
  isTEShowM = false;
  isTEShowQ = false;
  user: any;
  canvas: any;
  horizontalBarChart: any;

  pickYear = new FormControl(moment());
  pickType = new FormControl('');

  list1= 0;list2 = 0;list3 = 0;list4 = 0;list5 = 0;list6 = 0;list7 = 0;list8 = 0;list9 = 0;list10 = 0;
  list11 = 0;list12 = 0;list13 = 0;list14 = 0;list15 = 0;list16 = 0;list17 = 0;list18 = 0;list19 = 0;list20 = 0;
  list21 = 0;list22 = 0;list23 = 0;list24 = 0;list25 = 0;list26 = 0;list27 = 0;list28 = 0;list29 = 0;list30 = 0;
  list31 = 0;list32 = 0;list33 = 0;list34 = 0;list35 = 0;list36 = 0;list37 = 0;list38 = 0;list39 = 0;list40 = 0;
  list41 = 0;list42 = 0;list43 = 0;list44 = 0;list45 = 0;list46 = 0;list47 = 0;list48 = 0;list49 = 0;list50 = 0;
  list51 = 0;list52 = 0;list53 = 0;list54 = 0;
  constructor(private userService: UserService, private snackBar: MatSnackBar, private rfiInspectionService: RfiInspectionService) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.pickType.setValue('Monthly');
    this.getDataBYYear(this.pickYear.value, this.pickType.value);
  }

  chosenYearHandler(normalizedYear: Moment,datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.pickYear.value;
    ctrlValue.year(normalizedYear.year());
    this.pickYear.setValue(ctrlValue);
    datepicker.close();
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
 
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }
  onResize(event){
    event.target.innerWidth; // window width
    this.getDataBYYear(this.pickYear.value, this.pickType.value);

  }
  getDataBYYear(pickYear, pickType) {
    if(pickType == 'Quarterly'){
      var date = new Date(pickYear);
      var year = date.getFullYear();
      var startDate = new Date(year, 0, 2)
      var endDate = new Date(year, 11, 32, 0, 0, 0);
      this.toSearch = {
        start : startDate,
        end : endDate
      };
      this.isTEShow = true;
      this.isTEShowQ = true;
      this.isTEShowY = false;
      this.isTEShowM = false;
      this.isTEShowW = false;
      this.rfiInspectionService.getTotalStatusByEquipmentConducted(this.toSearch).subscribe(result => {
        if (!result) {
          return
        }
        this.list1 = 0;
        this.list2 = 0;
        this.list3 = 0;
        this.list4 = 0;
        if (result.length > 0) {
          var array = [];
          var count = 1;
          for(var i=0;i<result.length;i++){
            if(result[i]._id.month == 1 || result[i]._id.month == 2 || result[i]._id.month == 3)
            {
              this.list1 = this.list1 + parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 4 || result[i]._id.month == 5 || result[i]._id.month == 6)
            {
              this.list2 = this.list2 + parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 7 || result[i]._id.month == 8 || result[i]._id.month == 9)
            {
              this.list3 = this.list3 + parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 10 || result[i]._id.month == 11 || result[i]._id.month == 12)
            {
              this.list4 = this.list4 + parseInt(result[i].Total);
            }
          }
          
          this.isTEShow = true;
          this.isTEShowQ = true;
          this.isTEShowY = false;
          this.isTEShowM = false;
          this.isTEShowW = false;
          this.canvas = document.getElementById('horizontalBarChartTEQ');
          this.horizontalBarChart = new Chart(this.canvas, {
            type: 'horizontalBar',
            data: {
              labels: ['Jan to Mar','Apr to Jun','Jul to Sep','Oct to Dec'],
              datasets: [{
                // label: '# of Votes',   //#edadf5,#79bf91,#5ad9d9,#e1e78f
                data: [this.list1,this.list2,this.list3,this.list4],
                backgroundColor: [
                  '#79bf91','#edadf5','#ed834e','#acf266'
                ],
                fill: true,
                borderWidth: 1
              }],

            },
            options: {
              responsive: true,
              legend: {
                display: false
                
              },
              scales: {
                xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Total Equipment Inspected'
                  },
                  ticks: {
                    //stepSize: 1
                  },
                  display: true,
                  stacked: true,
                  offset: true
                }],
                yAxes: [{
                  display: true
                }]
              }

            }
          });
        } else {
          this.isTEShow = false;
          this.isTEShowQ = false;
          this.snackBar.open('No record found', '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else if(pickType == 'Weekly'){
      var date = new Date(pickYear);
      var year = date.getFullYear();
      var startDate = new Date(year, 0, 2)
      var endDate = new Date(year, 11, 32, 0, 0, 0);
      this.toSearch = {
        start : startDate,
        end : endDate
      };
      this.isTEShow = true;
      this.isTEShowW = true;
      this.isTEShowM = false;
      this.isTEShowQ = false;
      this.isTEShowY = false;
      this.rfiInspectionService.getTotalStatusByEquipmentConductedW(this.toSearch).subscribe(result => {
        if (!result) {
          return
        }
        this.list1 = 0;this.list2 = 0;this.list3 = 0;this.list4 = 0;this.list5 = 0;this.list6 = 0;this.list7 = 0;
        this.list8 = 0;this.list9 = 0;this.list10 = 0;this.list11 = 0;this.list12 = 0;this.list13 = 0;this.list14 = 0;
        this.list15 = 0;this.list16 = 0;this.list17 = 0;this.list18 = 0;this.list19 = 0;this.list20 = 0;this.list21 = 0;
        this.list22 = 0;this.list23 = 0;this.list24 = 0;this.list25 = 0;this.list26 = 0;this.list27 = 0;this.list28 = 0;
        this.list29 = 0;this.list30 = 0;this.list31 = 0;this.list32 = 0;this.list33 = 0;this.list34 = 0;this.list35 = 0;
        this.list36 = 0;this.list37 = 0;this.list38 = 0;this.list39 = 0;this.list40 = 0;this.list41 = 0;this.list42 = 0;
        this.list43 = 0;this.list44 = 0;this.list45 = 0;this.list46 = 0;this.list47 = 0;this.list48 = 0;this.list49 = 0;
        this.list50 = 0;this.list51 = 0;this.list52 = 0;this.list53 = 0;this.list54 = 0;
        if (result.length > 0) {
          var array =[];
          for(var i=0;i<result.length;i++){
            if(result[i]._id.week == 1)
            {
              this.list1 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 2)
            {
              this.list2 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 3)
            {
              this.list3 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 4)
            {
              this.list4 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 5)
            {
              this.list5 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 6)
            {
              this.list6 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 7)
            {
              this.list7 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 8)
            {
              this.list8 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 9)
            {
              this.list9 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 10)
            {
              this.list10 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 11)
            {
              this.list11 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 12)
            {
              this.list12 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 13)
            {
              this.list13 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 14)
            {
              this.list14 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 15)
            {
              this.list15 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 16)
            {
              this.list16 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 17)
            {
              this.list17 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 18)
            {
              this.list18 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 19)
            {
              this.list19 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 20)
            {
              this.list20 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 21)
            {
              this.list21 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 22)
            {
              this.list22 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 23)
            {
              this.list23 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 24)
            {
              this.list24 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 25)
            {
              this.list25 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 26)
            {
              this.list26 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 27)
            {
              this.list27 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 28)
            {
              this.list28 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 29)
            {
              this.list29 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 30)
            {
              this.list30 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 31)
            {
              this.list31 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 32)
            {
              this.list32 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 33)
            {
              this.list33 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 34)
            {
              this.list34 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 35)
            {
              this.list35 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 36)
            {
              this.list36 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 37)
            {
              this.list37 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 38)
            {
              this.list38 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 39)
            {
              this.list39 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 40)
            {
              this.list40 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 41)
            {
              this.list41 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 42)
            {
              this.list42 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 43)
            {
              this.list43 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 44)
            {
              this.list44 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 45)
            {
              this.list45 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 46)
            {
              this.list46 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 47)
            {
              this.list47 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 48)
            {
              this.list48 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 49)
            {
              this.list49 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 50)
            {
              this.list50 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 51)
            {
              this.list51 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 52)
            {
              this.list52 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 53)
            {
              this.list53 = parseInt(result[i].Total);
            }
            else if(result[i]._id.week == 54)
            {
              this.list54 = parseInt(result[i].Total);
            }
          }
          this.isTEShow = true;
          this.isTEShowW = true;
          this.isTEShowM = false;
          this.isTEShowQ = false;
          this.isTEShowY = false;
          this.canvas = document.getElementById('horizontalBarChartTEW');
          this.horizontalBarChart = new Chart(this.canvas, {
            type: 'horizontalBar',
            data: {
              labels: ['week1','week2','week3','week4','week5','week6','week7',
                       'week8','week9','week10','week11','week12','week13','week14',
                       'week15','week16','week17','week18','week19','week20','week21',
                       'week22','week23','week24','week25','week26','week27','week28',
                       'week29','week30','week31','week32','week33','week34','week35',
                       'week36','week37','week38','week39','week40','week41','week42',
                       'week43','week44','week45','week46','week47','week48','week49',
                       'week50','week51','week52','week53','week54'
                      ],
              datasets: [{
                // label: '# of Votes',   //#edadf5,#79bf91,#5ad9d9,#e1e78f
                data: [this.list1,this.list2,this.list3,this.list4,this.list5,this.list6,this.list7,
                       this.list8,this.list9,this.list10,this.list11,this.list12,this.list13,this.list14,
                       this.list15,this.list16,this.list17,this.list18,this.list19,this.list20,this.list21,
                       this.list22,this.list23,this.list24,this.list25,this.list26,this.list27,this.list28,
                       this.list29,this.list30,this.list31,this.list32,this.list33,this.list34,this.list35,
                       this.list36,this.list37,this.list38,this.list39,this.list40,this.list41,this.list42,
                       this.list43,this.list44,this.list45,this.list46,this.list47,this.list48,this.list49,
                       this.list50,this.list51,this.list52,this.list53,this.list54],
                backgroundColor: [
                  '#e1e78f','#5ad9d9','#79bf91','#edadf5','#ed834e','#acf266','#3de39b','#546e78',
                  '#a196a3','#d340ed','#9e7380','#76ad53','#e1e78f','#5ad9d9','#79bf91','#edadf5',
                  '#ed834e','#acf266','#3de39b','#546e78','#a196a3','#d340ed','#9e7380','#76ad53',
                  '#e1e78f','#5ad9d9','#79bf91','#edadf5','#ed834e','#acf266','#3de39b','#546e78',
                  '#a196a3','#d340ed','#9e7380','#76ad53','#e1e78f','#5ad9d9','#79bf91','#edadf5',
                  '#ed834e','#acf266','#3de39b','#546e78','#a196a3','#d340ed','#9e7380','#76ad53',
                  '#e1e78f','#5ad9d9','#79bf91','#edadf5','#ed834e','#acf266'
                ],
                fill: true,
                borderWidth: 1,
              }],

            },
            options: {
              responsive: true,
              legend: {
                display: false
              },
              scales: {
                xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Total Equipment Inspected'
                  },
                  ticks: {
                    //stepSize: 1
                  },
                  display: true,
                  stacked: true,
                  offset: true
                }],
                yAxes: [{
                  ticks: {
                    autoSkip: false
                  },
                  display: true
                }]
              }

            }
          });
        } else {
          this.isTEShow = false;
          this.isTEShowW = false;
          this.snackBar.open('No record found', '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else if(pickType == 'Yearly'){
      var date = new Date(pickYear);
      var year = date.getFullYear();
      var startDate = new Date(year, 0, 2)
      var endDate = new Date(year, 11, 32, 0, 0, 0);
      this.toSearch = {
        start : startDate,
        end : endDate
      };
      this.isTEShow = true;
      this.isTEShowY = true;
      this.isTEShowW = false;
      this.isTEShowQ = false;
      this.isTEShowM = false;
      this.rfiInspectionService.getTotalStatusByEquipmentConductedY(this.toSearch).subscribe(result => {
        if (!result) {
          return
        }
        this.list1 = 0;
        if (result.length > 0) {
          this.list1 = parseInt(result[0].Total);
          this.isTEShow = true;
          this.isTEShowY = true;
          this.isTEShowW = false;
          this.isTEShowQ = false;
          this.isTEShowM = false;
          this.canvas = document.getElementById('horizontalBarChartTEY');
          this.horizontalBarChart = new Chart(this.canvas, {
            type: 'horizontalBar',
            data: {
              labels: ['Yearly'],
              datasets: [{
                // label: '# of Votes',   //#edadf5,#79bf91,#5ad9d9,#e1e78f
                label:'Yearly',
                data: [this.list1],
                backgroundColor: [
                  '#5ad9d9'
                ],
                fill: true,
                borderWidth: 1
              }],

            },
            options: {
              responsive: true,
              legend: {
                display: false,
              },
              scales: {
                xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Total Equipment Inspected'
                  },
                  ticks: {
                    //stepSize: 1
                  },
                  display: true,
                  stacked: true,
                  offset: true
                }],
                yAxes: [{
                  display: true
                }]
              }

            }
          });
        } else {
          this.isTEShow = false;
          this.isTEShowY = false;
          this.snackBar.open('No record found', '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else{
      var date = new Date(pickYear);
      var year = date.getFullYear();
      var startDate = new Date(year, 0, 2)
      var endDate = new Date(year, 11, 32, 0, 0, 0);
      this.toSearch = {
        start : startDate,
        end : endDate
      };
      this.isTEShow = true;
      this.isTEShowM = true;
      this.isTEShowW = false;
      this.isTEShowY = false;
      this.isTEShowQ = false;
      this.rfiInspectionService.getTotalStatusByEquipmentConducted(this.toSearch).subscribe(result => {
        if (!result) {
          return
        }
        this.list1 = 0;
        this.list2 = 0;
        this.list3 = 0;
        this.list4 = 0;
        this.list5 = 0;
        this.list6 = 0;
        this.list7 = 0;
        this.list8 = 0;
        this.list9 = 0;
        this.list10 = 0;
        this.list11 = 0;
        this.list12 = 0;
        if (result.length > 0) {
          var array = [];
          var count = 1;
          for(var i=0;i<result.length;i++){
            if(result[i]._id.month == 1)
            {
              this.list1 = parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 2)
            {
              this.list2 = parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 3)
            {
              this.list3 = parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 4)
            {
              this.list4 = parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 5)
            {
              this.list5 = parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 6)
            {
              this.list6 = parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 7)
            {
              this.list7 = parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 8)
            {
              this.list8 = parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 9)
            {
              this.list9 = parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 10)
            {
              this.list10 = parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 11)
            {
              this.list11 = parseInt(result[i].Total);
            }
            else if(result[i]._id.month == 12)
            {
              this.list12 = parseInt(result[i].Total);
            }
          }
          
          this.isTEShow = true;
          this.isTEShowM = true;
          this.isTEShowW = false;
          this.isTEShowY = false;
          this.isTEShowQ = false;
          this.canvas = document.getElementById('horizontalBarChartTEM');
          this.horizontalBarChart = new Chart(this.canvas, {
            type: 'horizontalBar',
            data: {
              labels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
              datasets: [{
                // label: '# of Votes',   //#edadf5,#79bf91,#5ad9d9,#e1e78f               
                data: [this.list1,this.list2,this.list3,this.list4,this.list5,this.list6,this.list7,this.list8,this.list9,this.list10,this.list11,this.list12],
                backgroundColor: [
                  '#e1e78f','#5ad9d9','#79bf91','#edadf5','#ed834e','#acf266','#3de39b','#546e78','#a196a3','#d340ed','#9e7380','#76ad53'],
                fill: true,
                borderWidth: 1
              },],

            },
            options: {
              responsive: true,              
              legend: {
                display: false                
              },
              scales: {
                xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Total Equipment Inspected'
                  },
                  ticks: {
                    //stepSize: 1
                  },
                  display: true,
                  stacked: true,
                  offset: true
                }],
                yAxes: [{
                  display: true
                }]
              }

            }
          });
        } else {
          this.isTEShow = false;
          this.isTEShowM = false;
          this.snackBar.open('No record found', '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }
  }
}
