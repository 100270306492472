import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { UserService } from '../../admin/user/user.service'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { RfiInspectionService } from '../../master/rfi-inspection/rfi-inspection.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-inspection-overall-summary',
  templateUrl: './inspection-overall-summary.component.html',
  styleUrls: ['./inspection-overall-summary.component.css']
})
export class InspectionOverallSummaryComponent implements OnInit {

  displayedColumns = ['title', 'count', 'percentage'];

  temp = [];
  temp1 = [];
  dataSource: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  isLoggedIn$: Observable<boolean>;
  currentPermissions: any;
  toSearch: any;
  isShow = true;
  isInspector = true;
  user: any;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  alwaysShowCalendars: boolean;
  usersData: any;
  users: any;
  userName: any;
  inspectionDate = new FormControl('');
  inspector = new FormControl('');

  list1 = 0;
  list2 = 0;
  list3 = 0;
  list4 = 0;
  list5 = 0;
  list6 = 0;
  list7 = 0;
  list8 = 0;
  list9 = 0;
  list1Name = ''
  list2Name = ''
  list3Name = ''
  list4Name = ''
  list5Name = ''
  list6Name = ''
  list7Name = ''
  list8Name = ''
  list9Name = ''
  tempTotalCount = 0;
  finalList1 = '';
  finalList2 = '';
  finalList3 = '';
  finalList4 = '';
  finalList5 = '';
  finalList6 = '';
  finalList7 = '';
  finalList8 = '';
  finalList9 = '';
  inspectorColumn = false;
  width: Number;

  date = new Date();
  a = moment().startOf('day').subtract(1, 'month');
  b = moment().endOf('day')

  constructor(private userService: UserService, private snackBar: MatSnackBar,
    private rfiInspectionService: RfiInspectionService) { }



  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    if (this.user.data.role.length > 0) {
      for (var i = 0; i < this.user.data.role.length; i++) {
        if (this.user.data.role[i].name == 'Inspector' && this.user.data.role[i].isActive === true) {
          this.inspector.setValue(this.user.data._id)
          this.inspectorColumn = true;
        } else {
          this.inspector.setValue('All');
          this.inspectorColumn = false;
        }
      }
    }
    this.inspectionDate.setValue({
      start: moment(this.a).format('MM/DD/YYYY'),
      end: moment(this.b).format('MM/DD/YYYY')
    });
    this.width = screen.width;
    this.getUsers();
    this.getData(this.inspectionDate.value, this.inspector.value);
  }
  onResize(event) {
    this.width = event.target.innerWidth;
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  getUsers = () => {
    this.userService.getInspectors().subscribe(res => {
      this.users = res;
      this.usersData = this.users;

    });
  }
  filterInspector(val) {
    val = val.toLowerCase();
    this.usersData = this.users.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }
  getData(inspectionDate, inspector) {
    if (inspectionDate === null || inspectionDate.start === null) {
      inspectionDate = {
        startDate: null,
        endDate: null
      };

    } else {
      if (inspectionDate.start == undefined) {
        this.snackBar.open('Select date range', '', { duration: 2000, verticalPosition: "bottom" });
        return;
      }
      if (this.inspectionDate.value.start._d) {
        inspectionDate = {
          startDate: this.inspectionDate.value.start._d,
          endDate: this.inspectionDate.value.end._d
        };
      } else {
        inspectionDate = {
          startDate: this.inspectionDate.value.start,
          endDate: this.inspectionDate.value.end
        };
      }

    }
    this.toSearch = {
      inspectionDate: inspectionDate,
      inspector: inspector,
    };
    this.isShow = true;
    this.rfiInspectionService.getStatusByDate(this.toSearch).subscribe(result => {
      if (!result) {
        return
      }
      this.list1 = 0;
      this.list2 = 0;
      this.list3 = 0;
      this.list4 = 0;
      this.list5 = 0;
      this.list6 = 0;
      this.list7 = 0;
      this.list8 = 0;
      this.list9 = 0;
      this.finalList1 = ''
      this.finalList2 = ''
      this.finalList3 = ''
      this.finalList4 = ''
      this.finalList5 = ''
      this.finalList6 = ''
      this.finalList7 = ''
      this.finalList8 = ''
      this.finalList9 = ''
      var templistPercentage1 = 0;
      var templistPercentage2 = 0;
      var templistPercentage3 = 0;
      var templistPercentage4 = 0;
      var templistPercentage5 = 0;
      var templistPercentage6 = 0;
      var templistPercentage7 = 0;
      var templistPercentage8 = 0;
      var templistPercentage9 = 0;
      if (result.length > 0) {
        this.isShow = true;
        for (var i = 0; i < result.length; i++) {
          if (result[i].activatedStatus) {
            if (result[i].activatedStatus.status == "Inspection scheduled") {
              this.list1 = this.list1 + 1
            }
            if (result[i].activatedStatus.status == "Schedule accepted") {
              this.list2 = this.list2 + 1
            }
            if (result[i].activatedStatus.status == "Inspection completed. Send for review") {
              this.list3 = this.list3 + 1
            }
            if (result[i].activatedStatus.status == "Waiting for review") {
              this.list4 = this.list4 + 1
            }
            if (result[i].activatedStatus.status == "Review completed. Waiting for OTP approval") {
              this.list5 = this.list5 + 1
            }
            if (result[i].activatedStatus.status == "Approved") {
              this.list6 = this.list6 + 1
            }
            if (result[i].activatedStatus.status == "Schedule rejected") {
              this.list7 = this.list7 + 1
            }
            if (result[i].activatedStatus.status == "Cancelled") {
              this.list8 = this.list8 + 1
            }
            if (result[i].activatedStatus.status == "Inspection completed. Send for review") {
              if (result[i].rejectComment) {
                if (result[i].rejectComment.length > 0) {
                  this.list9 = this.list9 + 1;
                }
              }
            }
            this.tempTotalCount = this.list1 + this.list2 + this.list3 + this.list4 + this.list5 + this.list6 + this.list7 + this.list8 + this.list9;

            templistPercentage1 = (this.list1 * 100 / this.tempTotalCount);
            this.finalList1 = Math.round(templistPercentage1) + "%"

            templistPercentage2 = (this.list2 * 100 / this.tempTotalCount)
            this.finalList2 = Math.round(templistPercentage2) + "%"

            templistPercentage3 = (this.list3 * 100 / this.tempTotalCount);
            this.finalList3 = Math.round(templistPercentage3) + "%"

            templistPercentage4 = (this.list4 * 100 / this.tempTotalCount);
            this.finalList4 = Math.round(templistPercentage4) + "%"

            templistPercentage5 = (this.list5 * 100 / this.tempTotalCount);
            this.finalList5 = Math.round(templistPercentage5) + "%"

            templistPercentage6 = (this.list6 * 100 / this.tempTotalCount);
            this.finalList6 = Math.round(templistPercentage6) + "%"

            templistPercentage7 = (this.list7 * 100 / this.tempTotalCount);
            this.finalList7 = Math.round(templistPercentage7) + "%"

            templistPercentage8 = (this.list8 * 100 / this.tempTotalCount);
            this.finalList8 = Math.round(templistPercentage8) + "%"

            templistPercentage9 = (this.list9 * 100 / this.tempTotalCount);
            this.finalList9 = Math.round(templistPercentage9) + "%"

          }
        }

        /* this.list1=result[0].Inspectionscheduled;
        this.list2=result[0].Scheduleaccepted;
        this.list3=result[0].Inspectioncompleted;
        this.list4=result[0].Waitingforreview;
        this.list9=result[0].Reviewerrejected;
        this.list5=result[0].Reviewcompleted;
        this.list6=result[0].Approved;
        this.list7=result[0].Schedulerejected;
        this.list8=result[0].Cancelled; */

        
        this.tempTotalCount = this.list1 + this.list2 + this.list3 + this.list4 + this.list5 + this.list6 + this.list7 + this.list8 + this.list9;

        templistPercentage1 = (this.list1 * 100 / this.tempTotalCount);
        this.finalList1 = Math.round(templistPercentage1) + "%"

        templistPercentage2 = (this.list2 * 100 / this.tempTotalCount)
        this.finalList2 = Math.round(templistPercentage2) + "%"

        templistPercentage3 = (this.list3 * 100 / this.tempTotalCount);
        this.finalList3 = Math.round(templistPercentage3) + "%"

        templistPercentage4 = (this.list4 * 100 / this.tempTotalCount);
        this.finalList4 = Math.round(templistPercentage4) + "%"

        templistPercentage5 = (this.list5 * 100 / this.tempTotalCount);
        this.finalList5 = Math.round(templistPercentage5) + "%"

        templistPercentage6 = (this.list6 * 100 / this.tempTotalCount);
        this.finalList6 = Math.round(templistPercentage6) + "%"

        templistPercentage7 = (this.list7 * 100 / this.tempTotalCount);
        this.finalList7 = Math.round(templistPercentage7) + "%"

        templistPercentage8 = (this.list8 * 100 / this.tempTotalCount);
        this.finalList8 = Math.round(templistPercentage8) + "%"

        templistPercentage9 = (this.list9 * 100 / this.tempTotalCount);
        this.finalList9 = Math.round(templistPercentage9) + "%"

        var finalArray = [];
        finalArray.push(
          { title: 'Inspection scheduled', count: this.list1, percentage: this.finalList1 },
          { title: 'Schedule accepted', count: this.list2, percentage: this.finalList2 },
          { title: 'Inspection completed. Send for review', count: this.list3, percentage: this.finalList3 },
          { title: 'Waiting for review', count: this.list4, percentage: this.finalList4 },
          { title: 'Reviewer Rejected', count: this.list9, percentage: this.finalList9 },
          { title: 'Review completed. Waiting for OTP approval', count: this.list5, percentage: this.finalList5 },
          { title: 'Approved', count: this.list6, percentage: this.finalList6 },
          { title: 'Schedule rejected', count: this.list7, percentage: this.finalList7 },
          { title: 'Cancelled', count: this.list8, percentage: this.finalList8 },
          { title: 'Total', count: this.tempTotalCount, percentage: '' })

        this.temp = finalArray;
        this.temp1 = this.temp;
        this.dataSource = new MatTableDataSource(this.temp);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.temp = result;
        this.temp1 = this.temp;
        this.dataSource = new MatTableDataSource(this.temp);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }

    });

  }

}
