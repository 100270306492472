import { Component, OnInit, ViewChild } from '@angular/core';
import { QrcodeService } from '../qrcode.service';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MatBottomSheet, MatSnackBar, } from '@angular/material';
import * as moment from 'moment';
import { Validators, FormBuilder } from '@angular/forms';
import { GeoptionService } from '../../../services/geoption/geoption.service';
import { UserService } from '../../../admin/user/user.service'
import * as _ from 'underscore';
import { Options } from 'ng5-slider';
import { ReportService } from '../../../services/report/report.service';
import { environment } from '../../../../environments/environment';
import { FileService } from '../../../services/files/file.service';
interface SliderDetails {
  value: number;
  highValue: number;
  floor: number;
  ceil: number;
}

@Component({
  selector: 'app-qr-code-bulk-print',
  templateUrl: './qr-code-bulk-print.component.html',
  styleUrls: ['./qr-code-bulk-print.component.css']
})
export class QrCodeBulkPrintComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  temp = [];
  temp1 = [];
  dataSource;
  displayedColumns = [
    'check',
    'sno',
    'qrCode',
    'type',
    'inspector.inspectorName',
    'status',
    'qrScan.base64'
  ];
  filterForm: any;
  options = []; types = [];
  inspectors = [];
  users = []; usersData = [];

  value: number;
  highValue: number;
  isShow = true;

  sliders: SliderDetails =
    {
      value: 0,
      highValue: 0,
      floor: 0,
      ceil: 0
    };


  constructor(
    private qrcodeService: QrcodeService,
    private fb: FormBuilder,
    private geoptionService: GeoptionService,
    private userService: UserService,
    private reportService: ReportService,
    private fileService: FileService,
    private snackBar: MatSnackBar

  ) {
    this.filterForm = fb.group({
      'type': [null],
      'inspectors': [null],
      'used': [null],
      'notUsed': [null]
    });
  }

  ngOnInit() {
    this.getTableData();
    this.getTypes();
  }

  getUsers = () => {
    this.inspectors = [];
    this.userService.getInspectors().subscribe(res => {
      this.users = res;
      for (let i = 0; i < this.users.length; i++) {
        var filteredData = [];
        filteredData = _.filter(this.temp, ele => { return ele.inspector.ref == this.users[i]._id });
        if (filteredData.length > 0) {
          this.inspectors.push(this.users[i]);
        }
      }
    });
  }

  checkAll(data) {
    var value = data.target.checked;
    this.temp.forEach(element => {
      element.isChecked = value
    });
  }

  checkOne(data, row) {
    var value = data.target.checked;
    row.isChecked = value;
  }

  getTypes() {
    this.options = [];
    this.types = [];
    this.geoptionService.getRecordByOptionName('Type').subscribe(res => {
      this.options = res[0].option;
      this.options.forEach(element => {
        this.types.push(element.optionValue);
        this.filterForm.patchValue({ type: 'PASS' })
      });
    });
  }

  getData() {
    if (!this.filterForm.controls.type.value) {
      this.snackBar.open('Select any one type', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
    this.isShow = true;
    var inputData = { type: null, inspector: null, used: null, notUsed: null, fromRange: null, toRange: null };
    if (this.filterForm.controls.type.value) {
      inputData.type = this.filterForm.controls.type.value
    }
    if (this.filterForm.controls.inspectors.value) {
      inputData.inspector = this.filterForm.controls.inspectors.value
    }
    if (this.filterForm.controls.used.value) {
      inputData.used = this.filterForm.controls.used.value
    }
    if (this.filterForm.controls.notUsed.value) {
      inputData.notUsed = this.filterForm.controls.notUsed.value
    }
    inputData.fromRange = this.sliders.value;
    inputData.toRange = this.sliders.highValue;

    this.qrcodeService.getRecordsForBulkReport(inputData).subscribe(result => {
      this.temp = result;

      for (let i = 0; i < this.temp.length; i++) {
        if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
        if (this.temp[i].isUsed) { this.temp[i].status = 'Used' } else { this.temp[i].status = 'Not used' }
        this.temp[i].isChecked = false;
      }
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource( _.sortBy(this.temp, ['qrCode'], 'asc'));
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getNumberRange();
      // this.changeStepArray(this.temp);
    })
  }

  downloadFn() {
    this.isShow = false;
    var row = [];
    var tempData=[];
    tempData = _.sortBy(this.temp, ['qrCode'], 'asc');
    row = _.filter(tempData, ele => { return ele.isChecked === true });

    if (row.length <= 0) {
      this.isShow = true;
      this.snackBar.open('Select any one sticker', '', { duration: 2000, verticalPosition: "bottom" });
    }
    // else if (row.length > 150) {
    //   this.isShow = true;
    //   this.snackBar.open('Select maximum 150 stickers', '', { duration: 2000, verticalPosition: "bottom" });
    // } 
    else {
      this.reportService.generateQrCodeBulkReport(row).subscribe(res => {
        if (!res) {
          return;
        }
        this.isShow = true;
        var base64 = res.data;
        var blob = this.fileService._base64ToArrayBuffer(base64, 'application/pdf');

        if (environment.mobileApp) {
          this.fileService.openFileOnMobile(blob, 'QR code bulk report', 'application/pdf')
        } else {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
          } else {
            var objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);
          }
        }
      });
    }
  }


  getTableData() {
    this.qrcodeService.getQRCode().subscribe(data => {
      if (!data) {
        return;
      }
      this.temp = data;
      this.getUsers();

      for (let i = 0; i < this.temp.length; i++) {
        if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
        if (this.temp[i].isUsed) { this.temp[i].status = 'Used' } else { this.temp[i].status = 'Not used' }
        this.temp[i].isChecked = false;
      }
      var filteredData = [];
      filteredData = _.filter(this.temp, ele => { return ele.type === 'PASS' });
      this.temp = filteredData;
      this.getNumberRange();
      // this.changeStepArray(this.temp);
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource( _.sortBy(this.temp, ['qrCode'], 'asc'));
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });
  }

  getNumberRange() {
    var maxNumber = _.max(this.temp, function (o) { return o.qrNumber; });
    if (maxNumber.qrNumber) {
      this.highValue = maxNumber.qrNumber;
    } else {
      this.highValue = 0;
    }
    var minNumber = _.min(this.temp, function (o) { return o.qrNumber });
    if (minNumber.qrNumber) {
      this.value = minNumber.qrNumber;
    } else {
      this.value = 0;
    }

    if (this.value === this.highValue) {
      this.value = 0
    }

    this.sliders.value = this.value;
    this.sliders.highValue = this.highValue;
    this.sliders.floor = this.value;
    this.sliders.ceil = this.highValue;
  }

  getNumberRange2() {
    var maxNumber = _.max(this.temp, function (o) { return o.qrNumber; });
    if (maxNumber.qrNumber) {
      this.highValue = maxNumber.qrNumber;
    } else {
      this.highValue = 0;
    }
    var minNumber = _.min(this.temp, function (o) { return o.qrNumber });
    if (minNumber.qrNumber) {
      this.value = minNumber.qrNumber;
    } else {
      this.value = 0;
    }
    this.sliders.value = this.value;
    this.sliders.highValue = this.highValue;
  }



  resetFilter() {
    this.isShow = true;
    this.filterForm.patchValue({
      type: null,
      inspectors: null,
      used: null,
      notUsed: null
    })
    this.ngOnInit();
  }

  sliderOptions(): Options {
    return {
      floor: this.sliders.floor,
      ceil: this.sliders.ceil
      // showTicksValues: true,
      // stepsArray: this.changeStepArray(this.temp)
    };
  }

  changeStepArray(data) {
    if (data.length > 0) {
      data = _.sortBy(data, ['qrNumber']);
      let allDataArray = [];
      for (let i = 0; i < data.length; i++) {
        var subdata = { value: data[i].qrNumber };
        allDataArray.push(subdata);
      }
      allDataArray = _.uniq(allDataArray, o => o.value);
      return allDataArray;
    }

  }

  changeValues(data) {
    let value = data.value;
    var filteredData = [];
    filteredData = _.filter(this.temp, ele => { return ele.type === value });
    // this.changeStepArray(filteredData);

    var maxNumber = _.max(this.temp, function (o) { return o.qrNumber; });
    if (maxNumber.qrNumber) {
      this.highValue = maxNumber.qrNumber;
    } else {
      this.highValue = 0;
    }
    var minNumber = _.min(this.temp, function (o) { return o.qrNumber });
    if (minNumber.qrNumber) {
      this.value = minNumber.qrNumber;
    } else {
      this.value = 0;
    }

    if (this.value === this.highValue) {
      this.value = 0;
    }

    this.sliders.value = this.value;
    this.sliders.highValue = this.highValue;
    // this.sliders.floor = this.value;
    // this.sliders.ceil = this.highValue;
  }

}
