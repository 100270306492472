import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { RfiInspectionService } from '../../../master/rfi-inspection/rfi-inspection.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../general/dateformat-form';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';
@Component({
  selector: 'app-rfi-inspection-checklist-details',
  templateUrl: './rfi-inspection-checklist-details.component.html',
  styleUrls: ['./rfi-inspection-checklist-details.component.css'], providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class RfiInspectionChecklistDetailsComponent implements OnInit {
  categoryDetails = [];
  commonDynamicList = [];
  commonDynamicReferenceList = [];
  commonLoadTestList = [];
  referenceList = [];
  checkListDetails = [];
  inputData: any;
  inspectionDate = new FormControl('');
  isSave = false;

  permission: any;
  isWrite = false;
  user: any;
  testPerformedBy = new FormControl('');
  procedureRefNo = new FormControl('');
  refStandard = new FormControl('');
  weightCaluculation = new FormControl('');
  loadWeight = new FormControl('');
  loadTestedBy = new FormControl('');
  loadTestDetails = [];
  isColumn = false;
  isOffline: boolean;

  passCheckbox = false;
  failCheckbox = false;
  naCheckbox = false;

  constructor(private dialogRef: MatDialogRef<RfiInspectionChecklistDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any, private rfiInspectionService: RfiInspectionService, private offlineStorage: OfflineStorageService,
    private snackBar: MatSnackBar, private ngZone: NgZone) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;
    if (this.permission && this.permission.inspections && this.permission.inspections.rfi && this.permission.inspections.rfi.write) {
      this.isWrite = true;
    }
    this.isSave = this.data.data1.isSave;
    let value = localStorage.getItem('isOffline');
    if (value == 'true') {
      this.isOffline = true;
    }
    else {
      this.isOffline = false;
    }
    if (this.data) {
      if (this.data.data1) {
        this.commonDynamicList = this.data.data1.data.checklist.categoryDetails;
        this.commonDynamicReferenceList = this.data.data1.data.checklist.referenceDocuments;

      }
      let name = this.data.data1.data.checklist.name.toLowerCase();
      if (name.includes('load test')) {
        this.isColumn = true;
        if (this.data.data1.data.checklist.loadTest && this.data.data1.data.checklist.loadTest.length > 0) {
          this.testPerformedBy.setValue(this.data.data1.data.checklist.loadTest[0].testPerformedBy);
          this.procedureRefNo.setValue(this.data.data1.data.checklist.loadTest[0].procedureRefNo);
          this.refStandard.setValue(this.data.data1.data.checklist.loadTest[0].refStandard);
          this.weightCaluculation.setValue(this.data.data1.data.checklist.loadTest[0].weightCaluculation);
          this.loadWeight.setValue(this.data.data1.data.checklist.loadTest[0].loadWeight);
          this.loadTestedBy.setValue(this.data.data1.data.checklist.loadTest[0].loadTestedBy);
          this.commonLoadTestList = this.data.data1.data.checklist.loadTest[0].loadTestDetails;
        } else {
          this.loadTestDetails.push({
            weightOfLoadApplied: "",
            span: "",
            maxAllowedDeflection: "",
            actualDeflection: "",
            result: "",

            "addNew": true,
            "remove": false,
            "isActive": true,
          });
        }

      }
    }
    this.init();

    //   
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  init() {
    if (this.commonDynamicList && this.commonDynamicList.length > 0) {
      for (var i = 0; i < this.commonDynamicList.length; i++) {
        if (this.commonDynamicList[i].subItem && this.commonDynamicList[i].subItem.length > 0) {

        } else {
          this.commonDynamicList[i].subItem = [];
          this.commonDynamicList[i].subItem.push({
            "_id": this.commonDynamicList[i]._id,
            "name": '',
            "question": this.commonDynamicList[i].question,
          })
        }
      }
      for (var i = 0; i < this.commonDynamicList.length; i++) {
        this.commonDynamicList[i].addNew = false;
        this.commonDynamicList[i].remove = true;
        this.commonDynamicList[i].isActive = true;
        if (!(this.data.formType && this.data.formType === 'DOC')) {
          if (this.commonDynamicList[i].subItem && this.commonDynamicList[i].subItem.length > 0) {
            for (var j = 0; j < this.commonDynamicList[i].subItem.length; j++) {
              if (this.commonDynamicList[i].subItem != undefined && this.commonDynamicList[i].subItem.length > 0) {
                this.commonDynamicList[i].subItem[j].addNew = false;
                this.commonDynamicList[i].subItem[j].remove = true;
                this.commonDynamicList[i].subItem[j].isActive = true;
              }

              for (var k = 0; k < this.commonDynamicList[i].subItem[j].question.length; k++) {
                if (this.commonDynamicList[i].subItem[j].question != undefined && this.commonDynamicList[i].subItem[j].question.length > 0) {
                  this.commonDynamicList[i].subItem[j].question[k].addNew = false;
                  this.commonDynamicList[i].subItem[j].question[k].remove = true;
                  this.commonDynamicList[i].subItem[j].question[k].isActive = true;
                }
              }
              var last_element = this.commonDynamicList[i].subItem[j].question[this.commonDynamicList[i].subItem[j].question.length - 1];
              last_element.addNew = true;
              last_element.remove = true;
              if (this.commonDynamicList[i].subItem[j].question.length == 1) {
                last_element.remove = false;
              }
            }
            var last_element = this.commonDynamicList[i].subItem[this.commonDynamicList[i].subItem.length - 1];
            last_element.addNew = true;
            last_element.remove = true;
            if (this.commonDynamicList[i].subItem.length == 1) {
              last_element.remove = false;
            }
            this.categoryDetails.push(this.commonDynamicList[i]);


            var last_element = this.categoryDetails[this.categoryDetails.length - 1];
            last_element.addNew = true;
            last_element.remove = true;
            if (this.categoryDetails.length == 1) {
              last_element.remove = false;
            }
          }

        }
      }
    }
    if (this.commonDynamicReferenceList && this.commonDynamicReferenceList.length > 0) {
      for (var i = 0; i < this.commonDynamicReferenceList.length; i++) {
        this.commonDynamicReferenceList[i].addNew = false;
        this.commonDynamicReferenceList[i].remove = true;
        this.commonDynamicReferenceList[i].isActive = true;
        var last_element = this.commonDynamicReferenceList[i][this.commonDynamicReferenceList[i].length - 1];
        if (this.commonDynamicReferenceList[i].length == 1) {
        }
        this.referenceList.push(this.commonDynamicReferenceList[i]);
      }

      var last_element = this.referenceList[this.referenceList.length - 1];
      last_element.addNew = true;
      last_element.remove = true;
      if (this.referenceList.length == 1) {
        last_element.remove = false;
      }
    }

    if (this.commonLoadTestList && this.commonLoadTestList.length > 0) {
      for (var i = 0; i < this.commonLoadTestList.length; i++) {
        this.commonLoadTestList[i].addNew = false;
        this.commonLoadTestList[i].remove = true;
        this.commonLoadTestList[i].isActive = true;
        var last_element = this.commonLoadTestList[i][this.commonLoadTestList[i].length - 1];
        if (this.commonLoadTestList[i].length == 1) {
        }
        this.loadTestDetails.push(this.commonLoadTestList[i]);
      }

      var last_element = this.loadTestDetails[this.loadTestDetails.length - 1];
      last_element.addNew = true;
      last_element.remove = true;
      if (this.loadTestDetails.length == 1) {
        last_element.remove = false;
      }
    }

  }
  selectAllPass(event) {
    this.passCheckbox = event.checked;
    this.failCheckbox = false;
    this.naCheckbox = false;
    if (event.checked == true) {

      if (this.commonDynamicList && this.commonDynamicList.length > 0) {
        for (var i = 0; i < this.commonDynamicList.length; i++) {
          for (var j = 0; j < this.commonDynamicList[i].subItem.length; j++) {
            for (var k = 0; k < this.commonDynamicList[i].subItem[j].question.length; k++) {
              this.commonDynamicList[i].subItem[j].question[k].pass = true;
              this.commonDynamicList[i].subItem[j].question[k].fail = false;
              this.commonDynamicList[i].subItem[j].question[k].na = false;
            }
          }
        }
      }
    }
    else if (event.checked == false) {
      if (this.commonDynamicList && this.commonDynamicList.length > 0) {
        for (var i = 0; i < this.commonDynamicList.length; i++) {
          for (var j = 0; j < this.commonDynamicList[i].subItem.length; j++) {
            for (var k = 0; k < this.commonDynamicList[i].subItem[j].question.length; k++) {
              this.commonDynamicList[i].subItem[j].question[k].pass = false;
            }
          }
        }
      }
    }
  }
  selectAllFail(event) {
    this.failCheckbox = event.checked;
    this.passCheckbox = false;
    this.naCheckbox = false;

    if (event.checked == true) {
      if (this.commonDynamicList && this.commonDynamicList.length > 0) {
        for (var i = 0; i < this.commonDynamicList.length; i++) {
          for (var j = 0; j < this.commonDynamicList[i].subItem.length; j++) {
            for (var k = 0; k < this.commonDynamicList[i].subItem[j].question.length; k++) {
              this.commonDynamicList[i].subItem[j].question[k].pass = false;
              this.commonDynamicList[i].subItem[j].question[k].fail = true;
              this.commonDynamicList[i].subItem[j].question[k].na = false;
            }
          }
        }
      }
    }
    else if (event.checked == false) {
      if (this.commonDynamicList && this.commonDynamicList.length > 0) {
        for (var i = 0; i < this.commonDynamicList.length; i++) {
          for (var j = 0; j < this.commonDynamicList[i].subItem.length; j++) {
            for (var k = 0; k < this.commonDynamicList[i].subItem[j].question.length; k++) {

              this.commonDynamicList[i].subItem[j].question[k].fail = false;
            }
          }
        }
      }
    }
  }
  selectAllNA(event) {
    this.naCheckbox = event.checked;
    this.passCheckbox = false;
    this.failCheckbox = false;
    if (event.checked == true) {
      if (this.commonDynamicList && this.commonDynamicList.length > 0) {
        for (var i = 0; i < this.commonDynamicList.length; i++) {
          for (var j = 0; j < this.commonDynamicList[i].subItem.length; j++) {
            for (var k = 0; k < this.commonDynamicList[i].subItem[j].question.length; k++) {
              this.commonDynamicList[i].subItem[j].question[k].pass = false;
              this.commonDynamicList[i].subItem[j].question[k].fail = false;
              this.commonDynamicList[i].subItem[j].question[k].na = true;
            }
          }
        }
      }
    }
    else if (event.checked == false) {
      if (this.commonDynamicList && this.commonDynamicList.length > 0) {
        for (var i = 0; i < this.commonDynamicList.length; i++) {
          for (var j = 0; j < this.commonDynamicList[i].subItem.length; j++) {
            for (var k = 0; k < this.commonDynamicList[i].subItem[j].question.length; k++) {
              this.commonDynamicList[i].subItem[j].question[k].na = false;
            }
          }
        }
      }
    }
  }
  onTogglePass(event, data) {

    if (event.pass == true) {
      event.fail = false;
      event.na = false
    }
  }
  onToggleFail(event, data) {

    if (event.fail == true) {
      event.pass = false;
      event.na = false
    }
  }
  onToggleNA(event, data) {
    if (event.na == true) {
      event.pass = false;
      event.fail = false
    }
  }
  addRow(loadTestDetails, index) {
    this.loadTestDetails.push({
      weightOfLoadApplied: "",
      span: "",
      maxAllowedDeflection: "",
      actualDeflection: "",
      result: "",

      "addNew": false,
      "remove": false,
      "isActive": true,
    });
    this.loadTestDetails.forEach(function addData(row, index) {
      row.addNew = false;
      row.remove = true;
    });
    var last_element = this.loadTestDetails[this.loadTestDetails.length - 1];
    last_element.addNew = true;
    last_element.remove = true;
  };

  removeRow(loadTestDetails, index) {
    this.loadTestDetails[index].isActive = false;
    this.loadTestDetails.splice(index, 1);
    var last_element = this.loadTestDetails[this.loadTestDetails.length - 1];
    last_element.addNew = true;
    last_element.remove = true;
    if (this.loadTestDetails.length == 1) {
      last_element.remove = false;
    }
  };
  save(testPerformedBy: String,
    procedureRefNo: String,
    refStandard: String,
    weightCaluculation: String,
    loadWeight: String,
    loadTestedBy: String,
  ) {

    this.inputData = {
      testPerformedBy: testPerformedBy,
      procedureRefNo: procedureRefNo,
      refStandard: refStandard,
      weightCaluculation: weightCaluculation,
      loadWeight: loadWeight,
      loadTestedBy: loadTestedBy
    };

    var name = this.data.data1.data.checklist.name.toLowerCase();
    if (name.includes('load test')) {

      this.inputData.loadTest = [];
    }
    this.inputData.checkListDetails = this.data.data1.data,
      this.inputData.id = this.data.data1.id;
    this.inputData.chckId = this.data.data1.data.checklist.ref;
    if (name.includes('load test')) {
      this.inputData.loadTest.push({
        "testPerformedBy": this.inputData.testPerformedBy, "procedureRefNo": this.inputData.procedureRefNo,
        "refStandard": this.inputData.refStandard, "weightCaluculation": this.inputData.weightCaluculation, "loadWeight": this.inputData.loadWeight, "loadTestedBy": this.inputData.loadTestedBy, "loadTestDetails": this.loadTestDetails
      })
    }
    if (name.includes('load test')) {
      this.inputData.checkListDetails.checklist.loadTest = this.inputData.loadTest
    }

    if (this.inputData && this.inputData.checkListDetails && this.inputData.checkListDetails.checklist && this.inputData.checkListDetails.checklist.categoryDetails) {
      var data = this.inputData.checkListDetails.checklist.categoryDetails;
      for (var i = 0; i < data.length; i++) {
        for (var j = 0; j < data[i].subItem.length; j++) {
          var data2 = data[i].subItem[j].question;
          for (var j = 0; j < data2.length; j++) {
            if (!data2[j].pass && !data2[j].fail && !data2[j].na) {
              this.snackBar.open('Enter all questions', '', { duration: 2000, verticalPosition: "bottom" });
              return '';
            }
          }
        }
      }
    }
    var tempRfiData = [];
    var tempData = {
      _id: this.data.data1.id
    }
    var tempArr2 = [];
    var tempArr = [];
    var finalArray = [];
    if (this.isOffline == true) {
      this.offlineStorage.rfiHandler.getRFIById(this, tempData, function (res) {
        let _this = res.self;
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            tempRfiData.push(JSON.parse(res.data.item(i).inspectionData));
          }
        }
        if (tempRfiData && tempRfiData.length > 0) {
          for (var j = 0; j < tempRfiData[0].checkListDetails.length; j++) {
            tempArr.push(tempRfiData[0].checkListDetails[j])
          }
        }
        for (var n = 0; n < tempArr.length; n++) {
          if (tempArr[n].checklist.ref == _this.data.data1.data.checklist.ref) {
          } else {
            if (tempArr[n].checklist && tempArr[n].checklist.categoryDetails && tempArr[n].checklist.categoryDetails.length > 0) {
              for (let j = 0; j < tempArr[n].checklist.categoryDetails.length; j++) {
                if(tempArr[n].checklist.categoryDetails[j].subItem && tempArr[n].checklist.categoryDetails[j].subItem.length >0){
                }else{
                  tempArr[n].checklist.categoryDetails[j].subItem = [];
                  tempArr[n].checklist.categoryDetails[j].subItem.push({
                    "_id": tempArr[n].checklist.categoryDetails[j]._id,
                    "name": '',
                    "question": tempArr[n].checklist.categoryDetails[j].question,
                  })
                }
                
              }
            }
            tempArr2.push(tempArr[n])
          }
        }

        finalArray.push(_this.data.data1.data)
        for (var m = 0; m < tempArr2.length; m++) {
          finalArray.push(tempArr2[m])
        }
        _this.bindValue(tempRfiData, finalArray)
      });

    } else {
      var arrayDetails = [];
      var existingArray = [];
      if (this.data.data1 && this.data.data1.arrayDetails && this.data.data1.arrayDetails.length > 0) {
        arrayDetails = this.data.data1.arrayDetails;
        for (var i = 0; i < arrayDetails.length; i++) {
          if (arrayDetails[i].checklist && arrayDetails[i].checklist.categoryDetails &&
            arrayDetails[i].checklist.categoryDetails.length > 0) {
            for (let k = 0; k < arrayDetails[i].checklist.categoryDetails.length; k++) {
              if( arrayDetails[i].checklist.categoryDetails[k].subItem &&  arrayDetails[i].checklist.categoryDetails[k].subItem.length>0){
              }else{
                arrayDetails[i].checklist.categoryDetails[k].subItem = [];
                arrayDetails[i].checklist.categoryDetails[k].subItem.push({
                  "_id": arrayDetails[i].checklist.categoryDetails[k]._id,
                  "name": '',
                  "question": arrayDetails[i].checklist.categoryDetails[k].question,
                })
              }
              
            }
          }
          existingArray.push(arrayDetails[i]);
        }

      }
      this.inputData.existingArray = existingArray;
      this.rfiInspectionService.updateCategoryDetails(this.inputData).subscribe(res => {
        if (res['statusBool']) {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        }
      });
    }

  }
  bindValue(tempRfiData, finalArray) {
    tempRfiData[0].checkListDetails = finalArray;
    this.offlineStorage.rfiHandler.updateSelectedRFI(this, tempRfiData[0], function (res) {
      let _this = res.self;
      _this.ngZone.run(() => {
        setTimeout(() => {
          _this.snackBar.open('Updated successfully.', '', {
            duration: 5000,
            verticalPosition: 'bottom',
          });
        }, 0);
      });
      // _this.snackBar.open('Updated successfully', '', { duration: 2000, verticalPosition: 'bottom' });
      _this.dialogRef.close();
    });
  }
  btnClose() {
    this.dialogRef.close();
  }

}
