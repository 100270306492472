import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
// import * as ExcelJS from 'exceljs';
import * as ExcelJS from 'exceljs/dist/exceljs';
declare const ExcelJS: any;

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class ExcelService {

  data: any;

  constructor() { }

  file(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  saveAsExcelFile(data, string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', cellDates: true, cellStyles: true });
    this.file(excelBuffer, string);
  }

  saveAsExcelFileForExternal(data, companyData, countData, fileName) {
    let title = companyData.trackingSystem ? companyData.trackingSystem : '';
    let companyName = 'Company Name : ' + (companyData.companyName ? companyData.companyName : '');
    let dateField = 'Week Ending : ' + (companyData.toDate ? companyData.toDate : '');

    let workbook = new ExcelJS.Workbook();
    let wsName = 'Weekly Report';
    let worksheet = workbook.addWorksheet(wsName);
    let header = Object.keys(data[0]);

    let logoFile = companyData.logo ? companyData.logo : '';

    let logo = workbook.addImage({
      base64: logoFile,
      extension: 'png',
    });
    worksheet.addImage(logo, 'A1:B3');

    worksheet.mergeCells('C1:X1');
    var row = worksheet.getRow(1);
    row.getCell(9).value = title;
    row.font = { size: 16 }
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.fill = {
      type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
    }

    worksheet.mergeCells('C2:X2');
    var row2 = worksheet.getRow(2);
    row2.getCell(9).value = companyName;
    row2.font = { size: 15 }
    row2.alignment = { vertical: 'middle', horizontal: 'center' }
    row2.fill = {
      type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
    }

    worksheet.mergeCells('C3:X3');
    var row3 = worksheet.getRow(3);
    row3.getCell(9).value = dateField;
    row3.font = { size: 15 }
    row3.alignment = { vertical: 'middle', horizontal: 'center' }
    row3.fill = {
      type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
    }

    worksheet.addRow([]);
    worksheet.mergeCells('A4:X4');

    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ddd5d8dc' },
        // bgColor: { argb: 'FFFF0000' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    for (let i = 0; i < header.length; i++) {
      worksheet.getColumn(i + 1).width = 16;
      worksheet.getColumn(i + 1).alignment = { vertical: 'middle', horizontal: 'center' }
    }

    worksheet.getRow(5).alignment = { horizontal: 'left' }

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let qrSticker = data[i].QRCode ? data[i].QRCode : '';
        data[i].QRCode = workbook.addImage({
          base64: qrSticker,
          extension: 'png' || 'jpeg',
        });
        let j = i + 6;
        worksheet.addImage(data[i].QRCode, ('W' + j + ':' + 'W' + j));
        worksheet.addRow(Object.values(data[i])).height = 50;
      }
    }

    worksheet.addRow([]);

    let len = data.length + 7;

    if (companyData && companyData.trackingSystem &&
      companyData.trackingSystem == 'Contractor Cranes Weekly Tracking System') {

      // Pass, fail and total count
      worksheet.mergeCells('G' + (len + 1) + ':' + 'L' + (len + 1));
      var row4 = worksheet.getRow(len + 1);
      row4.getCell(7).value = 'Contractor Equipment Accepted : ' + (countData.pass ? countData.pass : '0');
      row4.font = { size: 13, color: { argb: '444ac959' } }
      row4.alignment = { vertical: 'middle', horizontal: 'center' }

      worksheet.mergeCells('G' + (len + 2) + ':' + 'L' + (len + 2));
      var row5 = worksheet.getRow(len + 2);
      row5.getCell(7).value = 'Contractor Equipment Rejected : ' + (countData.fail ? countData.fail : '0');
      row5.font = { size: 13, color: { argb: 'FFF1475B' } }
      row5.alignment = { vertical: 'middle', horizontal: 'center' }
      row5.getCell(7).fill = {
        type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
      }

      worksheet.mergeCells('G' + (len + 3) + ':' + 'L' + (len + 3));
      var row6 = worksheet.getRow(len + 3);
      row6.getCell(7).value = 'Total Contractor Cranes Inspected : ' + (countData.total ? countData.total : '0');
      row6.font = { size: 13, color: { argb: '22212CB4' } }
      row6.alignment = { vertical: 'middle', horizontal: 'center' }
    }
    else if (companyData && companyData.trackingSystem &&
      companyData.trackingSystem == 'ARAMCO Elevators & Associated Equipment Tracking System') {
      // Pass, fail and total count
      worksheet.mergeCells('G' + (len + 1) + ':' + 'L' + (len + 1));
      var row4 = worksheet.getRow(len + 1);
      row4.getCell(7).value = 'ARAMCO equipment accepted : ' + (countData.pass ? countData.pass : '0');
      row4.font = { size: 13, color: { argb: '444ac959' } }
      row4.alignment = { vertical: 'middle', horizontal: 'center' }

      worksheet.mergeCells('G' + (len + 2) + ':' + 'L' + (len + 2));
      var row5 = worksheet.getRow(len + 2);
      row5.getCell(7).value = 'ARAMCO equipment rejected : ' + (countData.fail ? countData.fail : '0');
      row5.font = { size: 13, color: { argb: 'FFF1475B' } }
      row5.alignment = { vertical: 'middle', horizontal: 'center' }
      row5.getCell(7).fill = {
        type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
      }

      worksheet.mergeCells('G' + (len + 3) + ':' + 'L' + (len + 3));
      var row6 = worksheet.getRow(len + 3);
      row6.getCell(7).value = 'Total ARAMCO equipment inspected : ' + (countData.total ? countData.total : '0');
      row6.font = { size: 13, color: { argb: '22212CB4' } }
      row6.alignment = { vertical: 'middle', horizontal: 'center' }
    }

    // Legend table
    worksheet.mergeCells('B' + len + ':' + 'E' + len);
    var tab1 = worksheet.getRow(len);
    tab1.getCell(2).value = 'Legend';
    tab1.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab1.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab1.font = { size: 14, bold: true, color: { argb: '0001579b' } }
    tab1.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getColumn(3).width = 21;

    var tab2 = worksheet.getRow(len + 1);
    tab2.getCell(2).value = 'P.I';
    tab2.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab2.getCell(3).value = 'Periodic Inspection';
    tab2.getCell(4).value = 'M';
    tab2.getCell(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab2.getCell(5).value = 'Manufacturer';

    worksheet.getRow(len + 1).getCell(2).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 1).getCell(3).font = { color: { argb: '000c0c0c' } }
    worksheet.getRow(len + 1).getCell(4).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 1).getCell(5).font = { color: { argb: '000c0c0c' } }

    tab2.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab2.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab2.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab2.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }


    var tab3 = worksheet.getRow(len + 2);
    tab3.getCell(2).value = 'Re.I';
    tab3.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab3.getCell(3).value = 'Reinspection';
    tab3.getCell(4).value = 'W';
    tab3.getCell(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab3.getCell(5).value = 'WitnessI';

    worksheet.getRow(len + 2).getCell(2).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 2).getCell(3).font = { color: { argb: '000c0c0c' } }
    worksheet.getRow(len + 2).getCell(4).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 2).getCell(5).font = { color: { argb: '000c0c0c' } }

    tab3.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab3.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab3.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab3.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }


    var tab4 = worksheet.getRow(len + 3);
    tab4.getCell(2).value = 'I.I';
    tab4.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab4.getCell(3).value = 'Initial Inspection';
    tab4.getCell(4).value = 'P';
    tab4.getCell(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab4.getCell(5).value = 'Perform';

    worksheet.getRow(len + 3).getCell(2).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 3).getCell(3).font = { color: { argb: '000c0c0c' } }
    worksheet.getRow(len + 3).getCell(4).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 3).getCell(5).font = { color: { argb: '000c0c0c' } }

    tab4.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab4.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab4.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab4.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }


    // xls download
    workbook.xlsx.writeBuffer().then((res) => {
      let blob = new Blob([res], { type: EXCEL_TYPE });
      FileSaver.saveAs(blob, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    })
  }

  saveAsExcelFileForExternalByInspector(data, companyData, countData, fileName) {
    let title = companyData.trackingSystem ? companyData.trackingSystem : '';
    let companyName = 'Company Name : ' + (companyData.companyName ? companyData.companyName : '');
    let dateField = 'Week Ending : ' + (companyData.toDate ? companyData.toDate : '');

    let workbook = new ExcelJS.Workbook();
    let wsName = 'Tracking Report';
    let worksheet = workbook.addWorksheet(wsName);
    let header = Object.keys(data[0]);

    let logoFile = companyData.logo ? companyData.logo : '';

    let logo = workbook.addImage({
      base64: logoFile,
      extension: 'png',
    });
    worksheet.addImage(logo, 'A1:B3');

    worksheet.mergeCells('C1:W1');
    var row = worksheet.getRow(1);
    row.getCell(9).value = title;
    row.font = { size: 16 }
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.fill = {
      type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
    }

    worksheet.mergeCells('C2:W2');
    var row2 = worksheet.getRow(2);
    row2.getCell(9).value = companyName;
    row2.font = { size: 15 }
    row2.alignment = { vertical: 'middle', horizontal: 'center' }
    row2.fill = {
      type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
    }

    worksheet.mergeCells('C3:W3');
    var row3 = worksheet.getRow(3);
    row3.getCell(9).value = dateField;
    row3.font = { size: 15 }
    row3.alignment = { vertical: 'middle', horizontal: 'center' }
    row3.fill = {
      type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
    }

    worksheet.addRow([]);
    worksheet.mergeCells('A4:W4');

    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ddd5d8dc' },
        // bgColor: { argb: 'FFFF0000' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    for (let i = 0; i < header.length; i++) {
      worksheet.getColumn(i + 1).width = 16;
      worksheet.getColumn(i + 1).alignment = { vertical: 'middle', horizontal: 'center' }
    }

    worksheet.getRow(5).alignment = { horizontal: 'left' }

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let qrSticker = data[i].QRCode ? data[i].QRCode : '';
        data[i].QRCode = workbook.addImage({
          base64: qrSticker,
          extension: 'png' || 'jpeg',
        });
        let j = i + 6;
        worksheet.addImage(data[i].QRCode, ('V' + j + ':' + 'V' + j));
        worksheet.addRow(Object.values(data[i])).height = 50;
      }
    }

    worksheet.addRow([]);

    let len = data.length + 7;

    if (companyData && companyData.trackingSystem &&
      companyData.trackingSystem == 'Tracking System') {

      // Pass, fail and total count
      worksheet.mergeCells('G' + (len + 1) + ':' + 'L' + (len + 1));
      var row4 = worksheet.getRow(len + 1);
      row4.getCell(7).value = 'Equipment Accepted : ' + (countData.pass ? countData.pass : '0');
      row4.font = { size: 13, color: { argb: '444ac959' } }
      row4.alignment = { vertical: 'middle', horizontal: 'center' }

      worksheet.mergeCells('G' + (len + 2) + ':' + 'L' + (len + 2));
      var row5 = worksheet.getRow(len + 2);
      row5.getCell(7).value = 'Equipment Rejected : ' + (countData.fail ? countData.fail : '0');
      row5.font = { size: 13, color: { argb: 'FFF1475B' } }
      row5.alignment = { vertical: 'middle', horizontal: 'center' }
      row5.getCell(7).fill = {
        type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
      }

      worksheet.mergeCells('G' + (len + 3) + ':' + 'L' + (len + 3));
      var row6 = worksheet.getRow(len + 3);
      row6.getCell(7).value = 'Total Cranes Inspected : ' + (countData.total ? countData.total : '0');
      row6.font = { size: 13, color: { argb: '22212CB4' } }
      row6.alignment = { vertical: 'middle', horizontal: 'center' }
    }


    // Legend table
    worksheet.mergeCells('B' + len + ':' + 'E' + len);
    var tab1 = worksheet.getRow(len);
    tab1.getCell(2).value = 'Legend';
    tab1.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab1.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab1.font = { size: 14, bold: true, color: { argb: '0001579b' } }
    tab1.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getColumn(3).width = 21;

    var tab2 = worksheet.getRow(len + 1);
    tab2.getCell(2).value = 'P.I';
    tab2.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab2.getCell(3).value = 'Periodic Inspection';
    tab2.getCell(4).value = 'M';
    tab2.getCell(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab2.getCell(5).value = 'Manufacturer';

    worksheet.getRow(len + 1).getCell(2).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 1).getCell(3).font = { color: { argb: '000c0c0c' } }
    worksheet.getRow(len + 1).getCell(4).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 1).getCell(5).font = { color: { argb: '000c0c0c' } }

    tab2.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab2.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab2.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab2.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }


    var tab3 = worksheet.getRow(len + 2);
    tab3.getCell(2).value = 'Re.I';
    tab3.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab3.getCell(3).value = 'Reinspection';
    tab3.getCell(4).value = 'W';
    tab3.getCell(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab3.getCell(5).value = 'WitnessI';

    worksheet.getRow(len + 2).getCell(2).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 2).getCell(3).font = { color: { argb: '000c0c0c' } }
    worksheet.getRow(len + 2).getCell(4).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 2).getCell(5).font = { color: { argb: '000c0c0c' } }

    tab3.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab3.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab3.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab3.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }


    var tab4 = worksheet.getRow(len + 3);
    tab4.getCell(2).value = 'I.I';
    tab4.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab4.getCell(3).value = 'Initial Inspection';
    tab4.getCell(4).value = 'P';
    tab4.getCell(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab4.getCell(5).value = 'Perform';

    worksheet.getRow(len + 3).getCell(2).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 3).getCell(3).font = { color: { argb: '000c0c0c' } }
    worksheet.getRow(len + 3).getCell(4).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 3).getCell(5).font = { color: { argb: '000c0c0c' } }

    tab4.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab4.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab4.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab4.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }


    // xls download
    workbook.xlsx.writeBuffer().then((res) => {
      let blob = new Blob([res], { type: EXCEL_TYPE });
      FileSaver.saveAs(blob, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    })
  }


  saveAsExcelFileForTrackingByInspector(data, companyData, countData, fileName) {
    let title = companyData.trackingSystem ? companyData.trackingSystem : '';
    let companyName = 'Company Name : ' + (companyData.companyName ? companyData.companyName : '');
    let dateField = 'Week Ending : ' + (companyData.toDate ? companyData.toDate : '');

    let workbook = new ExcelJS.Workbook();
    let wsName = 'Tracking Report';
    let worksheet = workbook.addWorksheet(wsName);
    let header = Object.keys(data[0]);

    let logoFile = companyData.logo ? companyData.logo : '';

    let logo = workbook.addImage({
      base64: logoFile,
      extension: 'png',
    });
    worksheet.addImage(logo, 'A1:B3');

    worksheet.mergeCells('C1:W1');
    var row = worksheet.getRow(1);
    row.getCell(9).value = title;
    row.font = { size: 16 }
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.fill = {
      type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
    }

    worksheet.mergeCells('C2:W2');
    var row2 = worksheet.getRow(2);
    row2.getCell(9).value = companyName;
    row2.font = { size: 15 }
    row2.alignment = { vertical: 'middle', horizontal: 'center' }
    row2.fill = {
      type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
    }

    worksheet.mergeCells('C3:W3');
    var row3 = worksheet.getRow(3);
    row3.getCell(9).value = dateField;
    row3.font = { size: 15 }
    row3.alignment = { vertical: 'middle', horizontal: 'center' }
    row3.fill = {
      type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
    }

    worksheet.addRow([]);
    worksheet.mergeCells('A4:W4');

    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ddd5d8dc' },
        // bgColor: { argb: 'FFFF0000' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    for (let i = 0; i < header.length; i++) {
      worksheet.getColumn(i + 1).width = 16;
      worksheet.getColumn(i + 1).alignment = { vertical: 'middle', horizontal: 'center' }
    }

    worksheet.getRow(5).alignment = { horizontal: 'left' }

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let qrSticker = data[i].QRCode ? data[i].QRCode : '';
        data[i].QRCode = workbook.addImage({
          base64: qrSticker,
          extension: 'png' || 'jpeg',
        });
        let j = i + 6;
        worksheet.addImage(data[i].QRCode, ('V' + j + ':' + 'V' + j));
        worksheet.addRow(Object.values(data[i])).height = 50;
      }
    }

    worksheet.addRow([]);

    let len = data.length + 7;

    if (companyData && companyData.trackingSystem &&
      companyData.trackingSystem == 'Tracking System') {

      // Pass, fail and total count
      worksheet.mergeCells('G' + (len + 1) + ':' + 'L' + (len + 1));
      var row4 = worksheet.getRow(len + 1);
      row4.getCell(7).value = 'Equipment Accepted : ' + (countData.pass ? countData.pass : '0');
      row4.font = { size: 13, color: { argb: '444ac959' } }
      row4.alignment = { vertical: 'middle', horizontal: 'center' }

      worksheet.mergeCells('G' + (len + 2) + ':' + 'L' + (len + 2));
      var row5 = worksheet.getRow(len + 2);
      row5.getCell(7).value = 'Equipment Rejected : ' + (countData.fail ? countData.fail : '0');
      row5.font = { size: 13, color: { argb: 'FFF1475B' } }
      row5.alignment = { vertical: 'middle', horizontal: 'center' }
      row5.getCell(7).fill = {
        type: 'pattern', pattern: 'solid', fgColor: { argb: '' }
      }

      worksheet.mergeCells('G' + (len + 3) + ':' + 'L' + (len + 3));
      var row6 = worksheet.getRow(len + 3);
      row6.getCell(7).value = 'Total Cranes Inspected : ' + (countData.total ? countData.total : '0');
      row6.font = { size: 13, color: { argb: '22212CB4' } }
      row6.alignment = { vertical: 'middle', horizontal: 'center' }
    }


    // Legend table
    worksheet.mergeCells('B' + len + ':' + 'E' + len);
    var tab1 = worksheet.getRow(len);
    tab1.getCell(2).value = 'Legend';
    tab1.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab1.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab1.font = { size: 14, bold: true, color: { argb: '0001579b' } }
    tab1.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getColumn(3).width = 21;

    var tab2 = worksheet.getRow(len + 1);
    tab2.getCell(2).value = 'P.I';
    tab2.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab2.getCell(3).value = 'Periodic Inspection';
    tab2.getCell(4).value = 'M';
    tab2.getCell(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab2.getCell(5).value = 'Manufacturer';

    worksheet.getRow(len + 1).getCell(2).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 1).getCell(3).font = { color: { argb: '000c0c0c' } }
    worksheet.getRow(len + 1).getCell(4).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 1).getCell(5).font = { color: { argb: '000c0c0c' } }

    tab2.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab2.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab2.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab2.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }


    var tab3 = worksheet.getRow(len + 2);
    tab3.getCell(2).value = 'Re.I';
    tab3.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab3.getCell(3).value = 'Reinspection';
    tab3.getCell(4).value = 'W';
    tab3.getCell(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab3.getCell(5).value = 'WitnessI';

    worksheet.getRow(len + 2).getCell(2).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 2).getCell(3).font = { color: { argb: '000c0c0c' } }
    worksheet.getRow(len + 2).getCell(4).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 2).getCell(5).font = { color: { argb: '000c0c0c' } }

    tab3.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab3.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab3.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab3.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }


    var tab4 = worksheet.getRow(len + 3);
    tab4.getCell(2).value = 'I.I';
    tab4.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab4.getCell(3).value = 'Initial Inspection';
    tab4.getCell(4).value = 'P';
    tab4.getCell(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddd5d8dc' } }
    tab4.getCell(5).value = 'Perform';

    worksheet.getRow(len + 3).getCell(2).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 3).getCell(3).font = { color: { argb: '000c0c0c' } }
    worksheet.getRow(len + 3).getCell(4).font = { bold: true, color: { argb: '00004e52' } }
    worksheet.getRow(len + 3).getCell(5).font = { color: { argb: '000c0c0c' } }

    tab4.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab4.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab4.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    tab4.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }


    // xls download
    workbook.xlsx.writeBuffer().then((res) => {
      let blob = new Blob([res], { type: EXCEL_TYPE });
      FileSaver.saveAs(blob, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    })
  }
}
