import { Component, OnInit,Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar
} from '@angular/material';
import { ScheduleInspectionService } from '../../master/scheduleInspection/schedule-inspection.service';
@Component({
  selector: 'app-internal-entry-report-form',
  templateUrl: './internal-entry-report-form.component.html',
  styleUrls: ['./internal-entry-report-form.component.css']
})
export class InternalEntryReportFormComponent implements OnInit {
  internalEntryForm: any;

  user: any;
  rowId = null;
  isColumn=true;
  constructor(
    private fb: FormBuilder, 
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private scheduleInspectionService: ScheduleInspectionService,
    private dialogRef: MatDialogRef<InternalEntryReportFormComponent>,  
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.internalEntryForm = fb.group({
      'esvNo': [null],
      'sesNo': [null],
      'invoiceNo': [null],
      'invoiceValue': [null],
      'paymentStatus': [null],
    });
   }

  ngOnInit() {
    if(this.data){
      if(this.data.table=='table2'){
        this.isColumn=false;
      }
      this.rowId = this.data._id;
     this.patchValue();
    }
    this.user = this.getFromLocalStorage('userDet');
  }
  get f() {
    return this.internalEntryForm.controls;
  }
  patchValue() {
   
    this.internalEntryForm.patchValue({
      esvNo: this.data.esvNo,
      sesNo: this.data.sesNo,
      invoiceNo: this.data.invoiceNo,
      invoiceValue: this.data.invoiceValue,
      paymentStatus:this.data.paymentStatus
    });
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  save() {
    var inputData = {
      esvNo: this.internalEntryForm.controls.esvNo.value,
      sesNo: this.internalEntryForm.controls.sesNo.value,
      invoiceNo: this.internalEntryForm.controls.invoiceNo.value,
      invoiceValue: this.internalEntryForm.controls.invoiceValue.value,
      paymentStatus: this.internalEntryForm.controls.paymentStatus.value,
      rowId: null,
      updatedBy:null
      
    }
   
    inputData.updatedBy = this.user.data.name;
    inputData.rowId = this.rowId;

    if (this.rowId) {
      this.scheduleInspectionService.updateInternalEntry(inputData).subscribe(result => {
        if (result['statusBool']) {
          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } else {
          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      
      });
    } 
    
  }
  btnClose() {
      this.dialogRef.close();
    
  }

}
