import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ClientService } from '../client.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.css']
})
export class ClientFormComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  inputData: any;
  user: any;

  name = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required]);
  description = new FormControl('');

  _id: string;
  constructor(
    private dialog: MatDialog,
    private clientService: ClientService,
    private dialogRef: MatDialogRef<ClientFormComponent>,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    if (Object.keys(this.data).length !== 0) {
      this.name.setValue(this.data.name);
      this.email.setValue(this.data.email);
      this.description.setValue(this.data.description);
    }
  }
  save(
    name: String,
    email: String,
    description: String,
  ): void {
    if (!name || !email) {
      return;
    }
    this.inputData = {
      name: name,
      email: email,
      description: description
    };
    if (Object.keys(this.data).length !== 0) {
      this.inputData._id = this.data._id;
      this.inputData.updatedBy = this.user.data.name;
      this.clientService.updateRecord(this.inputData).subscribe(res => {
        if (res[0]['statusBool']) {
          this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } 
      }, err => {
        if (err.error[0].statusHttp == 412) {
          this.snackBar.open(err.error[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else {
      this.inputData.createdBy = this.user.data.name;
      this.clientService.save(this.inputData).subscribe(res => {
        if (res['statusBool']) {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } 
      }, err => {
        if (err.error.statusHttp == 412) {
          this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }
  }

  getErrorCategory() {
    return this.name.hasError('required')
      ? 'Name is required'
      : '';
  }
  getErrorEmail(){
    return this.email.hasError('required')
      ? 'Email is required'
      : '';
  }
  btnClose() {
    if (
      this.name.dirty ||
      this.email.dirty ||
      this.description.dirty
    ) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: false,
        hasBackdrop: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
