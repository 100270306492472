import { Component, OnInit, ViewChild, Inject, ElementRef, NgZone } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { EquipmentService } from '../../equipment/equipment.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Observable } from 'rxjs';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../general/dateformat-form';
import { RfiInspectionService } from '../../../master/rfi-inspection/rfi-inspection.service';
import { EquipmentFormComponent } from '../../equipment/equipment-form/equipment-form.component';
import { SelectEquipmentComponent } from '../../../master/scheduleInspection/select-equipment/select-equipment.component';
import { RfiInspectionChecklistPopupComponent } from '../../../master/rfi-inspection/rfi-inspection-checklist-popup/rfi-inspection-checklist-popup.component';
import { RfiInspectionChecklistDetailsComponent } from '../../../master/rfi-inspection/rfi-inspection-checklist-details/rfi-inspection-checklist-details.component';
import { ReportService } from '../../../services/report/report.service';
import { ScheduleInspectionReportService } from '../../../master/scheduleInspection/schedule-inspection-report.service';
import * as moment from 'moment';
import { FileService } from '../../../services/files/file.service';
import { environment } from '../../../../environments/environment';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';
import { RfiInspectionEquipmentPopupComponent } from '../rfi-inspection-equipment-popup/rfi-inspection-equipment-popup.component';
import { SettingService } from '../../../services/setting/setting.service';
import { EquipmentCompanyService } from '../../../master/equipmentCompany/equipment-company.service';
import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
declare var window: any;
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-rfi-inspection-checklist',
  templateUrl: './rfi-inspection-checklist.component.html',
  styleUrls: ['./rfi-inspection-checklist.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class RfiInspectionChecklistComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  reportNo = new FormControl('');
  equipmentNo = new FormControl('', [Validators.required]);
  location = new FormControl('');
  equipmentSerialNumber = new FormControl('');
  model = new FormControl('');
  capacity = new FormControl('');
  manufacturer = new FormControl('');
  type = new FormControl('');
  manualSticker = new FormControl('');
  inspectionDate = new FormControl('');
  equipmentCompanyRefID = new FormControl('');
  projectTitle = new FormControl('', [Validators.required]);
  // name = new FormControl('');
  equID: any;
  categoryDetails = [];
  commonDynamicList = [];
  commonDynamicReferenceList = [];
  referenceList = [];
  checkListDetails = [];
  inputData: any;
  selectedVal = 'Yes';
  selectedValNA = 'NA';
  id: any;
  yourFlag = true;
  rfiDataId: any;
  data1: any;
  dataSource;

  equipmentCompanies: any;
  anotherEquipmentCompanies: any;
  displayedColumns = [
    'checklist.name',
    'checkListDetails',
    'report',
    'delete'

  ];
  temp = [];
  temp1 = [];
  isSave = true;
  status: any;
  clearButton: Boolean = false;

  permission: any;
  isWrite = false;
  user: any;
  isOffline: boolean;
  tempReportData = [];
  tmpClientName: any;
  showOfflineReport = false;
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private reportService: ReportService,
    private dialogRef: MatDialogRef<RfiInspectionChecklistComponent>,
    private bottomSheet: MatBottomSheet, private equipmentService: EquipmentService,
    private snackBar: MatSnackBar, private rfiInspectionService: RfiInspectionService,
    @Inject(MAT_DIALOG_DATA) private data: any, private ngZone: NgZone,
    private fileService: FileService, private offlineStorage: OfflineStorageService,
    private scheduleInspectionReportService: ScheduleInspectionReportService,
    private settingService: SettingService, private equipmentCompanyService: EquipmentCompanyService
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.getCompanyName();
    this.permission = this.user.data.permission;
    if (this.permission && this.permission.inspections && this.permission.inspections.rfi && this.permission.inspections.rfi.write) {
      this.isWrite = true;
    }
    let value = localStorage.getItem('isOffline');
    if (value == 'true') {
      this.isOffline = true;
      this.displayedColumns = [
        'checklist.name',
        'checkListDetails',
        'report',
        'delete'
      ];
    }
    else {
      this.isOffline = false;
      this.displayedColumns = [
        'checklist.name',
        'checkListDetails',
        'report',
        'delete'
      ];
    }
    if (Object.keys(this.data).length !== 0) {
      this.reportNo.setValue(this.data.data.inspectionSerialNumber);
      this.projectTitle.setValue(this.data.data.projectTitle);

      // var inspectionDate = this.convertISO(this.data.data.inspectionDate)
      var inspectionDate = moment(new Date(this.data.data.inspectionDate)).format('DD/MM/YYYY');
      this.inspectionDate.setValue(inspectionDate);
      if (this.data.data.equipment && this.data.data.equipment.equipmentNumber !== undefined && this.data.data.equipment.equipmentNumber !== null && this.data.data.equipment.equipmentNumber !== '') {
        this.equipmentNo.setValue(this.data.data.equipment.equipmentNumber);
        this.getEquipmentDeails();
      }
      this.commonDynamicList = this.data.data.checkListDetails.categoryDetails;
      this.commonDynamicReferenceList = this.data.data.checkListDetails.referenceDocuments;

      if (this.isOffline) {
        if (this.data.data._id) {

          this.offlineStorage.rfiReporthandler.getAllReportData(this, function (res) {
            let _this = res.self, subData = [];

            if (res && res.data && res.data.length > 0) {
              for (let i = 0; i < res.data.length; i++) {
                let data = JSON.parse(res.data.item(i).rfiReportData);
                subData.push(data);

              }
              if (subData.length > 0) {

                for (let k = 0; k < subData.length; k++) {
                  if (subData[k].scheduleIspectionId && subData[k].scheduleIspectionId.ref &&
                    subData[k].scheduleIspectionId.ref == _this.data.data._id) {
                    _this.tempReportData = subData[k];
                  }
                }
              }
            }
          });
        }
      }
      else {
        if (this.data.data._id) {
          let data = {
            srId: this.data.data._id
          };

          this.scheduleInspectionReportService.getScheduleReport(data).subscribe(result => {
            if (result.length > 0) {
              this.tempReportData = result;
            }
          });
        }
      }
      this.init();
    }
    // this.yourFlag = true;
    this.showtable();
    this.enableSave();
    this.getEquipmentDeails();
    this.checkStatus();
    this.getEquipmentCompanies();
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  enableSave() {
    this.status = this.data.data.activatedStatusName;
    if (this.status === 'Schedule accepted' || this.status === 'Inspection completed. Send for review') {
      this.displayedColumns = [
        'checklist.name',
        'checkListDetails',
        'report',
        'delete'
      ];
      this.isSave = true;
    } else {
      this.displayedColumns = [
        'checklist.name',
        'checkListDetails',
        'report',
      ];
      this.isSave = false;
    }
  }

  checkStatus() {
    if (this.status == 'Inspection scheduled' || this.status == 'Schedule accepted' ||
      this.status == 'Inspection completed. Send for review' || this.status == 'Waiting for review') {
      this.clearButton = true;
    }
  }

  init() {
    if (this.commonDynamicList && this.commonDynamicList.length > 0) {
      for (var i = 0; i < this.commonDynamicList.length; i++) {
        this.commonDynamicList[i].addNew = false;
        this.commonDynamicList[i].remove = true;
        this.commonDynamicList[i].isActive = true;

        if (!(this.data.formType && this.data.formType === 'DOC')) {
          for (var j = 0; j < this.commonDynamicList[i].question.length; j++) {
            if (this.commonDynamicList[i].question != undefined && this.commonDynamicList[i].question.length > 0) {
              for (var k = 0; k < this.commonDynamicList[i].question.length; k++) {
                if (this.commonDynamicList[i].question[k].passFail == 'true') {
                  this.yourFlag = true;
                }
                else {
                  this.yourFlag = false;
                }
              }
              this.commonDynamicList[i].question[j].addNew = false;
              this.commonDynamicList[i].question[j].remove = true;
              this.commonDynamicList[i].question[j].isActive = true;
            }
          }

          var last_element = this.commonDynamicList[i].question[this.commonDynamicList[i].question.length - 1];
          last_element.addNew = true;
          last_element.remove = true;
          if (this.commonDynamicList[i].question.length == 1) {
            last_element.remove = false;
          }
        }
        this.categoryDetails.push(this.commonDynamicList[i]);
      }

      var last_element = this.categoryDetails[this.categoryDetails.length - 1];
      last_element.addNew = true;
      last_element.remove = true;
      if (this.categoryDetails.length == 1) {
        last_element.remove = false;
      }
    }
    if (this.commonDynamicReferenceList && this.commonDynamicReferenceList.length > 0) {
      for (var i = 0; i < this.commonDynamicReferenceList.length; i++) {
        this.commonDynamicReferenceList[i].addNew = false;
        this.commonDynamicReferenceList[i].remove = true;
        this.commonDynamicReferenceList[i].isActive = true;
        var last_element = this.commonDynamicReferenceList[i][this.commonDynamicReferenceList[i].length - 1];
        if (this.commonDynamicReferenceList[i].length == 1) {
        }
        this.referenceList.push(this.commonDynamicReferenceList[i]);
      }

      var last_element = this.referenceList[this.referenceList.length - 1];
      last_element.addNew = true;
      last_element.remove = true;
      if (this.referenceList.length == 1) {
        last_element.remove = false;
      }
    }

  }
  getEquipmentDeails() {
    var equipmentData = [];
    if (this.isOffline == true) {
      this.offlineStorage.equipmentHandler.getExistingEquipmentByCategoryID(this, function (res) {
        let _this = res.self;

        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let subData = JSON.parse(res.data.item(i).equipmentData);
            subData.pid = res.data.item(i).pid;
            equipmentData.push(subData);
          }
          if (equipmentData.length > 0) {
            equipmentData.forEach(element => {
              if (_this.data.data.equipment) {
                if (_this.data.data.equipment.ref === element._id || _this.data.data.equipment.ref === element.pid) {
                  _this.equipmentNo.setValue(element.equipmentNumber);
                  _this.equipmentSerialNumber.setValue(element.equipmentSerialNumber);
                  _this.equipmentCompanyRefID.setValue(element.equipmentCompanyRefID);
                  if (element.manufacture && element.manufacture.name) {
                    _this.manufacturer.setValue(element.manufacture.name);
                  }
                  if (element.location && element.location.name) {
                    _this.location.setValue(element.location.name);
                  }
                  _this.model.setValue(element.model);
                  _this.capacity.setValue(element.capacity);
                  if (element.type && element.type.name) {
                    _this.type.setValue(element.type.name);
                  } if (_this.tempReportData.length > 0 && _this.tempReportData[0].updatedOldStickerNo) {
                    _this.manualSticker.setValue(_this.tempReportData[0].updatedOldStickerNo)
                  } else if ((_this.tempReportData.length > 0 && !_this.tempReportData[0].updatedOldStickerNo) && (!element.manualSticker)) {
                    _this.manualSticker.setValue("")
                  } else {
                    _this.manualSticker.setValue(element.updatedPreviousStickerNumber ? element.updatedPreviousStickerNumber : element.manualSticker);
                  }
                  // _this.manualSticker.setValue(element.updatedPreviousStickerNumber ? element.updatedPreviousStickerNumber : element.manualSticker);
                  // _this.checkPreviousSticker(element);
                  _this.equID = element._id !== undefined && element._id !== null ? element._id : element.pid
                }
              }
            });
          }
        }
      });
    } else {
      this.equipmentService.getRecords().subscribe(data => {
        if (data.length > 0) {
          data.forEach(element => {
            if (this.data.data.equipment && this.data.data.equipment.ref && this.data.data.equipment.ref === element._id) {
              this.equipmentNo.setValue(element.equipmentNumber);
              this.equipmentSerialNumber.setValue(element.equipmentSerialNumber);
              this.equipmentCompanyRefID.setValue(element.equipmentCompanyRefID);
              if (element.manufacture && element.manufacture.name) {
                this.manufacturer.setValue(element.manufacture.name)
              }
              if (element.location && element.location.name) {
                this.location.setValue(element.location.name)
              }
              this.model.setValue(element.model)
              this.capacity.setValue(element.capacity)
              if (element.type && element.type.name) {
                this.type.setValue(element.type.name)
              }
              // if (this.tempReportData.length > 0 && this.tempReportData[0].updatedOldStickerNo) {
              //   this.manualSticker.setValue(this.tempReportData[0].updatedOldStickerNo)
              // } else if ((this.tempReportData.length > 0 && !this.tempReportData[0].updatedOldStickerNo) && (!element.manualSticker)) {
              //   this.manualSticker.setValue("")
              // } else {
                this.manualSticker.setValue(element.updatedPreviousStickerNumber ? element.updatedPreviousStickerNumber : element.manualSticker);
              // }
              // this.checkPreviousSticker(element);
              this.equID = element._id
            }
          });
        }
      });
    }
  }
  getEquipmentCompanies() {
    if (this.isOffline == true) {
      this.offlineStorage.equipmentCompanyhandler.getEquipmentCompany(this, function (res) {
        let _this = res.self;
        var tempEquipmentCompanyData = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            tempEquipmentCompanyData.push(JSON.parse(res.data.item(i).equipmentCompanyData));
            _this.bindValue(tempEquipmentCompanyData);

          }
        }
      });
    } else {
      this.equipmentCompanyService.getEquipmentCompany().subscribe(result => {
        this.equipmentCompanies = result;
        this.anotherEquipmentCompanies = this.equipmentCompanies;
      });
    }
  }

  filterEquipmentCompanies(val) {
    val = val.toLowerCase();
    this.anotherEquipmentCompanies = this.equipmentCompanies.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.abbr.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }

  bindValue(data) {
    this.equipmentCompanies = data;
    this.anotherEquipmentCompanies = this.equipmentCompanies;
  }
  updateEquipmentCompany(equipmentCompanyRefID, manualSticker) {
    let inputData = {
      equipmentCompanyRefID: equipmentCompanyRefID,
      equId: this.equID,
      manualSticker: manualSticker,
      reportNo: this.reportNo.value
    }
    if (equipmentCompanyRefID !== undefined && equipmentCompanyRefID !== null && equipmentCompanyRefID !== '') {
      if (this.isOffline == true) {
        let data1 = {
          _id: this.equID
        }
        let filteredCompany = this.equipmentCompanies.filter(ele => ele._id === equipmentCompanyRefID);
        if (filteredCompany.length > 0) {
          var tempEquipmentCompany = filteredCompany[0]._id;
        }
        this.offlineStorage.equipmentHandler.getEquipmentById(this, data1, function (res) {
          let _this = res.self, temp = [];
          if (res && res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              let subData = JSON.parse(res.data.item(i).equipmentData);
              subData.pid = res.data.item(i).pid;
              temp.push(subData);
            }
            if (temp.length > 0) {
              temp[0].equipmentCompanyRefID = tempEquipmentCompany;
              temp[0].manualSticker = manualSticker;
              temp[0].updatedPreviousStickerNumber = manualSticker;
            }
            _this.offlineStorage.equipmentHandler.updateEquipmentCompanyById(_this, temp[0], function (res) {
              let _this = res.self;
              if (res) {
                let id =_this.data.data._id;
                _this.offlineStorage.rfiReporthandler.getRFIReportDataByRFIId(_this, id, function (res1) {
                  let _this = res1.self, temp1 = [];
                  if (res1 && res1.data && res1.data.length > 0) {
                    for (let i = 0; i < res1.data.length; i++) {
                      temp1.push(JSON.parse(res1.data.item(i).rfiReportData));
                    }
                  }

                  if (temp1.length > 0) {
                    temp1[0].updatedOldStickerNo = manualSticker;
                    _this.offlineStorage.rfiReporthandler.updateRFIReportById(_this, temp1[0], function (res2) {
                      _this.ngZone.run(() => {
                        setTimeout(() => {
                          _this.snackBar.open('Updated successfully', '', {
                            duration: 5000,
                            verticalPosition: 'bottom',
                          });
                        }, 0);
                      })
                    });
                  } else {
                    _this.ngZone.run(() => {
                      setTimeout(() => {
                        _this.snackBar.open('Updated successfully', '', {
                          duration: 5000,
                          verticalPosition: 'bottom',
                        });
                      }, 0);
                    })
                  }
                });
                // _this.ngZone.run(() => {
                //   setTimeout(() => {
                //     _this.snackBar.open(res.data, '', {
                //       duration: 5000,
                //       verticalPosition: 'bottom',
                //     });
                //   }, 0);
                // });
              }
            });
          }
        });

      } else {
        this.equipmentService.updateEquipmentCompanyByEquipmentId(inputData).subscribe(result => {
          if (result['statusBool']) {
            this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
            this.ngOnInit();
          }
        });
      }
    } else {
      this.snackBar.open('Please select inspection company', '', { duration: 2000 });
    }
  }
  // checkPreviousSticker(equipData) {
  //   if (this.tempReportData.length > 0) {
  //     let passData = [], failData = [];
  //     if (equipData.previousSticker.length > 1) {
  //       equipData.previousSticker.forEach(element => {
  //         if ((element.number).includes("PASS")) {
  //           passData.push(element);
  //         }
  //         else {
  //           failData.push(element);
  //         }
  //       });

  //       for (let i = 0; i < equipData.previousSticker.length; i++) {
  //         if (equipData.previousSticker[i].ref == this.tempReportData[0].oldStickerNumber) {
  //           if (equipData.previousSticker[i].number.includes("PASS")) {
  //             this.setPreviousData(passData, this.tempReportData[0].oldStickerNumber,equipData.manualSticker);
  //           }
  //           else if (equipData.previousSticker[i].number.includes("FAIL")) {
  //             this.setPreviousData(failData, this.tempReportData[0].oldStickerNumber,equipData.manualSticker);
  //           }
  //         }
  //       }
  //     }
  //     else if (this.tempReportData[0].updatedOldStickerNo || equipData.manualSticker){
  //       this.manualSticker.setValue(this.tempReportData[0].updatedOldStickerNo ? this.tempReportData[0].updatedOldStickerNo : equipData.manualSticker);
  //     }
  //     else {
  //       this.manualSticker.setValue('');
  //     }
  //   }
  // }

  // setPreviousData(data, oldSticker,manualSticker) {
  //   for (let j = 0; j < data.length; j++) {
  //     if (data[j].ref == oldSticker) {
  //       if (j > 0) {
  //         let setVal = data[j - 1].number ? data[j - 1].number : '';
  //         this.manualSticker.setValue(setVal);
  //       } else if(manualSticker) {
  //         this.manualSticker.setValue(manualSticker);
  //       }
  //       else {
  //         this.manualSticker.setValue('');
  //       }
  //     }
  //   }
  // }

  selectFromExisting() {
    let row = this.data.data;
    const dialogRef = this.dialog.open(SelectEquipmentComponent, {
      data: { data: row, },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.patchEquipmentValue('update');
    });
  }
  addExistingChecklist() {
    let row = this.data.data;
    row.isSave = this.isSave;
    const dialogRef = this.dialog.open(RfiInspectionChecklistPopupComponent, {
      data: { data: row, },
      disableClose: false,
      width: '600px',
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.showtable();
    });
  }
  showtable() {
    var data = {
      _id: this.data.data._id
    }
    var rfiData = [];
    if (this.isOffline == true) {
      this.offlineStorage.rfiHandler.getRFIById(this, data, function (res) {
        let _this = res.self;
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            rfiData.push(JSON.parse(res.data.item(i).inspectionData));
          }

        }
        _this.temp = rfiData;
        _this.temp1 = _this.temp;
        const res1 = [];

        for (var i = 0; i < _this.temp.length; i++) {
          for (var j = 0; j < _this.temp[i].checkListDetails.length; j++) {
            res1.push(_this.temp[i].checkListDetails[j]);
          }

        }
        _this.dataSource = new MatTableDataSource(res1);
        _this.dataSource.paginator = _this.paginator;
        _this.dataSource.sort = _this.sort;
      });
    }
    else {
      this.rfiInspectionService.getRFIById(data).subscribe(data => {

        this.temp = data;
        this.temp1 = this.temp;
        const res = [];

        for (var i = 0; i < this.temp.length; i++) {
          for (var j = 0; j < this.temp[i].checkListDetails.length; j++) {
            res.push(this.temp[i].checkListDetails[j]);
          }

        }
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      });
    }
  }
  btnAddNewEquipment() {
    var data = {
      "id": this.data.data._id,
      "data": this.data.data,
      "report": "ScheduleReport"
    }
    const dialogRef = this.dialog.open(EquipmentFormComponent, {
      data: { data: data, },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.patchEquipmentValue('new');
      }
    });
  }

  clearEquipmentDetails() {
    if (this.equipmentNo.value) {
      const dialogRef = this.dialog.open(RfiInspectionEquipmentPopupComponent, {
        data: this.data.data,
        disableClose: false,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.equipmentNo.reset();
          this.location.reset();
          this.manufacturer.reset();
          this.model.reset();
          this.capacity.reset();
          this.type.reset();
          this.manualSticker.reset();
          this.equipmentCompanyRefID.reset();
          this.equipmentSerialNumber.reset();
        }
      });
    }
  }

  checkList(data) {
    var data1 = {
      "id": this.data.data._id,
      "data": data,
      "isSave": this.isSave,
      "arrayDetails": this.temp[0].checkListDetails
    }
    const dialogRef = this.dialog.open(RfiInspectionChecklistDetailsComponent, {
      data: { data1 },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.patchEquipmentValue('new');
    });
  }
  patchEquipmentValue(string) {
    var data = {
      _id: this.data.data._id
    }
    var tempRfiData = [];
    var equipmentData = [];
    if (this.isOffline == true) {
      this.offlineStorage.rfiHandler.getRFIById(this, data, function (res) {
        let _this = res.self;

        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            tempRfiData.push(JSON.parse(res.data.item(i).inspectionData));
          }
        }
        if (string === 'new') {
          _this.offlineStorage.equipmentHandler.getExistingEquipmentToPatchNew(_this, function (res) {
            if (res && res.data && res.data.length > 0) {
              for (let i = 0; i < res.data.length; i++) {
                let subData = JSON.parse(res.data.item(i).equipmentData);
                subData.pid = res.data.item(i).pid;
                equipmentData.push(subData);
                if (equipmentData.length > 0) {
                  equipmentData.forEach(element => {
                    // if (tempRfiData[0].equipment) {
                    // if (tempRfiData[0].equipment.ref === element._id || tempRfiData[0].equipment.ref === element.pid) {
                    _this.equipmentNo.setValue(element.equipmentNumber);
                    _this.manufacturer.setValue(element.manufacture.name);
                    _this.equipmentCompanyRefID.setValue(element.equipmentCompanyRefID);
                    _this.equipmentSerialNumber.setValue(element.equipmentSerialNumber);
                    _this.location.setValue(element.location.name);
                    _this.model.setValue(element.model);
                    _this.capacity.setValue(element.capacity);
                    if (element.type && element.type.name) {
                      _this.type.setValue(element.type.name);
                    }
                    if (_this.tempReportData.length > 0 && _this.tempReportData[0].updatedOldStickerNo) {
                      _this.manualSticker.setValue(_this.tempReportData[0].updatedOldStickerNo)
                    } else if ((_this.tempReportData.length > 0 && !_this.tempReportData[0].updatedOldStickerNo) && (!element.manualSticker)) {
                      _this.manualSticker.setValue("")
                    } else {
                      _this.manualSticker.setValue(element.updatedPreviousStickerNumber ? element.updatedPreviousStickerNumber : element.manualSticker);
                    }
                    // _this.manualSticker.setValue(element.updatedPreviousStickerNumber ? element.updatedPreviousStickerNumber : element.manualSticker);
                    // _this.checkPreviousSticker(element);
                    _this.equID = element._id !== undefined && element._id !== null ? element._id : element.pid
                    // }
                    // }
                  });
                }
              }
            }
          });
        } else {
          _this.offlineStorage.equipmentHandler.getExistingEquipmentToPatch(_this, function (res) {
            if (res && res.data && res.data.length > 0) {
              for (let i = 0; i < res.data.length; i++) {
                equipmentData.push(JSON.parse(res.data.item(i).equipmentData));
                if (equipmentData.length > 0) {
                  equipmentData.forEach(element => {
                    if (tempRfiData[0].equipment) {
                      if (tempRfiData[0].equipment.ref === element._id || tempRfiData[0].equipment.ref === element.pid) {
                        _this.equipmentNo.setValue(element.equipmentNumber);
                        _this.equipmentSerialNumber.setValue(element.equipmentSerialNumber);
                        _this.equipmentCompanyRefID.setValue(element.equipmentCompanyRefID);
                        _this.manufacturer.setValue(element.manufacture.name);
                        _this.location.setValue(element.location.name);
                        _this.model.setValue(element.model);
                        _this.capacity.setValue(element.capacity);
                        if (element.type && element.type.name) {
                          _this.type.setValue(element.type.name);
                        }
                        if (_this.tempReportData.length > 0 && _this.tempReportData[0].updatedOldStickerNo) {
                          _this.manualSticker.setValue(_this.tempReportData[0].updatedOldStickerNo)
                        } else if ((_this.tempReportData.length > 0 && !_this.tempReportData[0].updatedOldStickerNo) && (!element.manualSticker)) {
                          _this.manualSticker.setValue("")
                        } else {
                          _this.manualSticker.setValue(element.updatedPreviousStickerNumber ? element.updatedPreviousStickerNumber : element.manualSticker);
                        }
                        // _this.manualSticker.setValue(element.updatedPreviousStickerNumber ? element.updatedPreviousStickerNumber : element.manualSticker);
                        // _this.checkPreviousSticker(element);
                        _this.equID = element._id !== undefined && element._id !== null ? element._id : element.pid
                      }
                    }
                  });
                }
              }
            }
          });
        }
      });

    }
    else {
      this.rfiInspectionService.getReportDataById(data).subscribe(result => {
        if (result.length > 0) {
          let data = {
            srId: this.data.data._id
          };

          this.scheduleInspectionReportService.getScheduleReport(data).subscribe(result1 => {
            this.tempReportData = result1;
            this.equipmentService.getRecords().subscribe(data => {
              if (data.length > 0) {
                data.forEach(element => {
                  if (result[0].equipment && result[0].equipment.ref && element._id && (result[0].equipment.ref === element._id)) {
                    this.equipmentNo.setValue(element.equipmentNumber);
                    this.equipmentSerialNumber.setValue(element.equipmentSerialNumber);
                    this.manufacturer.setValue(element.manufacture.name);
                    this.location.setValue(element.location.name);
                    this.model.setValue(element.model);
                    this.capacity.setValue(element.capacity);
                    this.type.setValue(element.type.name);
                    this.equipmentCompanyRefID.setValue(element.equipmentCompanyRefID);
                    // if (this.tempReportData.length > 0 && this.tempReportData[0].updatedOldStickerNo) {
                    //   this.manualSticker.setValue(this.tempReportData[0].updatedOldStickerNo)
                    // } else if ((this.tempReportData.length > 0 && !this.tempReportData[0].updatedOldStickerNo) && (!element.manualSticker)) {
                    //   this.manualSticker.setValue("")
                    // } else {
                      this.manualSticker.setValue(element.updatedPreviousStickerNumber ? element.updatedPreviousStickerNumber : element.manualSticker);
                    // }
                    // this.checkPreviousSticker(element);
                    this.equID = element._id;
                  }
                });
              }
            });

          });
        }
      })
    }
  }
  btnClose() {

    this.dialogRef.close();
  }
  deleteTable(item) {
    var data = {
      _id: this.data.data._id
    }
    var tempRfiData = [];
    if (this.isOffline == true) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: true },
        disableClose: false,
        hasBackdrop: true
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.offlineStorage.rfiHandler.getRFIById(this, data, function (res) {
            let _this = res.self;
            var checklistTemp = [];
            if (res && res.data && res.data.length > 0) {
              for (let i = 0; i < res.data.length; i++) {
                tempRfiData.push(JSON.parse(res.data.item(i).inspectionData));
              }
              if (tempRfiData && tempRfiData.length > 0) {
                if (tempRfiData[0].checkListDetails && tempRfiData[0].checkListDetails.length > 0) {

                  tempRfiData[0].checkListDetails.forEach(element => {
                    if (element && element.checklist && element.checklist.ref) {
                      if (element.checklist.ref !== item.checklist.ref) {
                        checklistTemp.push(element)
                      }
                    }
                  });
                  if (checklistTemp && checklistTemp.length > 0) {
                    tempRfiData[0].checkListDetails = [];
                    tempRfiData[0].checkListDetails = checklistTemp;
                  }else{
                    tempRfiData[0].checkListDetails = [];
                  }
                  debugger
                  _this.offlineStorage.rfiHandler.updateSelectedRFI(_this, tempRfiData[0], function (res) {
                    let _this = res.self;
                    _this.ngZone.run(() => {
                      setTimeout(() => {
                        _this.snackBar.open('Deleted successfully.', '', {
                          duration: 2000,
                          verticalPosition: 'bottom',
                        });
                        _this.ngOnInit();
                      }, 0);
                    });
                  });
                }
              }
            }


          });
        }
      });
    } else {
      item.rfiId = this.data.data._id
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: true },
        disableClose: false,
        hasBackdrop: true
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.rfiInspectionService.deleteChecklistById(item).subscribe(res => {
            if (res['statusBool']) {
              this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
            }
            this.ngOnInit();
          });
        }
      })
    }
  }
  convertISO(string) {
    if (string) {
      var date
      date = string.split("/"); // ["29", "1", "2016"]
      return new Date(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]));
    }
    else {
      return null;
    }
  }
  getErrorEquipmentNo() {
    return this.equipmentNo.hasError('required')
      ? 'Equipment No is required'
      : '';
  }
  getErrorProjectTitle() {
    return this.projectTitle.hasError('required')
      ? 'Project Name is required'
      : '';
  }


  onTogglePass(event, data) {

    if (event.pass == true) {
      event.fail = false;
      event.na = false
    }
  }
  onToggleFail(event, data) {

    if (event.fail == true) {
      event.pass = false;
      event.na = false
    }
  }
  onToggleNA(event, data) {
    if (event.na == true) {
      event.pass = false;
      event.fail = false
    }
  }
  save(
    projectTitle: String,
  ): void {
    if (!projectTitle) {
      this.snackBar.open('ProjectName is required', '', { duration: 2000, verticalPosition: "bottom" });
      return;
    }
    this.inputData = {};

    this.inputData.projectTitle = projectTitle;
    this.inputData.id = this.data.data._id;

    if (this.isOffline == true) {
      let data = {
        _id: this.data.data._id
      }
      this.offlineStorage.rfiHandler.getRFIById(this, data, function (res) {
        let _this = res.self;
        var rfiData = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            rfiData.push(JSON.parse(res.data.item(i).inspectionData));
          }

        }
        if (rfiData.length > 0) {

          rfiData[0].projectTitle = projectTitle;

        }
        _this.offlineStorage.rfiHandler.updateSelectedRFI(_this, rfiData[0], function (res) {
          let _this = res.self;
          if (res) {
            _this.ngZone.run(() => {
              setTimeout(() => {
                _this.snackBar.open(res.data, '', {
                  duration: 5000,
                  verticalPosition: 'bottom',
                });
              }, 0);
            });
          }
          _this.dialogRef.close();
        });
      });
    }
    else {
      this.rfiInspectionService.updateProjectNameByRfiID(this.inputData).subscribe(res => {
        if (res['statusBool']) {
          this.snackBar.open(res['message'], '', { duration: 2000 });
          this.dialogRef.close();
        }
      });
    }

  }
  getCompanyName() {
    var companyName = localStorage.getItem('ClientName');
    this.tmpClientName = companyName;
    if (companyName === '' || companyName === undefined || companyName === null) {
      this.settingService.getByKey('company').subscribe(result => {
        if (result.length > 0) {
          this.tmpClientName = result[0].value;
          localStorage.setItem('ClientName', result[0].value);
        }
      })
    }

  }

  printChecklist(row) {
    var data = {
      rfiData: this.data.data,
      row: row
    }
    if (this.isOffline === true) {
      this.printOfflineReport(row, data.rfiData);
    } else {
      this.reportService.generateChecklistReport(data).subscribe(res => {
        if (!res) {
          return;
        }
        var base64 = res.data;
        var blob = this.fileService._base64ToArrayBuffer(base64, 'application/pdf');

        if (environment.mobileApp) {
          this.fileService.openFileOnMobile(blob, row.checklist.name.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_'), 'application/pdf')
        } else {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
          } else {
            var objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);
          }
        }
      });
    }
  }
  printOfflineReport(row, rfiData) {
    var base64 = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw0NDw0NDQ8NDQ0NDQ0NDQ0NDQ8NDw0NFREWFhURFRUYHSggGBolGxUVITEhJSkrLi4uFx8zODMsNygtLisBCgoKDQ0NDg0PEisZFRkrKysrKysrKysrKystKysrLSsrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAKwBJgMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQIH/8QAIhABAQACAAQHAAAAAAAAAAAAAAERIQJBgfEiMVFhcbHw/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AO4gAAAlvlrr6KAAAAJICgAAAAAAAAAAAAAAAM8XFZeHEtluLczw6tzfrq0AAAAAAAAAAAAAAAAAAAAAAAAAAAAACZ7qAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJfZQAAAAAgAAAAAAAAAAAAAAAAAACfvlQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAoAAAAAAAAAJYCgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAARQAAAAAAAAAAAAEwoAAAACSqAAAAAAAAAAAAAAAAAAAAAAAAAAAAACXPLW5yzrKgAAAAAAAAAAAAAAAmFAAAAAAAABKoAAAAA//Z';

    var input = {

      saicNumber: null,
      discipline: null,
      equipmentNumber: null,
      equipmentSerialNumber: null,
      capacity: null,
      location: null,
      manufacture: null,
      model: null,
      type: null,
      reportNo: '',
      project: null,
      inspectionDate: null,
      projectTitle: null,
      projectNumber: null,
      woNo: null,
      companyName: null,
      keyNo: null,
      reqInspectionDate: null,
      issueDate: null,
      inspectorSign: base64,
      base64: base64,
      techName: null,
      checklistName: null,
      reviewerSign: base64,
      clientName: null,
      inspName: null,
      certificateNo: null,
      inspID: null,
      reviewerSignDate: null,
      loadTestSign: base64,
      loadTestSignDate: null,
      loadTestSignName: null,
      insType: '',
      witnessType: '',
      newStickerID: null,
      newStickerNo: null,
      newStickerQR: base64,
      qrcodeID: null,
      receiverId: null,
      inspectionSerialNumber: ''

    }
    input.inspectionSerialNumber = rfiData.inspectionSerialNumber;
    input.projectTitle = rfiData.projectTitle;
    input.clientName = rfiData.clientName;
    if (rfiData.inspectionDate) {
      input.inspectionDate = moment(rfiData.inspectionDate).format('DD-MMM-YYYY')

    }
    if (rfiData.reportNo) {
      input.reportNo = rfiData.reportNo;
    }
    if (row.checklist.loadTest && row.checklist.loadTest.length > 0) {

      var inspectordata = {
        refID: rfiData._id,
        module: 'Load test signature'
      }

      this.offlineStorage.attachmentHandler.getAttachmentByRefID(this, inspectordata, function (res) {
        let _this = res.self, temp3 = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            temp3.push(JSON.parse(res.data.item(i).attachmentData));
          }
        }

        if (temp3.length > 0) {
          input.loadTestSign = temp3[0].content
          input.loadTestSignDate = moment(temp3[0].createdDate).format('DD-MMM-YYYY')

        }

      });
    }
    if (rfiData.equipment !== undefined && rfiData.equipment !== null && rfiData.equipment !== '') {
      let data1 = {
        _id: rfiData.equipment.ref
      }
      this.offlineStorage.equipmentHandler.getEquipmentById(this, data1, function (res) {
        let _this = res.self, temp = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            temp.push(JSON.parse(res.data.item(i).equipmentData));
          }
        }

        if (temp.length > 0) {
          input.equipmentNumber = temp[0].equipmentNumber,
            input.location = temp[0].location.name,
            input.manufacture = temp[0].manufacture.name,
            input.model = temp[0].model,
            input.capacity = temp[0].capacity,
            input.type = temp[0].type.name,
            input.equipmentSerialNumber = temp[0].equipmentSerialNumber
        }
      });
    }
    let data3 = {
      _id: rfiData.inspectionID
    }
    this.offlineStorage.scheduleInspectionhandler.getScheduleInspectionById(this, data3, function (res) {
      let _this = res.self, scheduleInspectionData = [];
      if (res && res.data && res.data.length > 0) {
        for (let i = 0; i < res.data.length; i++) {
          scheduleInspectionData.push(JSON.parse(res.data.item(i).scheduleInspectionData));
        }
      }
      if (scheduleInspectionData.length > 0) {
        if (scheduleInspectionData[0].reqDate) { input.reqInspectionDate = moment(scheduleInspectionData[0].reqDate).format('DD-MMM-YYYY') }
        if (scheduleInspectionData[0].woNo) { input.woNo = scheduleInspectionData[0].woNo }
      }
      _this.offlineStorage.companyHandler.getCompany(_this, function (res) {
        let _this = res.self;
        let companyData = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            companyData.push(JSON.parse(res.data.item(i).companyData));
          }
        }
        if (companyData.length > 0) {
          if (companyData[0].name) { input.companyName = companyData[0].name; }
        }
        var id = rfiData._id;

        _this.offlineStorage.rfiReporthandler.getRFIReportDataByRFIId(_this, id, function (res) {
          let _this = res.self, temp = [];
          if (res && res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              temp.push(JSON.parse(res.data.item(i).rfiReportData));
            }
          }

          if (temp.length > 0) {
            input.receiverId = temp[0].receiverId,
              input.qrcodeID = temp[0].newStickerNumber.ref
            if (temp[0].type) {
              if (temp[0].type === 'Inspected') {
                input.insType = "√";
              } else {
                input.insType = "□";
              }
              if (temp[0].type === "Witnessed by 3rd Party") {
                input.witnessType = "√";
              } else {
                input.witnessType = "□";
              }
            } else {
              input.insType = "□";
              input.witnessType = "□";
            }
          }



          var id = input.qrcodeID;
          _this.offlineStorage.qrCodehandler.getqrCodeById(_this, id, function (res) {

            let _this = res.self, tem4 = [];
            if (res && res.data && res.data.length > 0) {
              for (let i = 0; i < res.data.length; i++) {
                tem4.push(JSON.parse(res.data.item(i).qrCodeData));
              }
            }
            if (tem4.length > 0) {
              input.newStickerQR = tem4[0].qrScan.base64
            } else {
              _this.offlineStorage.qrCodeUsedhandler.getqrCodeUsedById(_this, id, function (res) {

                let _this = res.self, temp4 = [];
                if (res && res.data && res.data.length > 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    temp4.push(JSON.parse(res.data.item(i).qrCodeUsedData));
                  }
                }
                if (temp4.length > 0) {
                  input.newStickerQR = temp4[0].qrScan.base64

                }
              });
            }

            var reviewerdata = {
              refID: rfiData._id,
              module: 'Reviewer signature'
            }

            _this.offlineStorage.attachmentHandler.getAttachmentByRefID(_this, reviewerdata, function (res) {
              let _this = res.self, temp2 = [];
              if (res && res.data && res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                  temp2.push(JSON.parse(res.data.item(i).attachmentData));
                }
              }
              if (temp2.length > 0) {
                input.reviewerSign = temp2[0].content
                input.reviewerSignDate = moment(temp2[0].createdDate).format('DD-MMM-YYYY')

              }
              var data2 = {
                _id: input.receiverId,
              }



              _this.offlineStorage.receiverHandler.getReceiverByIdOrPid(_this, data2, function (res) {
                let _this = res.self, temp1 = [];
                if (res && res.data && res.data.length > 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    temp1.push(JSON.parse(res.data.item(i).receiverData));
                  }
                }
                if (temp1.length > 0) {
                  input.techName = temp1[0].name
                }
                var inspectordata = {
                  refID: rfiData._id,
                  module: 'Inspector signature'
                }

                _this.offlineStorage.attachmentHandler.getAttachmentByRefID(_this, inspectordata, function (res) {
                  let _this = res.self, temp3 = [];
                  if (res && res.data && res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                      temp3.push(JSON.parse(res.data.item(i).attachmentData));
                    }
                  }

                  if (temp3.length > 0) {
                    input.inspectorSign = temp3[0].content
                  }

                  let data2 = {
                    _id: rfiData.inspector.ref
                  }
                  _this.offlineStorage.userHandler.getUserById(_this, data2, function (res) {
                    let _this = res.self, userData = [];
                    if (res && res.data && res.data.length > 0) {
                      for (let i = 0; i < res.data.length; i++) {
                        userData.push(JSON.parse(res.data.item(i).userData));
                      }
                    }
                    if (userData.length > 0) {
                      if (userData[0].name) { input.inspName = userData[0].name; }
                      if (userData[0].certificateNo) { input.certificateNo = userData[0].certificateNo; }
                    }
                    let data4 = {
                      _id: row.checklist.ref
                    }
                    _this.offlineStorage.checklisthandler.getChecklistById(_this, data4, function (res) {
                      let _this = res.self, checklistData = [];
                      if (res && res.data && res.data.length > 0) {
                        for (let i = 0; i < res.data.length; i++) {
                          checklistData.push(JSON.parse(res.data.item(i).checklistData));
                        }
                      }
                      if (checklistData.length > 0) {
                        if (checklistData[0].saicNo !== undefined && checklistData[0].saicNo !== null && checklistData[0].saicNo !== '') {
                          input.saicNumber = checklistData[0].saicNo;
                        }
                        if (checklistData[0].discipline) {
                          input.discipline = checklistData[0].discipline;
                        }
                        if (checklistData[0].name) {
                          input.checklistName = checklistData[0].name;
                        }
                        if (checklistData[0].issueDate) {
                          input.issueDate = moment(checklistData[0].issueDate).format('DD-MMM-YY')
                        }
                      }
                      var RFIData = input;
                      _this.generateReportData(RFIData, row);

                    });
                  });
                });
              });

            });
          });
        });
      });
    });

  }
  generateReportData(RFIData, row) {
    var companyName = localStorage.getItem('ClientName');

    if (RFIData.insType == '') {
      RFIData.insType = "□";
    }
    if (RFIData.witnessType == '') {

      RFIData.witnessType = "□";
    }
    debugger
    let content = [];
    if (row.checklist) {
      for (let m = 0; m < row.checklist.categoryDetails.length; m++) {
        var lObj = row.checklist.categoryDetails[m];
        if (row.checklist.categoryDetails[m].subItem && row.checklist.categoryDetails[m].subItem.length > 0) {
          if (lObj) {
            content.push({
              // absolutePosition: { x: 0,},
              margin: [0, -1, 0, 0],
              table: {
                widths: ['5.7%', '42.2%', '20.1%', '5.8%', '5.7%', '5.8%', '12.6%'],
                body: [
                  [{ text: m + 1, fontSize: 9, bold: true, alignment: 'center', fillColor: '#CCFFCC' },
                  { text: lObj.name, fontSize: 9, fillColor: '#CCFFCC' },
                  { text: ' ', fontSize: 9, fillColor: '#CCFFCC' },
                  { text: '', fontSize: 9, fillColor: '#CCFFCC' },
                  { text: '', fontSize: 9, fillColor: '#CCFFCC' },
                  { text: '', fontSize: 9, fillColor: '#CCFFCC' },
                  { text: '', fontSize: 9, fillColor: '#CCFFCC' }],


                ]
              }
            });

            let iii = m + 1;
            let iString1 = iii.toString() + '.';
            let kk = 0;
            for (let n = 0; n < row.checklist.categoryDetails[m].subItem.length; n++) {
              var obj2 = row.checklist.categoryDetails[m].subItem[n];
              if (obj2.name) {

                kk = kk + 1;
                let kString = kk.toString();
                let fullString = iString1 + kString;
                content.push({
                  // absolutePosition: { x: 0,},
                  margin: [0, -1, 0, 0],
                  table: {
                    widths: ['5.7%', '42.2%', '20.1%', '5.8%', '5.7%', '5.8%', '12.6%'],
                    body: [
                      [{ text: fullString, fontSize: 9, bold: true, alignment: 'center', fillColor: '#CCFFCC' },
                      { text: obj2.name, fontSize: 9, fillColor: '#CCFFCC' },
                      { text: ' ', fontSize: 9, fillColor: '#CCFFCC' },
                      { text: '', fontSize: 9, fillColor: '#CCFFCC' },
                      { text: '', fontSize: 9, fillColor: '#CCFFCC' },
                      { text: '', fontSize: 9, fillColor: '#CCFFCC' },
                      { text: '', fontSize: 9, fillColor: '#CCFFCC' }],


                    ]
                  }
                });
              }
              let ii = n + 1;
              let mm = m + 1;
              let iString;
              if (obj2.name) {
                iString = mm.toString() + '.' + ii.toString() + '.';

              } else {
                iString = mm.toString() + '.';

              }
              let k = 0;
              for (let j = 0; j < obj2.question.length; j++) {
                k = k + 1;
                let kString = k.toString();
                let fullString = iString + kString;


                let objj2 = obj2.question[j]
                var pass = '';
                var fail = '';
                var noResult = '';
                var reInspectionDate = ''
                var remarks = '';
                var dateRemarks = '';
                if (objj2) {
                  if (objj2.reInspectionDate !== undefined && objj2.reInspectionDate !== null && objj2.reInspectionDate !== '') {
                    reInspectionDate = moment(objj2.reInspectionDate).format('DD-MMM-YYYY')
                  }
                  if (objj2.pass && objj2.pass === true) {
                    pass = "√";
                  }
                  if (objj2.fail && objj2.fail === true) {
                    fail = "√";
                  }
                  if (objj2.na && objj2.na === true) {
                    noResult = "√";
                  }
                  if (objj2.remarks) {
                    remarks = objj2.remarks;
                  }
                  if (reInspectionDate && remarks) {
                    dateRemarks = reInspectionDate + ' - ' + remarks
                  } else if (reInspectionDate && remarks == '') {
                    dateRemarks = reInspectionDate;
                  } else if (remarks && reInspectionDate == '') {
                    dateRemarks = remarks;
                  }
                  content.push({
                    // margin: [10, 0, 0, 20],

                    table: {
                      widths: ['5.7%', '42.2%', '20.1%', '5.8%', '5.7%', '5.8%', '12.6%'],
                      body: [[{ text: fullString, fontSize: 9, alignment: 'center', },
                      { text: objj2.name, fontSize: 9 },
                      { text: objj2.reference, fontSize: 9, alignment: 'center', },
                      { text: pass, fontSize: 13, alignment: 'center', }, { text: fail, fontSize: 13, alignment: 'center', }, { text: noResult, fontSize: 13, alignment: 'center', }, { text: dateRemarks, fontSize: 9, alignment: 'center', }
                      ]]
                    }
                  })
                } else {
                  content.push({
                    // absolutePosition: { x: 0,},
                    table: {
                      widths: ['5.7%', '42.2%', '20.1%', '5.8%', '5.7%', '5.8%', '12.6%'],
                      body: [
                        [{ text: '' },
                        { text: '' },
                        { text: ' ' },
                        { text: '' },
                        { text: '' },
                        { text: '' },
                        { text: '' }],


                      ]
                    }
                  });
                }

              }
            }


          }
        } else {
          if (lObj) {
            content.push({
              margin: [0, -1, 0, 0],
              table: {
                widths: ['5.7%', '42.2%', '20.1%', '5.8%', '5.7%', '5.8%', '12.6%'],
                body: [
                  [{ text: m + 1, fontSize: 9, bold: true, alignment: 'center', fillColor: '#CCFFCC' },
                  { text: lObj.name, fontSize: 9, fillColor: '#CCFFCC' },
                  { text: ' ', fontSize: 9, fillColor: '#CCFFCC' },
                  { text: '', fontSize: 9, fillColor: '#CCFFCC' },
                  { text: '', fontSize: 9, fillColor: '#CCFFCC' },
                  { text: '', fontSize: 9, fillColor: '#CCFFCC' },
                  { text: '', fontSize: 9, fillColor: '#CCFFCC' }],


                ]
              }
            });
            let ii = m + 1;
            let iString = ii.toString() + '.';
            let k = 0;
            for (let j = 0; j < lObj.question.length; j++) {
              k = k + 1;
              let kString = k.toString();
              let fullString = iString + kString;


              let objj2 = lObj.question[j]
              var pass = '';
              var fail = '';
              var noResult = '';
              var reInspectionDate = ''
              var remarks = '';
              var dateRemarks = '';
              if (objj2) {
                if (objj2.reInspectionDate !== undefined && objj2.reInspectionDate !== null && objj2.reInspectionDate !== '') {
                  reInspectionDate = moment(objj2.reInspectionDate).format('DD-MMM-YYYY')
                }
                if (objj2.pass && objj2.pass === true) {
                  pass = "√";
                }
                if (objj2.fail && objj2.fail === true) {
                  fail = "√";
                }
                if (objj2.na && objj2.na === true) {
                  noResult = "√";
                }
                if (objj2.remarks) {
                  remarks = objj2.remarks;
                }
                if (reInspectionDate && remarks) {
                  dateRemarks = reInspectionDate + ' - ' + remarks
                } else if (reInspectionDate && remarks == '') {
                  dateRemarks = reInspectionDate;
                } else if (remarks && reInspectionDate == '') {
                  dateRemarks = remarks;
                }
                content.push({
                  table: {
                    widths: ['5.7%', '42.2%', '20.1%', '5.8%', '5.7%', '5.8%', '12.6%'],
                    body: [[{ text: fullString, fontSize: 9, alignment: 'center', },
                    { text: objj2.name, fontSize: 9 },
                    { text: objj2.reference, fontSize: 9, alignment: 'center', },
                    { text: pass, fontSize: 13, alignment: 'center', }, { text: fail, fontSize: 13, alignment: 'center', }, { text: noResult, fontSize: 13, alignment: 'center', }, { text: dateRemarks, fontSize: 9, alignment: 'center', }
                    ]]
                  }
                })
              } else {
                content.push({
                  table: {
                    widths: ['5.7%', '42.2%', '20.1%', '5.8%', '5.7%', '5.8%', '12.6%'],
                    body: [
                      [{ text: '' },
                      { text: '' },
                      { text: ' ' },
                      { text: '' },
                      { text: '' },
                      { text: '' },
                      { text: '' }],
                    ]
                  }
                });
              }

            }

          }
        }


      }
    }
    if (row.checklist.loadTest) {
      if (row.checklist.loadTest.length > 0) {
        var loadTestedBy = row.checklist.loadTest[0].loadTestedBy;
        content.push({
          // absolutePosition: { x: 0,},
          table: {
            widths: ['98.1%'],
            body: [
              [{ text: 'LOAD TEST DETAILS:', fillColor: '#99ffe6', alignment: 'center', bold: true, fontSize: 11, border: [true, true, true, true] },],


            ]
          },
        });
        content.push({
          // absolutePosition: { x: 0,},
          table: {
            widths: ['24.0%', '11.8%', '15.8% ', '20.3%', '26.0%'],
            body: [
              [{ text: 'TEST PERFORMED BY:(Name/Agency):', bold: true, fontSize: 9, border: [true, true, true, true] },
              { text: 'Procedure Reference No.', bold: true, fontSize: 9, border: [true, true, true, true] },
              { text: 'Ref. Standard for Load Test', bold: true, fontSize: 9, border: [true, true, true, true] },
              { text: 'Weight Calculation Verified', bold: true, fontSize: 9, border: [true, true, true, true] },
              { text: 'Load Cell used to determine exact test load weight (if NO, state reason)', bold: true, fontSize: 9, border: [true, true, true, true] }],
              [{ text: row.checklist.loadTest[0].testPerformedBy, fontSize: 9, },
              { text: row.checklist.loadTest[0].procedureRefNo, fontSize: 9, },
              { text: row.checklist.loadTest[0].refStandard, fontSize: 9, },
              { text: row.checklist.loadTest[0].weightCaluculation, fontSize: 9, },
              { text: row.checklist.loadTest[0].loadWeight, fontSize: 9, }]

            ]
          }
        });
        content.push({
          // absolutePosition: { x: 0,},
          table: {
            widths: ['8.0%', '14.6%', '12.0%', '16.0% ', '20.8%', '26.5%'],
            body: [
              [{ text: 'Test No.', bold: true, fontSize: 9, border: [true, true, true, true] },
              { text: 'Weight of Load Applied', bold: true, fontSize: 9, border: [true, true, true, true] },
              { text: 'Span', bold: true, fontSize: 9, border: [true, true, true, true] },
              { text: 'Max. allowed Deflection', bold: true, fontSize: 9, border: [true, true, true, true] },
              { text: 'Actual Deflection', bold: true, fontSize: 9, border: [true, true, true, true] },
              { text: 'RESULT', bold: true, fontSize: 10, border: [true, true, true, true] }]
            ]
          }
        });
        for (var n = 0; n < row.checklist.loadTest.length; n++) {
          {
            for (var p = 0; p < row.checklist.loadTest[n].loadTestDetails.length; p++) {

              var lObj2 = row.checklist.loadTest[n].loadTestDetails[p];
              if (lObj2) {
                content.push({
                  // absolutePosition: { x: 0,},
                  table: {
                    widths: ['8.0%', '14.6%', '12.0%', '16.0% ', '20.8%', '26.5%'],
                    body: [
                      [{ text: p + 1, alignment: 'center', fontSize: 9, },
                      { text: lObj2.weightOfLoadApplied, fontSize: 9, },
                      { text: lObj2.span, fontSize: 9, },
                      { text: lObj2.maxAllowedDeflection, fontSize: 9, },
                      { text: lObj2.actualDeflection, fontSize: 9, },
                      { text: lObj2.result, fontSize: 9, }]
                    ]
                  }
                });
              }
            }
          }
        }
      }
    }
    content.push({
      // absolutePosition: { x: 0,},
      table: {
        widths: ['98.1%'],
        body: [
          [{ text: 'REFERENCE DOCUMENTS:', bold: true, fontSize: 9, border: [true, true, true, true] },],


        ]
      },
    });
    for (let l = 0; l < row.checklist.referenceDocuments.length; l++) {
      var lObj = row.checklist.referenceDocuments[l]
      if (lObj) {

        content.push({
          // absolutePosition: { x: 0,},
          table: {
            widths: ['98.1%'],
            body: [

              [{ text: (l + 1) + '.' + lObj.name, fontSize: 9, border: [true, false, true, false] },],


            ]
          },
        });
      }

    }

    content.push({
      table: {
        widths: ['32.6%', '42.8%', '22.6%'],
        body: [
          [{ text: 'Load Tested By (If Applicable):', fontSize: 9, colSpan: 3, bold: true, border: [true, true, true, true] },
          { text: '', fontSize: 9, colSpan: 3, bold: true, },
          { text: '', fontSize: 9, colSpan: 3, bold: true, },],
          [{ text: 'Name ', fontSize: 8, border: [true, false, false, true] },
          { text: 'Signature ', fontSize: 8, border: [false, false, false, true] },
          { text: 'Date', fontSize: 8, bold: true, border: [false, false, true, true] }],
          [{ text: loadTestedBy, fontSize: 8, border: [true, false, false, true] },
          {
            image: RFIData.loadTestSign,
            width: 60,
            height: 60,
            border: [false, false, false, true]
          }, { text: RFIData.loadTestSignDate, fontSize: 8, border: [false, false, true, true] }]
        ]
      },
    });
    content.push({
      table: {
        widths: ['32.6%', '42.8%', '22.6%'],
        body: [
          [{ text: RFIData.insType + ' ' + 'Inspected ' + ' ' + RFIData.witnessType + ' ' + 'Witnessed by 3rd Party Certified Inspector: ', fontSize: 9, colSpan: 3, bold: true, border: [true, false, true, true] },
          { text: '', fontSize: 9, colSpan: 3, bold: true, },
          { text: '', fontSize: 9, colSpan: 3, bold: true, },],
          [{ text: 'Name ', fontSize: 8, border: [true, false, false, true] },
          { text: 'Signature ', fontSize: 8, border: [false, false, false, true] },
          { text: ' Certificate No. & Company:', fontSize: 8, bold: true, border: [false, false, true, true] }],
          [{ text: RFIData.inspName, fontSize: 8, border: [true, false, false, true] },
          {
            image: RFIData.inspectorSign,
            width: 60,
            height: 60,
            border: [false, false, false, true]
          }, { text: RFIData.certificateNo + ' & ' + RFIData.companyName, fontSize: 8, border: [false, false, true, true] }],


        ]
      },
    });
    content.push({
      table: {
        widths: ['32.6%', '42.8%', '22.6%'],
        body: [
          [{ text: 'User Representative:', fontSize: 9, colSpan: 3, bold: true, border: [true, false, true, true] },
          { text: '', fontSize: 9, colSpan: 3, bold: true, },
          { text: '', fontSize: 9, colSpan: 3, bold: true, },],
          [{ text: 'Name ', fontSize: 8, border: [true, false, false, true] },
          { text: 'Signature ', fontSize: 8, border: [false, false, false, true] },
          { text: 'Date', fontSize: 8, bold: true, border: [false, false, true, true] }],
          [{ text: RFIData.techName, fontSize: 8, border: [true, false, false, true] },
          {
            image: RFIData.reviewerSign,
            width: 60,
            height: 60,
            border: [false, false, false, true]
          }, { text: RFIData.reviewerSignDate, fontSize: 8, border: [false, false, true, true] }]
        ]
      },
      //   
    });
    if (companyName === "AMPS") {
      RFIData.watermarkOpacity = 0.1
      RFIData.watermarkText = ''
    } else {
      RFIData.watermarkOpacity = 0.1
      RFIData.watermarkText = 'DRAFT'
    }


    var statusValue = {
      RFIData: RFIData
    };
    if (companyName === "AMPS") {
      statusValue.RFIData.watermarkText = ''// needs to change

    } else {
      statusValue.RFIData.watermarkText = 'DRAFT'// needs to change

    }
    var docDefinition = {
      pageOrientation: 'portrait',
      pageMargins: [29, 266, 20, 20],
      watermark: { text: statusValue.RFIData.watermarkText, opacity: 0.1, bold: true, italics: false, fontSize: 100 },
      header: function (currentPage, pageCount, pageSize) {
        return [
          { stack: getHeader(currentPage, row, RFIData) }
        ]
      },

      content: content
    };
    var pdfDocGenerator = pdfMake.createPdf(docDefinition)
    var base64data;
    pdfDocGenerator.getBase64(function (encodedString) {
      base64data = encodedString;

      var blob = _base64ToArrayBuffer(base64data, 'application/pdf');
      if (environment.mobileApp) {
        openFileOnMobile(blob, row.checklist.name.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_'), 'application/pdf')
      } else {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        } else {
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
        }
      }
    })

  }
}
function openFileOnMobile(dataBlob, fileName, filetype) {
  var folderpath = (window as any).cordova.file.dataDirectory;
  window.resolveLocalFileSystemURL(folderpath, function (dir) {
    dir.getFile(fileName, { create: true }, function (file) {
      file.createWriter(function (fileWriter) {
        fileWriter.write(dataBlob);
        var finalPath = folderpath + fileName;
        (window as any).cordova.plugins.fileOpener2.open(finalPath, filetype,
          {
            error: function (e) {
              console.log('Error status: ' + e.status + ' - Error message: ' + e.message)
            },
            success: function () {
              console.log('File opened successfully');
            }
          });
      }, function (err) {
        console.log(err);
      });
    }, function (err) {
      console.log(err);
    });
  });

}
function _base64ToArrayBuffer(base64, type) {
  var byteCharacters = atob(base64);
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var a = document.createElement("a");
  document.body.appendChild(a);
  var blob = new Blob([byteArray], { type: type });
  return blob;
}

function getHeader(currentPage, checklistData, rfiData) {
  let content = [];

  var base64 = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw0NDw0NDQ8NDQ0NDQ0NDQ0NDQ8NDw0NFREWFhURFRUYHSggGBolGxUVITEhJSkrLi4uFx8zODMsNygtLisBCgoKDQ0NDg0PEisZFRkrKysrKysrKysrKystKysrLSsrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAKwBJgMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQIH/8QAIhABAQACAAQHAAAAAAAAAAAAAAERIQJBgfEiMVFhcbHw/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AO4gAAAlvlrr6KAAAAJICgAAAAAAAAAAAAAAAM8XFZeHEtluLczw6tzfrq0AAAAAAAAAAAAAAAAAAAAAAAAAAAAACZ7qAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJfZQAAAAAgAAAAAAAAAAAAAAAAAACfvlQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAoAAAAAAAAAJYCgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAARQAAAAAAAAAAAAEwoAAAACSqAAAAAAAAAAAAAAAAAAAAAAAAAAAAACXPLW5yzrKgAAAAAAAAAAAAAAAmFAAAAAAAABKoAAAAA//Z';

  content.push({
    style: 'tableExample',
    margin: [29, 30, 6, 20],

    table: {
      headerRows: 1,
      widths: ['43.6%', '10.6%', '9.6%', '18.6%', '13%'],
      heights: [0, 0, 0, 23],
      body: [
        [

          { text: 'SAUDI ARAMCO INSPECTION CHECKLIST', fontSize: 11, bold: true },

          { text: 'SAIC NUMBER', fontSize: 8, border: [true, true, false, false] },
          { text: 'ISSUE DATE', fontSize: 8, border: [true, true, false, false] },
          { text: 'DISCIPLINE', fontSize: 8, border: [true, true, true, false] },
          {
            rowSpan: 4,
            image: rfiData.newStickerQR,
            width: 80,
            height: 80,
            absolutePosition: { x: 487, y: 35 },
            border: [true, true, true, true]
          },

        ],
        // 
        [{ text: rfiData.checklistName, fontSize: 9, bold: true },
        { text: rfiData.saicNumber, fontSize: 9, bold: true, alignment: 'center', border: [false, false, true, true] },

        { text: rfiData.issueDate, fontSize: 9, alignment: 'center', border: [false, false, true, true] },
        { text: rfiData.discipline, fontSize: 9, bold: true, alignment: 'center', border: [false, false, true, true] }],

        [{ text: 'PROJECT TITLE', fontSize: 8, border: [true, true, false, false] },
        { text: 'WBS/BI/JO NUMBER', fontSize: 8, border: [true, true, false, false] },
        { text: 'INSPECTION COMPANY', colSpan: 2, fontSize: 8, border: [true, true, true, false] },
        ],
        [{ text: rfiData.projectTitle, fontSize: 9, alignment: 'center', bold: true, border: [true, false, true, true] },
        { text: rfiData.woNo, fontSize: 9, bold: true, alignment: 'center', border: [true, false, false, true] },
        { text: rfiData.companyName, fontSize: 9, colSpan: 2, bold: true, alignment: 'center', border: [true, false, true, true] }],
      ]
    },
    layout: {
      paddingLeft: function (i, node) { return 1; },
      paddingRight: function (i, node) { return 1; },
      paddingTop: function (i, node) { return 1; },
      paddingBottom: function (i, node) { return 1; },
    },

  });
  content.push({
    style: 'tableExample',

    margin: [29, -13, 6, 20],
    table: {
      headerRows: 1,
      widths: ['33.6%', '24.6%', '20.6%', '16.6%'],
      heights: [0, 0, 0, 20, 0, 23],
      body: [
        [{ text: 'EQUIPMENT ID NUMBER ', fontSize: 8, border: [true, true, false, false] },
        { text: 'EQUIPMENT DESCRIPTION/TYPE ', fontSize: 8, border: [true, true, false, false] },
        { text: 'SERIAL NO.', fontSize: 8, border: [true, true, false, false] },
        { text: 'REPORT No.:', fontSize: 8, border: [true, true, true, false] }],

        [{ text: rfiData.equipmentNumber, fontSize: 9, bold: true, alignment: 'center', border: [true, false, true, true] },
        { text: rfiData.type, fontSize: 9, bold: true, alignment: 'center', border: [false, false, true, true] },

        { text: rfiData.equipmentSerialNumber, fontSize: 9, bold: true, alignment: 'center', border: [false, false, true, true] },
        { text: rfiData.reportNo, fontSize: 9, bold: true, alignment: 'center', border: [false, false, true, false] }],

        [{ text: 'CAPACITY (SWL)', fontSize: 8, border: [true, true, false, false] },
        { text: 'MANUFACTURER', fontSize: 8, border: [true, true, false, false] },
        { text: 'FACILITY / LOCATION', fontSize: 8, border: [true, true, false, false] },
        { text: '', fontSize: 8, border: [true, false, true, false] }],
        //
        [{ text: rfiData.capacity, fontSize: 9, bold: true, alignment: 'center', border: [true, false, true, true] },
        { text: rfiData.manufacture, fontSize: 9, bold: true, alignment: 'center', border: [false, false, true, true] },
        { text: rfiData.location, fontSize: 9, bold: true, alignment: 'center', border: [false, false, true, true] },
        { text: '', fontSize: 9, bold: true, alignment: 'center', border: [true, false, true, false] }],

        [{ text: 'REQUESTED INSPECTION DATE', fontSize: 8, border: [true, true, false, false] },
        { text: 'RFI No', fontSize: 8, border: [true, true, false, false] },
        { text: 'TASK COMPLETED DATE ', fontSize: 8, border: [true, true, false, false] },
        { text: 'EQUIPMENT OWNER', fontSize: 8, border: [true, true, true, false] }],
        //
        [{ text: rfiData.reqInspectionDate, fontSize: 9, alignment: 'center', bold: true, border: [true, false, true, true] },
        { text: rfiData.inspectionSerialNumber, fontSize: 9, bold: true, alignment: 'center', border: [false, false, true, true] },
        { text: rfiData.inspectionDate, fontSize: 9, bold: true, alignment: 'center', border: [false, false, true, true] },
        { text: rfiData.clientName, fontSize: 9, bold: true, alignment: 'center', border: [true, false, true, true] }],
      ]
    }

  });
  content.push({
    style: 'tableExample',

    margin: [29, -13, 6, 20],
    table: {
      headerRows: 1,
      widths: ['5.6%', '41.0%', '19.6%', '5.6%', '5.6%', '5.6%', '12.1%'],
      body: [
        [{ text: 'ITEM', fontSize: 9, alignment: 'center', bold: true },
        { text: 'ACCEPTANCE CRITERIA ', fontSize: 9, alignment: 'center', bold: true },
        { text: 'REFERENCE ', fontSize: 9, alignment: 'center', bold: true },
        { text: 'PASS', fontSize: 9, alignment: 'center', bold: true },
        { text: 'FAIL', fontSize: 9, alignment: 'center', bold: true },
        { text: 'N/A', fontSize: 9, alignment: 'center', bold: true },
        { text: 'RE-INSP DATE', fontSize: 9, alignment: 'center', bold: true }],


      ]
    }

  });
  return content;
}