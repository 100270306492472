import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting/setting.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class RfiInspectionService {

  private apiUrl = '';  // URL to web api

  constructor(private http: HttpClient,
    private settingService: SettingService) {
  }

  updateStatus(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/updateStatus', data, httpOptions)
      .pipe(tap());
  }

  removeRfiEquipment(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/removeRfiEquipment', data, httpOptions)
      .pipe(tap());
  }

  updateOfflineRFI(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/updateOfflineRFI', data, httpOptions)
      .pipe(tap());
  }

  updateOfflineEquipment(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');

    return this.http
      .post<any>(this.apiUrl + '/updateOfflineEquipment', data, httpOptions)
      .pipe(tap());
  }

  updateOfflineLocation(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');

    return this.http
      .post<any>(this.apiUrl + '/updateOfflineLocation', data, httpOptions)
      .pipe(tap());
  }

  updateSignature(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');

    return this.http
      .post<any>(this.apiUrl + '/updateSignature', data, httpOptions)
      .pipe(tap());
  }

  updateRFIReport(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');

    return this.http
      .post<any>(this.apiUrl + '/updateRFIReport', data, httpOptions)
      .pipe(tap());
  }

  updateStatusCancelled(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/updateStatusCancelled', data, httpOptions)
      .pipe(tap());
  }

  updateStatusRejected(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/updateStatusRejected', data, httpOptions)
      .pipe(tap());
  }


  updateStatusReInspect(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/updateStatusReInspect', data, httpOptions)
      .pipe(tap());
  }

  approveForInspection(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/approveForInspection', data, httpOptions)
      .pipe(tap());
  }

  updateComment(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/updateComment', data, httpOptions)
      .pipe(tap());
  }


  getAllRecords(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getAllRecords', data, httpOptions)
      .pipe(tap());
  }

  getAllRFIWorkflow(): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');

    return this.http
      .get<any>(this.apiUrl + '/getAllRFIWorkflow')
      .pipe(
        tap()
      );
  }

  getAllRFIReportData(): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');

    return this.http
      .get<any>(this.apiUrl + '/getAllRFIReportData')
      .pipe(
        tap()
      );
  }

  getAllRecordsByInspector(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getAllRecordsByInspector', data, httpOptions)
      .pipe(tap());
  }

  getAllRecordsForCoordinator(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getAllRecordsForCoordinator', data, httpOptions)
      .pipe(tap());
  }

  getReportDataById(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getReportDataById', data, httpOptions)
      .pipe(tap());
  }

  getReportDataByScheduleId(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getReportDataByScheduleId', data, httpOptions)
      .pipe(tap());
  }

  sendOTPToMail(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/sendOTPToMail', data, httpOptions)
      .pipe(tap());
  }

  verifyOTP(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/verifyOTP', data, httpOptions)
      .pipe(tap());
  }

  otpTimerOut(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/otpTimerOut', data, httpOptions)
      .pipe(tap());
  }

  updateCategoryDetails(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/updateCategoryDetails', data, httpOptions)
      .pipe(tap());
  }

  getRFIByQrValue(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getRFIByQrValue', data, httpOptions)
      .pipe(tap());
  }

  checkEquipmentIsExist(EquipmentID: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(
        this.apiUrl + '/checkEquipmentIsExist',
        { "EquipmentID": EquipmentID },
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  updateChecklistById(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/updateChecklistById', data, httpOptions)
      .pipe(tap());
  }
  getRFIById(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getRFIById', data, httpOptions)

      .pipe(tap());
  }
  getStatusByDate(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getStatusByDate', data, httpOptions)

      .pipe(tap());
  }
  getStatusByDateN(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getStatusByDateN', data, httpOptions)

      .pipe(tap());
  }
  getStatusByDateNS(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getStatusByDateNS', data, httpOptions)

      .pipe(tap());
  }
  getRejectionStatusByDate(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getRejectionStatusByDate', data, httpOptions)

      .pipe(tap());
  }

  getRejectedRFIWorkflow(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getRejectedRFIWorkflow', data, httpOptions)

      .pipe(tap());
  }

  updateProjectNameByRfiID(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/updateProjectNameByRfiID', data, httpOptions)
      .pipe(tap());
  }

  getYearlyStatusByEquipment(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getYearlyStatusByEquipment', data, httpOptions)

      .pipe(tap());
  }
  getQuarterlyStatusByEquipment(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getQuarterlyStatusByEquipment', data, httpOptions)

      .pipe(tap());
  }
  getMonthlyStatusByEquipment(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getMonthlyStatusByEquipment', data, httpOptions)

      .pipe(tap());
  }

  getTotalStatusByEquipmentConducted(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getTotalStatusByEquipmentConducted', data, httpOptions)

      .pipe(tap());
  }
  getTotalStatusByEquipmentConductedY(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getTotalStatusByEquipmentConductedY', data, httpOptions)

      .pipe(tap());
  }
  getTotalStatusByEquipmentConductedW(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getTotalStatusByEquipmentConductedW', data, httpOptions)

      .pipe(tap());
  }
  getLoadTestByInspector(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getLoadTestByInspector', data, httpOptions)

      .pipe(tap());
  }
  getLoadTestByInspectorY(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getLoadTestByInspectorY', data, httpOptions)

      .pipe(tap());
  }
  getLoadTestByInspectorW(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getLoadTestByInspectorW', data, httpOptions)

      .pipe(tap());
  }
  getLoadTestConducted(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getLoadTestConducted', data, httpOptions)

      .pipe(tap());
  }
  getLoadTestConductedY(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getLoadTestConductedY', data, httpOptions)

      .pipe(tap());
  }
  getLoadTestConductedW(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getLoadTestConductedW', data, httpOptions)

      .pipe(tap());
  }


  getAllCustomerFeedbackRecords(): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .get<any>(this.apiUrl + '/getAllCustomerFeedbackRecords')
      .pipe(
        tap()
      );
  }

  getAllCustomerFeedbackRecordsByInspector(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getAllCustomerFeedbackRecordsByInspector', data, httpOptions)
      .pipe(tap());
  }

  getAllCustomerFeedbackRecordsForCoordinator(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getAllCustomerFeedbackRecordsForCoordinator', data, httpOptions)
      .pipe(tap());
  }

  getAllInspectorScheduleRecords(): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .get<any>(this.apiUrl + '/getAllInspectorScheduleRecords')
      .pipe(
        tap()
      );
  }

  getAllInspectorScheduleRecordsByInspector(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getAllInspectorScheduleRecordsByInspector', data, httpOptions)
      .pipe(tap());
  }

  getAllInspectorScheduleRecordsForCoordinator(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getAllInspectorScheduleRecordsForCoordinator', data, httpOptions)
      .pipe(tap());
  }
  getReportsByRFIId(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/getReportsByRFIId', data, httpOptions)
      .pipe(tap());
  }
  getAllStatus(): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .get<any>(this.apiUrl + '/getAllStatus')
      .pipe(
        tap()
      );
  }
  deleteChecklistById(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .post<any>(this.apiUrl + '/deleteChecklistById', data, httpOptions)
      .pipe(tap());
  }


  mobileLogDataToCollection(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('mobileLog');
    return this.http
      .post<any>(this.apiUrl + '/createRecord', data, httpOptions)
      .pipe(tap());
  }
}


