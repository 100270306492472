import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SettingService } from '../services/setting/setting.service';
@Injectable({
  providedIn: 'root'
})
export class LogService {
  private apiUrl = '';  // URL to web api

  constructor(private http: HttpClient,
    private settingService:SettingService) {
  }

 
  getLog(): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('log');
    return this.http
      .get<any>(this.apiUrl + '/getLog')
      .pipe(
        tap()
      );
  }
}
