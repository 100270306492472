import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
} from '@angular/material';
import { ContactService } from '../../../master/contact/contact.service';
import { ScheduleInspectionReportService } from '../../../master/scheduleInspection/schedule-inspection-report.service';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';

@Component({
  selector: 'app-schedule-inspection-report-email-form',
  templateUrl: './schedule-inspection-report-email-form.component.html',
  styleUrls: ['./schedule-inspection-report-email-form.component.css']
})
export class ScheduleInspectionReportEmailFormComponent implements OnInit {

  emailListForm: any;
  isSave = false;
  isOffline: boolean;
  existingData = [];
  contactData = [];
  allEmailData = [];
  filterData = [];
  showData = [];

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ScheduleInspectionReportEmailFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private contactService: ContactService,
    private scheduleInspectionReportService: ScheduleInspectionReportService, private offlineStorage: OfflineStorageService,private ngZone:NgZone,
    private snackBar: MatSnackBar,) {
    this.emailListForm = fb.group({
      'emailList': [null]
    });
    this.existingData = this.data.existingData;
  }

  ngOnInit() {
    let value = localStorage.getItem('isOffline');

    if (value == 'true') {
      this.isOffline = true;
    }
    else {
      this.isOffline = false;
    }
    this.getAllContact();
  }

  getAllContact(){
    if(this.isOffline){
      let tempData = [];
      this.offlineStorage.contactHandler.selectAllContact(this, function (res) {
        let _this = res.self;
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            tempData.push(JSON.parse(res.data.item(i).contactData));
          }

          if (tempData.length > 0) {
            tempData.forEach(element => {
              _this.allEmailData.push({
                ref: element._id,
                name: element.name,
                emailID: element.emailID
              });
            });
          }
        }
      _this.checkExistingContact();
      }); 
     
    }
    else{
      this.contactService.getContact().subscribe(data => {
        if (data.length > 0) {
          data.forEach(element => {
            this.allEmailData.push({
              ref: element._id,
              name: element.name,
              emailID: element.emailID
            });
          });
        }
        this.checkExistingContact();
      });
    }
  }

  checkExistingContact(){
    this.contactData = this.allEmailData;
    if(this.existingData.length > 0){
      for(let i = 0; i < this.existingData.length; i++){
        for(let j = 0; j < this.allEmailData.length; j++){
          if(this.existingData[i].ref == this.allEmailData[j].ref){
            this.contactData.splice(j, 1);
          }
        }
      }
    }
    this.filterData = this.contactData;
  }

  save() {

    let data = [];
    if(this.emailListForm.controls.emailList.value.length > 0){
      data.push(this.emailListForm.controls.emailList.value);
      data[0].forEach(element => {
        this.allEmailData.forEach(element1 => {
          if(element == element1.ref){
            this.existingData.push({
              ref: element1.ref,
              name: element1.name,
              emailID: element1.emailID
            });
          }
        });
      });
    }
    
    this.dialogRef.close({ data: this.existingData });
  }

  filterEmailList(val) {
    val = val.toLowerCase();
    this.filterData = this.contactData.filter(function (d) {
      return ((d.emailID.toLowerCase().indexOf(val) !== -1 || !val) 
        || (d.name.toLowerCase().indexOf(val) !== -1 || !val))
    });
  }

  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }

  get f() {
    return this.emailListForm.controls;
  }

  btnClose() {
    this.dialogRef.close();
  }
}
