import { Component, OnInit, ViewChild, Inject,ElementRef } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
  MatDialogConfig,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material';

import { ClientfeedbackService } from '../clientfeedback.service';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { ExcelService } from '../../../excel.service';
import { AuthService } from '../../../auth/auth.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { RfiInspectionService } from '../../rfi-inspection/rfi-inspection.service';
import { ClientfeedbackFormComponent } from '../clientfeedback-form/clientfeedback-form.component';
@Component({
  selector: 'app-clientfeedback-list',
  templateUrl: './clientfeedback-list.component.html',
  styleUrls: ['./clientfeedback-list.component.css']
})
export class ClientfeedbackListComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild('search',{static:false}) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  // dataSource: CompanyListDataSource;
  dataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns =['inspectionSerialNumber', 'reportNo', 'inspectionDate', 'projectNumber', 'clientName', 'inspector', 'category','ratings'];
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  userRoles: any;
  userId: any;
  isInspector = false;
  isAdmin = false;
  isReviewer = false;
  isVerifier = false;
  isCoordinator = false;
  permission: any;
  isWrite = false;
  constructor(
    private dialog: MatDialog,
    private ClientfeedbackService: ClientfeedbackService,  private snackBar: MatSnackBar,
    private rfiInspectionService: RfiInspectionService,
    private bottomSheet: MatBottomSheet,  private excelService: ExcelService, private authService: AuthService
  ) { }

  ngOnInit() {
    this.getUser();
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;
    this.getTableData();

    if (this.permission && this.permission.inspections && this.permission.inspections.CustomerFeedback && this.permission.inspections.CustomerFeedback.write) {
      this.isWrite = true;
    } else {
      this.displayedColumns = ['inspectionSerialNumber', 'reportNo', 'inspectionDate', 'projectNumber', 'clientName', 'inspector', 'category'];
    }
  }

  getUser() {
    this.user = this.getFromLocalStorage('userDet');
    this.userId = this.user.data._id;
    this.userRoles = this.user.data.role;
    this.checkRoles();
  }

  checkRoles() {
   
    for (var i = 0; i < this.userRoles.length; i++) {
      if (this.userRoles[i].name === 'Administrator' && this.userRoles[i].isActive === true) {
        this.isAdmin = true;
      } else if (this.userRoles[i].name === 'Inspector' && this.userRoles[i].isActive === true) {
        this.isInspector = true;
      } else if (this.userRoles[i].name === 'Reviewer' && this.userRoles[i].isActive === true) {
        this.isReviewer = true;
      } else if (this.userRoles[i].name === 'Co-ordinator' && this.userRoles[i].isActive === true) {
        this.isCoordinator = true;
      } else if (this.userRoles[i].name === 'Verifier' && this.userRoles[i].isActive === true) {
        this.isVerifier = true;
      }
    }
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  getTableData() {
   
        let userdata: any = {
          userID: this.userId
        }    
        if (this.isAdmin || this.isReviewer || this.isVerifier) { 
          this.rfiInspectionService.getAllCustomerFeedbackRecords().subscribe(data => {
     
            if (!data) {
                return;        
            }
            this.temp = data;

            this.generatedata();

          });
        } else if (this.isCoordinator) {      
          this.rfiInspectionService.getAllCustomerFeedbackRecordsForCoordinator(userdata).subscribe(data => {
            if (!data) {
              return;
            }
            this.temp = data;
            this.generatedata();
          });
        } else if (this.isInspector) {          
          this.rfiInspectionService.getAllCustomerFeedbackRecordsByInspector(userdata).subscribe(data => {
     
            if (!data) {
              return;
            }
            this.temp = data;            
            this.generatedata();
          });
        }

  }

  generatedata(){
    console.log(this.temp);
    for (let i = 0; i < this.temp.length; i++) {
      if (this.temp[i].phoneNo!== undefined &&this.temp[i].phoneNo === '+0-0')
      {
        this.temp[i].phoneNo='';
      }

      if (this.temp[i].project) {
        if (this.temp[i].project.name) {
          this.temp[i].projectName = this.temp[i].project.name;
        }
      }

      if (this.temp[i].inspectorDetailz && this.temp[i].inspectorDetailz.name) {
        this.temp[i].inspectorName = this.temp[i].inspectorDetailz.name;
      }
      if (this.temp[i].equipment) {
        this.temp[i].equipmentNumber = this.temp[i].equipment.equipmentNumber;
      }

      if (this.temp[i].categoryDetail && this.temp[i].categoryDetail.name) {
        this.temp[i].categoryName = this.temp[i].categoryDetail.name;
      }
      if (this.temp[i].scheduleInspectionDetail && this.temp[i].scheduleInspectionDetail.project) {
        this.temp[i].projectNumber = this.temp[i].scheduleInspectionDetail.project.number
      }
      if (this.temp[i].clientDetail && this.temp[i].clientDetail.client) {
        this.temp[i].clientName = this.temp[i].clientDetail.client.clientName
      }
      if (this.temp[i].inspectionDate) { this.temp[i].inspectionDates = moment(new Date(this.temp[i].inspectionDate)).format('DD/MMM/YYYY'); }
      if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
      if (this.temp[i].updatedDate) { this.temp[i].updatedDate = moment(new Date(this.temp[i].updatedDate)).format('DD/MM/YYYY'); }        
    }
    
    this.temp1 = this.temp;
    this.dataSource = new MatTableDataSource(this.temp);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  exportExcel() {
    var data1 = [];
    var i = 1;
    
    this.temp1.forEach(element => {
      var val = {
        'RFI No': element.inspectionSerialNumber,
        'Report No': element.reportNo,
        'Inspection Date': element.inspectionDates,
        'Project/Rig No': element.projectNumber,
        'Client Name': element.clientName,
        'Inspector Name': element.inspectorName,
        'Category': element.categoryName,
        'Created By': element.createdBy,
        'Created Date': element.createdDate
      };
      i = i + 1;
      data1.push(val);
    });
    this.excelService.saveAsExcelFile(data1, 'Clientfeedback');
  }

  GetFeedbackrating(data : any) {
    console.log(data);
      this.ClientfeedbackService.addRFIRatings(data)
        .subscribe((res: any) => {
          if (res['statusBool']) {
            this.snackBar.open(res['message'], 'OK', { duration: 2000, verticalPosition: "bottom" });
            this.ngOnInit();
          }
        }, err => {
          if (err.error.statusHttp === 412) {
            this.snackBar.open(err.error['message'], 'OK', { duration: 2000, verticalPosition: "bottom" });
            this.ngOnInit();
          }
        });
    
    const dialogRef = this.dialog.open(ClientfeedbackFormComponent, {
      data: { data },
      disableClose: false,
      hasBackdrop: true,
      height: "750px",
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.reportNo = d.reportNo ? d.reportNo : '';
      d.inspectionSerialNumber = d.inspectionSerialNumber ? d.inspectionSerialNumber : '';
      d.inspectionDates = d.inspectionDates ? d.inspectionDates : '';
      d.inspectorName = d.inspectorName ? d.inspectorName : '';
      d.categoryName = d.categoryName ? d.categoryName : '';
      d.projectNumber = d.projectNumber ? d.projectNumber : '';
      d.clientName = d.clientName ? d.clientName : ''; 
      return (
        d.reportNo.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.inspectionSerialNumber.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.inspectionDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.inspectorName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.categoryName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.projectNumber.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.clientName.toLowerCase().indexOf(val) !== -1 || !val) 
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
