import { Component, OnInit, ViewChild, Inject,ElementRef } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
  MatDialogConfig,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material';

import { InspectorService } from '../inspector.service';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { ExcelService } from '../../../excel.service';
import { AuthService } from '../../../auth/auth.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { InspectorFromComponent } from '../../../master/inspector/inspector-from/inspector-from.component';

@Component({
  selector: 'app-inspector-list',
  templateUrl: './inspector-list.component.html',
  styleUrls: ['./inspector-list.component.css']
})
export class InspectorListComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild('search',{static:false}) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  // dataSource: CompanyListDataSource;
  dataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [   
    'name',
    'emailId',
    'phoneNo',
    'mapCheckList'  
       
  ];
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  permission: any;
  isWrite = false;
  constructor(
    private dialog: MatDialog,
    private InspectorService: InspectorService,  private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,  private excelService: ExcelService, private authService: AuthService
  ) { }

  ngOnInit() {
    // this.getUser();
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;
    this.getTableData();
    if (this.permission && this.permission.inspections && this.permission.master.mapChecklist && this.permission.master.mapChecklist.write) {
      this.isWrite = true;
    } else {
      this.displayedColumns = [   
        'name',
        'emailId',
        'phoneNo'];
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  
  getTableData() {
    this.InspectorService.getInspectors().subscribe(data => {
      if (!data) {
        return;
      }
      
      this.temp = data;
      for (let i = 0; i < this.temp.length; i++) {
        if (this.temp[i].phoneNo!== undefined &&this.temp[i].phoneNo === '+0-0')
        {
          this.temp[i].phoneNo='';
        }
        if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
        if (this.temp[i].updatedDate) { this.temp[i].updatedDate = moment(new Date(this.temp[i].updatedDate)).format('DD/MM/YYYY'); }        
      }
      
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });
  }
  
  GetChecklist(data) {
    const dialogRef = this.dialog.open(InspectorFromComponent, {
      data: { data },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  exportExcel() {
    var data1 = [];
    var i = 1;
    
    this.temp1.forEach(element => {
      var val = {
        "Inspector": element.name,
        "Email ID": element.emailId,
        "Phone No": element.phoneNo
      };
      i = i + 1;
      data1.push(val);
    });
    this.excelService.saveAsExcelFile(data1, 'InspectorMaster');
  }
  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.name = d.name ? d.name : '';
      d.emailId = d.emailId ? d.emailId : '';
      d.phoneNo = d.phoneNo ? d.phoneNo : '';      
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.emailId.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.phoneNo.toLowerCase().indexOf(val) !== -1 || !val);
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}


