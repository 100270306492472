import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Chart } from 'chart.js';
import { UserService } from '../../admin/user/user.service'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { RfiInspectionService } from '../../master/rfi-inspection/rfi-inspection.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-inspection-status-pchart',
  templateUrl: './inspection-status-pchart.component.html',
  styleUrls: ['./inspection-status-pchart.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class InspectionStatusPchartComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;
  currentPermissions: any;
  toSearch: any;
  isShow = true;
  isInspector = true;
  user: any;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  alwaysShowCalendars: boolean;
  usersData: any;
  users: any;
  userName: any;
  inspectionDate = new FormControl('');
  inspector = new FormControl('');
  title = 'angular8chartjs';
  canvas: any;
  myChart: any;
  list1 = 0;
  list2 = 0;
  list3 = 0;
  list4 = 0;
  list5 = 0;
  list6 = 0;
  list7 = 0;
  list8 = 0;
  list1Name = ''
  list2Name = ''
  list3Name = ''
  list4Name = ''
  list5Name = ''
  list6Name = ''
  list7Name = ''
  list8Name = ''
  tempTotalCount = 0;
  finalList1 = '';
  finalList2 = '';
  finalList3 = '';
  finalList4 = '';
  finalList5 = '';
  finalList6 = '';
  finalList7 = '';
  finalList8 = '';
  inspectorColumn = false;

  date = new Date();
  a = moment().startOf('day').subtract(1, 'month');
  b = moment().endOf('day')

  constructor(private userService: UserService, private snackBar: MatSnackBar,
    private rfiInspectionService: RfiInspectionService) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    if (this.user.data.role.length > 0) {
      for (var i = 0; i < this.user.data.role.length; i++) {
        if (this.user.data.role[i].name == 'Inspector' && this.user.data.role[i].isActive === true) {
          this.inspector.setValue(this.user.data._id)
          this.inspectorColumn = true;
        } else {
          this.inspector.setValue('All');
          this.inspectorColumn = false;
        }
      }
    }
    this.inspectionDate.setValue({
      start: moment(this.a).format('MM/DD/YYYY'),
      end: moment(this.b).format('MM/DD/YYYY')
    });

    this.getUsers();
    this.getData(this.inspectionDate.value,this.inspector.value);
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  getUsers = () => {
    this.userService.getInspectors().subscribe(res => {
      this.users = res;
      this.usersData = this.users;

    });
  }
  filterInspector(val) {
    val = val.toLowerCase();
    this.usersData = this.users.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }
  onResize(event){
    event.target.innerWidth; // window width
    this.getData(this.inspectionDate.value,this.inspector.value);

  }
  getData(inspectionDate, inspector) {
    if (inspectionDate === null || inspectionDate.start === null) {
      inspectionDate = {
        startDate: null,
        endDate: null
      };

    } else {
      if (inspectionDate.start == undefined) {
        this.snackBar.open('Select date range', '', { duration: 2000, verticalPosition: "bottom" });
        return;
      }
      if (this.inspectionDate.value.start._d) {
        inspectionDate = {
          startDate: this.inspectionDate.value.start._d,
          endDate: this.inspectionDate.value.end._d
        };
      } else {
        inspectionDate = {
          startDate:this.inspectionDate.value.start,
          endDate: this.inspectionDate.value.end
        };
      }

    }
    this.toSearch = {
      inspectionDate: inspectionDate,
      inspector: inspector,
    };
    this.isShow = true;
    this.rfiInspectionService.getStatusByDateN(this.toSearch).subscribe(result => {
      if (!result) {
        return
      }
      this.list1 = 0;
      this.list2 = 0;
      this.list3 = 0;
      this.list4 = 0;
      this.list5 = 0;
      this.list6 = 0;
      this.list7 = 0;
      this.list8 = 0;
      this.list1Name = ''
      this.list2Name = ''
      this.list3Name = ''
      this.list4Name = ''
      this.list5Name = ''
      this.list6Name = ''
      this.list7Name = ''
      this.list8Name = ''
      this.finalList1 = ''
      this.finalList2 = ''
      this.finalList3 = ''
      this.finalList4 = ''
      this.finalList5 = ''
      this.finalList6 = ''
      this.finalList7 = ''
      this.finalList8 = ''
      var templistPercentage1 = 0;
      var templistPercentage2 = 0;
      var templistPercentage3 = 0;
      var templistPercentage4 = 0;
      var templistPercentage5 = 0;
      var templistPercentage6 = 0;
      var templistPercentage7 = 0;
      var templistPercentage8 = 0;
      if (result.length > 0) {
        this.isShow = true;
        /* for (var i = 0; i < result.length; i++) {
          if (result[i].activatedStatus) {
            if (result[i].activatedStatus.status == "Inspection scheduled") {
              this.list1 = this.list1 + 1
              this.list1Name = result[i].activatedStatus.status
            }
            if (result[i].activatedStatus.status == "Schedule accepted") {
              this.list2 = this.list2 + 1
              this.list2Name = result[i].activatedStatus.status
            }
            if (result[i].activatedStatus.status == "Inspection completed. Send for review") {
              this.list3 = this.list3 + 1
              this.list3Name = result[i].activatedStatus.status
            }
            if (result[i].activatedStatus.status == "Waiting for review") {
              this.list4 = this.list4 + 1
              this.list4Name = result[i].activatedStatus.status
            }
            if (result[i].activatedStatus.status == "Review completed. Waiting for OTP approval") {
              this.list5 = this.list5 + 1
              this.list5Name = result[i].activatedStatus.status
            }
            if (result[i].activatedStatus.status == "Approved") {
              this.list6 = this.list6 + 1
              this.list6Name = result[i].activatedStatus.status
            }
            if (result[i].activatedStatus.status == "Schedule rejected") {
              this.list7 = this.list7 + 1
              this.list7Name = result[i].activatedStatus.status
            }
            if (result[i].activatedStatus.status == "Cancelled") {
              this.list8 = this.list8 + 1
              this.list8Name = result[i].activatedStatus.status
            }
          }
        } */

        this.list1Name = "Inspection scheduled";
        this.list2Name = "Schedule accepted";
        this.list3Name = "Inspection completed. Send for review";
        this.list4Name = "Waiting for review";
        this.list5Name = "Review completed. Waiting for OTP approval";
        this.list6Name = "Approved";
        this.list7Name = "Schedule rejected";
        this.list8Name = "Cancelled";
        console.log("ttt");
        console.log(result);
        this.list1 = result[0].Inspectionscheduled;
        this.list2 = result[0].Scheduleaccepted;
        this.list3 = result[0].Inspectioncompleted;
        this.list4 = result[0].Waitingforreview;
        this.list5 = result[0].Reviewcompleted;
        this.list6 = result[0].Approved;
        this.list7 = result[0].Schedulerejected;
        this.list8 = result[0].Cancelled;

            this.tempTotalCount = this.list1 + this.list2 + this.list3 + this.list4 + this.list5 + this.list6 + this.list7 + this.list8

            templistPercentage1 = (this.list1 * 100 / this.tempTotalCount);
            this.finalList1 = this.list1Name + ' ' + '(' + this.list1 + '-' + Math.round(templistPercentage1) + "%" + ')'

            templistPercentage2 = (this.list2 * 100 / this.tempTotalCount)
            this.finalList2 = this.list2Name + ' ' + '(' + this.list2 + '-' + Math.round(templistPercentage2) + "%" + ')'

            templistPercentage3 = (this.list3 * 100 / this.tempTotalCount);
            this.finalList3 = this.list3Name + ' ' + '(' + this.list3 + '-' + Math.round(templistPercentage3) + "%" + ')'

            templistPercentage4 = (this.list4 * 100 / this.tempTotalCount);
            this.finalList4 = this.list4Name + ' ' + '(' + this.list4 + '-' + Math.round(templistPercentage4) + "%" + ')'

            templistPercentage5 = (this.list5 * 100 / this.tempTotalCount);
            this.finalList5 = this.list5Name + ' ' + '(' + this.list5 + '-' + Math.round(templistPercentage5) + "%" + ')'

            templistPercentage6 = (this.list6 * 100 / this.tempTotalCount);
            this.finalList6 = this.list6Name + ' ' + '(' + this.list6 + '-' + Math.round(templistPercentage6) + "%" + ')'

            templistPercentage7 = (this.list7 * 100 / this.tempTotalCount);
            this.finalList7 = this.list7Name + ' ' + '(' + this.list7 + '-' + Math.round(templistPercentage7) + "%" + ')'

            templistPercentage8 = (this.list8 * 100 / this.tempTotalCount);
            this.finalList8 = this.list8Name + ' ' + '(' + this.list8 + '-' + Math.round(templistPercentage8) + "%" + ')'

            this.canvas = document.getElementById('myChart');
            this.myChart = new Chart(this.canvas, {
              type: 'pie',

              data: {
                labels: [this.finalList1, this.finalList2, this.finalList3, this.finalList4, this.finalList5, this.finalList6, this.finalList7, this.finalList8],
                // labels: ['Inspection scheduled', 'Schedule accepted', 'Inspection completed. Send for review', 'Waiting for review', 'Review completed. Waiting for OTP approval', 'Approved', 'Schedule rejected', 'Cancelled'],
                datasets: [{
                  label: '# of Votes',
                  data: [this.list1, this.list2, this.list3, this.list4, this.list5, this.list6, this.list7, this.list8],
                  backgroundColor: [
                    '#212CB4',
                    '#3BBDDE',
                    '#D11EC6',
                    '#7D888A',
                    '#37D11E',
                    '#4AC959',
                    '#F1475B',
                    "#F19447"
                  ],
                  borderWidth: 1
                }]
              },
              options: {
                cutoutPercentage: 1,
                responsive: true,

                legend: {
                  display: true,
                  position: 'bottom',
                  

                  labels: {
                    fontColor: '#333',
                    fontSize:10

                    // usePointStyle: true
                  }
                },
                             }
            });
         
      }
      else {
        this.isShow = false;
        this.snackBar.open('No record found', '', { duration: 2000, verticalPosition: "bottom" });
      }

    });

  }
}
