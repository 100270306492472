import { Component, OnInit, Inject, ViewChild,NgZone } from '@angular/core';
import { ManufactureService } from '../manufacture.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';
@Component({
  selector: 'app-manufacture-form',
  templateUrl: './manufacture-form.component.html',
  styleUrls: ['./manufacture-form.component.css']
})
export class ManufactureFormComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  inputData: any;
  user: any;


  name = new FormControl('', [Validators.required]);
  description = new FormControl('');

  _id: string;
  isOffline: boolean;
  constructor(
    private dialog: MatDialog,
    private manufactureService: ManufactureService,
    private dialogRef: MatDialogRef<ManufactureFormComponent>,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,private offlineStorage: OfflineStorageService,private ngZone:NgZone,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    if (Object.keys(this.data).length !== 0) {
      this.name.setValue(this.data.name);
      this.description.setValue(this.data.description);
    }
    let value = localStorage.getItem('isOffline');
    if (value == 'true') {
      this.isOffline = true;
    }
    else {
      this.isOffline = false;
    }
  }


  save(
    name: String,
    description: String,
  ): void {
    if (!name) {
      return;
    }
    this.inputData = {
      name: name,
      description: description
    };
    if (Object.keys(this.data).length !== 0) {
      this.inputData._id = this.data._id;
      this.inputData.updatedBy = this.user.data.name;
      this.manufactureService.updateRecord(this.inputData).subscribe(res => {
        if (res[0]['statusBool']) {
          this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        }
      }, err => {
        if (err.error[0].statusHttp == 412) {
          this.snackBar.open(err.error[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else {
      this.inputData.createdBy = this.user.data.name;
      if (this.isOffline == true) {
        let isExist = false;

        this.offlineStorage.manufacturehandler.getManufacture(this, function (res) {
          let _this = res.self;
          let manufactureData = [];
          if (res && res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              var subData1 = JSON.parse(res.data.item(i).manufactureData);

              manufactureData.push(subData1);
            }
          }

          if (manufactureData && manufactureData.length > 0) {
            manufactureData.forEach(element => {
              if (element.name == name) {
                isExist = true;
              }
            });
          }
          if (!isExist) {
            let manufactureInfo = {

              name: _this.inputData.name,
              description: _this.inputData.description,
              createdBy: _this.inputData.createdBy
            }
            _this.offlineStorage.manufacturehandler.insertOfflineManufactureDetails(_this, manufactureInfo, function (res) {
              let _this = res.self;
              if (res) {
                var data2 = {
                  _id: res.data.insertId,
                  name: _this.inputData.name,
                  description: _this.inputData.description,
                  createdBy: _this.inputData.createdBy,
                  string:'new'
                }
                _this.ngZone.run(() => {
                  setTimeout(() => {
                    _this.snackBar.open('Saved successfully', '', {
                      duration: 5000,
                      verticalPosition: 'bottom',
                    });
                  }, 0);
                })
                _this.dialogRef.close(data2);
                console.log(res);
              }
            })
          }
          else {

            _this.ngZone.run(() => {
              setTimeout(() => {
                _this.snackBar.open('Duplicate entry', '', {
                  duration: 5000,
                  verticalPosition: 'bottom',
                });
              }, 0);
            });
          }

        });


      } else {
        this.manufactureService.save(this.inputData).subscribe(res => {
          if (res['statusBool']) {
            this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
            this.dialogRef.close(this.inputData);
          }
        }, err => {
          if (err.error.statusHttp == 412) {
            this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
        });
      }
    }
  }

  getErrorManufacture() {
    return this.name.hasError('required')
      ? 'Name is required'
      : '';
  }
  btnClose() {
    if (
      this.name.dirty ||
      this.description.dirty
    ) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: false,
        hasBackdrop: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
