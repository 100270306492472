import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
  MatDialogConfig,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { ExcelService } from '../../../excel.service';
import { AuthService } from '../../../auth/auth.service';
import { EquipmentService } from '../../../master/equipment/equipment.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { MobileLogPopupComponent } from '../mobile-log-popup/mobile-log-popup.component';
import { MobileLogService } from '../mobile-log.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-mobile-log-list',
  templateUrl: './mobile-log-list.component.html',
  styleUrls: ['./mobile-log-list.component.css']
})
export class MobileLogListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  alwaysShowCalendars: boolean;
  // dataSource: CompanyListDataSource;
  dataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'rfiNo',
    'module',
    'detail',
    'inspector',
    'createdBy',
    'createdDate',

  ];
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  permission: any;
  isWrite = false;

  dateRange = new FormControl('');

  date = new Date();
  a = moment().subtract(1, 'days');
  b = moment().endOf('day');
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet, private excelService: ExcelService, private authService: AuthService,
    private mobileLogService: MobileLogService
  ) { }

  ngOnInit() {
    this.dateRange.setValue({
      start: moment(this.a).format('MM/DD/YYYY'),
      end: moment(this.b).format('MM/DD/YYYY')
    });
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;
    this.getTableData();
      this.displayedColumns = ['rfiNo', 'module','detail', 'inspector', 'createdBy', 'createdDate'];
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  getTableData() {
    var inputData = {
      date: {
        fromDate: this.dateRange.value.start,
        toDate: this.dateRange.value.end
      },
    }
    this.mobileLogService.getAllRecords(inputData).subscribe(data => {
      if (!data) {
        return;
      }
      this.temp = data;

      for (let i = 0; i < this.temp.length; i++) {
        if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY hh:mm a'); }
      }
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });

  }



  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.rfiNo = d.rfiNo ? d.rfiNo : '';
      d.module = d.module ? d.module : '';
      d.inspector = d.inspector ? d.inspector : '';
      d.createdBy = d.createdBy ? d.createdBy : '';
      d.createdDate = d.createdDate ? d.createdDate : '';
      return (d.rfiNo.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.module.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.inspector.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdBy.toLowerCase().indexOf(val) !== -1 || !val);
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  detail(element) {
    const dialogRef = this.dialog.open(MobileLogPopupComponent, {
      data: { row: element },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
}