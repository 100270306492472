import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
  MatDialogConfig,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material';
import { ExcelService } from '../../excel.service';
import { LogService } from '../log.service';
import { AuthService } from '../../auth/auth.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  // dataSource: CompanyListDataSource;
  dataSource;
  displayedColumns = [
    'module',
    'reportNO',
    'error',
    'status',
    'action',
    'createdDate'
  ];
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  permission: any;
  isWrite = false;
  constructor(
    private dialog: MatDialog, private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private excelService: ExcelService,
    private logService: LogService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.getTableData();
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  getTableData() {
    this.logService.getLog().subscribe(data => {
      if (!data) {
        return;
      }
      this.temp = data;
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });
  }

  exportExcel() {
    var data1 = [];
    var i = 1;
    this.temp1.forEach(element => {
      var val = {
        "Module": element.module,
        "Report No": element.reportNO,
        'Error': element.error,
        'Status': element.status,
        'Action': element.action,
        'Created Date': element.createdDate,
      };
      i = i + 1;
      data1.push(val);
    });
    this.excelService.saveAsExcelFile(data1, 'Log');
  }
  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.module = d.module ? d.module : '';
      d.reportNO = d.reportNO ? d.reportNO : '';
      d.error = d.error ? d.error : '';
      // d.status = d.status ? d.status : '';
      d.action = d.action ? d.action : '';
      d.createdDate = d.createdDate ? d.createdDate : '';
      return (d.module.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.reportNO.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.error.toLowerCase().indexOf(val) !== -1 || !val) ||
        // (d.status.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.action.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdDate.toLowerCase().indexOf(val) !== -1 || !val);
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

}