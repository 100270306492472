import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ChecklistService } from '../checklist.service';
// import { ToastrService } from 'ngx-toastr';  
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar
} from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CategoryService } from '../../../master/category/category.service';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-checklist-form2',
  templateUrl: './checklist-form2.component.html',
  styleUrls: ['./checklist-form2.component.css']
})
export class ChecklistForm2Component implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  inputData: any;
  user: any;
  _id: string;
  categoryList: any;
  formType: boolean = false;
  categoryDetails = [];
  commonDynamicList = [];
  constructor(
    private checklistService: ChecklistService,
    private dialogRef: MatDialogRef<ChecklistForm2Component>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
  }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');

    if (this.data._id) {
      if (this.data.formType && this.data.formType === 'DOC') {
        this.formType = true;
        this.commonDynamicList = this.data.referenceDocuments;
      } else {
        this.commonDynamicList = this.data.categoryDetails;
      }
      this.init();
    }
  }

  save(): void {
    this.inputData = {};
    let isValidate = true;
    if (this.categoryDetails && this.categoryDetails.length > 0) {
      for (let i = 0; i < this.categoryDetails.length; i++) {
        if (this.categoryDetails[i].name === '') {
          isValidate = false;
          break;
        }
      }

      if (!isValidate) {
        this.snackBar.open('Field must not be empty', '', { duration: 2000, verticalPosition: "bottom" });
        return;
      }

      if (this.data.formType && this.data.formType === 'DOC') {
        this.inputData.referenceDocuments = this.categoryDetails;
      } else {
        this.inputData.categoryDetails = this.categoryDetails;
      }
      if (Object.keys(this.data).length !== 0) {
        this.inputData._id = this.data._id;
        this.inputData.updatedBy = this.user.data.name;
        this.checklistService.updateForm2Details(this.inputData).subscribe(res => {
          if (res[0]['statusBool']) {
            this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
            this.dialogRef.close();
          }
        }, err => {
          if (err.error[0].statusHttp == 412) {
            this.snackBar.open(err.error[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
        });
      } else {

      }
    }
  }

  initDynamicInput() {
    if (this.data.formType && this.data.formType === 'DOC') {
      this.categoryDetails = [{
        name: "",
        "addNew": true,
        "remove": false,
        "isActive": true
      }];
    } else {
      this.categoryDetails = [{
        name: "",
        subItem: [{
          "name": '',
          "isActive": true,
          "addNew": true,
          "remove": false,
          question: [{
            name: '',
            reference: '',
            passFail: null,
            NA: null,
            reInspectionDate: null,
            "addNew": true,
            "remove": false,
            "isActive": true
          }],
        }],
        "addNew": true,
        "remove": false,
        "isActive": true
      }];
    }
  }

  init() {
    debugger
    if (this.commonDynamicList && this.commonDynamicList.length > 0) {
      for (var i = 0; i < this.commonDynamicList.length; i++) {
        this.commonDynamicList[i].addNew = false;
        this.commonDynamicList[i].remove = true;
        this.commonDynamicList[i].isActive = true;

        if (!(this.data.formType && this.data.formType === 'DOC')) {
          for (var j = 0; j < this.commonDynamicList[i].subItem.length; j++) {
            if (this.commonDynamicList[i].subItem != undefined && this.commonDynamicList[i].subItem.length > 0) {
              this.commonDynamicList[i].subItem[j].addNew = false;
              this.commonDynamicList[i].subItem[j].remove = true;
              this.commonDynamicList[i].subItem[j].isActive = true;
            }

            for (var k = 0; k < this.commonDynamicList[i].subItem[j].question.length; k++) {
              if (this.commonDynamicList[i].subItem[j].question != undefined && this.commonDynamicList[i].subItem[j].question.length > 0) {
                this.commonDynamicList[i].subItem[j].question[k].addNew = false;
                this.commonDynamicList[i].subItem[j].question[k].remove = true;
                this.commonDynamicList[i].subItem[j].question[k].isActive = true;
              }
            }
            var last_element = this.commonDynamicList[i].subItem[j].question[this.commonDynamicList[i].subItem[j].question.length - 1];
            last_element.addNew = true;
            last_element.remove = true;
            if (this.commonDynamicList[i].subItem[j].question.length == 1) {
              last_element.remove = false;
            }
          }

          var last_element = this.commonDynamicList[i].subItem[this.commonDynamicList[i].subItem.length - 1];
          last_element.addNew = true;
          last_element.remove = true;
          if (this.commonDynamicList[i].subItem.length == 1) {
            last_element.remove = false;
          }

        }
        this.categoryDetails.push(this.commonDynamicList[i]);
        var last_element = this.categoryDetails[this.categoryDetails.length - 1];
        last_element.addNew = true;
        last_element.remove = true;
        if (this.categoryDetails.length == 1) {
          last_element.remove = false;
        }
      }
    } else {
      this.initDynamicInput();
    }

  }

  addCategoryRow(categoryDetails, index) {
    this.categoryDetails.push({
      name: "",
      subItem: [{
        "name": '',
        "isActive": true,
        "addNew": true,
        "remove": false,
        question: [{
          name: '',
          reference: '',
          passFail: null,
          NA: null,
          reInspectionDate: null,
          "addNew": true,
          "remove": false,
          "isActive": true
        }],
      }],
      "addNew": false,
      "remove": false,
      "isActive": true
    });
    this.categoryDetails.forEach(function addData(row, index) {
      row.addNew = false;
      row.remove = true;
    });
    var last_element = this.categoryDetails[this.categoryDetails.length - 1];
    last_element.addNew = true;
    last_element.remove = true;
  };

  removeCategoryRow(categoryDetails, index) {
    this.categoryDetails[index].isActive = false;
    this.categoryDetails.splice(index, 1);
    var last_element = this.categoryDetails[this.categoryDetails.length - 1];
    last_element.addNew = true;
    last_element.remove = true;
    if (this.categoryDetails.length == 1) {
      last_element.remove = false;
    }
  };

  addSubRow(categoryDetails, index) {
    debugger
    categoryDetails.subItem.push({
      "name": '',
      "isActive": true,
      "addNew": true,
      "remove": false,
      question: [{
        name: '',
        reference: '',
        passFail: null,
        NA: null,
        reInspectionDate: null,
        "addNew": true,
        "remove": false,
        "isActive": true
      }],
    });
    categoryDetails.subItem.forEach(function addData(row, index) {
      row.addNew = false;
      row.remove = true;
    });
    var last_element = categoryDetails.subItem[categoryDetails.subItem.length - 1];
    last_element.addNew = true;
    last_element.remove = true;
  };
  removeSubRow(categoryDetails, index) {
    categoryDetails.subItem[index].isActive = false;
    categoryDetails.subItem.splice(index, 1);
    var last_element = categoryDetails.subItem[categoryDetails.subItem.length - 1];
    last_element.addNew = true;
    last_element.remove = true;
    if (categoryDetails.subItem.length == 1) {
      last_element.remove = false;
    }
  };

  addQuestionRow(categoryDetails, question, index) {
    categoryDetails.question.push({
      name: "",
      reference: '',
      passFail: null,
      NA: null,
      reInspectionDate: null,
      "addNew": false,
      "remove": false,
      "isActive": true
    });
    categoryDetails.question.forEach(function addData(row, index) {
      row.addNew = false;
      row.remove = true;
    });
    var last_element = categoryDetails.question[categoryDetails.question.length - 1];
    last_element.addNew = true;
    last_element.remove = true;
  };

  removeQuestionRow(categoryDetails, question, index) {
    categoryDetails.question[index].isActive = false;
    categoryDetails.question.splice(index, 1);
    var last_element = categoryDetails.question[categoryDetails.question.length - 1];
    last_element.addNew = true;
    last_element.remove = true;
    if (categoryDetails.question.length == 1) {
      last_element.remove = false;
    }
  };

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  btnClose() {
    this.dialogRef.close();
  }

}