import { Component, OnInit, Inject, ViewChild, NgZone } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheet, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { AuthService } from '../../../auth/auth.service';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { FileUploader } from 'ng2-file-upload';
import { ActivatedRoute } from '@angular/router';
import { FileService } from '../../../services/files/file.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';

@Component({
  selector: 'app-attachment-form',
  templateUrl: './attachment-form.component.html',
  styleUrls: ['./attachment-form.component.css']
})

export class AttachmentFormComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public uploader: FileUploader = new FileUploader({ itemAlias: 'photo' });
  reportForm: any;
  user: any;
  inputData: any;
  file: any;
  temp = [];
  temp1 = [];
  dataSource;
  isUpdate: boolean = false;
  isNew: boolean = false;
  isShow: boolean = false;
  showDocument: any;
  jobById: any;
  showReportName = false;
  ID: any;
  displayedColumns = ['delete', 'name', 'createdBy', 'createdDate', 'download'];
  show = false;

  isInspector = false;
  isAdmin = false;
  isReviewer = false;
  isVerifier = false;
  isCoordinator = false;
  userRoles: any;
  userId: any;
  permission: any;
  isWrite = false;
  isOffline = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dialogRef: MatDialogRef<AttachmentFormComponent>,
    private bottomSheet: MatBottomSheet,
    private _fileService: FileService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar, private offlineStorage: OfflineStorageService, private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.reportForm = fb.group({
      'report': [null]
    });
  }

  getTableData() {
    var data = {
      refID: this.data.data._id,
      module: 'RFI'
    }
    if (this.isOffline) {
      this.offlineStorage.attachmentHandler.getAttachmentByRefID(this, data, function (res) {

        let _this = res.self;
        var tempData = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            tempData.push(JSON.parse(res.data.item(i).attachmentData));
          }
          for (let i = 0; i < tempData.length; i++) {
            if (tempData[i].createdDate) { tempData[i].createdDate = moment(new Date(tempData[i].createdDate)).format('DD/MM/YYYY'); }
          }
          _this.temp = tempData;
          _this.dataSource = new MatTableDataSource(tempData);
          _this.dataSource.paginator = _this.paginator;
          _this.dataSource.sort = _this.sort;
        }

      });
    } else {
      this._fileService.getRecord(data).subscribe(data => {
        if (!data) {
          return;
        }
        this.temp = data;
        for (let i = 0; i < this.temp.length; i++) {
          if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
          // if (this.temp[i].updatedDate) { this.temp[i].updatedDate = moment(new Date(this.temp[i].updatedDate)).format('DD/MM/YYYY'); }
        }
        this.temp1 = this.temp;
        this.dataSource = new MatTableDataSource(this.temp);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      });
    }
  }

  ngOnInit() {
    this.ID = this.data.data._id;
    this.user = this.getFromLocalStorage('userDet');
    let value = localStorage.getItem('isOffline');
    if (value === 'true') {
      this.isOffline = true;
    } else {
      this.isOffline = false;
    }
    this.permission = this.user.data.permission;
    if (this.permission && this.permission.inspections && this.permission.inspections.rfi && this.permission.inspections.rfi.write) {
      this.isWrite = true;
    } else {
      this.displayedColumns = ['name', 'createdBy', 'createdDate', 'download'];
    }
    this.getTableData();
    this.uploader.onAfterAddingFile = (file) => {
      console.log(file);
      file.withCredentials = false;
      this.file = file;
      var reader = new FileReader();
      console.log(this.file.file.rawFile);
      reader.readAsDataURL(this.file.file.rawFile);
      reader.onload = function () {
        debugger
        console.log(reader);
        console.log(reader.result);
        file.formData = reader.result;
      };
      reader.onerror = function (error) {
      };
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    };
    this.getUser();
  }

  getUser() {
    this.user = this.getFromLocalStorage('userDet');
    this.userId = this.user.data._id;
    this.userRoles = this.user.data.role;
    this.checkRoles();
  }

  checkRoles() {
    for (var i = 0; i < this.userRoles.length; i++) {
      if (this.userRoles[i].name === 'Administrator' && this.userRoles[i].isActive === true) {
        this.isAdmin = true;
      } else if (this.userRoles[i].name === 'Inspector' && this.userRoles[i].isActive === true) {
        this.isInspector = true;
      } else if (this.userRoles[i].name === 'Reviewer' && this.userRoles[i].isActive === true) {
        this.isReviewer = true;
      } else if (this.userRoles[i].name === 'Co-ordinator' && this.userRoles[i].isActive === true) {
        this.isCoordinator = true;
      } else if (this.userRoles[i].name === 'Verifier' && this.userRoles[i].isActive === true) {
        this.isVerifier = true;
      }
    }
    if(this.isOffline){
      this.displayedColumns = ['name', 'createdBy', 'createdDate', 'download']
    }
    if (this.isInspector || this.isAdmin) {
      this.show = true;
    } else {
      this.displayedColumns = ['name', 'createdBy', 'createdDate', 'download']
    }
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  get f() {
    return this.reportForm.controls;
  }

  save(): void {
    const inputData = {
      filetype: this.file.file.type,
      filename: this.file.file.name,
      filesize: this.file.file.size,
      base64: this.file.formData,
      module: 'RFI',
      refID: this.data.data._id,
      createdBy: this.user.data.name
    };
    const tempInputData = {
      filetype: this.file.file.type,
      filename: this.file.file.name,
      filesize: this.file.file.size,
      content: this.file.formData,
      module: 'RFI',
      refID: this.data.data._id,
      createdBy: this.user.data.name,
      createdDate: new Date()
    };
    // console.log(inputData);
    if (this.isOffline) {
      this.offlineStorage.attachmentHandler.insertAttachmentsInOffline(this, tempInputData, function (res) {
        let _this = res.self;
        _this.ngZone.run(() => {
          setTimeout(() => {
            _this.snackbar.open('Saved successfully', '', {
              duration: 5000,
              verticalPosition: 'bottom',
            });
          }, 0);
        })
        _this.dialogRef.close();
        console.log(res);
      });
    } else {
      this._fileService.uploadFileForRFIReport(inputData).subscribe(result => {
        if (result['statusBool']) {
          this.snackbar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.reportForm.patchValue({ report: null });
          // this.dialogRef.close();
          this.ngOnInit();
        } else {
          this.snackbar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }

  }

  convertFiletoArray(file) {
    var fileArray = [];
    for (var i = 0; i < file.length; i++) {
      var array = { filename: file[i].file.name, filesize: file[i].file.size, filetype: file[i].file.type }
      fileArray.push(array);
    }
    return fileArray;
  }

  download(row) {
    let content = row.content.split(',')[1];
    var base64 = content;
    var blob = this._fileService._base64ToArrayBuffer(base64, row.filetype);

    if (environment.mobileApp) {
      if (this.isOffline) {

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        } else {
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
        }

      } else {
        this._fileService.openFileOnMobile(blob, row.filename, row.filetype)
      }
    } else {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
      } else {
        var objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
      }
    }
  }

  deleteTable(row) {
    const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
      data: { deleteItem: true },
      disableClose: false,
      hasBackdrop: true
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        row.updatedBy = this.user.data.name;
        this._fileService.deleteFile(row).subscribe(res => {
          if (res['statusBool']) {
            this.snackbar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
          this.ngOnInit();
        });
      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }

  btnClose() {
    this.dialogRef.close();
  }

}


