import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SiteLocationService } from '../site-location.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
@Component({
  selector: 'app-site-location-form',
  templateUrl: './site-location-form.component.html',
  styleUrls: ['./site-location-form.component.css']
})
export class SiteLocationFormComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  inputData: any;
  user: any;
  type = new FormControl('', [Validators.required]);
  name = new FormControl('', [Validators.required]);
  types: string[] = ['On-Shore', 'Off-Shore'];
  _id: string;
  constructor(
    private dialog: MatDialog,
    private siteLocationService: SiteLocationService,
    private dialogRef: MatDialogRef<SiteLocationFormComponent>,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    if (Object.keys(this.data).length !== 0) {
      this.type.setValue(this.data.type);
      this.name.setValue(this.data.name);
    }
  }
  save(
    type: String,
    name: String,
  ): void {
    if (!type || !name) {
      return;
    }
    this.inputData = {
      type: type,
      name: name
    };
    if (Object.keys(this.data).length !== 0) {
      this.inputData._id = this.data._id;
      this.inputData.updatedBy = this.user.data.name;
      this.siteLocationService.updateRecord(this.inputData).subscribe(res => {
        if (res[0]['statusBool']) {
          this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } 
      }, err => {
        if (err.error[0].statusHttp == 412) {
          this.snackBar.open(err.error[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else {
      this.inputData.createdBy = this.user.data.name;
      this.siteLocationService.save(this.inputData).subscribe(res => {
        if (res['statusBool']) {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } 
      }, err => {
        if (err.error.statusHttp == 412) {
          this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }
  }

  getErrorType() {
    return this.type.hasError('required')
      ? 'Type is required'
      : '';
  }
  getErrorName() {
    return this.name.hasError('required')
      ? 'Name is required'
      : '';
  }
  btnClose() {
    if (
      this.type.dirty ||
      this.name.dirty
    ) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: false,
        hasBackdrop: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
}
