import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting/setting.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ClientfeedbackService {

  private apiUrl = '';

  constructor(private http: HttpClient,
    private settingService:SettingService) {
  }

  getInspectors(): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('inspector');
    return this.http
      .post<any>(this.apiUrl + '/getInspectors', httpOptions)
      .pipe(tap());
  }
  getAllRecords(): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('rfi');
    return this.http
      .get<any>(this.apiUrl + '/getAllCustomerFeedbackRecords')
      .pipe(
        tap()
      );
  }
  getClientFeedback(data: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('feedback');
    return this.http
      .post<any>(this.apiUrl + '/getClientFeedback',data)
      .pipe(
        tap()
      );
  }
  addRFIRatings(data: any): Observable<any[]> {
   
    this.apiUrl = this.settingService.getLocalStorageAppURL('feedback');
    return this.http
      .post<any>(this.apiUrl + '/addRFIRatings',data)
      .pipe(
        tap()
      );
  }

  updateRFIRatings(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('feedback');
    return this.http
      .post<any>(this.apiUrl + '/updateRFIRatings',data)
      .pipe(
        tap()
      );
  }
}
