import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
} from '@angular/material';
import { ScheduleInspectionFormComponent } from '../schedule-inspection-form/schedule-inspection-form.component';
import { ScheduleInspectionService } from '../schedule-inspection.service';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { ExcelService } from '../../../excel.service';
import { AuthService } from '../../../auth/auth.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ScheduleInspectionReportComponent } from '../schedule-inspection-report/schedule-inspection-report.component';
import { InspectionPopupComponent } from '../../../general/inspection-popup/inspection-popup.component';
import { ViewScheduleInspectionnComponent }from '../view-schedule-inspectionn/view-schedule-inspectionn.component';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SettingService } from '../../../services/setting/setting.service'

@Component({
  selector: 'app-schedule-inspection-list',
  templateUrl: './schedule-inspection-list.component.html',
  styleUrls: ['./schedule-inspection-list.component.css']
})

export class ScheduleInspectionListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  // dataSource: CompanyListDataSource;
  dataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'edit',
    'keyNo',
    'inspectionDate',
    'project',
    'rpoNo',
    'woNo',
    'inspectionType',
    'siteLocationType',
    'reqEmail',
    // 'reqTelephone',
    // 'reqMobile',
    'approveInspection'
  ];
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  isMobileApp: boolean = false;
  userRoles: any;
  userId: any;

  isInspector = false;
  isAdmin = false;
  isReviewer = false;
  isVerifier = false;
  isCoordinator = false;
  permission: any;
  isWrite = false;
  companyName : any;

  constructor(
    private dialog: MatDialog,
    private ScheduleInspectionService: ScheduleInspectionService,
    private snackBar: MatSnackBar,private router: Router,
    private bottomSheet: MatBottomSheet, private excelService: ExcelService, private authService: AuthService,
    private settingService: SettingService
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');

    this.permission = this.user.data.permission;
    this.isMobileApp = environment.mobileApp;
    this.getCompanyName();
    this.getUser();
    this.getTableData();
    if (this.permission && this.permission.inspections && this.permission.inspections.schedule && this.permission.inspections.schedule.write) {
      this.isWrite = true;
    } else {
      this.displayedColumns = ['keyNo', 'inspectionDate', 'project', 'rpoNo', 'woNo', 'inspectionType', 'siteLocationType', 'reqEmail', 'approveInspection'
      ];
    }

  }
  getCompanyName() {
    this.settingService.getByKey('client').subscribe(result => {
      if (result.length > 0) {
        this.companyName = result[0].value;      
        
      }
    })

  }
  getUser() {
    this.user = this.getFromLocalStorage('userDet');
    this.userId = this.user.data._id;
    this.userRoles = this.user.data.role;
    this.checkRoles();
  }

  checkRoles() {
    for (var i = 0; i < this.userRoles.length; i++) {
      if (this.userRoles[i].name === 'Administrator' && this.userRoles[i].isActive === true) {
        this.isAdmin = true;
      } else if (this.userRoles[i].name === 'Inspector' && this.userRoles[i].isActive === true) {
        this.isInspector = true;
      } else if (this.userRoles[i].name === 'Reviewer' && this.userRoles[i].isActive === true) {
        this.isReviewer = true;
      } else if (this.userRoles[i].name === 'Co-ordinator' && this.userRoles[i].isActive === true) {
        this.isCoordinator = true;
      } else if (this.userRoles[i].name === 'Verifier' && this.userRoles[i].isActive === true) {
        this.isVerifier = true;
      }
    }
    console.log(this.isAdmin);
    console.log(this.isInspector);
    console.log(this.isCoordinator);
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  getTableData() {

    if (this.isCoordinator) {
      var data = {
        userID: this.userId
      }
      this.ScheduleInspectionService.getAllRecordsForCoordinator(data).subscribe(data => {
        if (!data) {
          return;
        }
        this.temp = data;
        for (let i = 0; i < this.temp.length; i++) {
          if (this.temp[i].project) {
            if (this.temp[i].project.name) {
              this.temp[i].projectName = this.temp[i].project.name;
            }
          }

          if (this.temp[i].inspector) {
            this.temp[i].inspectorName = this.temp[i].inspector.inspectorName;

          }
          if (this.temp[i].equipment) {
            this.temp[i].equipmentNumber = this.temp[i].equipment.equipmentNumber;
          }

          if (this.temp[i].category) {
            if (this.temp[i].category.ref) {
              this.temp[i].categoryName = this.temp[i].category.categoryName;
            }
          }
          this.temp[i].keyNo = this.temp[i].keyNo;
          if (this.temp[i].createdDate) { this.temp[i].inspectionDates = moment(new Date(this.temp[i].createdDate)).format('DD/MMM/YYYY'); }
          if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
          if (this.temp[i].updatedDate) { this.temp[i].updatedDate = moment(new Date(this.temp[i].updatedDate)).format('DD/MM/YYYY'); }
        }

        this.temp1 = this.temp;
        this.dataSource = new MatTableDataSource(this.temp);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      });
    }

    if (this.isAdmin || this.isReviewer || this.isVerifier) {
      this.ScheduleInspectionService.getAllRecords().subscribe(data => {
        if (!data) {
          return;
        }
        this.temp = data;
        for (let i = 0; i < this.temp.length; i++) {
          if (this.temp[i].project) {
            if (this.temp[i].project.name) {
              this.temp[i].projectName = this.temp[i].project.name;
            }
          }

          if (this.temp[i].inspector) {
            this.temp[i].inspectorName = this.temp[i].inspector.inspectorName;

          }
          if (this.temp[i].equipment) {
            this.temp[i].equipmentNumber = this.temp[i].equipment.equipmentNumber;
          }

          if (this.temp[i].category) {
            if (this.temp[i].category.ref) {
              this.temp[i].categoryName = this.temp[i].category.categoryName;
            }
          }
          this.temp[i].keyNo = this.temp[i].keyNo;
          if (this.temp[i].createdDate) { this.temp[i].inspectionDates = moment(new Date(this.temp[i].createdDate)).format('DD/MMM/YYYY'); }
          if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
          if (this.temp[i].updatedDate) { this.temp[i].updatedDate = moment(new Date(this.temp[i].updatedDate)).format('DD/MM/YYYY'); }
        }

        this.temp1 = this.temp;
        this.dataSource = new MatTableDataSource(this.temp);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }
  }
  btnAddNewClick() {
    const dialogRef = this.dialog.open(ScheduleInspectionFormComponent, {
      data: {},
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
    
  }
  viewSchedule() {
    // const dialogRef = this.dialog.open(ViewScheduleInspectionnComponent, {
    //   data: {},
    //   disableClose: false,
    //   hasBackdrop: true
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.ngOnInit();
    // });
    this.router.navigateByUrl('master/viewSchedule');
  }

  deleteTable(item) {
    const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
      data: { deleteItem: true },
      disableClose: false,
      hasBackdrop: true
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        this.ScheduleInspectionService.deleteById(item).subscribe(result => {
          if (result['statusBool'] == -1) {
            this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
            this.ngOnInit();
          }
          else {
            this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
            this.ngOnInit();
          }
        }, err => {
          throw err;
        });
      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }


  getTable(row) {

    let dialogRef = this.dialog.open(ScheduleInspectionFormComponent, {
      data: row,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }


  exportExcel() {
    let data1 = [];
    let i = 1;
    this.temp1.forEach(element => {
      let val = {
        'Key No': element.keyNo,
        'Scheduled Date': element.inspectionDate,
        'Project Name': element.projectName,
        'RPO No': element.rpoNo,
        'WO No': element.woNo,
        'Inspection Type': element.inspectionType,
        'Sitelocation Type': element.siteLocationType,
        'Requester Email': element.reqEmail,
        'Requester Telephone': element.reqTelephone,
        'Requester Mobile': element.reqMobile,
        'Created By': element.createdBy,
        'Created Date': element.createdDate,
        'Updated By': element.updatedBy,
        'Updated Date': element.updatedDate,
      };
      i = i + 1;
      data1.push(val);
    });
    this.excelService.saveAsExcelFile(data1, 'Schedule Master');
  }
  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.keyNo = d.keyNo ? d.keyNo : '';
      d.inspectionDate = d.inspectionDate ? d.inspectionDate : '';
      d.projectName = d.projectName ? d.projectName : '';
      d.rpoNo = d.rpoNo ? d.rpoNo : '';
      d.woNo = d.woNo ? d.woNo : '';
      d.inspectionType = d.inspectionType ? d.inspectionType : '';
      d.siteLocationType = d.siteLocationType ? d.siteLocationType : '';
      d.reqEmail = d.reqEmail ? d.reqEmail : '';
      d.createdBy = d.createdBy ? d.createdBy : '';
      d.createdDate = d.createdDate ? d.createdDate : '';
      d.updatedBy = d.updatedBy ? d.updatedBy : '';
      d.updatedDate = d.updatedDate ? d.updatedDate : '';
      return (d.keyNo.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.inspectionDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.projectName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.rpoNo.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.woNo.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.inspectionType.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.siteLocationType.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.reqEmail.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedDate.toLowerCase().indexOf(val) !== -1 || !val);
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  createReport(data) {
    const dialogRef = this.dialog.open(ScheduleInspectionReportComponent, {
      data: { data },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  approveForInspection(row) {
    let dialogRef = this.dialog.open(InspectionPopupComponent, {
      data: row,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

}
