import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { PermissionService } from '../../permission/permission.service';
import { WidgetService } from '../widget.service';

@Component({
  selector: 'app-widget-form',
  templateUrl: './widget-form.component.html',
  styleUrls: ['./widget-form.component.css']
})
export class WidgetFormComponent implements OnInit {
  user: any;
  permission: any;
  moduleList: any;
  widgetForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<WidgetFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private permissionService: PermissionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private widgetService: WidgetService) { }

  ngOnInit() {
    this.createForm();
    this.getPermissions();
  }

  createForm() {
    this.widgetForm = this.fb.group({
      module: ['', Validators.required],
      name: ['', Validators.required],
      componentName: ['', Validators.required],
      noCols: '',
      noRows: '',
      description: ['']
    });
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  getPermissions() {
    this.permissionService.getRecord().subscribe(results => {
      if (!results) {
        return;
      }
      const moduleList = [];
      this.permission = results || [];
      this.permission.forEach(row => {
        if (row.module != null) {
          const isvalid = moduleList.includes(row.module);
          if (!isvalid) {
            moduleList.push(row.module);
          }
        }
      });
      this.moduleList = moduleList;
    });

  }

  btnClose() {
    this.dialogRef.close(false);
  }

  async save() {
    this.user = this.getFromLocalStorage('userDet');

    try {
      const widget = this.widgetForm.value;
      if (widget.module === undefined || widget.module.length === 0) {
        return;
      }
      if (widget.name === undefined || widget.name.length === 0) {
        return;
      }
      if (widget.componentName === undefined || widget.componentName.length === 0) {
        return;
      }
      widget.cols = widget.noCols;
      widget.rows = widget.noRows;
      widget.createdBy = this.user.data.name;
      const data: any = await this.widgetService.createRecord(widget);
      if (data !== undefined && data.statusBool !== false) {
        this.snackBar.open(data.message, '', { duration: 2000, verticalPosition: "bottom" });
        this.dialogRef.close(true);
      } else if (data !== undefined) {
        this.snackBar.open(data.message, '', { duration: 2000, verticalPosition: "bottom" });
      }
    } catch (e) {
      if (e.error && e.error.message) {
        this.snackBar.open(e.error.message, '', { duration: 2000, verticalPosition: "bottom" });
      } else {
        this.snackBar.open(e.message, '', { duration: 2000, verticalPosition: "bottom" });
      }
    }
  }

}
