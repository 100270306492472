import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { PermissionService } from '../../permission/permission.service';
import { FormControl, Validators } from '@angular/forms';
import { RoleManagementService } from '../role-management.service';
import { AlertDialogComponent } from '../../../general/alert-dialog/alert-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-role-management-edit',
  templateUrl: './role-management-edit.component.html',
  styleUrls: ['./role-management-edit.component.css']
})
export class RoleManagementEditComponent implements OnInit {
  permissions = []; inputData: any; data: any;
  chkAllWrite = true; chkAllRead = true; chkAllReadWrite = true;
  isDisabled: Boolean; roleName: String;
  name = new FormControl('', [Validators.required]);
  description = new FormControl();
  user: any; permissionData = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private roleManagementService: RoleManagementService
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.isDisabled = false;
    this.getPermissions();
    const id = this.route.snapshot.paramMap.get('_id');
    if (id) {
      this.bindData(id);
    }
  }

  getPermissions = () => {
    this.permissionService.getRecord().subscribe(res => {
      this.permissions = res;
      if (this.permissions.length > 0) {
        this.showPermissions();
      }
    });
  }

  changeRead = (id, checked) => {
    if (this.permissions !== undefined && this.permissions.length > 0) {
      this.permissions.forEach(element => {
        if (element._id === id) {
          element.read = !checked;
          if (element.read || element.read == false) {
            element.write = false;
          }
        }
      });
    }
    this.showPermissions();
  }

  changeWrite = (id, checked) => {
    if (this.permissions !== undefined && this.permissions.length > 0) {
      this.permissions.forEach(element => {
        if (element._id === id) {
          element.write = !checked;
          if (element.write) {
            element.read = true;
          }
        }
      });
    }
    this.showPermissions();
  }

  bindData = (id) => {
    const option = {
      _id: id,
      isActive: true
    };
    this.roleManagementService.getRecordByID(option).subscribe(res => {
      if (res) {
        this.isDisabled = true;
        this.data = res;
        this.name.setValue(this.data.name);
        this.roleName = this.data.name;
        this.description.setValue(this.data.description);
        // this.permissions = this.data.permission;
        this.bindPermissions(this.data.permission, this.permissions);
        if (this.permissions.length > 0) {
          this.showPermissions();
        }
      }
    });
  }

  bindPermissions = (dataPerms, allPerms) => {
    if (allPerms.length > 0 && dataPerms.length > 0) {
      dataPerms.forEach(dataElement => {
        allPerms.forEach(allElement => {
          if (dataElement.ref === allElement._id) {
            allElement.read = dataElement.read;
            allElement.write = dataElement.write;
          }
        });
      });
    }
    this.permissions = allPerms;
  }

  save(
    name: String,
    description: String
    // _id: String
  ): void {
    if (!name) {
      return;
    }
    this.inputData = {
      name: name,
      description: description,
      createdBy: this.user.data.name
    };


    if (this.data !== undefined && Object.keys(this.data).length !== 0) {
      this.preparePermissions();
      this.inputData.permission = this.permissions;
      this.inputData._id = this.data._id;
      this.inputData.updatedBy = this.user.data.name;
      this.roleManagementService.updateRecord(this.inputData).subscribe(res => {
        if (res) {
          this.data = undefined;
          this.router.navigateByUrl('admin/roleManagementView');
        }
      });
    } else {
      this.preparePermissions();
      this.inputData.permission = this.permissions;
      this.roleManagementService.createRecord(this.inputData).subscribe(res => {
        if (res) {
          this.router.navigateByUrl('admin/roleManagementView');
        }
      });


    }
  }

  preparePermissions = () => {
    if (this.permissions) {
      const arr = [];
      this.permissions.forEach(element => {
        if (element.read === undefined) {
          element.read = false;
        }
        if (element.write === undefined) {
          element.write = false;
        }

        arr.push({
          ref: element._id,
          module: element.module,
          name: element.name,
          description: element.description,
          read: element.read,
          write: element.write,
          isActive: element.isActive
        });
      });
      this.permissions = arr;
    }
  }

  showPermissions = () => {
    let temp = [];
    let uniqueModules = [];
    this.permissions.forEach(element => {
      let flag = false;
      if (uniqueModules.length === 0) {
        uniqueModules.push(element.module);
      } else {
        uniqueModules.forEach(elem => {
          if (elem === element.module) {
            flag = true;
          }
        });
        if (!flag) {
          uniqueModules.push(element.module);
        }
      }
    });

    if (uniqueModules.length > 0) {
      uniqueModules.forEach(elem => {
        let isFirst = false;
        this.permissions.forEach((element, index) => {
          if (elem === element.module) {
            if (!isFirst) {
              isFirst = true;
              temp.push({
                _id: element._id,
                module: element.module,
                name: '',
                read: '',
                write: '',
                ref: '',
                isActive: element.isActive
              });
              temp.push({
                _id: element._id,
                module: '',
                name: element.name,
                read: element.read,
                write: element.write,
                ref: element.ref,
                isActive: element.isActive
              });
            } else {
              temp.push({
                _id: element._id,
                module: '',
                name: element.name,
                read: element.read,
                write: element.write,
                ref: element.ref,
                isActive: element.isActive
              });
            }
          }
        });
      });
    }
    this.permissionData = temp;
  }

  selectAllRead = () => {
    if (this.permissions) {
      this.permissions.forEach(el => {
        el.read = this.chkAllRead;
        if (this.chkAllRead) {
          el.write = !this.chkAllRead;
        }
      });
      this.chkAllRead = !this.chkAllRead;
    }
    this.showPermissions();
  }

  selectAllWrite = () => {
    if (this.permissions) {
      this.permissions.forEach(el => {
        el.write = this.chkAllWrite;
        el.read = this.chkAllWrite;
      });
      this.chkAllWrite = !this.chkAllWrite;
    }
    this.showPermissions();
  }

  selectAllReadWrite = () => {
    if (this.permissions) {
      this.permissions.forEach(el => {
        el.write = this.chkAllReadWrite;
        el.read = this.chkAllReadWrite;
      });
      this.chkAllReadWrite = !this.chkAllReadWrite;
    }
    this.showPermissions();
  }

  btnClose = () => {
    this.router.navigateByUrl('admin/roleManagementView');
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
