import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private apiUrl = '';  // URL to web api

  constructor(private http: HttpClient) {
  }

  getByKey(data: any): Observable<any[]> {
    this.apiUrl = this.getLocalStorageAppURL('setting');
    const inputData = { data };
    return this.http.post<any[]>(`${this.apiUrl}/getByKey`, inputData, httpOptions)
      .pipe(
        tap()
      );
  }

  getLocalStorageAppURL(value) {
    var app_url;
    if (environment.mobileApp) {
      app_url = localStorage.getItem('app_url');
    } else {
      app_url = environment.apiURL;
    }

    if (value === 'login') {
      app_url = app_url + '/api/auth';
    } else if (value === 'status') {
      app_url = app_url + '/api/status'
    }
    else if (value === 'auditLog') {
      app_url = app_url + '/api/admin/auditLog'
    }
    else if (value === 'permission') {
      app_url = app_url + '/api/userManagement/permission'
    }
    else if (value === 'roles') {
      app_url = app_url + '/api/userManagement/role'
    }
    else if (value === 'users') {
      app_url = app_url + '/api/admin/userManagement'
    }
    else if (value === 'widget') {
      app_url = app_url + '/api/userManagement'
    }
    else if (value === 'password') {
      app_url = app_url + '/api/admin/userManagement'
    }
    else if (value === 'opFilter') {
      app_url = app_url + '/api/general/opFilter'
    }
    else if (value === 'category') {
      app_url = app_url + '/api/master/category'
    }
    else if (value === 'checklist') {
      app_url = app_url + '/api/master/checklist'
    }
    else if (value === 'client') {
      app_url = app_url + '/api/master/client'
    }
    else if (value === 'company') {
      app_url = app_url + '/api/master/company'
    }
    else if (value === 'contact') {
      app_url = app_url + '/api/master/contact'
    }
    else if (value === 'department') {
      app_url = app_url + '/api/master/department'
    }
    else if (value === 'equipment') {
      app_url = app_url + '/api/master/equipment'
    }
    else if (value === 'equipmentCompany') {
      app_url = app_url + '/api/master/equipmentCompany'
    }
    else if (value === 'inspector') {
      app_url = app_url + '/api/admin/userManagement'
    }
    else if (value === 'location') {
      app_url = app_url + '/api/master/location'
    }
    else if (value === 'manufacture') {
      app_url = app_url + '/api/master/manufacture'
    }
    else if (value === 'project') {
      app_url = app_url + '/api/master/project'
    }
    else if (value === 'qrcode') {
      app_url = app_url + '/api/master/qrcode'
    }
    else if (value === 'rfi') {
      app_url = app_url + '/api/master/rfiInspection'
    }
    else if (value === 'scheduleReport') {
      app_url = app_url + '/api/master/scheduleInspectionReport'
    }
    else if (value === 'schedule') {
      app_url = app_url + '/api/master/scheduleInspection'
    }
    else if (value === 'siteLocation') {
      app_url = app_url + '/api/master/siteLocation'
    }
    else if (value === 'type') {
      app_url = app_url + '/api/master/type'
    }
    else if (value === 'file') {
      app_url = app_url + '/api/file'
    }
    else if (value === 'geoption') {
      app_url = app_url + '/api/general/geOption'
    }
    else if (value === 'geseed') {
      app_url = app_url + '/api/general/geSeed'
    }
    else if (value === 'report') {
      app_url = app_url + '/api/report'
    }
    else if (value === 'setting') {
      app_url = app_url + '/api/administration/setting'
    }
    else if (value === 'receiver') {
      app_url = app_url + '/api/master/receiver'
    }
    else if (value === 'log') {
      app_url = app_url + '/api/master/log'
    }
    else if (value === 'feedback') {
      app_url = app_url + '/api/master/clientfeedback'
    }
    else if (value === 'notification') {
      app_url = app_url + '/api/admin/notification'
    }
    else if (value === 'mobileLog') {
      app_url = app_url + '/api/master/mobileLog'
    }
    

    return app_url;

  }
}