import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Component({
  selector: 'app-sticker-history',
  templateUrl: './sticker-history.component.html',
  styleUrls: ['./sticker-history.component.css']
})
export class StickerHistoryComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  dataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [

    'number',
    'updatedDate'

  ];
  temp = [];
  temp1 = [];
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialog: MatDialog,
    private dialogRef: MatDialogRef<StickerHistoryComponent>,) { }

  ngOnInit() {
    if (this.data) {
      this.getTable(this.data)

    }
  }
  getTable(data) {
    if (data.previousSticker.length > 0) {
      this.temp = data.previousSticker;
      for (let i = this.temp.length - 1; i >= 0; i--) {
        if (this.temp[i].updatedDate) {
          this.temp1.push(this.temp[i])
        }
      }
      this.dataSource = new MatTableDataSource(this.temp1);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }
}
