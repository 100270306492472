import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppDashboardComponent } from '../app-dashboard/app-dashboard.component';
import { ConfirmDeleteComponent } from '../master/confirm-delete/confirm-delete.component';
import { ConfirmSubmitComponent } from '../master/confirm-submit/confirm-submit.component';

import { LandingPageComponent } from '../mobile/landing-page/landing-page.component';

import { LoginComponent } from '../auth/login/login.component';
import { AuthService } from '../auth/auth.service';
import { AuthGuard } from '../auth/auth.guard';
import { UserFormComponent } from '../admin/user/user-form/user-form.component';
import { UserListComponent } from '../admin/user/user-list/user-list.component';
import { SpinnerComponent } from '../general/spinner/spinner.component';
import { ChangePasswordComponent } from '../auth/change-password/change-password.component';
import { PermissionViewComponent } from '../admin/permission/permission-view/permission-view.component';
import { RoleManagementViewComponent } from '../admin/role/role-management-view/role-management-view.component';
import { RoleManagementEditComponent } from '../admin/role/role-management-edit/role-management-edit.component';
import { UserRoleListComponent } from '../admin/role/user-role-list/user-role-list.component';
import { DepartmentListComponent } from '../master/department/department-list/department-list.component';
import { DepartmentFormComponent } from '../master/department/department-form/department-form.component';
import { CategoryListComponent } from '../master/category/category-list/category-list.component';
import { CategoryFormComponent } from '../master/category/category-form/category-form.component';
import { LocationListComponent } from '../master/location/location-list/location-list.component';
import { LocationFormComponent } from '../master/location/location-form/location-form.component';
import { TypeListComponent } from '../master/type/type-list/type-list.component';
import { TypeFormComponent } from '../master/type/type-form/type-form.component';
import { ManufactureListComponent } from '../master/manufacture/manufacture-list/manufacture-list.component';
import { ManufactureFormComponent } from '../master/manufacture/manufacture-form/manufacture-form.component';
import { EquipmentListComponent } from '../master/equipment/equipment-list/equipment-list.component';
import { QrcodeListComponent } from '../master/qrcode/qrcode-list/qrcode-list.component';
import { QrcodeFormComponent } from '../master/qrcode/qrcode-form/qrcode-form.component';
import { ProjectListComponent } from '../master/project/project-list/project-list.component';
import { ScheduleInspectionListComponent } from '../master/scheduleInspection/schedule-inspection-list/schedule-inspection-list.component';
import { ScheduleInspectionReportComponent } from '../master/scheduleInspection/schedule-inspection-report/schedule-inspection-report.component';
import { RfiInspectionListComponent } from '../master/rfi-inspection/rfi-inspection-list/rfi-inspection-list.component';
import { ClientListComponent } from '../master/client/client-list/client-list.component';
import { ClientFormComponent } from '../master/client/client-form/client-form.component';
import { CompanyListComponent } from '../master/company/company-list/company-list.component';
import { CompanyFormComponent } from '../master/company/company-form/company-form.component';
import { SiteLocationFormComponent } from '../master/siteLocation/site-location-form/site-location-form.component';
import { SiteLocationListComponent } from '../master/siteLocation/site-location-list/site-location-list.component';
import { ChecklistListComponent } from '../master/checklist/checklist-list/checklist-list.component';
import { ChecklistFormComponent } from '../master/checklist/checklist-form/checklist-form.component';
import { InspectorListComponent } from '../master/inspector/inspector-list/inspector-list.component';
import { RfiInspectionChecklistComponent } from '../master/rfi-inspection/rfi-inspection-checklist/rfi-inspection-checklist.component';
import { RfiReportShowComponent } from '../master/report/rfi-report-show/rfi-report-show.component';
import { OfflineInspectionComponent } from '../inspections/offline-inspection/offline-inspection.component';
import { WeeklyReportComponent } from '../reports/weekly-report/weekly-report.component';
import { ContactListComponent } from '../master/contact/contact-list/contact-list.component';
import { ContactFormComponent } from '../master/contact/contact-form/contact-form.component';
import { EquipmentCompanyListComponent } from '../master/equipmentCompany/equipment-company-list/equipment-company-list.component';
import { EquipmentCompanyFormComponent } from '../master/equipmentCompany/equipment-company-form/equipment-company-form.component';
import { InternalEntryReportComponent } from '../reports/internal-entry-report/internal-entry-report.component';
import { WidgetListComponent } from '../admin/widget/widget-list/widget-list.component';
import { UserWidgetComponent } from '../admin/user/user-widget/user-widget.component';
import { QrCodeBulkPrintComponent } from '../master/qrcode/qr-code-bulk-print/qr-code-bulk-print.component';
import { ViewScheduleInspectionnComponent } from '../master/scheduleInspection/view-schedule-inspectionn/view-schedule-inspectionn.component';
import { AuditLogListComponent } from '../admin/auditLog/audit-log-list/audit-log-list.component';
import { ReceiverFormComponent } from '../master/receiver/receiver-form/receiver-form.component';
import { ReceiverListComponent } from '../master/receiver/receiver-list/receiver-list.component';
import { LogComponent } from '../master/log/log.component';
import { ClientfeedbackListComponent } from '../master/clientfeedback/clientfeedback-list/clientfeedback-list.component';
import { CustomerFeedbackEvaluationReportComponent } from '../reports/customer-feedback-evaluation-report/customer-feedback-evaluation-report.component';
import { ForgetPasswordComponent } from '../auth/forget-password/forget-password.component';
import { InspectorscheduleListComponent } from '../master/inspectorschedule/inspectorschedule-list/inspectorschedule-list.component';
import { NotificationMailComponent } from '../admin/notification-mail/notification-mail.component';
import { WeeklyReportByInspectorComponent } from '../reports/weekly-report-by-inspector/weekly-report-by-inspector.component';
import { TrackingReportComponent } from '../reports/tracking-report/tracking-report.component';
import { MobileLogListComponent } from '../master/mobilelog/mobile-log-list/mobile-log-list.component';



const routes: Routes = [
  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '', component: LandingPageComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgetPasswordComponent },
  { path: 'dashboard', component: AppDashboardComponent, canActivate: [AuthGuard] },

  { path: 'delete', component: ConfirmDeleteComponent, canActivate: [AuthGuard] },
  { path: 'submit', component: ConfirmSubmitComponent, canActivate: [AuthGuard] },
  { path: 'auth/changePassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'admin/permissionView', component: PermissionViewComponent, canActivate: [AuthGuard] },
  { path: 'admin/roleManagementView', component: RoleManagementViewComponent, canActivate: [AuthGuard] },
  { path: 'general/spinner', component: SpinnerComponent, canActivate: [AuthGuard] },

  { path: 'mobile/landing', component: RfiInspectionListComponent },
  { path: 'mobile/landing', component: RfiInspectionListComponent, canActivate: [AuthGuard] },
  { path: 'admin/userList', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'admin/userList', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'admin/user/:id/widgets', component: UserWidgetComponent, canActivate: [AuthGuard] },
  { path: 'admin/widgets', component: WidgetListComponent, canActivate: [AuthGuard] },
  { path: 'master/qrrpt/:term', component: RfiReportShowComponent },

  {
    path: 'role/create',
    component: RoleManagementEditComponent, canActivate: [AuthGuard]
  },
  {
    path: 'role/edit/:_id',
    component: RoleManagementEditComponent, canActivate: [AuthGuard]
  },
  {
    path: 'role/userRoleList',
    component: UserRoleListComponent, canActivate: [AuthGuard]
  },
  { path: 'master/departmentForm', component: DepartmentFormComponent, canActivate: [AuthGuard] },
  { path: 'master/departmentList', component: DepartmentListComponent, canActivate: [AuthGuard] },
  { path: 'master/categoryForm', component: CategoryFormComponent, canActivate: [AuthGuard] },
  { path: 'master/categoryList', component: CategoryListComponent, canActivate: [AuthGuard] },
  { path: 'master/locationForm', component: LocationFormComponent, canActivate: [AuthGuard] },
  { path: 'master/locationList', component: LocationListComponent, canActivate: [AuthGuard] },
  { path: 'master/typeForm', component: TypeFormComponent, canActivate: [AuthGuard] },
  { path: 'master/typeList', component: TypeListComponent, canActivate: [AuthGuard] },
  { path: 'master/manufactureForm', component: ManufactureFormComponent, canActivate: [AuthGuard] },
  { path: 'master/manufactureList', component: ManufactureListComponent, canActivate: [AuthGuard] },
  { path: 'master/equipmentList', component: EquipmentListComponent, canActivate: [AuthGuard] },
  { path: 'master/qrcodeForm', component: QrcodeFormComponent, canActivate: [AuthGuard] },
  { path: 'master/qrcodeList', component: QrcodeListComponent, canActivate: [AuthGuard] },
  { path: 'master/qrCodeBulkPrint', component: QrCodeBulkPrintComponent, canActivate: [AuthGuard] },
  { path: 'master/projectList', component: ProjectListComponent, canActivate: [AuthGuard] },
  { path: 'master/scheduleList', component: ScheduleInspectionListComponent, canActivate: [AuthGuard] },
  { path: 'master/scheduleReport', component: ScheduleInspectionReportComponent, canActivate: [AuthGuard] },
  { path: 'master/rfiInspectionList', component: RfiInspectionListComponent, canActivate: [AuthGuard] },
  { path: 'master/clientForm', component: ClientFormComponent, canActivate: [AuthGuard] },
  { path: 'master/clientList', component: ClientListComponent, canActivate: [AuthGuard] },
  { path: 'master/receiverForm', component: ReceiverFormComponent, canActivate: [AuthGuard] },
  { path: 'master/receiverList', component: ReceiverListComponent, canActivate: [AuthGuard] },
  { path: 'master/companyForm', component: CompanyFormComponent, canActivate: [AuthGuard] },
  { path: 'master/companyList', component: CompanyListComponent, canActivate: [AuthGuard] },
  { path: 'master/siteLocationForm', component: SiteLocationFormComponent, canActivate: [AuthGuard] },
  { path: 'master/siteLocationList', component: SiteLocationListComponent, canActivate: [AuthGuard] },
  { path: 'master/checklistForm', component: ChecklistFormComponent, canActivate: [AuthGuard] },
  { path: 'master/checklistList', component: ChecklistListComponent, canActivate: [AuthGuard] },
  { path: 'master/inspectorList', component: InspectorListComponent, canActivate: [AuthGuard] },
  { path: 'master/rfiInspectionChecklist', component: RfiInspectionChecklistComponent, canActivate: [AuthGuard] },
  { path: 'report/weeklyReport', component: WeeklyReportComponent, canActivate: [AuthGuard] },
  { path: 'inspections/offline', component: OfflineInspectionComponent },
  { path: 'master/contactForm', component: ContactFormComponent },
  { path: 'master/contactList', component: ContactListComponent },
  { path: 'master/equipemtCompanyForm', component: EquipmentCompanyFormComponent },
  { path: 'master/equipemtCompanyList', component: EquipmentCompanyListComponent },
  { path: 'report/internalEntryReport', component: InternalEntryReportComponent },
  { path: 'master/viewSchedule', component: ViewScheduleInspectionnComponent, canActivate: [AuthGuard] },
  { path: 'admin/auditLog', component: AuditLogListComponent, canActivate: [AuthGuard] },
  { path: 'master/log', component: LogComponent, canActivate: [AuthGuard] },
  { path: 'master/clientfeedbackList', component: ClientfeedbackListComponent, canActivate: [AuthGuard] },
  { path: 'report/CustomerFeedbackEvaluationReport', component: CustomerFeedbackEvaluationReportComponent, canActivate: [AuthGuard] },
  { path: 'master/inspectorscheduleList', component: InspectorscheduleListComponent, canActivate: [AuthGuard] },
  { path: 'admin/notification', component: NotificationMailComponent, canActivate: [AuthGuard] },
  { path: 'report/weeklyReportbyInspector', component: WeeklyReportByInspectorComponent, canActivate: [AuthGuard] },
  { path: 'report/trackingReport', component: TrackingReportComponent, canActivate: [AuthGuard] },
  { path: 'master/dataToSync', component: MobileLogListComponent, canActivate: [AuthGuard] },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],
  exports: [RouterModule],
  declarations: [],
  providers: [AuthService, AuthGuard]
})
export class AppRoutingModule { }
