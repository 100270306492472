import { Component, OnInit, ViewChild, Inject,ElementRef } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
  MatDialogConfig,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material';
import { DepartmentFormComponent } from '../department-form/department-form.component';
import { DepartmentService } from '../department.service';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { ExcelService } from '../../../excel.service';
import { AuthService } from '../../../auth/auth.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Component({
  selector: 'app-department-list',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.css']
})
export class DepartmentListComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild('search',{static:false}) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  // dataSource: CompanyListDataSource;
  dataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'edit',
    'name',
    'description',
    'createdBy',
    'createdDate',
    'updatedBy',
    'updatedDate'
    
  ];
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  constructor(
    private dialog: MatDialog,
    private departmentService: DepartmentService,  private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,  private excelService: ExcelService, private authService: AuthService
  ) { }

  ngOnInit() {
    // this.getUser();
    this.getTableData();
  }
  // getUser() {
  //   this.isLoggedIn$ = this.authService.isUserLoggedIn;
  //   this.authService.LoggedUserDetail.subscribe(res => {
  //     this.user = res;
  //     this.checkPermission();
  //   });
  // }

  // checkPermission() {
  //   const permission = this.user.data.permission;
  //   if (permission.master.department) {
  //       this.canRead =permission.master.department.read;
  //       this.canWrite = permission.master.department.write;
  //   }
  //     if (!this.canWrite) {
  //       this.displayedColumns = ['name', 'description'];
  //     }
    
  // }
  getTableData() {
    this.departmentService.getDepartment().subscribe(data => {
      if (!data) {
        return;
      }
      this.temp = data;
      for (let i = 0; i < this.temp.length; i++) {
        if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
        if (this.temp[i].updatedDate) { this.temp[i].updatedDate = moment(new Date(this.temp[i].updatedDate)).format('DD/MM/YYYY'); }
      }
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });
  }
  btnAddNewClick() {
    const dialogRef = this.dialog.open(DepartmentFormComponent, {
      data: {},
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  deleteTable(item) {
    const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
      data: { deleteItem: true },
      disableClose: false,
      hasBackdrop: true
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        this.departmentService.deleteById(item).subscribe(res => {
          if (res[0]['statusBool']) {
            this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          } 
          this.ngOnInit();
        });
      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }
  getTable(row) {
    this.departmentService.getRecordById(row).subscribe(res => {
      const dialogRef = this.dialog.open(DepartmentFormComponent, {
        data: res[0]
      });
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });
    });
  }

  exportExcel() {
    var data1 = [];
    var i = 1;
    this.temp1.forEach(element => {
      var val = {
        "Name": element.name,
        "Description": element.description,
        'Created By': element.createdBy,
        'Created Date': element.createdDate,
        'Updated By': element.updatedBy,
        'Updated Date': element.updatedDate
      };
      i = i + 1;
      data1.push(val);
    });
    this.excelService.saveAsExcelFile(data1, 'DepartmentMaster');
  }
  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.name = d.name ? d.name : '';
      d.description = d.description ? d.description : '';
      d.createdBy = d.createdBy ? d.createdBy : '';
      d.createdDate = d.createdDate ? d.createdDate : '';
      d.updatedBy = d.updatedBy ? d.updatedBy : '';
      d.updatedDate = d.updatedDate ? d.updatedDate : '';
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.description.toLowerCase().indexOf(val) !== -1 || !val)||
        (d.createdDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedDate.toLowerCase().indexOf(val) !== -1 || !val);
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}

