import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting/setting.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class GeoptionService {

  private apiUrl = '';  // URL to web api

  constructor(private http: HttpClient,
    private settingService:SettingService) {
  }

  getRecordByOptionName(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('geoption');
    const inputData = { data };
    return this.http.post<any[]>(`${this.apiUrl}/getRecordsByOptionName`, inputData, httpOptions)
      .pipe(
        tap()
      );
  }

}