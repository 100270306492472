import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef } from '@angular/core';
import { MatDialogConfig, MatDialog, MatSidenav, MatBottomSheet, MatSnackBar } from '@angular/material';
import { Send } from '../mobile-native-interface/send.service.service';
import { OfflineStorageService } from '../mobile-native-interface/offline-storage.service.service';
import { SettingService } from '../services/setting/setting.service';
import { OfflineDeleteDialogComponent } from '../general/offline-delete-dialog/offline-delete-dialog.component';
import { AppConfirmService } from '../general/app-confirm/app-confirm.service';


@Component({
  selector: 'app-navigation',
  templateUrl: './app-navigation.component.html',
  styleUrls: ['./app-navigation.component.css']
})
export class AppNavigationComponent implements OnInit {
  private data = new BehaviorSubject('');
  loginHeaderLogo = environment.logo;
  currentPermissions: any;
  isLoggedIn$: Observable<boolean>;
  options: FormGroup;
  isMobileApp: Boolean = environment.mobileApp;
  showOperations: boolean;
  inputData: any;
  user: any;
  userEmail: String = '';
  userName: String = '';
  isClient: Boolean = false;
  isVendor: Boolean = false;
  isAdmin: Boolean = false;
  isHide: Boolean = false;
  versionNumber: String = environment.versionNumber;
  private _mobileQueryListener: () => void;
  @ViewChild('drawer', { static: false }) drawer: MatSidenav;
  permission: any;
  manufactureRead = false;
  rfiRead = false;
  clientfeedbackRead = false;
  typeRead = false;
  locationRead = false;
  contractorRead = false;
  receiverRead = false;
  siteLocationRead = false;
  categoryRead = false;
  checklistRead = false;
  companyRead = false;
  contactRead = false;
  equipmentCompanyRead = false;
  projectRead = false;
  equipmentRead = false;
  qrcodeRead = false;
  weeklyReportRead = false;
  internalReportRead = false;
  scheduleRead = false;
  mapChecklistRead = false;
  userRead = false;
  permissionRead = false;
  roleRead = false;
  offlineRead = false;
  logRead = false;
  showMasters = true;
  showReports = true;
  showAdminMenu = true;
  showProjects = true;
  showInspections = true;
  widgetsRead = false;
  CustomerFeedbackReportRead = false;
  customerFeedbackRead = false;
  InspectorscheduleRead = false;
  notificationRead = false;
  weeklyReportbyInspectorRead = false;
  trackingReportRead = false;
  ClientLogo: any;
  isOffline: String;

  companyName = '';
  width: Number;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  mobileQuery: MediaQueryList;

  constructor(
    public auth: AuthService,
    private breakpointObserver: BreakpointObserver,
    fb: FormBuilder,
    private router: Router,
    changeDetectorRef: ChangeDetectorRef, private bottomSheet: MatBottomSheet,
    media: MediaMatcher,
    private offlineStorage: OfflineStorageService,
    private sendService: Send,
    private settingService: SettingService, private dialog: MatDialog,
    private snackbar: MatSnackBar, private ngZone: NgZone,
    private confirmService:AppConfirmService
  ) {
    this.options = fb.group({
      bottom: 0,
      fixed: false,
      top: 0
    });
    this.mobileQuery = media.matchMedia('(max-width: 1024px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnInit() {

    this.getCompanyName();
    if (environment.mobileApp) {
      this.loginHeaderLogo = 'assets/images/icons/logo.jpg';
      // CREATE OFFLINE DB

      this.offlineStorage.databaseHandler.createDatabase(this);
      setTimeout(() => {
        // START SYNC OFFLINE DATA TO SERVER
        this.sendService.sendServiceInit();
      }, 2000);
    }
    this.width = screen.width;
    this.isLoggedIn$ = this.auth.isUserLoggedIn;
    if (this.isLoggedIn$) {
      var result = JSON.parse(localStorage.getItem('userDet'));
      this.auth.navigatePageLanding(result);
    }
    this.auth.LoggedUserDetail.subscribe(res => {
      this.isClient = false;
      this.isAdmin = false;
      this.isHide = false;
      this.userEmail = '';
      this.user = res;
      if (this.user && this.user.data) {
        if (this.user.data.userId) {
          this.userEmail = this.user.data.userId;
        }
        if (this.user.data.name) {
          this.userName = this.user.data.name;
        }
        this.getPermissions();
        if (this.user.data.isClient) {
          this.isClient = true;
        }
        if (this.user.data.isVendor) {
          this.isVendor = true;
        }
        if (this.user.data.isAdmin) {
          this.isAdmin = true;
        }
      }
    });

  }

  // readLocalStorageValue(key: String) {
  //   return localStorage.getItem('isOffline');
  // }
  readLocalStorageValue1(key: String) {
    var client = localStorage.getItem('Client');
    var path;
    if (client == 'true') {
      path = localStorage.getItem('path');
      this.ClientLogo = path;
      return localStorage.getItem('Client');
    }
  }
  getCompanyName() {
    this.settingService.getByKey('company').subscribe(result => {
      if (result.length > 0) {
        this.companyName = result[0].value;
        localStorage.setItem('ClientName', this.companyName);

        if (this.companyName === 'AMPS') {
          localStorage.setItem('Client', 'true');
          if (environment.mobileApp) {
            this.ClientLogo = 'assets/images/icons/AMPS.jpg';
            localStorage.setItem('path', 'assets/images/icons/AMPS.jpg');
          }
          else {
            this.ClientLogo = 'images/icons/AMPS.jpg';
            localStorage.setItem('path', 'images/icons/AMPS.jpg');
          }
        } else if (this.companyName === 'SGS') {
          localStorage.setItem('Client', 'true');
          if (environment.mobileApp) {
            this.ClientLogo = 'assets/images/icons/SGS.jpg';
            localStorage.setItem('path', 'assets/images/icons/SGS.jpg');
          } else {
            this.ClientLogo = 'images/icons/SGS.jpg';
            localStorage.setItem('path', 'images/icons/SGS.jpg');
          }
        }
      }
    })

  }
  onResize(event) {
    this.width = event.target.innerWidth;
  }

  getPermissions() {
    if (this.user.data.permission !== undefined && this.user.data.permission !== null && this.user.data.permission !== '') {
      this.permission = this.user.data.permission;
    }
    if (this.permission && this.permission.master && this.permission.master.manufacture) {
      this.manufactureRead = this.permission.master.manufacture.read;
    }
    if (this.permission && this.permission.inspections && this.permission.inspections.rfi) {
      this.rfiRead = this.permission.inspections.rfi.read;
    }
    if (this.permission && this.permission.inspections && this.permission.inspections.clientFeedbackForm) {
      this.clientfeedbackRead = this.permission.inspections.clientFeedbackForm.read;
    }
    if (this.permission && this.permission.master && this.permission.master.type) {
      this.typeRead = this.permission.master.type.read;
    }
    if (this.permission && this.permission.master && this.permission.master.location) {
      this.locationRead = this.permission.master.location.read;
    }
    if (this.permission && this.permission.master && this.permission.master.contractor) {
      this.contractorRead = this.permission.master.contractor.read;
    }
    if (this.permission && this.permission.master && this.permission.master.receiver) {
      this.receiverRead = this.permission.master.receiver.read;
    }
    if (this.permission && this.permission.master && this.permission.master.siteLocation) {
      this.siteLocationRead = this.permission.master.siteLocation.read;
    }
    if (this.permission && this.permission.master && this.permission.master.category) {
      this.categoryRead = this.permission.master.category.read;
    }
    if (this.permission && this.permission.master && this.permission.master.checklist) {
      this.checklistRead = this.permission.master.checklist.read;
    }
    if (this.permission && this.permission.master && this.permission.master.company) {
      this.companyRead = this.permission.master.company.read;
    }
    if (this.permission && this.permission.master && this.permission.master.contact) {
      this.contactRead = this.permission.master.contact.read;
    }
    if (this.permission && this.permission.master && this.permission.master.equipmentCompany) {
      this.equipmentCompanyRead = this.permission.master.equipmentCompany.read;
    }
    if (this.permission && this.permission.master && this.permission.master.mapChecklist) {
      this.mapChecklistRead = this.permission.master.mapChecklist.read;
    }
    if (this.permission && this.permission.master && this.permission.master.equipment) {
      this.equipmentRead = this.permission.master.equipment.read;
    }
    if (this.manufactureRead || this.typeRead || this.locationRead || this.contractorRead || this.siteLocationRead || this.categoryRead
      || this.checklistRead || this.companyRead || this.contactRead || this.equipmentCompanyRead || this.mapChecklistRead || this.equipmentRead || this.receiverRead) {
      this.showMasters = true;

    } else {
      this.showMasters = false;
    }

    if (this.permission && this.permission.project && this.permission.project.project) {
      this.projectRead = this.permission.project.project.read;
    }

    if (this.permission && this.permission.project && this.permission.project.qrcode) {
      this.qrcodeRead = this.permission.project.qrcode.read;
    }



    if (this.projectRead || this.equipmentRead || this.qrcodeRead) {
      this.showProjects = true;
    } else {
      this.showProjects = false;
    }
    if (this.permission && this.permission.reports && this.permission.reports.weeklyReport) {
      this.weeklyReportRead = this.permission.reports.weeklyReport.read;
    }
    if (this.permission && this.permission.reports && this.permission.reports.internalReport) {
      this.internalReportRead = this.permission.reports.internalReport.read;
    }

    if (this.permission && this.permission.reports && this.permission.reports.CustomerFeedbackReport) {
      this.CustomerFeedbackReportRead = this.permission.reports.CustomerFeedbackReport.read;

    }
    if (this.permission && this.permission.reports && this.permission.reports.weeklyReportbyInspector) {
      this.weeklyReportbyInspectorRead = this.permission.reports.weeklyReportbyInspector.read;

    }
    if (this.permission && this.permission.reports && this.permission.reports.trackingReport) {
      this.trackingReportRead = this.permission.reports.trackingReport.read;

    }

    if (this.weeklyReportRead || this.internalReportRead || this.CustomerFeedbackReportRead || this.weeklyReportbyInspectorRead || this.trackingReportRead) {
      this.showReports = true;
    } else {
      this.showReports = false;
    }

    if (this.permission && this.permission.inspections && this.permission.inspections.schedule) {
      this.scheduleRead = this.permission.inspections.schedule.read;
    }

    if (this.permission && this.permission.inspections && this.permission.inspections.CustomerFeedback) {
      this.customerFeedbackRead = this.permission.inspections.CustomerFeedback.read;
    }

    if (this.permission && this.permission.inspections && this.permission.inspections.Inspectorschedule) {
      this.InspectorscheduleRead = this.permission.inspections.Inspectorschedule.read;
    }

    if (this.permission && this.permission.inspections && this.permission.inspections.offline) {
      this.offlineRead = this.permission.inspections.offline.read;
    }

    if (this.scheduleRead || this.rfiRead || this.offlineRead || this.clientfeedbackRead) {
      this.showInspections = true;
    } else {
      this.showInspections = false;
    }
    if (this.permission && this.permission.admin && this.permission.admin.user) {
      this.userRead = this.permission.admin.user.read;
    }
    if (this.permission && this.permission.admin && this.permission.admin.permission) {
      this.permissionRead = this.permission.admin.permission.read;
    }
    if (this.permission && this.permission.admin && this.permission.admin.role) {
      this.roleRead = this.permission.admin.role.read;
    }
    if (this.permission && this.permission.admin && this.permission.admin.widgets) {
      this.widgetsRead = this.permission.admin.widgets.read;
    }
    if (this.permission && this.permission.admin && this.permission.admin.log) {
      this.logRead = this.permission.admin.log.read;
    }
    if (this.permission && this.permission.admin && this.permission.admin.notification) {
      this.notificationRead = this.permission.admin.notification.read;
    }

    if (this.userRead || this.permissionRead || this.roleRead || this.widgetsRead || this.logRead || this.notificationRead) {
      this.showAdminMenu = true;
    } else {
      this.showAdminMenu = false;
    }
  }

  closeDialog() {
    if (this.mobileQuery.matches) {
      this.drawer.toggle();
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.menuVis = false;
  }

  menuVis = true;
  menuDriven() {
    if (this.menuVis) {
      this.menuVis = false;
    } else {
      this.menuVis = true;
    }
  }

  openDialog() {
    this.router.navigate(['/auth/changePassword']);
  }
  deleteOfflineData() {
    let dialogRef = this.dialog.open(OfflineDeleteDialogComponent, {
      data: this.data,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.offlineStorage.offlineDataDeleteHandler.deleteDatabase(this, function (res) {
          let _this = res.self;

          if (res) {
            _this.ngZone.run(() => {
              setTimeout(() => {
                _this.snackbar.open(res.data, '', {
                  duration: 5000,
                  verticalPosition: 'bottom',
                });
              }, 0);
            });
            // _this.snackbar.open(res.data, '', { duration: 2000, verticalPosition: "bottom" });
          }
          _this.ngOnInit();
        });
      }
    });

  }
  logout() {
    this.confirmService.confirm({ message: `Are you sure want to logout?` })
      .subscribe(res => {
        if (res) {
          this.auth.logout();
        }
      })
  }

}
