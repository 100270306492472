import { Component, OnInit,Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
@Component({
  selector: 'app-mobile-log-popup',
  templateUrl: './mobile-log-popup.component.html',
  styleUrls: ['./mobile-log-popup.component.css']
})
export class MobileLogPopupComponent implements OnInit {

  tempData:any;
  constructor(
    private dialogRef: MatDialogRef<MobileLogPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any

  ) { }

  ngOnInit() {
    if (this.data && this.data.row) {
      this.tempData = this.data.row.offlineData
    }
  }
  save(){
    
  }
  btnClose() {
    
      this.dialogRef.close();
  }
}
