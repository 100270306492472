import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { ScheduleInspectionService } from '../../scheduleInspection/schedule-inspection.service';
import { ScheduleInspectionReportService } from '../../scheduleInspection/schedule-inspection-report.service';
import { ProjectService } from '../../project/project.service';
import { ReceiverService } from '../../receiver/receiver.service';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';
import * as _ from 'underscore';
@Component({
  selector: 'app-select-receiver',
  templateUrl: './select-receiver.component.html',
  styleUrls: ['./select-receiver.component.css']
})
export class SelectReceiverComponent implements OnInit {
  clientDetails: any;
  receivers = [];
  inputData: any;
  anotherReceivers = [];
  isOffline: boolean;
  clientId: any;
  name = new FormControl('', [Validators.required]);
  constructor(
    private dialog: MatDialog, private snackBar: MatSnackBar,
    private scheduleInspectionService: ScheduleInspectionService,
    private scheduleInspectionReportService: ScheduleInspectionReportService,
    private receiverService: ReceiverService,
    private dialogRef: MatDialogRef<SelectReceiverComponent>, private bottomSheet: MatBottomSheet, private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) private data: any, private offlineStorage: OfflineStorageService
  ) { }

  ngOnInit() {
    let value = localStorage.getItem('isOffline');
    if (value == 'true') {
      this.isOffline = true;
    }
    else {
      this.isOffline = false;
    }
    if (this.data) {
      if (this.data.data) {
        if (this.data.data.clientDetail) {
          if (this.data.data.clientDetail.client) {
            this.clientId = this.data.data.clientDetail.client.ref
          }
        }
      }
    }
    this.getReceivers();
  }
  getReceivers() {
    if (this.isOffline == true) {
      this.offlineStorage.receiverHandler.getReceiver(this, function (res) {
        let _this = res.self;
        var receiverData = [];
        var finalReceiverData = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            var datas = JSON.parse(res.data.item(i).receiverData);
            receiverData.push(datas);
          }
          if (receiverData && receiverData.length > 0) {
            for (let j = 0; j < receiverData.length; j++) {
              if (receiverData[j].clientId) {
                if (receiverData[j].clientId[0] == _this.clientId) {
                  finalReceiverData.push(receiverData[j]);
                }
              }
              else {
                if (receiverData[j].client == _this.clientId) {
                  finalReceiverData.push(receiverData[j]);
                }
              }

            }
          }
          if (finalReceiverData.length > 0) {
            _this.bindValue(finalReceiverData);
          } else {
            _this.bindValue(receiverData);
          }

        }
      });
    } else {
      this.receiverService.getReceiver().subscribe(data => {
        if (!data) {
          return;
        } else {
          for (let j = 0; j < data.length; j++) {
            if (data[j].clientId[0] === this.clientId) {
              this.receivers.push(data[j]);
            }
          }
          if (this.receivers.length > 0) {
            this.anotherReceivers = this.receivers;
          } else {
            this.receivers = data;
            this.anotherReceivers = this.receivers;
          }

        }
      });
    }
  }

  bindValue(data) {
    this.receivers = data;
    this.anotherReceivers = this.receivers;
  }
  save(
    name: String,
  ): void {
    this.inputData = {
      name: name
    }

    var selectedReciver = [];
    selectedReciver = _.filter(this.anotherReceivers, ele => { return ele._id == this.name.value });
    if (selectedReciver.length > 0) {
      this.inputData.name = selectedReciver[0].name;
      this.inputData._id = selectedReciver[0]._id;
      this.inputData.badgeNo = selectedReciver[0].badgeNo;
      this.inputData.emailID = selectedReciver[0].emailID;
      this.inputData.telephone = selectedReciver[0].telephone;
    }
    this.dialogRef.close(this.inputData);
  }
  filterReceiver(val) {
    val = val.toLowerCase();
    this.anotherReceivers = this.receivers.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }

  btnClose() {
    this.dialogRef.close();
  }
}
