import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet
} from "@angular/material";
import { PermissionService } from '../permission.service';
import { PermissionUserAndRolelistComponent } from '../permission-user-and-rolelist/permission-user-and-rolelist.component';
import { result } from 'lodash';

@Component({
  selector: 'app-permission-view',
  templateUrl: './permission-view.component.html',
  styleUrls: ['./permission-view.component.css']
})
export class PermissionViewComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  dataSource;

  // displayedColumns = ["module", "name", "description",  "createdBy", "createdDate", "updatedBy", "updatedDate", "userAndRoles", "delete"];
  displayedColumns = ["module", "name", "description", "createdBy", "createdDate", "updatedBy", "updatedDate"];
  doFilter: Boolean;
  temp = [];
  temp1 = [];
  user: any;
  permission: any;
  isWrite = false;
  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private PermissionService: PermissionService
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;

    if (this.permission && this.permission.admin && this.permission.admin.permission && this.permission.admin.permission.write) {
      this.isWrite = true;
    } else {
      // this.displayedColumns = ["module", "name", "description", "createdBy", "createdDate", "updatedBy", "updatedDate", "userAndRoles"];
      this.displayedColumns = ["module", "name", "description", "createdBy", "createdDate", "updatedBy", "updatedDate"];

    }
    this.PermissionService.getRecord().subscribe(results => {
      if (!results) {
        return
      }
      this.temp = results;
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  getUserAndRoles = (row) => {
    let dialogRef = this.dialog.open(PermissionUserAndRolelistComponent, {
      data: row,
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }


  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }


  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.module = d.module ? d.module : '';
      d.name = d.name ? d.name : '';
      d.description = d.description ? d.description : '';
      d.createdBy = d.createdBy ? d.createdBy : '';
      d.createdDate = d.createdDate ? d.createdDate : '';
      d.updatedBy = d.updatedBy ? d.updatedBy : '';
      d.updatedDate = d.updatedDate ? d.updatedDate : '';
      return (d.module.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.name.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.description.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedDate.toLowerCase().indexOf(val) !== -1 || !val);
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

}
