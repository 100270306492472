import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-reject-history',
  templateUrl: './reject-history.component.html',
  styleUrls: ['./reject-history.component.css']
})
export class RejectHistoryComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  dataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [

    'comment','updatedBy','updatedDate'

  ];
  temp = [];
  temp1 = [];
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialog: MatDialog,
    private dialogRef: MatDialogRef<RejectHistoryComponent>,) { }

  ngOnInit() {
    if (this.data) {
      this.getTable(this.data)

    }
  }

  getTable(data) {
    if (data.rejectComment) {
      if (data.rejectComment.length > 0) {
        this.temp = data.rejectComment;
        for (let i = this.temp.length - 1; i >= 0; i--) {
            this.temp1.push(this.temp[i])
        }
      }
    }
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
