try {
  var app = document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;
  var isMobileDevice = false;
  var platform = 'web';
  if (app) {
    isMobileDevice = true;
  }
  if (isMobileDevice) {
    if (/Android/.exec(navigator.userAgent)) {
      platform = 'android';
    } else if (/(iPad)|(iPhone)|(iPod)/.exec(navigator.userAgent)) {
      platform = 'ios';
    } else if (/(BB10)|(PlayBook)|(BlackBerry)/.exec(navigator.userAgent)) {
      platform = 'blackberry';
    }
  }
} catch (ex) {
  console.log(ex);
}

export const environment = {
  production: false,
  apiURL: '',
  mobileApp: isMobileDevice,
  platform: platform, // => Check iOS or Android
  versionNumber: '2.0.36',
  logo:'images/icons/logo.jpg'
};
