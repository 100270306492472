import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MatBottomSheet, MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ScheduleInspectionService } from '../../scheduleInspection/schedule-inspection.service';
import { RfiInspectionService } from '../../rfi-inspection/rfi-inspection.service';
import * as _ from 'underscore';
import { Router, ActivatedRoute } from '@angular/router';
import { ExcelService } from '../../../excel.service';
@Component({
  selector: 'app-view-schedule-inspectionn',
  templateUrl: './view-schedule-inspectionn.component.html',
  styleUrls: ['./view-schedule-inspectionn.component.css']
})
export class ViewScheduleInspectionnComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;

  dataSource;
  data1: any;
  displayedColumns = [
    'inspectionSerialNumber',
    'reportNo',
    'rpoNo',
    'inspectionDate',
    'projectNumber',
    'clientName',
    'inspector',
    'category',
    'status'

  ];
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  userRoles: any;
  userId: any;

  isInspector = false;
  isAdmin = false;
  isReviewer = false;
  isVerifier = false;
  isCoordinator = false;
  attachments: any;
  scheduleData: any;
  constructor(private dialog: MatDialog,
    private rfiInspectionService: RfiInspectionService, private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet, private router: Router, private excelService: ExcelService,
    private ScheduleInspectionService: ScheduleInspectionService) { }

  ngOnInit() {
    this.getUser();
    this.user = this.getFromLocalStorage('userDet');

    this.getAllAttachments();

  }
  getAllAttachments() {
    this.getTableData(false);
  }

  getUser() {
    this.user = this.getFromLocalStorage('userDet');
    this.userId = this.user.data._id;
    this.userRoles = this.user.data.role;
    this.checkRoles();
  }

  checkRoles() {
    for (var i = 0; i < this.userRoles.length; i++) {
      if (this.userRoles[i].name === 'Administrator' && this.userRoles[i].isActive === true) {
        this.isAdmin = true;
      } else if (this.userRoles[i].name === 'Inspector' && this.userRoles[i].isActive === true) {
        this.isInspector = true;
      } else if (this.userRoles[i].name === 'Reviewer' && this.userRoles[i].isActive === true) {
        this.isReviewer = true;
      } else if (this.userRoles[i].name === 'Co-ordinator' && this.userRoles[i].isActive === true) {
        this.isCoordinator = true;
      } else if (this.userRoles[i].name === 'Verifier' && this.userRoles[i].isActive === true) {
        this.isVerifier = true;
      }
    }

  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  getTableData(isOffline) {

    this.ScheduleInspectionService.getAllRecords().subscribe(result => {
      this.scheduleData = result;
      //DOUBT
      if (this.isAdmin || this.isReviewer || this.isVerifier) {
        this.getForAdmin(isOffline);
      } else if (this.isCoordinator) {
        this.getForCoorinator(isOffline);
      } else if (this.isInspector) {
        this.getForInspector(isOffline);
      }
    })

  }

  getForCoorinator(isOffline) {
    let data: any = {
      userID: this.userId,
      showAll:true,
    }

    this.rfiInspectionService.getAllRecordsForCoordinator(data).subscribe(data => {
      if (!data) {
        return;
      }
      this.getForCoorinator_Formatting(data);

    });
  }

  getForCoorinator_Formatting(data) {
    this.temp = data;

    for (let i = 0; i < this.temp.length; i++) {

      let scheduleData = [];
      scheduleData = _.filter(this.scheduleData, ele => ele._id === this.temp[i].inspectionID);
      if (scheduleData.length > 0) {
        this.temp[i].rpoNo = scheduleData[0].rpoNo;
      }

      if (this.temp[i].attachmentCount === 0) {
        this.temp[i].redButton = true;
      } else {
        this.temp[i].redButton = false;
      }



      if (this.temp[i].project) {
        if (this.temp[i].project.name) {
          this.temp[i].projectName = this.temp[i].project.name;
        }
      }

      if (this.temp[i].inspectorDetailz && this.temp[i].inspectorDetailz.name) {
        this.temp[i].inspectorName = this.temp[i].inspectorDetailz.name;
      }

      if (this.temp[i].equipment) {
        this.temp[i].equipmentNumber = this.temp[i].equipment.equipmentNumber;
      }

      if (this.temp[i].categoryDetail && this.temp[i].categoryDetail.name) {
        this.temp[i].categoryName = this.temp[i].categoryDetail.name;
      }
      if (this.temp[i].scheduleInspectionDetail && this.temp[i].scheduleInspectionDetail.project) {
        this.temp[i].projectNumber = this.temp[i].scheduleInspectionDetail.project.number
      }
      if (this.temp[i].clientDetail && this.temp[i].clientDetail.client) {
        this.temp[i].clientName = this.temp[i].clientDetail.client.clientName
      }
      this.temp[i].keyNo = this.temp[i].keyNo;
      if (this.temp[i].activatedStatus) {
        if (this.temp[i].activatedStatus.status) {
          this.temp[i].activatedStatusName = this.temp[i].activatedStatus.status;
        }
      }
      if (this.temp[i].inspectionDate) { this.temp[i].inspectionDates = moment(new Date(this.temp[i].inspectionDate)).format('DD/MMM/YYYY'); }
      if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
      if (this.temp[i].updatedDate) { this.temp[i].updatedDate = moment(new Date(this.temp[i].updatedDate)).format('DD/MM/YYYY'); }

      if (this.temp[i].activatedStatusName === 'Cancelled' || this.temp[i].activatedStatusName === 'Schedule rejected') {
        this.temp[i].showApprove = false;
        this.temp[i].showCancel = false;
        this.temp[i].showReject = false;
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }
      if (this.temp[i].activatedStatusName === 'Inspection scheduled') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = true;
          this.temp[i].showReject = true;
          this.temp[i].showEditReport = false;
          this.temp[i].showGenerateReport = false;
        }
      }
      if (this.temp[i].activatedStatusName === 'Schedule accepted') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = false;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = true;
          this.temp[i].showEditReport = true;
          this.temp[i].showGenerateReport = true;
        }
      }
      if (this.temp[i].activatedStatusName === 'Inspection completed. Send for review') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = true;
          this.temp[i].showEditReport = true;
          this.temp[i].showGenerateReport = true;
        }
        if (!this.temp[i].equipment) {
          this.temp[i].showApprove = false;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = false;
        }
      }
      if (this.temp[i].activatedStatusName === 'Waiting for review') {
        if (this.isReviewer || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = false;
        }
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }
      if (this.temp[i].activatedStatusName === 'Review completed. Waiting for OTP approval') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = false;
        }
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }

      if (this.temp[i].activatedStatusName === 'Approved') {
        this.temp[i].showApprove = false;
        this.temp[i].showCancel = false;
        this.temp[i].showReject = false;
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }
    }
    this.temp1 = this.temp;
    this.dataSource = new MatTableDataSource(this.temp);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getForInspector(isOffline) {
    let data: any = {
      userID: this.userId,
      showAll:true,
    }
    this.rfiInspectionService.getAllRecordsByInspector(data).subscribe(data => {
      if (!data) {
        return;
      }

      this.getForInspector_Formatting(data);

    });
  }

  getForInspector_Formatting(data) {
    this.temp = data;

    for (let i = 0; i < this.temp.length; i++) {

      let scheduleData = [];
      scheduleData = _.filter(this.scheduleData, ele => ele._id === this.temp[i].inspectionID);
      if (scheduleData.length > 0) {
        this.temp[i].rpoNo = scheduleData[0].rpoNo;
      }

      if (this.temp[i].attachmentCount === 0) {
        this.temp[i].redButton = true;
      } else {
        this.temp[i].redButton = false;
      }

      if (this.temp[i].project) {
        if (this.temp[i].project.name) {
          this.temp[i].projectName = this.temp[i].project.name;
        }
      }

      if (this.temp[i].inspectorDetailz && this.temp[i].inspectorDetailz.name) {
        this.temp[i].inspectorName = this.temp[i].inspectorDetailz.name;
      }
      if (this.temp[i].equipment) {
        this.temp[i].equipmentNumber = this.temp[i].equipment.equipmentNumber;
      }

      if (this.temp[i].categoryDetail && this.temp[i].categoryDetail.name) {
        this.temp[i].categoryName = this.temp[i].categoryDetail.name;
      }
      if (this.temp[i].scheduleInspectionDetail && this.temp[i].scheduleInspectionDetail.project) {
        this.temp[i].projectNumber = this.temp[i].scheduleInspectionDetail.project.number
      }
      if (this.temp[i].clientDetail && this.temp[i].clientDetail.client) {
        this.temp[i].clientName = this.temp[i].clientDetail.client.clientName
      }
      this.temp[i].keyNo = this.temp[i].keyNo;
      if (this.temp[i].activatedStatus) {
        if (this.temp[i].activatedStatus.status) {
          this.temp[i].activatedStatusName = this.temp[i].activatedStatus.status;
        }
      }
      if (this.temp[i].inspectionDate) { this.temp[i].inspectionDates = moment(new Date(this.temp[i].inspectionDate)).format('DD/MMM/YYYY'); }
      if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
      if (this.temp[i].updatedDate) { this.temp[i].updatedDate = moment(new Date(this.temp[i].updatedDate)).format('DD/MM/YYYY'); }

      if (this.temp[i].activatedStatusName === 'Cancelled' || this.temp[i].activatedStatusName === 'Schedule rejected') {
        this.temp[i].showApprove = false;
        this.temp[i].showCancel = false;
        this.temp[i].showReject = false;
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }
      if (this.temp[i].activatedStatusName === 'Inspection scheduled') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = true;
          this.temp[i].showReject = true;
          this.temp[i].showEditReport = false;
          this.temp[i].showGenerateReport = false;
        }
      }
      if (this.temp[i].activatedStatusName === 'Schedule accepted') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = false;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = true;
          this.temp[i].showEditReport = true;
          this.temp[i].showGenerateReport = true;
        }
      }
      if (this.temp[i].activatedStatusName === 'Inspection completed. Send for review') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = true;
          this.temp[i].showEditReport = true;
          this.temp[i].showGenerateReport = true;
        }
        if (!this.temp[i].equipment) {
          this.temp[i].showApprove = false;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = false;
        }
      }
      if (this.temp[i].activatedStatusName === 'Waiting for review') {
        if (this.isReviewer || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = false;
        }
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }
      if (this.temp[i].activatedStatusName === 'Review completed. Waiting for OTP approval') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = false;
        }
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }

      if (this.temp[i].activatedStatusName === 'Approved') {
        this.temp[i].showApprove = false;
        this.temp[i].showCancel = false;
        this.temp[i].showReject = false;
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }
    }
    this.temp1 = this.temp;
    this.dataSource = new MatTableDataSource(this.temp);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getForAdmin(isOffline) {
    var data = {
      showAll: true
    }
    this.rfiInspectionService.getAllRecords(data).subscribe(data => {
      if (!data) {
        return;
      }

      this.getForAdmin_Formatting(data);

    });
  }

  getForAdmin_Formatting(data) {

    this.temp = data;

    for (let i = 0; i < this.temp.length; i++) {

      let scheduleData = [];
      scheduleData = _.filter(this.scheduleData, ele => ele._id === this.temp[i].inspectionID);
      if (scheduleData.length > 0) {
        this.temp[i].rpoNo = scheduleData[0].rpoNo;
      }

      if (this.temp[i].attachmentCount === 0) {
        this.temp[i].redButton = true;
      } else {
        this.temp[i].redButton = false;
      }

      if (this.temp[i].project) {
        if (this.temp[i].project.name) {
          this.temp[i].projectName = this.temp[i].project.name;
        }
      }

      if (this.temp[i].inspectorDetailz && this.temp[i].inspectorDetailz.name) {
        this.temp[i].inspectorName = this.temp[i].inspectorDetailz.name;
      }
      if (this.temp[i].equipment) {
        this.temp[i].equipmentNumber = this.temp[i].equipment.equipmentNumber;
      }

      if (this.temp[i].categoryDetail && this.temp[i].categoryDetail.name) {
        this.temp[i].categoryName = this.temp[i].categoryDetail.name;
      }
      if (this.temp[i].scheduleInspectionDetail && this.temp[i].scheduleInspectionDetail.project) {
        this.temp[i].projectNumber = this.temp[i].scheduleInspectionDetail.project.number
      }
      if (this.temp[i].clientDetail && this.temp[i].clientDetail.client) {
        this.temp[i].clientName = this.temp[i].clientDetail.client.clientName
      }

      this.temp[i].keyNo = this.temp[i].keyNo;
      if (this.temp[i].activatedStatus) {
        if (this.temp[i].activatedStatus.status) {
          this.temp[i].activatedStatusName = this.temp[i].activatedStatus.status;
        }
      }
      if (this.temp[i].inspectionDate) { this.temp[i].inspectionDates = moment(new Date(this.temp[i].inspectionDate)).format('DD/MMM/YYYY'); }
      if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
      if (this.temp[i].updatedDate) { this.temp[i].updatedDate = moment(new Date(this.temp[i].updatedDate)).format('DD/MM/YYYY'); }

      if (this.temp[i].activatedStatusName === 'Cancelled' || this.temp[i].activatedStatusName === 'Schedule rejected') {
        this.temp[i].showApprove = false;
        this.temp[i].showCancel = false;
        this.temp[i].showReject = false;
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }
      if (this.temp[i].activatedStatusName === 'Inspection scheduled') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = true;
          this.temp[i].showReject = true;
          this.temp[i].showEditReport = false;
          this.temp[i].showGenerateReport = false;
        }
      }
      if (this.temp[i].activatedStatusName === 'Schedule accepted') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = false;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = true;
          this.temp[i].showEditReport = true;
          this.temp[i].showGenerateReport = true;
        }
      }
      if (this.temp[i].activatedStatusName === 'Inspection completed. Send for review') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = true;
          this.temp[i].showEditReport = true;
          this.temp[i].showGenerateReport = true;
        }
        if (!this.temp[i].equipment) {
          this.temp[i].showApprove = false;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = false;
        }
      }
      if (this.temp[i].activatedStatusName === 'Waiting for review') {
        if (this.isReviewer || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = false;
        }
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }
      if (this.temp[i].activatedStatusName === 'Review completed. Waiting for OTP approval') {
        if (this.isInspector || this.isAdmin) {
          this.temp[i].showApprove = true;
          this.temp[i].showCancel = false;
          this.temp[i].showReject = false;
        }
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }

      if (this.temp[i].activatedStatusName === 'Approved') {
        this.temp[i].showApprove = false;
        this.temp[i].showCancel = false;
        this.temp[i].showReject = false;
        this.temp[i].showEditReport = true;
        this.temp[i].showGenerateReport = true;
      }
    }
    this.temp1 = this.temp;
    this.dataSource = new MatTableDataSource(this.temp);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  backToSchedule() {
    this.router.navigateByUrl('master/scheduleList');
  }
  exportExcel() {
    let data1 = [];
    let i = 1;
    this.temp1.forEach(element => {
      let val = {
        'RFI No': element.inspectionSerialNumber,
        'Report No': element.reportNo,
        'RPO/PO No': element.rpoNo,
        'Inspection Date': element.inspectionDates,
        'Inspector Name': element.inspectorName,
        'Category': element.categoryName,
        'Status': element.activatedStatusName,
        'Created By': element.createdBy,
        'Created Date': element.createdDate
      };
      i = i + 1;
      data1.push(val);
    });
    this.excelService.saveAsExcelFile(data1, 'Inspections');
  }

  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }
  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.reportNo = d.reportNo ? d.reportNo : '';
      d.inspectionSerialNumber = d.inspectionSerialNumber ? d.inspectionSerialNumber : '';
      d.rpoNo = d.rpoNo ? d.rpoNo : '';
      d.inspectionDates = d.inspectionDates ? d.inspectionDates : '';
      d.inspectorName = d.inspectorName ? d.inspectorName : '';
      d.categoryName = d.categoryName ? d.categoryName : '';
      d.activatedStatusName = d.activatedStatusName ? d.activatedStatusName : '';
      d.projectNumber = d.projectNumber ? d.projectNumber : '';
      d.clientName = d.clientName ? d.clientName : '';
      return (d.reportNo.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.inspectionSerialNumber.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.rpoNo.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.inspectionDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.inspectorName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.categoryName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.projectNumber.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.clientName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.activatedStatusName.toLowerCase().indexOf(val) !== -1 || !val)

    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


}
