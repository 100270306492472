import { Injectable } from '@angular/core';
import { ScheduledRfidataPopupComponent } from 'src/app/inspections/scheduled-rfidata-popup/scheduled-rfidata-popup.component';
import { StickerHistoryComponent } from 'src/app/master/equipment/sticker-history/sticker-history.component';
import { InspectorListComponent } from 'src/app/master/inspector/inspector-list/inspector-list.component';
import { LocationListComponent } from 'src/app/master/location/location-list/location-list.component';
import { RfiInspectionChecklistDetailsComponent } from 'src/app/master/rfi-inspection/rfi-inspection-checklist-details/rfi-inspection-checklist-details.component';
import { RfiInspectionChecklistPopupComponent } from 'src/app/master/rfi-inspection/rfi-inspection-checklist-popup/rfi-inspection-checklist-popup.component';
import { SelectEquipmentComponent } from 'src/app/master/scheduleInspection/select-equipment/select-equipment.component';
import {InspectionStatusPchartComponent } from 'src/app/app-dashboard/inspection-status-pchart/inspection-status-pchart.component';
import {StatusBarchartComponent } from 'src/app/app-dashboard/status-barchart/status-barchart.component';
import {InspectionOverallSummaryComponent } from 'src/app/app-dashboard/inspection-overall-summary/inspection-overall-summary.component';
import {InspectorEquipmentBarchartComponent } from 'src/app/app-dashboard/inspector-equipment-barchart/inspector-equipment-barchart.component';
import {TotalEquipmentInspectedComponent } from 'src/app/app-dashboard/total-equipment-inspected/total-equipment-inspected.component';
import {TotalLoadtestComponent } from 'src/app/app-dashboard/total-loadtest/total-loadtest.component';
import {TotalLoadtestInspectedComponent } from 'src/app/app-dashboard/total-loadtest-inspected/total-loadtest-inspected.component';
import {ReviewerRejectionStatusComponent } from 'src/app/app-dashboard/reviewer-rejection-status/reviewer-rejection-status.component';

@Injectable({
  providedIn: 'root'
})
export class WidgetListService {

  constructor() { }

  getWidgestListOboject() {
    return {
      selectEquipmentComponent: SelectEquipmentComponent,
      inspectorListComponent: InspectorListComponent,
      locationListComponent: LocationListComponent,
      rfiInspectionChecklistPopupComponent: RfiInspectionChecklistPopupComponent,
      rfiInspectionChecklistDetailsComponent: RfiInspectionChecklistDetailsComponent,
      stickerHistoryComponent: StickerHistoryComponent,
      scheduledRfidataPopupComponent: ScheduledRfidataPopupComponent,
      inspectionStatusPchartComponent: InspectionStatusPchartComponent,
      statusBarchartComponent:StatusBarchartComponent,
      InspectionOverallSummaryComponent:InspectionOverallSummaryComponent,
      InspectorEquipmentBarchartComponent:InspectorEquipmentBarchartComponent,
      TotalEquipmentInspectedComponent:TotalEquipmentInspectedComponent,
      TotalLoadtestComponent:TotalLoadtestComponent,
      TotalLoadtestInspectedComponent:TotalLoadtestInspectedComponent,
      reviewerRejectionStatusComponent:ReviewerRejectionStatusComponent
    };

  }
}
