import { Component, OnInit, Inject } from '@angular/core';
import { ContactService } from '../contact.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../general/dateformat-form';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  contactForm: any;
 
  user: any;
  
  rowId = null;
  isSave = false;
  isUpdate = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ContactFormComponent>, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,

    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private contactService: ContactService
  ) {
    this.contactForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'emailID': [null, [Validators.required, Validators.email]],
      'description': [null]
    });
  }

  ngOnInit() {
    if (this.data._id) {
      this.rowId = this.data._id;
      this.isUpdate = true;
      this.patchValue();
    } else {
      this.isSave = true;
    }
    this.user = this.getFromLocalStorage('userDet');
  }

  save() {
    var inputData = {
      name: this.contactForm.controls.name.value,
      emailID: this.contactForm.controls.emailID.value,
      description: this.contactForm.controls.description.value,
      createdBy: null,
      updatedBy: null,
      rowId: null,
      
    }
   
    inputData.createdBy = this.user.data.name;
    inputData.updatedBy = this.user.data.name;
    inputData.rowId = this.rowId;

    if (this.rowId) {
      this.contactService.updateRecord(inputData).subscribe(result => {
        if (result[0]['statusBool']) {
          this.snackBar.open(result[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } else {
          this.snackBar.open(result[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      }, err => {
        if (err.error[0].statusHttp === 412) {
          this.snackBar.open(err.error[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else {
      this.contactService.save(inputData).subscribe(result => {
        if (result['statusBool']) {
         
          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        }
      }, err => {
        if (err.error.statusHttp === 412) {
          this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }
  }

  get f() {
    return this.contactForm.controls;
  }

  patchValue() {
   
    this.contactForm.patchValue({
      name: this.data.name,
      emailID: this.data.emailID,
      description: this.data.description,
    });
  }


  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  btnClose() {
    if (this.contactForm.dirty) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: true,
        hasBackdrop: true
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

}
