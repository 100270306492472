import { Component, OnInit, Inject, NgZone, ViewChild } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { ReceiverService } from '../receiver.service';
import { ScheduleInspectionService } from '../../scheduleInspection/schedule-inspection.service';
import { ProjectService } from '../../project/project.service';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';
import { ClientService } from '../../client/client.service';
@Component({
  selector: 'app-receiver-form',
  templateUrl: './receiver-form.component.html',
  styleUrls: ['./receiver-form.component.css']
})
export class ReceiverFormComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  inputData: any;
  user: any;
  clientDetails: any;
  isOffline: boolean;
  name = new FormControl('', [Validators.required]);
  badgeNo = new FormControl('', [Validators.required]);
  emailID = new FormControl(null, [Validators.required, Validators.email, Validators.pattern('^[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\\.[a-zA-Z]{2,4}$')]);

  telephone = new FormControl('');
  client = new FormControl('', [Validators.required]);
  _id: string;
  rowId: any;
  clients: any;
  ClientData: any;
  constructor(
    private dialog: MatDialog, private snackBar: MatSnackBar, private receiverService: ReceiverService,
    private scheduleInspectionService: ScheduleInspectionService,
    private projectService: ProjectService, private ngZone: NgZone, private clientService: ClientService,
    private dialogRef: MatDialogRef<ReceiverFormComponent>, private bottomSheet: MatBottomSheet, private offlineStorage: OfflineStorageService,
    @Inject(MAT_DIALOG_DATA) private data: any,) { }

  ngOnInit() {

    let value = localStorage.getItem('isOffline');
    if (value == 'true') {
      this.isOffline = true;
    }
    else {
      this.isOffline = false;
    }
    this.user = this.getFromLocalStorage('userDet');

    if (Object.keys(this.data).length !== 0) {
      this.rowId = this.data._id;
      this.name.setValue(this.data.name);
      this.badgeNo.setValue(this.data.badgeNo);
      this.emailID.setValue(this.data.emailID);
      this.telephone.setValue(this.data.telephone);
      this.client.setValue(this.data.client);
    }
    this.getClient();

    console.log(this.client)
  }
  getClient() {
    if (this.isOffline == true) {
      this.offlineStorage.clientHandler.getClients(this, function (res) {
        let _this = res.self;
        var tempTypeData = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            tempTypeData.push(JSON.parse(res.data.item(i).clientData));
          }
          if (tempTypeData.length > 0) {
            _this.bindValue(tempTypeData);
          }
        }
      });
    } else {
      this.clientService.getClient().subscribe(data => {
        this.clients = data;
        this.ClientData = this.clients;
      })
    }
  }
  bindValue(tempTypeData) {
    this.clients = tempTypeData;
    this.ClientData = this.clients;

  }
  save(
    name: String,
    badgeNo: String,
    emailID: String,
    telephone: String,
    client: String,
  ): void {
    if (!name || !emailID || !badgeNo || !client) {
      return;
    }
    this.inputData = {
      name: name,
      badgeNo: badgeNo,
      emailID: emailID,
      telephone: telephone,
      client: client
    };
    if (this.rowId) {
      this.inputData._id = this.data._id;
      this.inputData.updatedBy = this.user.data.name;
      this.receiverService.updateRecord(this.inputData).subscribe(res => {
        if (res[0]['statusBool']) {
          this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        }
      }, err => {
        if (err.error[0].statusHttp == 412) {
          this.snackBar.open(err.error[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else {
      this.inputData.createdBy = this.user.data.name;
      if (this.isOffline == true) {
        let isExist = false;

        this.offlineStorage.receiverHandler.getReceiver(this, function (res) {
          let _this = res.self;
          let receiverData = [];
          if (res && res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              var subData1 = JSON.parse(res.data.item(i).receiverData);

              receiverData.push(subData1);
            }
          }

          if (receiverData && receiverData.length > 0) {
            receiverData.forEach(element => {
              if ( (element._id !==null && element._id !== undefined && element._id !=='') && element.name == name.trim() && element.badgeNo === badgeNo) {
                isExist = true;
              }
            });
          }
          if (!isExist) {
            let receiverInfo = {

              name: _this.inputData.name,
              badgeNo: _this.inputData.badgeNo,
              emailID: _this.inputData.emailID,
              telephone: _this.inputData.telephone,
              client: _this.inputData.client,
              createdBy: _this.inputData.createdBy,

            }
            _this.offlineStorage.receiverHandler.insertOfflineReceiverDetails(_this, receiverInfo, function (res) {
              let _this = res.self;
              if (res) {
                var data = {
                  _id: res.data.insertId,
                  name: _this.inputData.name,
                  badgeNo: _this.inputData.badgeNo,
                  emailID: _this.inputData.emailID,
                  telephone: _this.inputData.telephone,
                  string: 'new'
                }
                var data2 = {
                  data: data
                }
                _this.ngZone.run(() => {
                  setTimeout(() => {
                    _this.snackBar.open('Saved successfully', '', {
                      duration: 5000,
                      verticalPosition: 'bottom',
                    });
                  }, 0);
                })
                _this.dialogRef.close(data2);
                console.log(res);
              }
            })
          }
          else {

            _this.ngZone.run(() => {
              setTimeout(() => {
                _this.snackBar.open('Duplicate entry', '', {
                  duration: 5000,
                  verticalPosition: 'bottom',
                });
              }, 0);
            });
          }

        });


      } else {
        this.receiverService.save(this.inputData).subscribe(res => {
          if (res['statusBool']) {
            this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
            this.dialogRef.close(res);
          }
        }, err => {
          if (err.error.statusHttp == 412) {
            this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
        });
      }

    }

  }
  getErrorReceiverName() {
    return this.name.hasError('required')
      ? 'Name is required'
      : '';
  }

  getErrorBadgeNo() {
    return this.badgeNo.hasError('required')
      ? 'Badge number is required'
      : '';
  }

  getErrorClient() {
    return this.client.hasError('required')
      ? 'Client name is required'
      : '';
  }



  btnClose() {
    if (
      this.name.dirty ||
      this.badgeNo.dirty ||
      this.emailID.dirty ||
      this.client.dirty

    ) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: false,
        hasBackdrop: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  filterClient(val) {
    val = val.toLowerCase();
    this.ClientData = this.clients.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }

}
