import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { environment } from '../../environments/environment';
import { SettingService } from '../services/setting/setting.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AppDashboardService {
  private apiUrl = '';
  public newBinChartSubject = new Subject<any>();
  dateSelected: string;
  public newChartSubject = new Subject<any>();
  public newChartLine = new Subject<any>();
  public newChartDelivery = new Subject<any>();
  public newVehicleDelChartSubject = new Subject<any>();
  public newDelTruckChartSubject = new Subject<any>();



  constructor(private http: HttpClient,
    private settingService:SettingService) {
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }


}
