import { Component } from '@angular/core';
// import { Router, ActivatedRoute } from '@angular/router';
// import { BackButtonDialogComponent } from '../app/general/back-button-dialog/back-button-dialog.component';
// import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MatBottomSheet, MatSnackBar } from '@angular/material';
// import { Location } from '@angular/common'
// import { SettingService } from '../app/services/setting/setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  // companyName: any;
  // constructor(
  //   private dialog: MatDialog,
  //   private router: Router,
  //   private location: Location,
  //   private settingService: SettingService
  // ) {
  // }

  // ngOnInit(): void {

  //   var companyName = localStorage.getItem('ClientName');
  //   if (companyName !== undefined && companyName !== null && companyName !== '') {
  //     if (companyName === 'AMPS')
  //       document.addEventListener('backbutton', () => this.onBackbuttonPress(this), false);
  //   } else {
  //     this.settingService.getByKey('company').subscribe(result => {
  //       if (result.length > 0) {
  //         this.companyName = result[0].value;
  //         localStorage.setItem('ClientName', this.companyName);
  //         if (this.companyName === 'AMPS') {
  //           document.addEventListener('backbutton', () => this.onBackbuttonPress(this), false);
  //         } else {
  //           var companyName = localStorage.getItem('ClientName');
  //           if (companyName === 'AMPS') {
  //             document.addEventListener('backbutton', () => this.onBackbuttonPress(this), false);
  //           }
  //         }
  //       } else {
  //         var companyName = localStorage.getItem('ClientName');
  //         if (companyName === 'AMPS') {
  //           document.addEventListener('backbutton', () => this.onBackbuttonPress(this), false);
  //         }
  //       }
  //     });
  //   }

  // }
  // onBackbuttonPress(_this) {
  //   var companyName = localStorage.getItem('ClientName');
  //   if (companyName !== undefined && companyName !== null && companyName !== '') {
  //     if (companyName === 'AMPS') {
  //       var user: any;
  //       var url: any;

  //       user = localStorage.getItem('userDet');
  //       var temp = JSON.parse(user)


  //       if (temp && temp.data.role.length > 0) {
  //         for (var i = 0; i < temp.data.role.length; i++) {
  //           if (temp.data.role[i].name === 'Administrator' && temp.data.role[i].isActive === true) {
  //             url = '/dashboard';
  //           } else if (temp.data.role[i].name === 'Inspector' && temp.data.role[i].isActive === true) {
  //             url = '/master/rfiInspectionList';
  //           } else if (temp.data.role[i].name === 'Reviewer' && temp.data.role[i].isActive === true) {
  //             url = '/master/rfiInspectionList';
  //           } else if (temp.data.role[i].name === 'Co-ordinator' && temp.data.role[i].isActive === true) {
  //             url = '/master/scheduleList'
  //           } else {
  //             url = '/dashboard';
  //           }
  //         }
  //       }
  //       if (_this.router.url === url) {
  //         let dialogRef = this.dialog.open(BackButtonDialogComponent, {
  //           disableClose: true
  //         });
  //         dialogRef.afterClosed().subscribe(result => {
  //           if (result === true) {
  //             this.router.navigateByUrl('login');
  //             this.removeLocalStorage('userDet');
  //             this.removeLocalStorage('ClientName');

  //           }
  //         });
  //       }
  //       else {
  //         this.location.back()

  //       }
  //     } else {
  //       this.location.back()

  //     }

  //   } else {
  //     this.location.back()

  //   }
  // }
  // removeLocalStorage(key: string) {
  //   localStorage.removeItem(key);
  // }
}


