import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet, MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-notes-popup',
  templateUrl: './notes-popup.component.html',
  styleUrls: ['./notes-popup.component.css']
})
export class NotesPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<NotesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,) { }

  ngOnInit() {
  }
  Delete() {
    this.dialogRef.close(true)
  }
  btnClose() {
    this.dialogRef.close(false)
  }
}
