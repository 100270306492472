import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting/setting.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private apiUrl = '';  // URL to web api

  constructor(private http: HttpClient,
    private settingService: SettingService) {
  }

  save(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('category');
    return this.http
      .post<any>(this.apiUrl + '/createRecord', data, httpOptions)
      .pipe(tap());
  }

  getCategory(): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('category');

    return this.http
      .get<any>(this.apiUrl + '/getAllRecords')
      .pipe(
        tap()
      );
  }

  deleteById(category: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('category');

    return this.http
      .post<any>(
        this.apiUrl + '/deleteRecord',
        category,
        httpOptions
      )
      .pipe(
        tap()
      );
  }
  getRecordById(category: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('category');

    return this.http
      .post<any>(
        this.apiUrl + '/getRecordById',
        category,
        httpOptions
      )
      .pipe(
        tap()
      );
  }
  updateRecord(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('category');

    return this.http
      .post<any>(this.apiUrl + '/updateRecord', data, httpOptions)
      .pipe(tap());
  }
}
