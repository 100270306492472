import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MatBottomSheet, MatSnackBar } from '@angular/material';
import { EquipmentFormComponent } from '../equipment-form/equipment-form.component';
import { EquipmentService } from '../equipment.service';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { ExcelService } from '../../../excel.service';
import { AuthService } from '../../../auth/auth.service';
import { RfiInspectionService } from '../../../master/rfi-inspection/rfi-inspection.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { StickerHistoryComponent } from '../sticker-history/sticker-history.component';
@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.css']
})

export class EquipmentListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  dataSource;
  displayedColumns = [
    'edit',
    'project',
    'equipmentNumber',
    'equipmentSerialNumber',
    'category',
    'location',
    'manufacture',
    'model',
    'type',
    'capacity',
    'updatedPreviousStickerNumber',
    'equipmentCompanyName',
    'createdBy',
    'createdDate',
    'updatedBy',
    'updatedDate'
  ];
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  permission: any;
  isWrite = false;

  constructor(
    private dialog: MatDialog,
    private equipmentService: EquipmentService, private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet, private excelService: ExcelService,
    private RfiInspectionService: RfiInspectionService
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;
    this.getTableData();
    if (this.permission && this.permission.master && this.permission.master.equipment && this.permission.master.equipment.write) {
      this.isWrite = true;
    } else {
      this.displayedColumns = ['project', 'equipmentNumber', 'equipmentSerialNumber', 'category', 'location', 'manufacture', 'model', 'type', 'capacity', 'updatedPreviousStickerNumber', 'equipmentCompanyName', 'createdBy', 'createdDate', 'updatedBy', 'updatedDate'
      ];
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  getTableData() {
    this.equipmentService.getRecords().subscribe(data => {
      if (!data) {
        return;
      }
      this.temp = data;
      if (this.temp && this.temp.length) {
        for (var i = 0; i < this.temp.length; i++) {

          if (this.temp[i].projectDetail && this.temp[i].projectDetail.length > 0) {
            this.temp[i].projectName = this.temp[i].projectDetail[0].projectName;
          }
          if (this.temp[i].manufactureDetail && this.temp[i].manufactureDetail.length > 0) {
            this.temp[i].manufactureName = this.temp[i].manufactureDetail[0].name;
          }

          if (this.temp[i].categoryDetail && this.temp[i].categoryDetail.length > 0) {
            this.temp[i].categoryName = this.temp[i].categoryDetail[0].name;
          }
          if (this.temp[i].typeDetail && this.temp[i].typeDetail.length > 0) {
            this.temp[i].typeName = this.temp[i].typeDetail[0].name;
          }
          if (this.temp[i].locationDetail && this.temp[i].locationDetail.length > 0) {
            this.temp[i].locationName = this.temp[i].locationDetail[0].name;
          }
          if (this.temp[i].equipmentcompanyDetail && this.temp[i].equipmentcompanyDetail.length > 0) {

            this.temp[i].equipmentCompanyName = this.temp[i].equipmentcompanyDetail[0].name
          }
          // if (this.temp[i].updatedExpiryDate) { this.temp[i].updatedExpiryDate = moment(new Date(this.temp[i].updatedExpiryDate)).format('DD/MM/YYYY'); }
          if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
          if (this.temp[i].updatedDate) { this.temp[i].updatedDate = moment(new Date(this.temp[i].updatedDate)).format('DD/MM/YYYY'); }
        }
      }
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  btnAddNewClick() {
    const dialogRef = this.dialog.open(EquipmentFormComponent, {
      data: {},
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  deleteTable(item) {
    const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
      data: { deleteItem: true },
      disableClose: false,
      hasBackdrop: true
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        this.RfiInspectionService.checkEquipmentIsExist(item._id).subscribe(data => {
          if (data && data.length > 0) {
            this.snackBar.open('Equipment exist in rfi inspection.', '', { duration: 4000 });
            return;
          }

          else {
            this.equipmentService.deleteById(item).subscribe(result => {
              if (result['statusBool'] == -1) {
                this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
                this.ngOnInit();
              } else {
                this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
                this.ngOnInit();
              }
            }, err => {
              throw err;
            });
          }
          if (result === false) {
            this.ngOnInit();
          }
        });
      }
    });
  }

  getTable(row) {
    let dialogRef = this.dialog.open(EquipmentFormComponent, {
      data: row,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  viewStickerDetail(row) {
    let dialogRef = this.dialog.open(StickerHistoryComponent, {
      data: row,
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  exportExcel() {
    let data1 = [];
    let i = 1;
    this.temp1.forEach(element => {
      let val = {
        'Project / Vendor': element.projectName,
        'Equipment Number': element.equipmentNumber,
        'Serial Number': element.equipmentSerialNumber,
        'Category': element.categoryName,
        'Location': element.locationName,
        'Manufacture': element.manufactureName,
        'Model': element.model,
        'Type': element.typeName,
        'Capacity': element.capacity,
        'Previous Sticker Number': element.updatedPreviousStickerNumber,
        'Equipment company': element.equipmentCompanyName,
        'Created By': element.createdBy,
        'Created Date': element.createdDate,
        'Updated By': element.updatedBy,
        'Updated Date': element.updatedDate,
      };
      i = i + 1;
      data1.push(val);
    });
    this.excelService.saveAsExcelFile(data1, 'Equipment Master');
  }

  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.projectName = d.projectName ? d.projectName : '';
      d.equipmentSerialNumber = d.equipmentSerialNumber ? d.equipmentSerialNumber : '';
      d.equipmentNumber = d.equipmentNumber ? d.equipmentNumber : '';
      d.locationName = d.locationName ? d.locationName : '';
      d.manufactureName = d.manufactureName ? d.manufactureName : '';
      d.categoryName = d.categoryName ? d.categoryName : '';
      d.model = d.model ? d.model : '';
      d.capacity = d.capacity ? d.capacity : '';
      d.updatedPreviousStickerNumber = d.updatedPreviousStickerNumber ? d.updatedPreviousStickerNumber : '';
      d.equipmentCompanyName = d.equipmentCompanyName ? d.equipmentCompanyName : '';
      d.typeName = d.typeName ? d.typeName : '';
      d.createdBy = d.createdBy ? d.createdBy : '';
      d.createdDate = d.createdDate ? d.createdDate : '';
      d.updatedBy = d.updatedBy ? d.updatedBy : '';
      d.updatedDate = d.updatedDate ? d.updatedDate : '';
      return (d.projectName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.equipmentSerialNumber.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.equipmentNumber.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.locationName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.manufactureName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.categoryName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.model.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.capacity.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedPreviousStickerNumber.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.equipmentCompanyName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.typeName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedDate.toLowerCase().indexOf(val) !== -1 || !val);
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
