import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatIconModule,
  MatSelectModule,
  MatOptionModule,
  MatDatepickerModule,
  MatBottomSheetModule,
  MatListModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatStepperModule,
  MatDialogModule,
  MatRadioModule,
  MatCardModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatBadgeModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { ConfirmSubmitComponent } from './confirm-submit/confirm-submit.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
// import { FlatpickrModule } from 'angularx-flatpickr';
import { DepartmentListComponent } from './department/department-list/department-list.component';
import { DepartmentFormComponent } from './department/department-form/department-form.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import { CategoryFormComponent } from './category/category-form/category-form.component';
import { LocationListComponent } from './location/location-list/location-list.component';
import { LocationFormComponent } from './location/location-form/location-form.component';
import { TypeFormComponent } from './type/type-form/type-form.component';
import { TypeListComponent } from './type/type-list/type-list.component';
import { ManufactureFormComponent } from './manufacture/manufacture-form/manufacture-form.component';
import { ManufactureListComponent } from './manufacture/manufacture-list/manufacture-list.component';
import { EquipmentFormComponent } from './equipment/equipment-form/equipment-form.component';
import { EquipmentListComponent } from './equipment/equipment-list/equipment-list.component';
import { QrcodeFormComponent } from './qrcode/qrcode-form/qrcode-form.component';
import { QrcodeListComponent } from './qrcode/qrcode-list/qrcode-list.component';
import { ProjectFormComponent } from './project/project-form/project-form.component';
import { ProjectListComponent } from './project/project-list/project-list.component';
import { ScheduleInspectionFormComponent } from './scheduleInspection/schedule-inspection-form/schedule-inspection-form.component';
import { ScheduleInspectionListComponent } from './scheduleInspection/schedule-inspection-list/schedule-inspection-list.component';
import { ScheduleInspectionReportComponent } from './scheduleInspection/schedule-inspection-report/schedule-inspection-report.component';
// import { RfiInspectionListComponent } from './rfi-inspection/rfi-inspection-list/rfi-inspection-list.component';
import { WorkflowPopupComponent } from './rfi-inspection/workflow-popup/workflow-popup.component';
import { ClientFormComponent } from './client/client-form/client-form.component';
import { ClientListComponent } from './client/client-list/client-list.component';
import { CompanyFormComponent } from './company/company-form/company-form.component';
import { CompanyListComponent } from './company/company-list/company-list.component';
import { SiteLocationFormComponent } from './siteLocation/site-location-form/site-location-form.component';
import { SiteLocationListComponent } from './siteLocation/site-location-list/site-location-list.component'
import { ChecklistListComponent } from './checklist/checklist-list/checklist-list.component';
import { ChecklistFormComponent } from './checklist/checklist-form/checklist-form.component';
import { ChecklistForm2Component } from './checklist/checklist-form2/checklist-form2.component';
import { SelectEquipmentComponent } from '../master/scheduleInspection/select-equipment/select-equipment.component';
import { InspectorListComponent } from './inspector/inspector-list/inspector-list.component';
import { InspectorFromComponent } from './inspector/inspector-from/inspector-from.component';
import { RfiInspectionChecklistComponent } from './rfi-inspection/rfi-inspection-checklist/rfi-inspection-checklist.component';
import { AttachmentFormComponent } from './attachments/attachment-form/attachment-form.component';
import { FileUploadModule } from 'ng2-file-upload';
import { RfiReportShowComponent } from './report/rfi-report-show/rfi-report-show.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RfiInspectionChecklistPopupComponent } from './rfi-inspection/rfi-inspection-checklist-popup/rfi-inspection-checklist-popup.component';
import { RfiInspectionChecklistDetailsComponent } from './rfi-inspection/rfi-inspection-checklist-details/rfi-inspection-checklist-details.component';
import { ContactFormComponent } from './contact/contact-form/contact-form.component';
import { ContactListComponent } from './contact/contact-list/contact-list.component';
import { EquipmentCompanyListComponent } from './equipmentCompany/equipment-company-list/equipment-company-list.component';
import { EquipmentCompanyFormComponent } from './equipmentCompany/equipment-company-form/equipment-company-form.component';
import { ScheduledRfidataPopupComponent } from '../inspections/scheduled-rfidata-popup/scheduled-rfidata-popup.component';
import { StickerHistoryComponent } from './equipment/sticker-history/sticker-history.component';
import { QrCodeBulkPrintComponent } from './qrcode/qr-code-bulk-print/qr-code-bulk-print.component';
import { Ng5SliderModule } from 'ng5-slider';
import { ViewScheduleInspectionnComponent } from './scheduleInspection/view-schedule-inspectionn/view-schedule-inspectionn.component';
import { ReceiverFormComponent } from './receiver/receiver-form/receiver-form.component';
import { SelectReceiverComponent } from './scheduleInspection/select-receiver/select-receiver.component';
import { RfiInspectionEquipmentPopupComponent } from './rfi-inspection/rfi-inspection-equipment-popup/rfi-inspection-equipment-popup.component';
import { ScheduleInspectionReportEmailFormComponent } from './scheduleInspection/schedule-inspection-report-email-form/schedule-inspection-report-email-form.component';
import { ReceiverListComponent } from './receiver/receiver-list/receiver-list.component';
import { RejectHistoryComponent } from './rfi-inspection/reject-history/reject-history.component';
import { LogComponent } from './log/log.component';
import { ClientfeedbackListComponent } from './clientfeedback/clientfeedback-list/clientfeedback-list.component';
import { ClientfeedbackFormComponent } from './clientfeedback/clientfeedback-form/clientfeedback-form.component';
import { InspectorscheduleListComponent } from './inspectorschedule/inspectorschedule-list/inspectorschedule-list.component';
import { MobileLogListComponent } from './mobilelog/mobile-log-list/mobile-log-list.component';
import { MobileLogPopupComponent } from './mobilelog/mobile-log-popup/mobile-log-popup.component';


@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatBottomSheetModule,
    MatListModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDialogModule,
    MatCardModule,
    FlexLayoutModule,
    FileUploadModule,
    PdfViewerModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'YOUR_KEY'
    // }),
    MatSlideToggleModule,
    NgxDaterangepickerMd,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    Ng5SliderModule,
    NgbModalModule,
    // FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),    
    
  ],
  declarations: [
    ConfirmDeleteComponent,
    ConfirmSubmitComponent,
    DepartmentListComponent,
    DepartmentFormComponent,
    CategoryListComponent,
    CategoryFormComponent,
    LocationListComponent,
    LocationFormComponent,
    TypeFormComponent,
    TypeListComponent,
    ManufactureFormComponent,
    ManufactureListComponent,
    EquipmentFormComponent,
    EquipmentListComponent,
    QrcodeFormComponent,
    QrcodeListComponent,
    ProjectFormComponent,
    ProjectListComponent,
    ScheduleInspectionFormComponent,
    ScheduleInspectionListComponent,
    ScheduleInspectionReportComponent,

    ClientFormComponent,
    ClientListComponent,
    CompanyFormComponent,
    CompanyListComponent,
    // RfiInspectionListComponent,
    WorkflowPopupComponent,
    SiteLocationFormComponent,
    SiteLocationListComponent,
    ChecklistListComponent,
    ChecklistFormComponent,
    ChecklistForm2Component,
    SelectEquipmentComponent,
    InspectorListComponent,
    InspectorFromComponent,
    RfiInspectionChecklistComponent,
    AttachmentFormComponent,
    RfiReportShowComponent,
    RfiInspectionChecklistPopupComponent,
    RfiInspectionChecklistDetailsComponent,
    ContactFormComponent,
    ContactListComponent,
    EquipmentCompanyListComponent,
    EquipmentCompanyFormComponent,
    StickerHistoryComponent,
    QrCodeBulkPrintComponent,
    ViewScheduleInspectionnComponent,
    ReceiverFormComponent,
    SelectReceiverComponent,
    RfiInspectionEquipmentPopupComponent,
    ScheduleInspectionReportEmailFormComponent,
    ReceiverListComponent,
    RejectHistoryComponent,
    LogComponent,
    ClientfeedbackListComponent,
    ClientfeedbackFormComponent,
    InspectorscheduleListComponent,
    MobileLogListComponent,
    MobileLogPopupComponent
  ],
  entryComponents: [
    ProjectFormComponent,
    EquipmentFormComponent,
    ScheduleInspectionFormComponent,
    WorkflowPopupComponent,
    ChecklistForm2Component,
    SelectEquipmentComponent,
    InspectorListComponent,
    InspectorFromComponent,
    AttachmentFormComponent,
    RfiInspectionChecklistPopupComponent,
    RfiInspectionChecklistDetailsComponent,
    ScheduledRfidataPopupComponent,
    StickerHistoryComponent,
    LocationListComponent,
    QrCodeBulkPrintComponent,
    ReceiverFormComponent,
    SelectReceiverComponent,
    RfiInspectionEquipmentPopupComponent,
    ScheduleInspectionReportEmailFormComponent,
    RejectHistoryComponent,
    ClientfeedbackFormComponent,
    MobileLogPopupComponent
  ]
})
export class MasterModule { }
