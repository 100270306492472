import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { CompanyService } from '../company.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingService } from '../../../services/setting/setting.service';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.css']
})
export class CompanyFormComponent implements OnInit {
  // public uploaderForDocument: FileUploader = new FileUploader({ itemAlias: 'photo' });
  public uploader: FileUploader = new FileUploader({ itemAlias: 'photo' });

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  inputData: any;
  user: any;
  file: any;
  name = new FormControl('', [Validators.required]);
  nameInArabic = new FormControl('');
  contactPerson = new FormControl('');
  emailId = new FormControl('', [Validators.required, Validators.email]);
  telephone = new FormControl('');
  fax = new FormControl('');
  website = new FormControl('');
  address = new FormControl('');
  abbr = new FormControl('', [Validators.required]);
  resume = new FormControl('');
  showResume = new FormControl('');
  isShow = true;
  logo = null;
  companyName = '';

  _id: string;
  constructor(
    private dialog: MatDialog,
    private companyService: CompanyService,
    private dialogRef: MatDialogRef<CompanyFormComponent>,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private sanitizer: DomSanitizer,
    private settingService: SettingService
  ) { }

  getCompanyName() {
    this.settingService.getByKey('company').subscribe(result => {
      if (result.length > 0) {
        this.companyName = result[0].value;
      }
    })

  }

  ngOnInit() {
    this.getCompanyName();
    this.user = this.getFromLocalStorage('userDet');
    if (Object.keys(this.data).length !== 0) {
      this.name.setValue(this.data.name);
      this.nameInArabic.setValue(this.data.nameInArabic);
      this.contactPerson.setValue(this.data.description);
      this.emailId.setValue(this.data.emailId);
      this.telephone.setValue(this.data.telephone);
      this.fax.setValue(this.data.fax);
      this.website.setValue(this.data.website);
      this.address.setValue(this.data.address);
      this.abbr.setValue(this.data.abbr);
      if (this.data.logo) {
        this.logo = this.data.logo;
      } else {
        this.isShow = false;
      }
    }

    this.uploader.onAfterAddingFile = (file) => {
      this.isShow = true;

      let url = (window.URL) ? window.URL.createObjectURL(file._file) : (window as any).webkitURL.createObjectURL(file._file);
      this.logo = url;

      file.withCredentials = false;
      this.file = file;
      var reader = new FileReader();
      reader.readAsDataURL(this.file.file.rawFile);
      reader.onload = function () {
        file.formData = reader.result;
      };
      reader.onerror = function (error) {
      };
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    };

  }

  save(
    name: String,
    nameInArabic: String,
    contactPerson: String,
    emailId: String,
    telephone: Number,
    fax: Number,
    website: String,
    address: String,
    abbr: String
  ): void {
    if (!name || !emailId || !abbr) {
      return;
    }
    this.inputData = {
      name: name,
      nameInArabic: nameInArabic,
      contactPerson: contactPerson,
      emailId: emailId,
      telephone: telephone,
      fax: fax,
      website: website,
      address: address,
      abbr: abbr
    };
    if (this.file) {
      this.logo = this.file.formData;
    } else {
      this.logo = this.logo;
    }
    this.inputData.logo = this.logo;

    if (Object.keys(this.data).length !== 0) {
      this.inputData._id = this.data._id;
      this.inputData.updatedBy = this.user.data.name;
      this.companyService.updateRecord(this.inputData).subscribe(res => {
        if (res[0]['statusBool']) {
          this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        }
      }, err => {
        if (err.error[0].statusHttp == 412) {
          this.snackBar.open(err.error[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else {

      this.inputData.createdBy = this.user.data.name;
      this.companyService.save(this.inputData).subscribe(res => {
        if (res['statusBool']) {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        }
      }, err => {
        if (err.error.statusHttp == 412) {
          this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }
  }

  getErrorCompany() {
    return this.name.hasError('required')
      ? 'Name is required'
      : '';
  }

  getErrorABBR() {
    return this.abbr.hasError('required')
      ? 'ABBR is required'
      : '';
  }

  btnClose() {
    if (
      this.name.dirty ||
      this.emailId.dirty
    ) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: false,
        hasBackdrop: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  delete() {
    this.logo = null;
    this.file = null;
    this.isShow = false;
  }

}
