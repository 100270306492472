import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef, OnInit
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { NgbModal, NgbTimepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { RfiInspectionService } from '../../rfi-inspection/rfi-inspection.service';
import * as moment from 'moment';
import { FileUploadModule } from 'ng2-file-upload';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

// interface Film {
//   id: number;
//   title: string;
//   release_date: string;
// }


@Component({
  selector: 'app-inspectorschedule-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './inspectorschedule-list.component.html',
  styleUrls: ['./inspectorschedule-list.component.css']
})
export class InspectorscheduleListComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [];

  events: CalendarEvent[];

  activeDayIsOpen: boolean = false;
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  userRoles: any;
  userId: any;
  isInspector = false;
  isAdmin = false;
  isReviewer = false;
  isVerifier = false;
  isCoordinator = false;
  permission: any;
  isWrite = false; user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  Inspectortitle: any;
  Isshow: any;

  constructor(
    private modal: NgbModal,
    private rfiInspectionService: RfiInspectionService
  ) { }

  ngOnInit() {
    this.getUser();
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;
    this.getTableData();

    //this.generatedata();
    //console.log(this.temp1)

    if (this.permission && this.permission.inspections && this.permission.inspections.Inspectorschedule && this.permission.inspections.Inspectorschedule.write) {
      this.isWrite = true;
    } else {
      //this.displayedColumns = ['inspectionSerialNumber', 'reportNo', 'inspectionDate', 'projectNumber', 'clientName', 'inspector', 'category'];
    }
  }

  getUser() {
    this.user = this.getFromLocalStorage('userDet');
    this.userId = this.user.data._id;
    this.userRoles = this.user.data.role;
    this.checkRoles();
  }

  checkRoles() {

    for (var i = 0; i < this.userRoles.length; i++) {
      if (this.userRoles[i].name === 'Administrator' && this.userRoles[i].isActive === true) {
        this.isAdmin = true;
      } else if (this.userRoles[i].name === 'Inspector' && this.userRoles[i].isActive === true) {
        this.isInspector = true;
      } else if (this.userRoles[i].name === 'Reviewer' && this.userRoles[i].isActive === true) {
        this.isReviewer = true;
      } else if (this.userRoles[i].name === 'Co-ordinator' && this.userRoles[i].isActive === true) {
        this.isCoordinator = true;
      } else if (this.userRoles[i].name === 'Verifier' && this.userRoles[i].isActive === true) {
        this.isVerifier = true;
      }
    }
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  getTableData() {
    let userdata: any = {
      userID: this.userId
    }
    if (this.isAdmin || this.isReviewer || this.isVerifier) {
      this.rfiInspectionService.getAllInspectorScheduleRecords().subscribe(data => {

        if (!data) {
          return;
        }
        this.temp = data;
        //console.log(data)

        if (data.length > 0) {
          this.generatedata();

        }

      });
    } else if (this.isCoordinator) {
      this.rfiInspectionService.getAllInspectorScheduleRecordsForCoordinator(userdata).subscribe(data => {
        if (!data) {
          return;
        }
        this.temp = data;
        this.generatedata();
      });
    } else if (this.isInspector) {
      this.rfiInspectionService.getAllInspectorScheduleRecordsByInspector(userdata).subscribe(data => {

        if (!data) {
          return;
        }
        this.temp = data;
        this.generatedata();
      });
    }


  }

  generatedata() {
    this.temp1 = this.temp;
    //this.activeDayIsOpen = true;
    this.events = this.temp1.map((film: any) => {
      return {
        title: film._id.InspectorName,
        start: new Date(
          film._id.inspectionDate
        ),
        color: colors.lightgreen,
        allDay: true,
        meta: {
          film,
        },
      };

    });
     console.log(this.events)
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    debugger
    var date1 = moment(new Date(date)).format('DD/MMM/YYYY');
    this.temp1 = [];
    var inspector = [];
    var week2 = [];
    for (let i = 0; i < this.temp.length; i++) {
      if (this.temp[i]._id.inspectionDate) {
        this.temp[i]._id.inspectionDates = moment(new Date(this.temp[i]._id.inspectionDate)).format('DD/MMM/YYYY');
      }
      var week1 = this.temp.filter(function (d) { return (d.inspectionDates == date1) });
      //console.log(week1)
    }


    this.events = this.temp1.map((film: any) => {
      return {
        title: film._id.InspectorName,
        start: new Date(
          film._id.inspectionDate
        ),
        color: colors.lightgreen,
        allDay: true,
        meta: {
          film,
        },
      };
    });
    this.generatedata();


    if (isSameMonth(date, this.viewDate)) {

      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        //this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }



  setView(view: CalendarView) {
    this.view = view;
    //this.generatedata();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.generatedata();
  }

  getStatus(date: any, badgetotal: any, date1: any, title: any): any {
    //var datetime = moment(new Date(date1)).format('YYYY-MM-DD');
    //var datetime1 = moment(new Date(date)).format('YYYY-MM-DD');
    if (
      moment(new Date(date)).format('YYYY-MM-DD') === moment(new Date(date1)).format('YYYY-MM-DD')) {
      this.Inspectortitle = title;
      this.Isshow = 'block';
      
      // console.log(date) 
      // console.log(date) 
    }
    else {
      this.Inspectortitle = null;
      this.Isshow = 'none';
    }
    //console.log(this.Inspectortitle2)    
    return this.Inspectortitle
  }

  reverseAndTimeStamp(dateString) {
    const reverse = new Date(dateString);
    return reverse.getTime();
  }

}
