import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MatBottomSheet, MatSnackBar, } from '@angular/material';
import { QrcodeFormComponent } from '../qrcode-form/qrcode-form.component';
import { QrcodeService } from '../qrcode.service';
import { ReportService } from '../../../services/report/report.service';
import { ExcelService } from '../../../excel.service';
import * as moment from 'moment';
import { FileService } from '../../../services/files/file.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { AppConfirmService } from '../../../general/app-confirm/app-confirm.service';

@Component({
  selector: 'app-qrcode-list',
  templateUrl: './qrcode-list.component.html',
  styleUrls: ['./qrcode-list.component.css']
})
export class QrcodeListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  // isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  // dataSource: CompanyListDataSource;
  dataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'sno',
    'qrCode',
    'type',
    'inspector.inspectorName',
    'qrScan.base64',
    'report',
    'createdBy',
    'createdDate'


  ];
  temp = [];
  temp1 = [];
  data1: any;
  doFilter: Boolean;
  permission: any;
  isWrite = false;
  constructor(
    private dialog: MatDialog,
    private qrcodeService: QrcodeService,
    private reportService: ReportService,
    private excelService: ExcelService,
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private fileService: FileService,
    private router: Router,
    private confirmService: AppConfirmService,
  ) { }



  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;
    this.getTableData();
    if (this.permission && this.permission.project && this.permission.project.qrcode && this.permission.project.qrcode.write) {
      this.isWrite = true;

      if (this.permission && this.permission.project && this.permission.project.stickerRevoke && this.permission.project.stickerRevoke.write) {
        this.displayedColumns = ['sno', 'qrCode', 'type', 'inspector.inspectorName', 'qrScan.base64', 'report', 'revoke', 'revokedBy',
          'revokedDate',
          'createdBy', 'createdDate'];
      } else {
        this.displayedColumns = ['sno', 'qrCode', 'type', 'inspector.inspectorName', 'qrScan.base64', 'report',
          'createdBy', 'createdDate'];
      }
    } else {
      if (this.permission && this.permission.project && this.permission.project.stickerRevoke && this.permission.project.stickerRevoke.write) {
        this.displayedColumns = ['sno', 'qrCode', 'type', 'inspector.inspectorName', 'qrScan.base64', 'report', 'revoke', 'revokedBy',
          'revokedDate',
          'createdBy', 'createdDate'];
      } else {
        this.displayedColumns = ['sno', 'qrCode', 'type', 'inspector.inspectorName', 'qrScan.base64', 'report',
          'createdBy', 'createdDate'];
      }
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  getTableData() {
    this.qrcodeService.getQRCode().subscribe(data => {
      if (!data) {
        return;
      }
      this.temp = data;
      for (let i = 0; i < this.temp.length; i++) {
        if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
        if (this.temp[i].revokedDate) { this.temp[i].revokedDate = moment(new Date(this.temp[i].revokedDate)).format('DD/MM/YYYY'); }
      }
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });
  }
  btnAddNewClick() {
    const dialogRef = this.dialog.open(QrcodeFormComponent, {
      data: { type: "PASS" },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  bulkPrint() {
    this.router.navigate(['/master/qrCodeBulkPrint']);
  }

  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  print(row) {
    this.reportService.generateIndividualQRReport(row).subscribe(res => {

      if (!res) {
        return;
      }
      var base64 = res.data;
      var blob = this.fileService._base64ToArrayBuffer(base64, 'application/pdf');

      if (environment.mobileApp) {
        this.fileService.openFileOnMobile(blob, row.qrCode, 'application/pdf')
      } else {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        } else {
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
        }
      }
    });
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.createdBy = d.createdBy ? d.createdBy : '';
      d.createdDate = d.createdDate ? d.createdDate : '';
      d.revokedBy = d.revokedBy ? d.revokedBy : '';
      d.revokedDate = d.revokedDate ? d.revokedDate : '';
      d.qrCode = d.qrCode ? d.qrCode : '';
      d.type = d.type ? d.type : '';
      d.inspector.inspectorName = d.inspector.inspectorName ? d.inspector.inspectorName : '';
      return (d.qrCode.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.type.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.inspector.inspectorName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.revokedBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.revokedDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdBy.toLowerCase().indexOf(val) !== -1 || !val)
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  exportExcel() {
    var data1 = [];
    var i = 1;
    this.temp1.forEach(element => {
      var val = {
        "S.No": i,
        "QR Number": element.qrCode,
        "Type": element.type,
        "Inspector": element.inspector.inspectorName,
        'Revoked By': element.revokedBy,
        'Revoked Date': element.revokedDate,
        'Created By': element.createdBy,
        'Created Date': element.createdDate

      };
      i = i + 1;
      data1.push(val);
    });
    this.excelService.saveAsExcelFile(data1, 'QR Code Master');
  }
  revoke(row) {
    row.revokedByUser = this.user.data.name;
    this.confirmService.confirm({ message: `Are you sure want to revoke the sticker?` })
      .subscribe(res => {
        if (res) {
          this.qrcodeService.revokeSticker(row).subscribe(res => {
            if (res['statusBool']) {
              this.snackBar.open(res['message'], '', { duration: 3000, verticalPosition: "bottom" });
              this.getTableData();
            }
          });
        } else {
          row.revokedBy = null;
          row.revokedDate = null;
        }
      })
  }
}
