import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
declare var window: any;
import { SettingService } from '../../services/setting/setting.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private apiUrl = '';  // URL to web api

  constructor(private http: HttpClient,
    private settingService:SettingService) {
  }

  getRecord(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('file');
    return this.http.post<any[]>(`${this.apiUrl}/getRecord`, data, httpOptions)
      .pipe(
        tap()
      );
  }

  getAllRecords(): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('file');
    return this.http.post<any[]>(`${this.apiUrl}/getAllRecords`, httpOptions)
      .pipe(
        tap()
      );
  }
  uploadFileForRFIReport(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('file');
    return this.http.post<any[]>(`${this.apiUrl}/uploadFileForRFIReport`, data, httpOptions)
      .pipe(
        tap()
      );
  }

  deleteFile(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('file');
    return this.http.post<any[]>(`${this.apiUrl}/deleteFile`, data, httpOptions)
      .pipe(
        tap()
      );
  }

  getAllRecordByModule(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('file');
    return this.http.post<any[]>(`${this.apiUrl}/getAllRecordByModule`, data, httpOptions)
      .pipe(
        tap()
      );
  }
  getAttachmentById(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('file');
    return this.http.post<any[]>(`${this.apiUrl}/getAttachmentById`, data, httpOptions)
      .pipe(
        tap()
      );
  }

  openFileOnMobile(dataBlob, fileName, filetype) {
    var folderpath = (window as any).cordova.file.dataDirectory;
    window.resolveLocalFileSystemURL(folderpath, function (dir) {
      dir.getFile(fileName, { create: true }, function (file) {
        file.createWriter(function (fileWriter) {
          fileWriter.write(dataBlob);
          var finalPath = folderpath + fileName;
          (window as any).cordova.plugins.fileOpener2.open(finalPath, filetype,
            {
              error: function (e) {
                console.log('Error status: ' + e.status + ' - Error message: ' + e.message)
              },
              success: function () {
                console.log('File opened successfully');
              }
            });
        }, function (err) {
          console.log(err);
        });
      }, function (err) {
        console.log(err);
      });
    });

  }

  _base64ToArrayBuffer(base64, type) {
    var byteCharacters = atob(base64);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var a = document.createElement("a");
    document.body.appendChild(a);
    var blob = new Blob([byteArray], { type: type });
    return blob;
  }

}