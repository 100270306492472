import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { UserService } from '../../../admin/user/user.service'
import { QrcodeService } from '../qrcode.service';
import { GeoptionService } from '../../../services/geoption/geoption.service'
import { GeseedService } from '../../../services/geseed/geseed.service'
import { SettingService } from '../../../services/setting/setting.service'
import * as _ from 'underscore';
@Component({
  selector: 'app-qrcode-form',
  templateUrl: './qrcode-form.component.html',
  styleUrls: ['./qrcode-form.component.css']
})
export class QrcodeFormComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  users = []; USERS = [];
  options = []; types = [];

  inputData: any;
  seedData: any;
  user: any;
  qrNumber: any;
  ISDisabled = true;
  module: any;
  nextvalue: Number

  lastQRFailNo: any;
  lastQRFailPadNo: any;
  lastQRFailCode: any;

  lastQRPassNo: any;
  lastQRPassPadNo: any;
  lastQRPassCode: any;
  qrPrefix: any;
  usersData: any;
  public startingNo = '';
  len: any;
  key: any;
  currentYear: any;
  qrPrefixValue :any;
  inspector = new FormControl('', [Validators.required]);
  type = new FormControl('PASS', [Validators.required]);
  totalCount = new FormControl('', [Validators.required]);
  insertFn: any;
  currentDate = new Date();
  constructor(
    private dialogRef: MatDialogRef<QrcodeFormComponent>,
    private userService: UserService,
    private geoptionService: GeoptionService,
    private qrcodeService: QrcodeService,
    private settingService: SettingService,
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private geseedService: GeseedService, @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.getKey();
    this.getUsers();
    this.getTypes();
    if (Object.keys(this.data).length !== 0) {
      this.inspector.setValue(this.data.user);
      this.type.setValue(this.data.type);
      this.totalCount.setValue(this.data.totalCount);

    }
    if (this.data.type == 'PASS') {
      this.getValues();
    }

    this.getQRCode();
  }
  getQRCode() {
    var tempdata1 = [];
    var tempdata2 = [];
    this.qrcodeService.getQRCode().subscribe(data => {
      for (var q = 0; q < data.length; q++) {
        if (data[q].type == "PASS") {

          tempdata1.push(data[q]);
          var dt1 = tempdata1.reduce((acc, dd) => acc = acc > dd.qrNumber ? acc : dd.qrNumber, 0);
          for (var j = 0; j < tempdata1.length; j++) {
            if (dt1 == tempdata1[j].qrNumber) {
              this.lastQRPassCode = tempdata1[j].qrCode
            }
          }

        } else if (data[q].type == "FAIL") {
          tempdata2.push(data[q]);
          var dt2 = tempdata2.reduce((acc, dd) => acc = acc > dd.qrNumber ? acc : dd.qrNumber, 0);
          for (var g = 0; g < tempdata2.length; g++) {
            if (dt2 == tempdata2[g].qrNumber) {
              this.lastQRFailCode = tempdata2[g].qrCode
            }
          }
        }
      }
    });
  }
  getKey() {
    this.settingService.getByKey("company").subscribe(res => {
      this.qrPrefixValue = res[0].value;
      this.currentYear = this.currentDate.getFullYear();
      if (res[0].value == "SGS")
      this.qrPrefix = res[0].value + '-';
      else
      this.qrPrefix = res[0].value + '-' ;
    });
  }
  pad(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  onChangeType(event) {
    if (event.value == "PASS") {
      this.geseedService.getRecordBySeedName('QR-PASS').subscribe(res => {
        this.startingNo = res[0].seedConfig.nextValue;
        this.len = res[0].seedConfig.minValLen;
        this.key = this.pad(this.startingNo, this.len);
      });
    }
    else if (event.value == "FAIL") {
      this.geseedService.getRecordBySeedName('QR-FAIL').subscribe(res => {
        this.startingNo = res[0].seedConfig.nextValue;
        this.len = res[0].seedConfig.minValLen;
        this.key = this.pad(this.startingNo, this.len);
      });
    }

  }
  getTypes() {

    this.geoptionService.getRecordByOptionName('Type').subscribe(res => {
      this.options = res[0].option;
      this.options.forEach(element => {
        this.types.push(element.optionValue);
      });
    });
  }
  getValuesQRFail() {
    this.geseedService.getRecordBySeedName('QR-FAIL').subscribe(res => {
      this.startingNo = res[0].seedConfig.nextValue;
      this.len = res[0].seedConfig.minValLen;
      this.key = this.pad(this.startingNo, this.len);

    });
  }
  getValues() {
    this.geseedService.getRecordBySeedName('QR-PASS').subscribe(res => {
      this.startingNo = res[0].seedConfig.nextValue;
      this.len = res[0].seedConfig.minValLen;
      this.key = this.pad(this.startingNo, this.len);
    });
  }
  getUsers = () => {
    this.userService.getInspectors().subscribe(res => {
      this.users = res;
      this.usersData = this.users;
    });
  }
  save(
    qrPrefix: String,
    key: String,
    inspector: String,
    type: String,
    totalCount: Number,
    qrNumber: any
  ): void {
    if (!key || !totalCount || !inspector) {
      return;
    }
    var selectedInspector = [];
    selectedInspector = _.filter(this.users, ele => { return ele._id == this.inspector.value });
    var name = selectedInspector[0].name;
    var id = selectedInspector[0]._id;

    this.inputData = {
      qrPrefix: qrPrefix,
      key: key,
      inspector: inspector,
      type: type,
      totalCount: totalCount,
      qrNumber: qrNumber
    }
    this.inputData.name = name;
    this.inputData.id = id;
    var data1 = [];
    var tempData = [];
    if (this.inputData.totalCount) {
      for (let step = 0; step < this.inputData.totalCount; step++) {
        var val = {
          "type": this.inputData.type,
          "qrNumber": this.startingNo + step,
          "inspector": this.inputData.id,
          'qrType': this.inputData.type,
          "qrprefix": this.inputData.qrPrefix,
          "createdBy": this.user.data.name,
          "totalcount": this.inputData.totalCount,
          "inspectorName": this.inputData.name
        };
        data1.push(val);
        if ((step + 1) == this.inputData.totalCount) {
          if (data1.length >= 1) {
            for (var k = 0; k < data1.length; k++) {
              tempData.push({
                'type': data1[k].type, 'qrNumber': this.pad(data1[k].qrNumber, this.len), 'inspector': data1[k].inspector,
                'qrCode': data1[k].qrprefix + data1[k].type + '-' + this.pad(data1[k].qrNumber, this.len),
                'createdBy': data1[k].createdBy, 'totalCount': data1[k].totalCount, 'inspectorName': data1[k].inspectorName
              })
            }

            for (var i = 0; i < tempData.length; i++) {
              this.qrcodeService.save(tempData[i]).subscribe(res => {
                if (res['statusBool']) {
                  this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
                  this.dialogRef.close();

                }
              });
              if ((i + 1) == data1.length) {
                var count = this.startingNo + totalCount;
                if (tempData[i].type == "PASS") {
                  var seedData = {
                    "module": 'QR-PASS',
                    "nextValue": count,
                    "updatedBy": this.user.data.name
                  }

                  this.geseedService.updateRecord(seedData).subscribe(res => {
                    // nextValue = res[0].seedConfig.nextValue;
                  });
                }
                else if (tempData[i].type == "FAIL") {
                  // this.seedData.module ='QR-FAIL'
                  var seedData = {
                    "module": 'QR-FAIL',
                    "nextValue": count,
                    "updatedBy": this.user.data.name
                  }
                  this.geseedService.updateRecord(seedData).subscribe(res => {

                  });
                }

              }
            }
          }
        }

      }

    }




  }
  getErrorCount() {
    return this.totalCount.hasError('required')
      ? 'Count is required'
      : '';
  }
  getErrorInspector() {
    return this.inspector.hasError('required')
      ? 'Inspector is required'
      : '';
  }
  filterInspector(val) {
    val = val.toLowerCase();
    this.usersData = this.users.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }
  btnClose() {
    if (
      this.type.dirty ||
      this.inspector.dirty ||
      this.totalCount.dirty
    ) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: false,
        hasBackdrop: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
