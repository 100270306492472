import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting/setting.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = '';
  constructor(private http: HttpClient,
    private settingService:SettingService) {
  }

  getRecord(): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');
    return this.http
      .post<any>(this.apiUrl + '/getRecord', httpOptions)
      .pipe(tap());
  }

  createRecord(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');

    return this.http
      .post<any>(this.apiUrl + '/createRecord', data, httpOptions)
      .pipe(tap());
  }

  updateRecord(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');

    return this.http
      .post<any>(this.apiUrl + '/updateRecord', data, httpOptions)
      .pipe(tap());
  }

  deleteRecord(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');

    return this.http
      .post<any>(this.apiUrl + '/deleteRecord', data, httpOptions)
      .pipe(tap());
  }

  resetPassword(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');

    return this.http
      .post<any>(this.apiUrl + '/resetPassword', data, httpOptions)
      .pipe(tap());
  }

  getUserByEmailId(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');

    return this.http
      .post<any>(this.apiUrl + '/getUserByEmailId', data, httpOptions)
      .pipe(tap());
  }

  updateLock(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');

    return this.http
      .post<any>(this.apiUrl + '/lockRecord', data, httpOptions)
      .pipe(tap());
  }

  getUsersByVendorId(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');

    return this.http
      .post<any>(this.apiUrl + '/getUsersByVendorId', data, httpOptions)
      .pipe(tap());
  }

  getInspectors(): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');
    return this.http
      .post<any>(this.apiUrl + '/getInspectors', httpOptions)
      .pipe(tap());
  }

  checkChecklistIsExist(checklistID: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');

    return this.http
      .post<any>(
        this.apiUrl + '/checkChecklistIsExist',
        { "checklistID": checklistID },
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  getUserByCategory(data: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');

    return this.http
      .post<any>(
        this.apiUrl + '/getUserByCategory', data,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  updateWidgets(data) {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');

    return this.http.post(this.apiUrl + '/updateWidgets', data, httpOptions).toPromise();
  }
  getWidetsByuser(id) {
    this.apiUrl = this.settingService.getLocalStorageAppURL('users');

    return this.http.get(this.apiUrl + '/getWidetsByuser/' + id, httpOptions).toPromise();
  }

}
