import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  isAdmin: Boolean = false;
  show = false;

  constructor(private router: Router, private auth: AuthService) { }

  ngOnInit() {

    this.auth.LoggedUserDetail.subscribe(res => {


      this.isAdmin = false;
      const user = res;
      if (user && user.data) {
        if (user.data.isAdmin) {
          this.isAdmin = true;
        }
      }
    });
  }


  navigate(isScanPage, option) {
    if (isScanPage && isScanPage === true) {
      this.router.navigate(['/operation/showStatusByScan'], { queryParams: { status: option } });
    } else {
      this.router.navigate([option]);
    }
  }

}
