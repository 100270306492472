import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource,MatDialogRef } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { NotificationMailService } from './notification-mail.service';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';

@Component({
  selector: 'app-notification-mail',
  templateUrl: './notification-mail.component.html',
  styleUrls: ['./notification-mail.component.css']
})
export class NotificationMailComponent implements OnInit {
  temp = []; temp1 = []; dataSource: any;
  statusName = ['Pending list','Sent list'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search',{static:false}) searchElement: ElementRef;

  displayedColumns = ['subject', 'sentDate','to', 'cc', 'bcc', 'body'];
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  dateRange = new FormControl('');
  status = new FormControl('');
  doFilter: Boolean;

  date = new Date();
  a = moment().startOf('day');
  b = moment().endOf('day');
  constructor(
    private dialog: MatDialog,
    private notificationMailService: NotificationMailService,
  ) { }

  ngOnInit() {
    this.dateRange.setValue({
      start: moment(this.b).format('MM/DD/YYYY'),
      end: moment(this.b).format('MM/DD/YYYY')
    });
    this.status.setValue(this.statusName[0]);
    this.getData();
  }
  getData() {
    var inputData = {
      status: this.status.value,
      date: {
        fromDate: this.dateRange.value.start,
        toDate: this.dateRange.value.end
      },
    }
    this.notificationMailService.getData(inputData).subscribe(result => {
      if (!result) {
        return;
      }
        this.temp = result;
        this.temp1 = this.temp;
        this.dataSource = new MatTableDataSource(this.temp);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    });
  }
  myFunction(row) {
    const dialogRef = this.dialog.open(NotificationPopupComponent, {
      maxWidth: '730px',
      data: row,
      height: '80%',
      disableClose: false
    });
  }

  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.subject = d.subject ? d.subject : '';
      d.to = d.to ? d.to : '';
      d.cc = d.cc ? d.cc : '';
      d.sentDate = d.sentDate ? d.sentDate : '';
      d.bcc = d.bcc ? d.bcc : '';
      d.body = d.body ? d.body : '';
      return (d.subject.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.to.toLowerCase().indexOf(val) !== -1 || !val)||
        (d.cc.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.sentDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.bcc.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.body.toLowerCase().indexOf(val) !== -1 || !val);

    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


}
