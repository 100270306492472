import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet, MatDialog, MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-receiver-detail-dialog',
  templateUrl: './receiver-detail-dialog.component.html',
  styleUrls: ['./receiver-detail-dialog.component.css']
})
export class ReceiverDetailDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ReceiverDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
  }
  Delete() {
    this.dialogRef.close(true)
  }
  btnClose() {
    this.dialogRef.close(false)
  }
}
