import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
  MatDialogConfig,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material';
import { EquipmentCompanyFormComponent } from '../equipment-company-form/equipment-company-form.component';
import { EquipmentCompanyService } from '../equipment-company.service';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { ExcelService } from '../../../excel.service';
import { AuthService } from '../../../auth/auth.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Component({
  selector: 'app-equipment-company-list',
  templateUrl: './equipment-company-list.component.html',
  styleUrls: ['./equipment-company-list.component.css']
})
export class EquipmentCompanyListComponent implements OnInit {

 
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  // dataSource: CompanyListDataSource;
  dataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'edit',
    'name',
    'abbr',
    'contactPerson',
    'emailID',
    'telephone',
    'fax',
    'website',
    'address'

  ];
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  permission: any;
  isWrite = false;
  constructor(
    private dialog: MatDialog,
    private equipmentCompanyService: EquipmentCompanyService, private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet, private excelService: ExcelService, private authService: AuthService
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;
    this.getTableData();
    if (this.permission && this.permission.master && this.permission.master.equipmentCompany && this.permission.master.equipmentCompany.write) {
      this.isWrite = true;
    } else {
      this.displayedColumns = ['name','abbr','contactPerson','emailID','telephone','fax','website','address'];
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  getTableData() {
    this.equipmentCompanyService.getEquipmentCompany().subscribe(data => {
      if (!data) {
        return;
      }
      this.temp = data;

      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });
  }
  btnAddNewClick() {
    const dialogRef = this.dialog.open(EquipmentCompanyFormComponent, {
      data: {},
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  deleteTable(item) {
    const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
      data: { deleteItem: true },
      disableClose: false,
      hasBackdrop: true
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        this.equipmentCompanyService.deleteById(item).subscribe(res => {
          if (res[0]['statusBool']) {
            this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
          this.ngOnInit();
        });
      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }
  getTable(row) {
    this.equipmentCompanyService.getRecordById(row).subscribe(res => {
      const dialogRef = this.dialog.open(EquipmentCompanyFormComponent, {
        data: res[0]
      });
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });
    });
  }

  exportExcel() {
    var data1 = [];
    var i = 1;
    this.temp1.forEach(element => {
      var val = {
        "Name": element.name,
        "ABBR": element.abbr,
        "Contact Person": element.contactPerson,
        "Email ID": element.emailID,
        "Telephone": element.telephone,
        "Fax": element.fax,
        "Website": element.website,
        "Address": element.address
      };
      i = i + 1;
      data1.push(val);
    });
    this.excelService.saveAsExcelFile(data1, 'EquipmentCompanyMaster');
  }
  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.name = d.name ? d.name : '';
      d.abbr = d.abbr ? d.abbr : '';
      d.contactPerson = d.contactPerson ? d.contactPerson : '';
      d.emailID = d.emailID ? d.emailID : '';
      d.fax = d.fax ? d.fax : '';
      d.website = d.website ? d.website : '';
      d.address = d.address ? d.address : '';
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.abbr.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.contactPerson.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.emailID.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.fax.toString().toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.website.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.address.toLowerCase().indexOf(val) !== -1 || !val) 
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }




}

