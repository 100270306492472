import { Component, OnInit, NgZone,ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ReportService } from '../../services/report/report.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../general/dateformat-form';
import { DateAdapter, MAT_DATE_FORMATS ,  MAT_DATE_LOCALE} from '@angular/material/core';
//import { MatSnackBar } from '@angular/material';
import * as _moment from 'moment';
import { GeoptionService } from '../../services/geoption/geoption.service'
import * as _ from 'underscore';
import { environment } from '../../../environments/environment';
import { FileService } from '../../services/files/file.service';
import { ExcelService } from '../../excel.service';
import { UserService } from '../../admin/user/user.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from "@angular/material-moment-adapter";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatOption
} from '@angular/material';
const moment =  _moment;
// export const MY_FORMATS = {
//  parse: {
//    dateInput: "DD/MM/YYYY"
//  },
//  display: {
//    dateInput: "DD/MM/YYYY",
//    monthYearLabel: "MMMM YYYY",
//    dateA11yLabel: "LL",
//    monthYearA11yLabel: "MMMM YYYY"
//  }
// };
@Component({
  selector: 'app-customer-feedback-evaluation-report',
  templateUrl: './customer-feedback-evaluation-report.component.html',
  styleUrls: ['./customer-feedback-evaluation-report.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class CustomerFeedbackEvaluationReportComponent implements OnInit {

  reportForm: any;
  public response: any;
  public url: any;
  public safeSrc: SafeResourceUrl;
  src: any;
  image: any;
  showDownload = false;
  temp: any;
  companyData: any;
  countData: any;
  types: any;
  anotherTypes: any;
  typeName = '';
  inspectorList: any;
  anotherinspectorList: any;
  inspectorForm: any;
  data: any;
  inspectors:any;
  
  
  @ViewChild('allSelectedComp', { static: true }) private allSelectedComp: MatOption;
  constructor(
    private fb: FormBuilder,
    private reportService: ReportService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private geoptionService: GeoptionService,
    private fileService: FileService,
    private excelService: ExcelService,
    private snackbar : MatSnackBar,
    private ngZone: NgZone,
    private inspectorService: UserService,
  ) { 
    this.reportForm = fb.group({
      'fromDate': [null, Validators.compose([Validators.required])],
      'toDate': [null, Validators.compose([Validators.required])],
      'inspector': [null, Validators.compose([Validators.required])]

    });
    
  }

  toggleAllSelection(val)
  {
    debugger

  }

  ngOnInit() {
    this.getInspectors();

  }

  get f() {
    return this.reportForm.controls;
  }
  
  
  getInspectors() {
    
    this.inspectorService.getInspectors().subscribe(result => {
      
      this.inspectorList = result;
      this.anotherinspectorList = this.inspectorList;
     // this.dropdownList=this.inspectorList;
    });
  }
  
  
  tosslePerComp(all) {
    if (this.allSelectedComp.selected) {
      this.allSelectedComp.deselect();
      return false;
    }
    if (this.reportForm.controls.inspector.value.length === this.anotherinspectorList.length) {
      this.allSelectedComp.select();
    }

  }

  selectAllComp() {
    if (this.allSelectedComp.selected) {
      this.reportForm.controls.inspector 
        .patchValue([...this.anotherinspectorList.map(item=>item),0]);
    } else {
      this.reportForm.controls.inspector.patchValue([]);
    }
    
  }


  filterInspector(val) {
    val = val.toLowerCase();
    this.anotherinspectorList = this.inspectorList.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val);
    });
  }

  
  getData() {
    this.showDownload = false;

    let fromDate = this.reportForm.controls.fromDate.value;
    let toDate = this.reportForm.controls.toDate.value;
    let inspector = this.reportForm.controls.inspector.value;
    let exactDate = toDate;

    if (!fromDate) {
      this.snackBar.open('From date is required', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
    if (!toDate) {
      this.snackBar.open('To date is required', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
    if (!inspector) {
      this.snackBar.open('inspector is required', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
     toDate = toDate.setDate(toDate.getDate() + 1);
     toDate = new Date(toDate).toISOString();

     fromDate = fromDate.setDate(fromDate.getDate() - 1);
     fromDate = new Date(fromDate).toISOString();

    var selectedType = [];
    selectedType = _.filter(this.types, ele => { return ele._id == this.reportForm.controls.type.value });
    if (selectedType.length > 0) {
      this.typeName = selectedType[0].optionValue.toLowerCase();
    }

    var data = {
      fromDate: fromDate,
      toDate: toDate,
      showDate: exactDate,
      inspector: inspector
    }
    
    this.reportService.generateCustomerFeedbackReport(data).subscribe(res => {
      debugger
      if (res['statusBool']) {
        this.response = res['data'];
        this.temp=res['data1'];
        this.showDownload = true;
        const byteArray = new Uint8Array(atob(this.response).split('').map(char => char.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        this.url = window.URL.createObjectURL(blob);
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        this.src = this.url;
        this.image = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }

    })
  }
  
  public downloadFn() {
    var string1 = 'Customer Feedback Report_';
    var string2 = moment(new Date(this.reportForm.controls.toDate.value)).format('DD_MM_YYYY');
    var fileName = string1 + string2 + '.pdf';

    var blob = this.fileService._base64ToArrayBuffer(this.response, 'application/pdf');
    if (environment.mobileApp) {
      this.fileService.openFileOnMobile(blob, fileName, 'application/pdf')
    } else {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
      } else {
        var objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
      }
    }

  }
  

  exportExcel() {
   // if (this.typeName === 'aramco direct') {
      var data1 = [];
      var i = 1;
     // this.companyData['trackingSystem'] = 'ARAMCO Elevators & Associated Equipment Tracking System';
      this.temp.forEach(element => {
        debugger
        var totalCount=  element.rfiratingDetail.RFIRating[0].AvailabilityofRequestedEquipmentCrew[0] +
        element.rfiratingDetail.RFIRating[0].EquipmentConditionandPerformance[0] +
        element.rfiratingDetail.RFIRating[0].CrewProfessionalismandCompetency[0] +
        element.rfiratingDetail.RFIRating[0].AttitudeCommunication[0] +
        element.rfiratingDetail.RFIRating[0].DataQualityServiceReport[0] +
        element.rfiratingDetail.RFIRating[0].CompliancetoHSEQRequirements[0] +
        element.rfiratingDetail.RFIRating[0].CompletionofJobonTime[0] +
        element.rfiratingDetail.RFIRating[0].ManagementCommitmentResponse[0] +
        element.rfiratingDetail.RFIRating[0].Rectifyingdefectspromptlyandtoyoursatisfaction[0] +
        element.rfiratingDetail.RFIRating[0].OverallJobPerformance[0]

        var val = {
          "SR #": i,
          "Inspector Name": element.inspectorDetails.name,
          'Report No': element.reportNo,
          'Emp #': '',
          'Date': element.inspectionDate,
          'Client': element.clientDetail.name,
          'Location': element.locationDetail.name,
          'Avilability of Requested Equipmnet/Crew': element.rfiratingDetail.RFIRating[0].AvailabilityofRequestedEquipmentCrew[0],
          'Equipment condition & performance': element.rfiratingDetail.RFIRating[0].EquipmentConditionandPerformance[0],
          'Crew Professionalism & Competency': element.rfiratingDetail.RFIRating[0].CrewProfessionalismandCompetency[0],
          'Attitude/ Communication': element.rfiratingDetail.RFIRating[0].AttitudeCommunication[0],
          'Data Quality/Service Report': element.rfiratingDetail.RFIRating[0].DataQualityServiceReport[0],
          'Compliance to HSEQ Requirements': element.rfiratingDetail.RFIRating[0].CompliancetoHSEQRequirements[0],
          'Completion of job on time': element.rfiratingDetail.RFIRating[0].CompletionofJobonTime[0],
          'Management commitment/Response': element.rfiratingDetail.RFIRating[0].ManagementCommitmentResponse[0],
          'Rectifying defects promptly & to your satisfaction': element.rfiratingDetail.RFIRating[0].Rectifyingdefectspromptlyandtoyoursatisfaction[0],
          'Over All Job performance': element.rfiratingDetail.RFIRating[0].OverallJobPerformance[0],
          'Total Score': totalCount,          
        };
        i = i + 1;
        data1.push(val);
      });
      if(data1.length > 0){
        this.excelService.saveAsExcelFile(data1,  'Customer Feedback report');
      }
      else{
        this.ngZone.run(() => {
          setTimeout(() => {
            this.snackbar.open('No records found','', {
              duration: 5000,
              verticalPosition: 'bottom',
            });
          }, 0);
        });
      }

    //} 
    
  }
}
