import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';
import { AppUrlFormComponent } from '../app-url-form/app-url-form.component';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MatBottomSheet, MatSnackBar } from '@angular/material';
import { fade } from '../login/login.animations';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
  animations: [
    fade
  ]
})
export class ForgetPasswordComponent implements OnInit {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userDetail: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private data = new BehaviorSubject('');
  currentData = this.data.asObservable();
  form: FormGroup;
  private formSubmitAttempt: boolean;
  showSpinner: Boolean = false;
  isError: Boolean = false;
  errMsg: String;
  versionNumber: String = environment.versionNumber;
  mobileApp: Boolean = environment.mobileApp;
  loginHeaderLogo = environment.logo;
  isMobile: boolean = false;


  userType = 'email'
  isRequest = true;
  showOTP = false;
  isReset = false;
  userHide = true;
  uID = null;
  otpVal = '';
  submitTxt = 'Send OTP';
  fID = null;
  sub: any;
  id = null;
  id1 = null;
  counter;
  isResendOTPDisable = true;
  interval;
  timeLeft = 59; authVal = '';
  password;
  cpassword;
  userEmail;
  isResendotp = false;
  isLinkreset = false;
  isUserreset = false;
  aLink: any;
  eLink: any;
  rule = "PASSWORD RULE" + "\r\n" + '• ' + "The new password must be at least 12 characters." + "\r\n" + '• ' + "Password must contains any two of the below criteria" + "\r\n" + "\t" + '• ' + 'Upper case (A through Z),Lower case (a through z)' + "\r\n" + "\t" + '• ' + 'Digits (0-9)' + "\r\n" + "\t" + '• ' + 'Special Characters (!, $, #, %, etc.)' + "\r\n" + '• ' + "Previous three passwords cant be used." + "\r\n" + '• ' + "Password cant be same as login ID."
  isButton = true;
  OTP: any;
  showsendOTP: Boolean = false;
  showverifyOTP: Boolean = false;
  showPassword: Boolean = false;
  constructor(
    private fb: FormBuilder,
    private adminService: AuthService,
    private router: Router,
    private routeTest: ActivatedRoute,
    private offlineStorage: OfflineStorageService,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private authService: AuthService,

  ) { }

  ngOnInit() {

    this.form = this.fb.group({
      emailId: ['', Validators.required],
      OTP: ['', Validators.required],
      password: [''],
      cnfmpassword: [''],

    });

    this.authService.forgotPasswordInit();
    if (environment.mobileApp) {
      this.loginHeaderLogo = 'assets/images/icons/logo.jpg';
    }
    this.showsendOTP = true;
    this.showverifyOTP = false;
    this.showPassword = false;

    this.isMobile = environment.mobileApp;
    localStorage.setItem('isMobile', this.isMobile.toString());

    this.processRequest();

  }

  reqOTP() {
    this.userEmail = this.form.value.emailId;
    if (this.userEmail === undefined || this.userEmail === null || this.userEmail.length === 0) {
      this.snack.open('Please enter email ID.', 'OK', { duration: 2000, verticalPosition: "bottom" });
      return;
    }
    var data = {
      email: this.userEmail,
      mode: this.userType
    }
    this.adminService.forgotPassword(data).subscribe(res => {

      this.processResponse(res);

    });
  }
  startTimer() {
    this.interval;
    this.timeLeft = 59;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {

        this.timeLeft--;

        this.isResendOTPDisable = true;
      } else {
        this.isResendOTPDisable = false;
      }
    }, 1000)
  }

  sign() {
    this.router.navigate(['/login']);
  }

  reset() {

    this.OTP = this.form.value.OTP;
    if (this.showverifyOTP) {
      if (this.OTP === undefined || this.OTP.length === 0) {
        return;
      }
      var inputData = { mode: 'verify', uID: this.uID, otp: this.OTP };
      this.adminService.forgotPassword(inputData).subscribe(res => {

        var resData: any;
        resData = res;

        console.log(resData)
        if (resData.isValid) {
          this.showsendOTP = false;
          this.showverifyOTP = false;
          this.showPassword = true;

        }
        else {
          this.snack.open(res['message'], 'OK', { duration: 2000, verticalPosition: "bottom" });
        }

        // console.log(res[0])
        // if (res.isValid == true) {

        //   this.showsendOTP = false;
        //   this.showverifyOTP = false;
        //   this.showPassword = true;

        //   this.processOTP(res);
        // }

      });

    } else {
      if (this.showPassword) {
        this.password = this.form.value.password
        this.cpassword = this.form.value.cnfmpassword
        
        if (this.password === undefined || this.password.length === 0)
        {
          this.snack.open('Please enter the Password.', 'OK', { duration: 2000, verticalPosition: "bottom" });
          return;
        }
         
        if (this.cpassword === undefined || this.cpassword.length === 0)
        {
          this.snack.open('Please enter the Confirm Password.', 'OK', { duration: 2000, verticalPosition: "bottom" });
          return;
        }
        

        if (this.password !== this.cpassword) {
          // this.snackBar.open('Saved successfully', '', { duration: 2000, verticalPosition: "bottom" });
          this.snack.open('Password and confirmation password does not match.', 'OK', { duration: 2000, verticalPosition: "bottom" });
          return;
        }
        // this.router.navigate(['/login']);
        if (this.isUserreset) {
          console.log(1)
          var data = { mode: 'userLink', password: this.password, cpassword: this.cpassword, link: this.aLink };
          this.adminService.forgotPassword(data).subscribe(res => {
            this.processResponse(res);
          });
        }
        else if (!this.isLinkreset) {
          console.log(2)
          var data1 = { mode: 'reset', password: this.password, cpassword: this.cpassword, uID: this.uID, reqID: this.fID };
          this.adminService.forgotPassword(data1).subscribe(res => {

            this.processResponse(res);
          });
        }
        else {
          console.log(3)
          this.fID = -1;
          var data2 = { mode: 'emailLink', password: this.password, cpassword: this.cpassword, link: this.eLink };
          this.adminService.forgotPassword(data2).subscribe(res => {
            this.processResponse(res);
          });
        }
      } else {
        this.reqOTP();
      }
    }
  }
  processOTP(res) {
    if (res.statusBool) {
      this.isRequest = false;
      this.showOTP = false;
      this.isReset = true;
      this.uID = res.data.result;
      this.authVal = '';
      this.submitTxt = 'Submit';
    } else {
      this.snack.open(res.data.result, 'OK', { duration: 2000, verticalPosition: "bottom" });

    }
  }
  processResponse(res) {

    if (res['message'] === 'Your password is sucessfully changed.') {
      this.router.navigate(['/login']);
    }
    debugger;
    if (res.isValid) {
      if (this.isReset && !this.showOTP) {
        this.router.navigate(['/login']);
        this.snack.open(res['message'], 'OK', { duration: 3000, verticalPosition: "bottom" });
      }
      else {


        this.showsendOTP = false;
        this.showverifyOTP = true;
        this.showPassword = false;

        // this.isRequest = false;
        // this.showOTP = true;

        this.uID = res.data.result;
        this.fID = res.data.uID;

        this.snack.open(res['message'], 'OK', { duration: 2000, verticalPosition: "bottom" });
        // this.submitTxt = 'Verify OTP';
        // this.authVal = '';




        this.startTimer();
      }
    } else {

      this.snack.open(res['message'], 'OK', { duration: 2000, verticalPosition: "bottom" });

    }
  }

  sendOTP() {
    this.reqOTP();
  };

  processRequest() {
    this.eLink = this.getParameterByName('eLink', '');
    this.aLink = this.getParameterByName('uA', '');

    if (this.eLink !== undefined && this.eLink !== null) {
      this.isRequest = false;
      this.isLinkreset = true;
      var obj = {};
      obj['link'] = this.eLink;
      obj['mode'] = 'emailLink';
      this.adminService.forgotPassword(obj).subscribe(res => {
        this.processLink(res);
      });
    }
    else if (this.aLink !== undefined && this.aLink !== null) {
      this.isRequest = false;
      this.isUserreset = true;
      var obj = {};
      obj['link'] = this.aLink;
      obj['mode'] = 'userLink';
      this.adminService.forgotPassword(obj).subscribe(res => {
        this.processLink(res);
      });
    } else {
      this.isLinkreset = false;
      this.isUserreset = false;
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  processLink(result) {
    if (result.statusBool) {
      this.isReset = true;
      this.snack.open(result['message'], 'OK', { duration: 2000, verticalPosition: "bottom" });
      if (this.fID === -1) {
        this.router.navigate(['sessions/signin']);
      }
    } else {
      if (result.data === 'Verified') {
        this.router.navigate(['sessions/signin']);
      } else if (result.data === 'Expired') {
        this.authVal = result['message'];
        this.isButton = false;
      } else {
        this.snack.open(result['message'], 'OK', { duration: 2000, verticalPosition: "bottom" });
      }
    }
    this.submitTxt = 'Submit';
  }

}
