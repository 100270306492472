import { Component, OnInit, Inject } from '@angular/core';
import { EquipmentCompanyService } from '../equipment-company.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../general/dateformat-form';
@Component({
  selector: 'app-equipment-company-form',
  templateUrl: './equipment-company-form.component.html',
  styleUrls: ['./equipment-company-form.component.css']
})
export class EquipmentCompanyFormComponent implements OnInit {
  equipmentCompanyForm: any;

  user: any;

  rowId = null;
  isSave = false;
  isUpdate = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EquipmentCompanyFormComponent>, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,

    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private equipmentCompanyService: EquipmentCompanyService
  ) {
    this.equipmentCompanyForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'abbr': [null, Validators.compose([Validators.required])],
      'emailID': [null, [Validators.required, Validators.email]],
      'contactPerson': [null],
      'telephone': [null],
      'fax': [null],
      'website': [null],
      'address': [null],
    });
  }

  ngOnInit() {
    if (this.data._id) {
      this.rowId = this.data._id;
      this.isUpdate = true;
      this.patchValue();
    } else {
      this.isSave = true;
    }
    this.user = this.getFromLocalStorage('userDet');
  }

  save() {
    var inputData = {
      name: this.equipmentCompanyForm.controls.name.value,
      abbr: this.equipmentCompanyForm.controls.abbr.value,
      emailID: this.equipmentCompanyForm.controls.emailID.value,
      contactPerson: this.equipmentCompanyForm.controls.contactPerson.value,
      telephone: this.equipmentCompanyForm.controls.telephone.value,
      fax: this.equipmentCompanyForm.controls.fax.value,
      website: this.equipmentCompanyForm.controls.website.value,
      address: this.equipmentCompanyForm.controls.address.value,
      createdBy: null,
      updatedBy: null,
      rowId: null,

    }

    inputData.createdBy = this.user.data.name;
    inputData.updatedBy = this.user.data.name;
    inputData.rowId = this.rowId;

    if (this.rowId) {
      this.equipmentCompanyService.updateRecord(inputData).subscribe(result => {
        if (result[0]['statusBool']) {
          this.snackBar.open(result[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } else {
          this.snackBar.open(result[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      }, err => {
        if (err.error[0].statusHttp === 412) {
          this.snackBar.open(err.error[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else {
      this.equipmentCompanyService.save(inputData).subscribe(result => {
        if (result['statusBool']) {

          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        }
      }, err => {
        if (err.error.statusHttp === 412) {
          this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }
  }

  get f() {
    return this.equipmentCompanyForm.controls;
  }

  patchValue() {

    this.equipmentCompanyForm.patchValue({
      name: this.data.name,
      contactPerson: this.data.contactPerson,
      abbr: this.data.abbr,
      emailID: this.data.emailID,
      telephone: this.data.telephone,
      fax: this.data.fax,
      website: this.data.website,
      address: this.data.address,
    });
  }


  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  btnClose() {
    if (this.equipmentCompanyForm.dirty) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: true,
        hasBackdrop: true
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }


}
