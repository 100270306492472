import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RfiInspectionService } from '../../rfi-inspection/rfi-inspection.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '../../../auth/auth.service';
import { ReportService } from '../../../services/report/report.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-rfi-report-show',
  templateUrl: './rfi-report-show.component.html',
  styleUrls: ['./rfi-report-show.component.css']
})
export class RfiReportShowComponent implements OnInit {

  public response: any;
  public response2 = [];
  public url = [];
  public safeSrc: SafeResourceUrl = [];
  src = [];
  image = [];
  urlPdf: any;
isDefault =  false;
  constructor(
    private route: ActivatedRoute,
    private rfiInspectionService: RfiInspectionService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.authService.showReport();
    const id = this.route.snapshot.paramMap.get('term');
    const data = { qrValue: id };

    this.rfiInspectionService.getRFIByQrValue(data).subscribe((result: any) => {
      if (/Android/.exec(navigator.userAgent)) {
        this.isDefault = true;
        this.response = this._base64ToArrayBuffer(result.data);
      } else {
        this.isDefault = false;
        const byteArray = new Uint8Array(atob(result.data).split('').map(char => char.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.location.href = url;

      }
      // const data1 = _base64ToArrayBuffer(result.data);
      // function _base64ToArrayBuffer(base64) {
      //   let binary_string = base64.replace(/\\n/g, '');
      //   binary_string = window.atob(binary_string);
      //   const len = binary_string.length;
      //   const bytes = new Uint8Array(len);
      //   for (let i = 0; i < len; i++) {
      //     bytes[i] = binary_string.charCodeAt(i);
      //   }
      //   return bytes.buffer;
      // }
      // const file = new Blob([data1], { type: 'application/pdf' });

      // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //   window.navigator.msSaveOrOpenBlob(file);
      // } else {
      //   const objectUrl = URL.createObjectURL(file);
      //   window.open(objectUrl);
      // }


      // const byteArray = new Uint8Array(atob(result.data).split('').map(char => char.charCodeAt(0)));
      // const blob = new Blob([byteArray], { type: 'application/pdf' });
      // const url = URL.createObjectURL(blob);
      // window.location.href = url;

      // window.open(url);
      // i.e. display the PDF content via iframe
      // document.querySelector("iframe").src = url;
      // if (data.length > 0) {
      // for (let i = 0; i < data.length; i++) {
      // this.urlPdf = this._base64ToArrayBuffer(result.data);

      // let pdfWindow = window.open('');
      // pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64," + result.data + "'></iframe>");

      //   if (data[i].content.includes('data:image')) {
      //     this.response2.push(data[i].content);
      //   }
      // const byteArray = new Uint8Array(atob(this.response[i]).split('').map(char => char.charCodeAt(0)));
      // const blob = new Blob([byteArray], { type: 'application/pdf' });
      // this.url[i] = window.URL.createObjectURL(blob);
      // this.safeSrc[i] = this.sanitizer.bypassSecurityTrustResourceUrl(this.url[i]);
      // this.src[i] = this.url[i];
      // }
      // }
    });
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = base64.replace(/\\n/g, '');
    binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }




}
