import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { reject } from 'q';
import { SettingService } from '../services/setting/setting.service';
import { OfflineStorageService } from '../mobile-native-interface/offline-storage.service.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userDetail: BehaviorSubject<any> = new BehaviorSubject<any>({});
  redirectUrl: string;
  isInspector = false;
  isAdmin = false;
  isReviewer = false;
  isCoordinator = false;

  companyName:any;
  private apiUrl = '';
  private token: string;
  constructor(private router: Router, private http: HttpClient,
    private settingService: SettingService) {

  }

  ngOnInit() {
  }

  get isUserLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get LoggedUserDetail() {
    if (this.userDetail.value.emailId !== undefined) {
      return this.userDetail.asObservable();
    } else {
      this.loggedIn.next(true);
      const user = this.getFromLocalStorage('userDet');
      this.userDetail.next(user);
      return this.userDetail.asObservable();
    }
  }

  login(user: any, response) {
    this.authenticate(user).subscribe(result => {
      if (result.statusBool) {
        this.insertLocalStorage('userDet', JSON.stringify(result));
        this.userDetail.next(result);
        this.loggedIn.next(true);
        this.navigateForLanding(result);
      } else {
        response(result);
      }

    });

  }

  navigateForLanding(result) {
    if (environment.mobileApp) {
      // this.router.navigate(['/mobile/landing']);
      this.navigatePageLanding(result)
    } else {
      this.navigatePageLanding(result)
    }
  }

  navigatePageLanding(result) {
    if (result && result.data) {
      if (result.data.role.length > 0) {
        for (var i = 0; i < result.data.role.length; i++) {
          if (result.data.role[i].name === 'Administrator' && result.data.role[i].isActive === true) {
            this.isAdmin = true;
            this.router.navigate(['/dashboard']);
          } else if (result.data.role[i].name === 'Inspector' && result.data.role[i].isActive === true) {
            this.isInspector = true;
            this.router.navigate(['/master/rfiInspectionList']);
          } else if (result.data.role[i].name === 'Reviewer' && result.data.role[i].isActive === true) {
            this.isReviewer = true;
            this.router.navigate(['/master/rfiInspectionList']);
          } else if (result.data.role[i].name === 'Co-ordinator' && result.data.role[i].isActive === true) {
            this.isCoordinator = true;
            this.router.navigate(['/master/scheduleList']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      }
    }
    this.getCompanyName();
  }

  getCompanyName() {
    this.settingService.getByKey('company').subscribe(result => {
      if (result.length > 0) {
        this.companyName = result[0].value;
        localStorage.setItem('ClientName', this.companyName);

      }
    });
  }
  authenticate(user: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('login');
    return this.http.post<any>(this.apiUrl + '/login', user, httpOptions).pipe(
      tap()
    );
  }

  logout() {
    this.loggedIn.next(false);
    this.userDetail.next({});
    this.removeLocalStorage('userDet');
    this.removeLocalStorage('isMobile');
    this.removeLocalStorage('ClientName');
    this.removeLocalStorage('filterYear');
    this.removeLocalStorage('Opfilter');
    this.router.navigate(['/login']);
  }

  forgotPasswordInit() {
    this.loggedIn.next(false);
    this.userDetail.next({});
    this.removeLocalStorage('userDet');
    this.removeLocalStorage('isMobile');
    this.removeLocalStorage('ClientName');
    //this.router.navigate(['/login']);
  }

  showReport() {
    this.loggedIn.next(false);
  }

  logoutFromLoginPage() {
    this.loggedIn.next(false);
    this.userDetail.next({});
    this.removeLocalStorage('userDet');
    this.removeLocalStorage('isMobile');
    this.removeLocalStorage('ClientName');
    this.router.navigate(['/customerTrac']);
  }

  insertLocalStorage(key: string, info: any) {
    localStorage.setItem(key, info);
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  removeLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  isLoggednIn() {
    return this.getFromLocalStorage('userDet') !== null;
  }

  // forgotPassword(data: any): Observable<any> {
  //   this.apiUrl = 'http://localhost:4000/api/admin/userManagement/user'//this.settingService.getLocalStorageAppURL('users');
  //   console.log(this.apiUrl)
  //   return this.http
  //     .post<any>(
  //       this.apiUrl + '/forgotPassword', data,
  //       httpOptions
  //     )
  //     .pipe(
  //       tap()
  //     );
  // }

  forgotPassword(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('inspector');
    return this.http
      .post<any>(this.apiUrl + '/forgotPassword', data, httpOptions)
      .pipe(tap());
  }



}
