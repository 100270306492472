import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTableDataSource,
  MatBottomSheet
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { RoleManagementService } from '../../role/role-management.service';
import { ConfirmDeleteComponent } from '../../../master/confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-user-permission-list',
  templateUrl: './user-permission-list.component.html'
  // styleUrls: ['./user-permission-list.component.css']
})
export class UserPermissionListComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  dataSource;

  displayedColumns = ['name', 'module', 'read', 'write', 'delete'];
  roleName: any; permissionsList: any; user: any;


  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<UserPermissionListComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private bottomSheet: MatBottomSheet,
    private RoleService: RoleManagementService
  ) { }

  ngOnInit() {
    if (this.data) {
      this.user = this.getFromLocalStorage('userDet');
      this.dataSource = new MatTableDataSource(this.data.permission);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  changeRead = (row) => {
    this.data.permission.forEach(el => {
      if (el._id === row._id) {
        el.read = (row.read) ? false : true;
      }
    });
  }

  changeWrite = (row) => {
    this.data.permission.forEach(el => {
      if (el._id === row._id) {
        el.write = (row.write) ? false : true;
      }
    });
  }

  btnUpdate = () => {
    this.data.updatedBy = this.user.data.userName;
    this.RoleService.updateRecord(this.data).subscribe(res => {
      this.dialogRef.close();
      this.ngOnInit();
    });
  }

  deleteTable = (item) => {
    const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
      data: { deleteItem: true },
      disableClose: false,
      hasBackdrop: false
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        this.data.permission.forEach(el => {
          if (el._id === item._id) {
            el.isActive = false;
          }
        });
        this.data.updatedBy = this.user.data.userName;
        this.RoleService.updateRecord(this.data).subscribe(res => {
          this.dialogRef.close();
          this.ngOnInit();
        });
      }
      if (result === false) {
        this.dialogRef.close();
        this.ngOnInit();
      }
    });
  }

  btnClose = () => {
    this.dialogRef.close();
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
