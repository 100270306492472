import { Injectable } from '@angular/core';
// import { CompactType, GridType } from 'angular-gridster2';
// import { GridDashboardOptions } from './grid-dashboard-options-model';

@Injectable({
  providedIn: 'root'
})
export class GridsterSettingsService {

  constructor() { }

  // resetRowValues(data) {
  //   data.cols = data.noCols || 5;
  //   data.rows = data.noRows || 3;
  //   data.y = 0;
  //   data.x = 0;
  //   return data;
  // }

  // public getDashBoardOptions(): GridDashboardOptions {
  //   return {
  //     gridType: GridType.Fixed,
  //     compactType: CompactType.None,
  //     margin: 10,
  //     outerMargin: true,
  //     outerMarginTop: null,
  //     outerMarginRight: null,
  //     outerMarginBottom: null,
  //     outerMarginLeft: null,
  //     mobileBreakpoint: 640,
  //     minCols: 1,
  //     maxCols: 100,
  //     minRows: 1,
  //     maxRows: 100,
  //     maxItemCols: 100,
  //     minItemCols: 1,
  //     maxItemRows: 100,
  //     minItemRows: 1,
  //     maxItemArea: 1000,
  //     minItemArea: 1,
  //     defaultItemCols: 1,
  //     defaultItemRows: 1,
  //     fixedColWidth: 105,
  //     fixedRowHeight: 105,
  //     keepFixedHeightInMobile: true,
  //     keepFixedWidthInMobile: false,
  //     scrollSensitivity: 10,
  //     scrollSpeed: 20,
  //     enableEmptyCellClick: false,
  //     enableEmptyCellContextMenu: false,
  //     enableEmptyCellDrop: false,
  //     enableEmptyCellDrag: false,
  //     emptyCellDragMaxCols: 50,
  //     emptyCellDragMaxRows: 50,
  //     ignoreMarginInRow: false,
  //     draggable: {
  //       delayStart: 0,
  //       enabled: true,
  //       ignoreContentClass: 'gridster-item-content',
  //       ignoreContent: true,
  //       dragHandleClass: 'drag-handler',
  //       dropOverItems: false
  //     },
  //     resizable: {
  //       enabled: true,
  //     },
  //     swap: false,
  //     pushItems: true,
  //     disablePushOnDrag: false,
  //     disablePushOnResize: false,
  //     pushDirections: { north: true, east: true, south: true, west: true },
  //     pushResizeItems: false,
  //     displayGrid: function () { },
  //     disableWindowResize: false,
  //     disableWarnings: false,
  //     scrollToNewItems: false,
  //     itemChangeCallback: function () { },
  //     itemResizeCallback: function () { }
  //   };
  // }
}
