import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting/setting.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiUrl = ''; // URL to web api

  constructor(private http: HttpClient,
    private settingService:SettingService) {
  }



  generateIndividualQRReport(qrcode): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateIndividualQRCodeReport',
        qrcode,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  generateQrCodeBulkReport(qrcode): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateQrCodeBulkReport',
        qrcode,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  generateScheduleInspectionReport(scheduleInspection): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateScheduleInspectionReport',
        scheduleInspection,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  generateRFIReport(scheduleInspection): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateRFIReport',
        scheduleInspection,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  generateWeeklyReport(scheduleInspection): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateWeeklyReport',
        scheduleInspection,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  generateChecklistReport(checklist): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateChecklistReport',
        checklist,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  generateChecklistReports(checklist): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateChecklistReports',
        checklist,
        httpOptions
      )
      .pipe(
        tap()
      );
  }
  
  generateCustomerFeedbackReport(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateCustomerFeedbackReport',
        data,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  generateWeeklyReportByInspector(scheduleInspection): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateWeeklyReportByInspector',
        scheduleInspection,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  generateWeeklyReportByInspectorInGrid(scheduleInspection): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateWeeklyReportByInspectorInGrid',
        scheduleInspection,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  generateTrackingReportByInspectorInGrid(scheduleInspection): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateTrackingReportByInspectorInGrid',
        scheduleInspection,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  generateTrackingReportByInspector(scheduleInspection): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('report');
    return this.http
      .post<any>(
        this.apiUrl + '/generateTrackingReportByInspector',
        scheduleInspection,
        httpOptions
      )
      .pipe(
        tap()
      );
  }
}
