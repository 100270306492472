import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting/setting.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private apiUrl = '';
  constructor(private http: HttpClient,
    private settingService: SettingService) {
  }

  getRecord(): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('permission');
    return this.http
      .post<any>(this.apiUrl + '/getRecord', httpOptions)
      .pipe(tap());
  }

}
