import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting/setting.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class ScheduleInspectionService {
  private apiUrl = '';  // URL to web api

  constructor(private http: HttpClient,
    private settingService: SettingService) {
  }

  save(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .post<any>(this.apiUrl + '/createRecord', data, httpOptions)
      .pipe(tap());
  }

  getAllRecords(): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .get<any>(this.apiUrl + '/getAllRecords')
      .pipe(
        tap()
      );
  }

  getAllIsActiveRecords(): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .get<any>(this.apiUrl + '/getAllIsActiveRecords')
      .pipe(
        tap()
      );
  }

  getAllRecordsForCoordinator(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .post<any>(this.apiUrl + '/getAllRecordsForCoordinator', data, httpOptions)
      .pipe(tap());
  }

  deleteById(department: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .post<any>(
        this.apiUrl + '/deleteRecord',
        department,
        httpOptions
      )
      .pipe(
        tap()
      );
  }
  // getRecordById(department: any): Observable<any> {
  //       return this.http
  //           .post<any>(
  //               this.apiUrl + '/getRecordById',
  //               department,
  //               httpOptions
  //           )
  //           .pipe(
  //               tap()
  //           );
  //   }

  updateRecord(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .post<any>(this.apiUrl + '/updateRecord', data, httpOptions)
      .pipe(tap());
  }

  updateEquipmentNumberById(data): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http.post<any>(`${this.apiUrl}/updateEquipmentNumberById`, data, httpOptions)
      .pipe(
        tap()
      );
  }



  checkSiteLocationIsExist(locationID: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .post<any>(
        this.apiUrl + '/checkSiteLocationIsExist',
        { "locationID": locationID },
        httpOptions
      )
      .pipe(
        tap()
      );
  }
  getInternalEntryData(data: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .post<any>(this.apiUrl + '/getInternalEntryData', data, httpOptions)
      .pipe(tap());
  }

  updateInternalEntry(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .post<any>(this.apiUrl + '/updateInternalEntry', data, httpOptions)
      .pipe(tap());
  }

  getScheduleDataByInspectionID(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .post<any>(this.apiUrl + '/getScheduleDataByInspectionID', data, httpOptions)
      .pipe(tap());
  }
  checkReceiverIsExist(receiverID: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .post<any>(
        this.apiUrl + '/checkReceiverIsExist',
        { "receiverID": receiverID },
        httpOptions
      )
      .pipe(
        tap()
      );
  }
  checkInspectorAlreadyAssigned(data: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('schedule');
    return this.http
      .post<any>(
        this.apiUrl + '/checkInspectorAlreadyAssigned',
        data,
        httpOptions
      )
      .pipe(
        tap()
      );
  }
}

