import { ChangeDetectorRef, Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MatBottomSheet, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';
import * as _ from 'underscore';
import { EquipmentService } from '../../master/equipment/equipment.service';
import { RfiInspectionService } from '../../master/rfi-inspection/rfi-inspection.service';
import { QrcodeService } from '../../master/qrcode/qrcode.service';
import { LocationService } from '../../master/location/location.service';
import { TypeService } from '../../master/type/type.service';
import { ChecklistService } from '../../master/checklist/checklist.service'
import { ScheduleInspectionService } from '../../master/scheduleInspection/schedule-inspection.service'
import { ManufactureService } from '../../master/manufacture/manufacture.service';
import { EquipmentCompanyService } from '../../master/equipmentCompany/equipment-company.service';
import { ReceiverService } from '../../master/receiver/receiver.service';
import { ProjectService } from '../../master/project/project.service';
import { FileService } from '../../services/files/file.service';
import { ContactService } from '../../master/contact/contact.service';
import { CompanyService } from '../../master/company/company.service';
import { ClientService } from '../../master/client/client.service';
import { UserService } from '../../admin/user/user.service';
import { result } from 'lodash';

@Component({
  selector: 'app-scheduled-rfidata-popup',
  templateUrl: './scheduled-rfidata-popup.component.html',
  styleUrls: ['./scheduled-rfidata-popup.component.css']
})
export class ScheduledRfidataPopupComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource;
  displayedColumns = [
    'reportNo',
    'inspectionDate',
    'inspector',
    'category',
    'status'
  ];
  data;
  user: any;
  userID: any;
  isLoading = false;
  selectedCheckbox = false;

  loading = false;

  constructor(
    private offlineStorage: OfflineStorageService,
    private dialogRef: MatDialogRef<ScheduledRfidataPopupComponent>,
    private snackBar: MatSnackBar,
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private datas: any,
    private equipmentService: EquipmentService,
    private rfiInspectionService: RfiInspectionService,
    private qrcodeService: QrcodeService,
    private locationService: LocationService,
    private typeService: TypeService,
    private manufactureService: ManufactureService,
    private equipmentCompanyService: EquipmentCompanyService,
    private checklistService: ChecklistService,
    private scheduleInspectionService: ScheduleInspectionService,
    private fileService: FileService,
    private receiverService: ReceiverService,
    private projectService: ProjectService,
    private contactService: ContactService,
    private clientService: ClientService,
    private companyService: CompanyService,
    private userService: UserService,
    private ngZone: NgZone
  ) {
  }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.userID = this.user.data._id;
    console.log(this.datas)
    this.data = _.filter(this.datas, ele => (this.reverseAndTimeStamp(moment(ele.inspectionDate).format('YYYY-MM-DD')) >= this.reverseAndTimeStamp(moment(this.datas.scheduleDate).format('YYYY-MM-DD'))) &&
      (this.reverseAndTimeStamp(moment(ele.inspectionDate).format('YYYY-MM-DD')) <= this.reverseAndTimeStamp(moment(this.datas.toDate).format('YYYY-MM-DD'))));
  }
  reverseAndTimeStamp(dateString) {
    const reverse = new Date(dateString);
    return reverse.getTime();
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  onCheckboxChange(event, obj) {
    if (event == true) {
      obj.isReadyToInspection = true;
    } else if (event == false) {
      obj.isReadyToInspection = false;
    }
  }

  takeOfflineFnc() {
    this.loading = true;
    this.deleteOfflineData();
    this.getAllEquipmentDetails();
    this.getAllWorkflowDetails();
    this.getQRCodeByInspector();
    this.getReportData();
    this.getLocations();
    this.getTypes();
    this.getManufactures();
    this.getEquipmentCompanies();
    this.checklist();
    this.scheduleInspection();
    // this.getAttachments();
    this.getReceiver();
    this.getProject();
    this.getAllContacts();
    this.getClients()
    this.getCompany();
    this.getUser();
    this.getQRCodeUsedByInspector();
    this.getRFIInspection();
  }

  checkAndInsertSelecetedRFI(i, _this) {
    if (_this.data[i].isReadyToInspection && _this.data[i].isReadyToInspection === true) {
      console.log(_this.data[i]);
      _this.offlineStorage.rfiHandler.insertSelectedRFI(_this, _this.data[i], function (res) {
        console.log(res);
        let _this = res.self;
        _this.incrementInsertCount(i, _this);
      });
    } else {
      _this.incrementInsertCount(i, _this);
    }
  }

  incrementInsertCount(i, _this) {
    i = i + 1;
    if (_this.data.length > i) {
      _this.checkAndInsertSelecetedRFI(i, _this);
    } else {
      _this.offlineStorage.attachmentHandler.deleteSyncedAttachment(_this, function (res) {
        _this.insertAttachment();
      });

    }
  }
  insertAttachment() {
    var subData = [];
    var attachmentData = [];
    let index = 0;
    subData = _.filter(this.data, ele => ele.isReadyToInspection === true);
    for (let i = 0; i < subData.length; i++) {

      this.fileService.getAttachmentById(subData[i]).subscribe(res => {
        if (res) {
          index++;
          for (let j = 0; j < res.length; j++) {
            attachmentData.push(res[j]);
          }
        }
        if (index === subData.length) {
          this.insertAttachmentAgainstRFIRecord(attachmentData);
        }
      });
    }


  }

  insertAttachmentAgainstRFIRecord(attachmentData) {
    if (attachmentData.length > 0) {
      var index = 0;
      this.offlineStorage.attachmentHandler.insertAttachments(this, attachmentData, function (res) {
        let _this = res.self;
        index++;
        if (index == attachmentData.length) {
          _this.spinnerLoad();
        }
      });
    } else {
      this.spinnerLoad();
    }

  }
  cancelPopup() {
    this.dialogRef.close(false);
  }

  dummyClickEventForPageRender() {
    let element: HTMLElement = document.getElementById('auto_trigger') as HTMLElement;
    element.click()
  }
  ;
  editModal(param) {
    console.log(param);
  }
  deleteOfflineData() {
    this.offlineStorage.offlineDataDeleteHandler.deleteDatabase(this, function (res) {
      let _this = res.self;

      if (res) {
        console.log('Deleted sucessfully')
      }
    });
  }
  getAllEquipmentDetails() {
    this.deleteSyncedEquipment();
    this.equipmentService.getRecords().subscribe(result => {
      if (result.length > 0) {

        this.offlineStorage.equipmentHandler.insertEquipmentDetails(this, result, function (res) {
        });
      }
    })
  }

  deleteSyncedEquipment() {
    this.offlineStorage.equipmentHandler.deleteSyncedEquipment(this, function (res) {
    });
  }

  getAllContacts() {
    this.deleteSyncedContact();
    this.contactService.getContact().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.contactHandler.insertContactDetails(this, result, function (res) {
        });
      }
    })

  }

  deleteSyncedContact() {
    this.offlineStorage.contactHandler.deleteSyncedContacts(this, function (res) {
    })

  }

  getAllWorkflowDetails() {
    this.deleteSyncedWorkflow();
    this.rfiInspectionService.getAllRFIWorkflow().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.workflowHandler.insertWorkflowDetails(this, result, function (res) {
        });
      }
    })
  }

  deleteSyncedWorkflow() {
    this.offlineStorage.workflowHandler.deleteSyncedWorkflow(this, function (res) {
    });
  }

  getQRCodeByInspector() {

    this.deleteSyncedQrcode();
    var data = {
      inspector: this.userID
    }
    this.qrcodeService.getAllRecordsByInspectorOffline(data).subscribe(data => {
      //this.qrcodeService.getQRCode().subscribe(result => {
      if (data.length > 0) {
        this.offlineStorage.qrCodehandler.insertQrcodeDetails(this, data, function (res) {
        });
      }
    })
  }

  deleteSyncedQrcode() {
    this.offlineStorage.qrCodehandler.deleteSyncedQrcode(this, function (res) {
    });
  }

  getReportData() {
    this.deleteSyncedReportData();
    this.rfiInspectionService.getAllRFIReportData().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.rfiReporthandler.insertrfiReportDetails(this, result, function (res) {
        });
      }
    })
  }

  deleteSyncedReportData() {
    this.offlineStorage.rfiReporthandler.deleteSyncedRfiReport(this, function (res) {
    });
  }

  getLocations() {
    this.deleteSyncedLocations();
    this.locationService.getLocations().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.locationhandler.insertLocationDetails(this, result, function (res) {
        });
      }
    })
  }

  deleteSyncedLocations() {
    this.offlineStorage.locationhandler.deleteSyncedLocations(this, function (res) {
    });
  }

  getManufactures() {
    this.deleteSyncedManufactures();
    this.manufactureService.getManufacture().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.manufacturehandler.insertManufatureDetails(this, result, function (res) {
        });
      }
    })
  }

  deleteSyncedManufactures() {
    this.offlineStorage.manufacturehandler.deleteSyncedManufacture(this, function (res) {
    });
  }

  getTypes() {
    this.deleteSyncedTypes();
    this.typeService.getTypes().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.typehandler.insertTypeDetails(this, result, function (res) {
        });

      }
    })
  }

  deleteSyncedTypes() {
    this.offlineStorage.typehandler.deleteSyncedTypes(this, function (res) {
    });
  }

  getReceiver() {
    this.deleteSyncedReceiver();
    this.receiverService.getReceiver().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.receiverHandler.insertReceiverDetails(this, result, function (res) {
        });
      }
    })
  }
  deleteSyncedReceiver() {
    this.offlineStorage.receiverHandler.deleteSyncedReceiver(this, function (res) {
    });
  }

  getProject() {
    this.deleteSyncedProject();
    this.projectService.getProjects().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.projectHandler.insertProjectDetails(this, result, function (res) {
        });

      }
    })
  }
  deleteSyncedProject() {
    this.offlineStorage.projectHandler.deleteSyncedProject(this, function (res) {
    });
  }

  getCompany() {
    this.deleteSyncedCompany();
    this.companyService.getCompany().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.companyHandler.insertCompanyDetails(this, result, function (res) {
        });
      }
    })

  }

  deleteSyncedCompany() {
    this.offlineStorage.companyHandler.deleteSyncedCompany(this, function (res) {
    })

  }

  getUser() {
    this.deleteSyncedUser();
    this.userService.getRecord().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.userHandler.insertUserDetails(this, result, function (res) {
        });
      }
    })

  }

  deleteSyncedUser() {
    this.offlineStorage.userHandler.deleteSyncedUser(this, function (res) {
    })

  }

  getQRCodeUsedByInspector() {

    this.deleteSyncedQrCodeUsed();
    var data = {
      inspector: this.userID
    }
    this.qrcodeService.getAllRecordsUsedByInspectorOffline(data).subscribe(data => {
      if (data.length > 0) {
        this.offlineStorage.qrCodeUsedhandler.insertQrCodeUsedDetails(this, data, function (res) {
        });
      }
    })
  }

  deleteSyncedQrCodeUsed() {
    this.offlineStorage.qrCodeUsedhandler.deleteSyncedQrCodeUsed(this, function (res) {
    });
  }


  checklist() {
    this.deleteSyncedChecklist();
    this.checklistService.getChecklist().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.checklisthandler.insertChecklistDetails(this, result, function (res) {
        });
      }
    })
  }

  deleteSyncedChecklist() {
    this.offlineStorage.checklisthandler.deleteSyncedChecklist(this, function (res) {
    });
  }

  getEquipmentCompanies() {
    this.deleteSyncedEquipmentCompanies();
    this.equipmentCompanyService.getEquipmentCompany().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.equipmentCompanyhandler.insertEquipmentCompanyDetails(this, result, function (res) {
        });
      }
    })
  }

  deleteSyncedEquipmentCompanies() {
    this.offlineStorage.equipmentCompanyhandler.deleteSyncedequipmentCompany(this, function (res) {
    });
  }

  scheduleInspection() {
    this.deleteSyncedScheduleInspections();
    this.scheduleInspectionService.getAllRecords().subscribe(result => {
      if (result.length > 0) {
        this.offlineStorage.scheduleInspectionhandler.insertScheduleInspectionDetails(this, result, function (res) {
        });
      }
    })
  }

  deleteSyncedScheduleInspections() {
    this.offlineStorage.scheduleInspectionhandler.deleteSyncedScheduleInspection(this, function (res) {
    });
  }

  getAttachments() {
    this.deleteSyncedAttachments();
    this.fileService.getAllRecords().subscribe(result => {
      if (result.length > 0) {
        var subData = [];
        subData = _.filter(this.data, ele => ele.isReadyToInspection === true);
        let finalData = this.filterDataByID(result, subData);
        if (finalData.length > 0) {
          this.offlineStorage.attachmentHandler.insertAttachments(this, finalData, function (res) {
          });
        }
      }
    })
  }

  deleteSyncedAttachments() {
    this.offlineStorage.attachmentHandler.deleteSyncedAttachment(this, function (res) {
    });
  }

  filterDataByID(allData, filteredID) {
    var finalData = [];
    for (let i = 0; i < filteredID.length; i++) {
      if (filteredID[i].isReadyToInspection) {
        var subData = [];
        subData = _.filter(allData, ele => ele.refID == filteredID[i]._id);
        if (subData.length > 0) {
          for (let j = 0; j < subData.length; j++) {
            finalData.push(subData[j]);
          }
        }
      }
    }
    return finalData;
  }

  getClients() {
    this.deleteSyncedClient();
    this.clientService.getClient().subscribe(result => {
      if (result.length > 0) {

        this.offlineStorage.clientHandler.insertClientDetails(this, result, function (res) {
        });
      }
    })
  }

  deleteSyncedClient() {
    this.offlineStorage.clientHandler.deleteSyncedClient(this, function (res) {
    });
  }
  spinnerLoad() {
    setTimeout(() => {
      this.dialogRef.close();
      this.ref.detectChanges();
      this.dummyClickEventForPageRender();
      this.loading = false;
      this.snackBar.open('Data stored successfully', '', { duration: 2000, verticalPosition: "bottom" });
    }, 8000);
  }

  getRFIInspection() {
    var offlineDataSelectedAtleastOne = false;
    for (let jj = 0; jj < this.data.length; jj++) {
      if (this.data[jj].isReadyToInspection && this.data[jj].isReadyToInspection === true) {
        offlineDataSelectedAtleastOne = true;
        break;
      }
    }
    if (offlineDataSelectedAtleastOne) {
      this.offlineStorage.syncRFIHandler.getSelectedRFIToSend(this, function (res) {
        let _this = res.self;
        if (res && res.data && res.data.length > 0) {
          _this.snackBar.open('Syncing is inprogress', '', { duration: 2000, verticalPosition: "bottom" });
          _this.snackBar.open('Some datas are not synced', '', { duration: 2000, verticalPosition: "bottom" });
          _this.dummyClickEventForPageRender();
        } else {
          _this.offlineStorage.rfiHandler.deleteSyncedRFI(_this, function (res) {
            var i = 0;
            _this.checkAndInsertSelecetedRFI(i, _this);
          });
        }
      });
    } else {
      this.loading = false;
      // this.isLoading = false;
      this.snackBar.open('No data selected to take offline!!!', '', { duration: 2000, verticalPosition: "bottom" });
    }
  }
}
