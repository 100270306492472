import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { WidgetFormComponent } from '../widget-form/widget-form.component';
import { WidgetService } from '../widget.service';

@Component({
  selector: 'app-widget-list',
  templateUrl: './widget-list.component.html',
  styleUrls: ['./widget-list.component.css']
})
export class WidgetListComponent implements OnInit {
  doFilter = false;
  permission: any;
  isWrite = false;
  user: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource;
  displayedColumns = ['action', 'module', 'name', 'componentName', 'noRows', 'noCols', 'description', 'createdDate'];
  constructor(private dialog: MatDialog,
    private widgetService: WidgetService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;

    if (this.permission && this.permission.admin && this.permission.admin.widgets && this.permission.admin.widgets.write) {
      this.isWrite = true;
    } else {
      this. displayedColumns = ['module', 'name', 'componentName', 'noRows', 'noCols', 'description', 'createdDate'];
      
    }
    this.getRecord();
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  addWidgets(): void {
    const dialogRef = this.dialog.open(WidgetFormComponent, {
      width: '50%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getRecord();
      }
      console.log('The dialog was closed');
    });
  }

  updateFilter(e) {
    this.dataSource.filter = e.target.value;
  }

  btnFilter() {
    this.doFilter = !this.doFilter;
    this.dataSource.filter = '';
  }

  async deleteTable(row) {
    const data = { ...row };
    data.isActive = false;
    try {
      const result: any = await this.widgetService.updateRecord(data);
      this.snackBar.open('Record deleted successfully', '', { duration: 2000, verticalPosition: "bottom" });
      this.getRecord();
    } catch (e) {
      this.snackBar.open('Widget deleted failed', '', { duration: 2000, verticalPosition: "bottom" });
    }
  }

  async getRecord() {
    try {
      const data: any = await this.widgetService.getRecord();
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } catch (e) {
      console.error(e);
    }
  }


}
