import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Chart } from 'chart.js';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { UserService } from '../../admin/user/user.service'
import { ScheduleInspectionReportService } from '../../master/scheduleInspection/schedule-inspection-report.service';
import { RfiInspectionService } from '../../master/rfi-inspection/rfi-inspection.service';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
// import { Int32 } from 'bson';
@Component({
  selector: 'app-status-barchart',
  templateUrl: './status-barchart.component.html',
  styleUrls: ['./status-barchart.component.css']
 /*  ,
  host: {
    '(window:resize)': 'onResize($event)'
  } */
})
export class StatusBarchartComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;
  title = 'angular8chartjs';
  toSearch: any;
  isShow = true;
  user: any;
  canvas: any;
  barChart: any;
  inspectorName: any;
  resultData:any=[];
  dataSource: any;
  displayedColumns = ['Title', 'Count'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  alwaysShowCalendars: boolean;
  usersData: any;
  users: any;
  inspectionDate = new FormControl('');
  inspector = new FormControl('');

  list1 = 0;
  list2 = 0;
  inspectorColumn = false;
  date = new Date();
  a = moment().startOf('day').subtract(1, 'month');
  b = moment().endOf('day')
  constructor(private userService: UserService, private snackBar: MatSnackBar,
    private scheduleInspectionReportService: ScheduleInspectionReportService,
  private rfiInspectionService: RfiInspectionService) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    if (this.user.data.role.length > 0) {
      for (var i = 0; i < this.user.data.role.length; i++) {
        if (this.user.data.role[i].name == 'Inspector') {
          this.inspector.setValue(this.user.data._id)
          this.inspectorColumn = true;
        } else {
          this.inspector.setValue('All');
          this.inspectorColumn = false;
        }
      }
    }
    this.inspectionDate.setValue({
      start: moment(this.a).format('MM/DD/YYYY'),
      end: moment(this.b).format('MM/DD/YYYY')
    });



    this.getUsers();
    this.getData1(this.inspectionDate.value, this.inspector.value);
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  getUsers = () => {
    this.userService.getInspectors().subscribe(res => {
      this.users = res;
      this.usersData = this.users;

    });
  }
  filterInspector(val) {
    val = val.toLowerCase();
    this.usersData = this.users.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }
  onResize(event) {
    event.target.innerWidth; // window width
    this.getData1(this.inspectionDate.value, this.inspector.value);

  }
  getData1(inspectionDate, inspector) {
    if (inspectionDate === null || inspectionDate.start === null) {
      inspectionDate = {
        startDate: null,
        endDate: null
      };

    } else {
      if (inspectionDate.start == undefined) {
        this.snackBar.open('Select date range', '', { duration: 2000, verticalPosition: "bottom" });
        return;
      }
      if (this.inspectionDate.value.start._d) {
        inspectionDate = {
          startDate: this.inspectionDate.value.start._d,
          endDate: this.inspectionDate.value.end._d
        };
      } else {
        inspectionDate = {
          startDate: this.inspectionDate.value.start,
          endDate: this.inspectionDate.value.end
        };
      }
      let subData = _.filter(this.usersData, ele => ele._id == inspector);
      if (subData.length > 0) {
        this.inspectorName = subData[0].name
      }else{
        this.inspectorName='';
      }

    }
    this.toSearch = {
      inspectionDate: inspectionDate,
      inspector: inspector,
      inspectorName: this.inspectorName
    };
    this.isShow = true;
   // this.scheduleInspectionReportService.getResultByDate(this.toSearch).subscribe(result => {
    this.rfiInspectionService.getStatusByDateNS(this.toSearch).subscribe(result => {
      if (!result) {
        return
      }
     
      this.list1 = 0;
      this.list2 = 0;
      
     
       if (result.length > 0) {
        for (var i = 0; i < result.length; i++) {
          if(result[i]._id[0]=="PASS")
          {
            this.list1 = this.list1 + result[i].Total;
          }
          else
          {
            this.list2 = this.list2 + result[i].Total;
          }
        /* this.list1 = result[0].Pass;
        this.list2 = result[0].Fail; */
        }
          this.isShow = true;
          this.resultData=[];
          this.resultData.push({Title:"Pass",Count:this.list1});
          this.resultData.push({Title:"Fail",Count:this.list2});
          console.log('abc1');
          console.log(this.resultData);
          this.dataSource = new MatTableDataSource(this.resultData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          /* this.canvas = document.getElementById('barChart');
          this.barChart = new Chart(this.canvas, {
            type: 'bar',
            data: {
              labels: ['Pass', 'Fail'],
              datasets: [{
                // label: '# of Votes',
                data: [this.list1, this.list2],
                backgroundColor: [
                  '#79bf91',
                  '#edadf5'
                  
                ],
                fill: true,
                borderWidth: 1
              }],

            },
            options: {
              responsive: true,
              legend: {
                display: false
              },
              scales: {
                xAxes: [{
                  display: true
                }],
                yAxes: [{
                  // ticks: {
                  //   stepSize: 1
                  // },
                  display: true,
                  stacked: true,
                }]
              }

            }
          }); */
       
      } else { 
        this.isShow = false;
        this.snackBar.open('No record found', '', { duration: 2000, verticalPosition: "bottom" });
      } 

    });

  }




}
