import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ChecklistService } from '../checklist.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar
} from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { CategoryService } from '../../../master/category/category.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../general/dateformat-form';
@Component({
  selector: 'app-checklist-form',
  templateUrl: './checklist-form.component.html',
  styleUrls: ['./checklist-form.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class ChecklistFormComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  inputData: any;
  user: any;
  isSave = false;
  isUpdate = false;
  // name = new FormControl('', [Validators.required]);
  // saicNo = new FormControl('');
  // discipline = new FormControl('');
  // category = new FormControl('');
  _id: string;
  categoryList: any;
  anotherCategoryList: any;
  checklistForm: any;
  name = new FormControl('', [Validators.required]);
  constructor(
    private fb: FormBuilder,
    private checklistService: ChecklistService,
    private dialogRef: MatDialogRef<ChecklistFormComponent>,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    private categoryService: CategoryService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.checklistForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'saicNo': [null],
      'discipline': [null],
      'category': [null, Validators.compose([Validators.required])],
      'issueDate': [null],
    });
  }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    if (this.data._id) {
      this.isUpdate = true;
      this.patchValue();
    } else {
      this.isSave = true;
    }
    this.getCategory();
  }

  patchValue() {
    this.checklistForm.patchValue({
      name: this.data.name,
      saicNo: this.data.saicNo,
      discipline: this.data.discipline,
      issueDate: this.data.issueDate
    })
  }

  getCategory() {
    this.categoryService.getCategory().subscribe(result => {
      this.categoryList = result;
      this.anotherCategoryList = this.categoryList
      var tempCat = [];
      for (let i = 0; i < this.data.category.length; i++) {
        for (let j = 0; j < this.categoryList.length; j++) {
          if (this.categoryList[j]._id === this.data.category[i].ref) {
            tempCat.push(this.categoryList[j]);
          }
        }
      }
      if (tempCat.length > 0) {
        this.checklistForm.patchValue({ category: tempCat });
      }
    });
  }

  save(): void {
    this.inputData = {
      name: this.checklistForm.controls.name.value,
      saicNo: this.checklistForm.controls.saicNo.value,
      discipline: this.checklistForm.controls.discipline.value,
      category: this.checklistForm.controls.category.value,
      issueDate: this.checklistForm.controls.issueDate.value,
    };
    if (Object.keys(this.data).length !== 0) {
      this.inputData._id = this.data._id;
      this.inputData.updatedBy = this.user.data.name;
      this.checklistService.updateRecord(this.inputData).subscribe(res => {
        if (res[0]['statusBool']) {
          this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        }
      }, err => {
        if (err.error[0].statusHttp == 412) {
          this.snackBar.open(err.error[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else {
      this.inputData.createdBy = this.user.data.name;
      this.checklistService.save(this.inputData).subscribe(res => {
        if (res['statusBool']) {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        }
      }, err => {
        if (err.error.statusHttp == 412) {
          this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }
  }

  getErrorChecklist() {

    return this.name.hasError('required')
      ? 'Checklist is required'
      : '';

    // return this.name.hasError('required')
    //   ? 'Name is required'
    //   : '';
  }
  btnClose() {
    this.dialogRef.close();
  }
  get f() {
    return this.checklistForm.controls;
  }

  filterCategory(val) {
    val = val.toLowerCase();
    this.anotherCategoryList = this.categoryList.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val);
    });
  }

  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
