import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet, MatDialog, MatSnackBar } from '@angular/material';
import { Validators, FormBuilder } from '@angular/forms';
import { RfiInspectionService } from '../../master/rfi-inspection/rfi-inspection.service';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';

@Component({
  selector: 'app-command-popup',
  templateUrl: './command-popup.component.html',
  styleUrls: ['./command-popup.component.css']
})
export class CommandPopupComponent implements OnInit {

  commentForm: any;
  user: any;
  isOffline: any;


  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CommandPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private rfiInspectionService: RfiInspectionService,
    private snackBar: MatSnackBar, private offlineStorage: OfflineStorageService, private ngZone: NgZone,
  ) {
    this.commentForm = fb.group({
      'comment': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    let data = this.getFromLocalStorage('isOffline');
    if (data == true) {
      this.isOffline = true;
    } else {
      this.isOffline = false;
    }
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  get f() {
    return this.commentForm.controls;
  }

  btnClose() {
    this.dialogRef.close(false)
  }

  save() {
    if (this.isOffline) {
      var data1 = {
        _id: this.data._id,
      }
      var finalData = [];
      this.offlineStorage.rfiHandler.getRFIById(this, data1, function (res) {
        let _this = res.self;

        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            finalData.push(JSON.parse(res.data.item(i).inspectionData));
          }

        }
        if (finalData.length > 0) {

          let comment = {
            status: null,
            updatedBy: _this.user.data.name,
            comment: _this.commentForm.controls.comment.value
          }
          if (_this.data.moduleStatus === 'Cancel') {
            comment.status = 'Cancelled';
          } else if (_this.data.moduleStatus === 'Reject') {
            comment.status = 'Schedule rejected';
          }
          finalData[0].comment = comment;
          _this.offlineStorage.rfiHandler.updateSelectedRFI(this, finalData[0], function (res) {
            // _this.snackBar.open('Updated successfully', '', { duration: 2000, verticalPosition: "bottom" });
            _this.dialogRef.close(true);
          });
        }


      });

    }
    else {
      let inputData = {
        rfiID: this.data._id,
        status: null,
        updatedBy: this.user.data.name,
        comment: this.commentForm.controls.comment.value
      }
      if (this.data.moduleStatus === 'Cancel') {
        inputData.status = 'Cancelled';
      } else if (this.data.moduleStatus === 'Reject') {
        inputData.status = 'Schedule rejected';
      }else if(this.data.moduleStatus === 'ReInspect'){
        inputData.status = 'Inspection completed. Send for review';
      }

      this.rfiInspectionService.updateComment(inputData).subscribe(result => {
        if (result['statusBool']) {
          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close(true);
        } else {
          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      })
    }
  }

}
