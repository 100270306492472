import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { fade } from './login.animations';
import { environment } from '../../../environments/environment';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';
import { AppUrlFormComponent } from '../app-url-form/app-url-form.component';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MatBottomSheet, MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    fade
  ]
})
export class LoginComponent implements OnInit {
  private data = new BehaviorSubject('');
  currentData = this.data.asObservable();
  form: FormGroup;
  private formSubmitAttempt: boolean;
  showSpinner: Boolean = false;
  isError: Boolean = false;
  errMsg: String;
  versionNumber: String = environment.versionNumber;
  mobileApp: Boolean = environment.mobileApp;
  loginHeaderLogo = environment.logo;
  isMobile: boolean = false;
  // loginHeaderLogo = 'images/icons/logo.jpg';  

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private routeTest: ActivatedRoute,
    private offlineStorage: OfflineStorageService,
    private dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      emailId: ['', Validators.required],
      password: ['', Validators.required],
      versionNumber: this.versionNumber,
      mobileApp: this.mobileApp
    });

    this.authService.logout();

    if (environment.mobileApp) {
      this.loginHeaderLogo = 'assets/images/icons/logo.jpg';
      // DB INIT!!!
      this.offlineStorage.databaseHandler.createDatabase(this);
    }

    this.isMobile = environment.mobileApp;
    localStorage.setItem('isMobile', this.isMobile.toString());
  }

  detectMob() {
    return ((window.innerWidth <= 1024));
  }

  isFieldInvalid(field: string) {
    if (this.form.get(field) !== undefined && this.form.get(field) !== null) {
      return (
        (!this.form.get(field).valid && this.form.get(field).touched) ||
        (this.form.get(field).untouched && this.formSubmitAttempt)
      );
    }
  }

  onSubmit() {
    if (this.form.value.emailId === "") {
      this.isError = true; 
      return;
    } else if (this.form.value.password === "") {
      this.isError = true; 
      return;
    } else {
      let data = localStorage.getItem('app_url');
      if (data || !this.mobileApp) {
        this.authService.login(this.form.value, (msg) => {
          this.isError = true;
          this.errMsg = msg.message;
        });
      } else {
        this.isError = true;
        this.errMsg = 'Please enter app url'
      }
    }
  }

  openSettingURL = () => {
    let dialogRef = this.dialog.open(AppUrlFormComponent, {
      data: {},
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
}