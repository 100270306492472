import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { EquipmentService } from '../../../master/equipment/equipment.service';
import { ScheduleInspectionService } from '../../../master/scheduleInspection/schedule-inspection.service';
import * as _ from 'underscore';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';
@Component({
  selector: 'app-select-equipment',
  templateUrl: './select-equipment.component.html',
  styleUrls: ['./select-equipment.component.css']
})
export class SelectEquipmentComponent implements OnInit {

  equipmentForm: any;
  categoryID: any;
  equipments: any;
  anotherEquipments: any;
  isOffline: boolean;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SelectEquipmentComponent>,
    private equipmentService: EquipmentService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private scheduleInspectionService: ScheduleInspectionService,
    private snackBar: MatSnackBar, private offlineStorage: OfflineStorageService, private ngZone: NgZone
  ) {
    this.equipmentForm = fb.group({
      'equipment': [null]
    });
  }

  ngOnInit() {
    if (this.data) {
      if (this.data.data) {
        if (this.data.data.category) {
          if (this.data.data.category.ref) {
            let data = {
              _id: this.data.data.category.ref,
              row: this.data.data
            }
            let value = localStorage.getItem('isOffline');
            if (value == 'true') {
              this.isOffline = true;
            }
            else {
              this.isOffline = false;
            }
            if (this.isOffline == true) {
              this.offlineStorage.equipmentHandler.getExistingEquipmentByCategoryID(this, function (res) {
                let _this = res.self;
                var equipmentData = [];
                var tempequipmentData = [];
                if (res && res.data && res.data.length > 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    var datas = JSON.parse(res.data.item(i).equipmentData);
                    datas.id = res.data.item(i).pid;
                    equipmentData.push(datas);
                  }
                }
                if (equipmentData && equipmentData.length > 0) {
                  for (var j = 0; j < equipmentData.length; j++) {
                    if (equipmentData[j].category.ref === data._id || equipmentData[j].project.ref === _this.data.data.scheduleInspectionDetail.project.ref) {
                      tempequipmentData.push(equipmentData[j]);
                    }
                  }
                  var data1 = [];
                  data1 = _.filter(tempequipmentData, ele => { return ele.updatedPreviousStickerNumber != null && ele.updatedPreviousStickerNumber != undefined && ele.updatedPreviousStickerNumber != '' });

                  _this.bindValue(data1);
                }
              });
            } else {
              this.equipmentService.getRecordByCategoryId(data).subscribe(result => {
                var data = [];
                data = _.filter(result, ele => { return ele.updatedPreviousStickerNumber != null && ele.updatedPreviousStickerNumber != undefined && ele.updatedPreviousStickerNumber != '' });
                this.equipments = _.sortBy(data, ['updatedPreviousStickerNumber'], 'asc');
                this.anotherEquipments = _.sortBy(data, ['updatedPreviousStickerNumber'], 'asc');
              });
            }



          }
        }
      }
    }
  }

  bindValue(data) {
    this.equipments = _.sortBy(data, ['updatedPreviousStickerNumber'], 'asc');;
    this.anotherEquipments = _.sortBy(data, ['updatedPreviousStickerNumber'], 'asc');
  }

  get f() {
    return this.equipmentForm.controls;
  }

  filterEquipments(val) {
    val = val.toLowerCase();
    this.anotherEquipments = this.equipments.filter(function (d) {
      return (d.updatedPreviousStickerNumber.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }
  save() {
    var value;
    if (this.equipmentForm.controls.equipment.value._id) {
      value = this.equipmentForm.controls.equipment.value._id
    } else {
      value = this.equipmentForm.controls.equipment.value.id
    }
    var data = {
      equipmentID: value,
      rfiID: this.data.data._id
    }
    var tempRfiData = [];
    var tempequipmentData = [];
    var equipment = [];
    if (this.isOffline == true) {
      var data4 = {
        _id: value,
      }

      this.offlineStorage.equipmentHandler.getEquipmentById(this, data4, function (res) {
        let _this = res.self;
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            var subData = JSON.parse(res.data.item(i).equipmentData);
            subData.pid = res.data.item(i).pid;
            tempequipmentData.push(subData);
          }
        }
        var data5 = {
          _id: _this.data.data._id
        }

        _this.offlineStorage.rfiHandler.getRFIById(this, data5, function (res) {


          if (res && res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              var subData = JSON.parse(res.data.item(i).inspectionData);
              subData.pid = res.data.item(i).pid;
              tempRfiData.push(subData);

            }
          }
          if (!tempequipmentData[0]._id) {
            tempequipmentData[0]._id = tempequipmentData[0].pid
          }

          equipment.push({
            "ref": tempequipmentData[0]._id,
            "equipmentNumber": tempequipmentData[0].equipmentNumber,
          });
          tempRfiData[0].equipment = equipment[0];
          _this.offlineStorage.rfiHandler.updateSelectedRFI(_this, tempRfiData[0], function (res) {

            var id = _this.data.data._id;

            _this.offlineStorage.rfiReporthandler.getRFIReportDataByRFIId(_this, id, function (res) {
              let _this = res.self, temp = [];
              if (res && res.data && res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                  temp.push(JSON.parse(res.data.item(i).rfiReportData));
                }
              }

              if (temp.length > 0) {
                temp[0].updatedOldStickerNo = tempequipmentData[0].updatedPreviousStickerNumber;
                _this.offlineStorage.rfiReporthandler.updateRFIReportById(_this, temp[0], function (res) {

                  _this.ngZone.run(() => {
                    setTimeout(() => {
                      _this.snackBar.open('Updated successfully', '', {
                        duration: 5000,
                        verticalPosition: 'bottom',
                      });
                    }, 0);
                  });
                  _this.dialogRef.close();
                });
              } else {
                _this.ngZone.run(() => {
                  setTimeout(() => {
                    _this.snackBar.open('Updated successfully', '', {
                      duration: 5000,
                      verticalPosition: 'bottom',
                    });
                  }, 0);
                });
                _this.dialogRef.close();
              }
            });
          });

        });

      });
    } else {
      this.scheduleInspectionService.updateEquipmentNumberById(data).subscribe(result => {
        this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        this.dialogRef.close();
      })
    }
  }
  btnClose() {
    this.dialogRef.close();
  }


}
