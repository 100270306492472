import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
  MatDialogConfig,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material';

import { ChecklistFormComponent } from '../checklist-form/checklist-form.component';
import { ChecklistService } from '../checklist.service';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { ExcelService } from '../../../excel.service';
import { AuthService } from '../../../auth/auth.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ChecklistForm2Component } from '../checklist-form2/checklist-form2.component';
import { UserService } from '../../../admin/user/user.service';
@Component({
  selector: 'app-checklist-list',
  templateUrl: './checklist-list.component.html',
  styleUrls: ['./checklist-list.component.css']
})
export class ChecklistListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  // dataSource: ChecklistListDataSource;
  dataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'edit',
    'name',
    'saicNo',
    'discipline',
    'category',
    'issueDate',
    'checksheet',
    'documents'
  ];
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  permission: any;
  isWrite = false;

  constructor(
    private dialog: MatDialog,
    private checklistService: ChecklistService, private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet, private excelService: ExcelService, private authService: AuthService,
    private UserService: UserService
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;
    this.getTableData();
    if (this.permission && this.permission.master && this.permission.master.checklist && this.permission.master.checklist.write) {
      this.isWrite = true;
    } else {
      this.displayedColumns = ['name', 'saicNo', 'discipline', 'category', 'issueDate', 'checksheet', 'documents'];
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  getRequiredServices(servicesObject) {
    const requiredServices = [];
    if (servicesObject && servicesObject.length > 0) {
      Object.entries(servicesObject).forEach(([key, value]) => {
        requiredServices.push(value["name"]);
      })
      return requiredServices.join();
    }
  }

  getTableData() {
    this.checklistService.getChecklist().subscribe(data => {
      if (!data) {
        return;
      }
      this.temp = data;
      if (this.temp && this.temp.length > 0) {
        for (let i = 0; i < this.temp.length; i++) {
          this.temp[i].category = [];
          if (this.temp[i].issueDate) { this.temp[i].issueDate = moment(new Date(this.temp[i].issueDate)).format('DD/MM/YYYY'); };
          if (this.temp[i].categoryDetail && this.temp[i].categoryDetail.length > 0) {
            for (let j = 0; j < this.temp[i].categoryDetail.length; j++) {
              this.temp[i].category.push({ name: this.temp[i].categoryDetail[j].name })

            }
          }
        }
      }
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  btnAddNewClick() {
    const dialogRef = this.dialog.open(ChecklistFormComponent, {
      data: {},
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  deleteTable(item) {
    const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
      data: { deleteItem: true },
      disableClose: false,
      hasBackdrop: true
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        this.UserService.checkChecklistIsExist(item._id).subscribe(data => {

          if (data && data.length > 0) {
            this.snackBar.open('Checklist exist in checklist mapping.', '', { duration: 4000 });
            return;
          }
          else {
            this.checklistService.deleteById(item).subscribe(res => {
              if (res[0]['statusBool']) {
                this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
              }
              this.ngOnInit();
            });
          }
          if (result === false) {
            this.ngOnInit();
          }
        });
      }
    });
  }

  getTable(row) {
    this.checklistService.getRecordById(row).subscribe(res => {
      const dialogRef = this.dialog.open(ChecklistFormComponent, {
        data: res[0]
      });
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });
    });
  }

  getTableForm2(row, formType) {
    this.checklistService.getRecordById(row).subscribe(res => {
      if (formType === 2) {
        if (res && res.length > 0) {
          res[0].formType = 'DOC';
        }
      }
      const dialogRef = this.dialog.open(ChecklistForm2Component, {
        data: res[0],
        width: '700px'
      });
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });
    });
  }

  exportExcel() {
    var data1 = [];
    var i = 1;
    this.temp1.forEach(element => {
      var val = {
        "Name": element.name,
        "Saic Number": element.saicNo,
        "Discipline": element.discipline,
        "Category": element.category,
        "Issue Date": element.issueDate
      };
      i = i + 1;
      data1.push(val);
    });
    this.excelService.saveAsExcelFile(data1, 'ChecklistMaster');
  }

  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.name = d.name ? d.name : '';
      d.saicNo = d.saicNo ? d.saicNo : '';
      d.discipline = d.discipline ? d.discipline : '';
      d.issueDate = d.issueDate ? d.issueDate : '';
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.saicNo.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.discipline.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.issueDate.toLowerCase().indexOf(val) !== -1 || !val)
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}