import { Component, EventEmitter, OnInit } from '@angular/core';
import { AppDashboardService } from './app-dashboard.service';
import { AuthService } from '../auth/auth.service';
// import { ObservableMedia } from '@angular/flex-layout';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/operator/startWith';
import * as d3 from 'd3';
// import { GridsterSettingsService } from './gridster-settings/gridster-settings.service';
// import { DisplayGrid, GridsterConfig, GridsterItem } from 'angular-gridster2';
import { WidgetListService } from './gridster-settings/widget-list.service';
import { UserService } from '../admin/user/user.service';
import { DashboardAddWidgetsComponent } from './dashboard-add-widgets/dashboard-add-widgets.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-dashboard',
  templateUrl: './app-dashboard.component.html',
  styleUrls: ['./app-dashboard.component.css']
})
export class AppDashboardComponent implements OnInit {

  currentPermissions: any;

  // options: GridsterConfig;
  filterDashboard = [];
  gridHeight: number;
  gridsterHeight: number;
  isgridheight: boolean;
  dashboard= [];
  resizeEvent: EventEmitter<any> = new EventEmitter<any>();
  user: any;
  loggedUserSubscription: any;
  
  constructor(
    private userService: UserService,
    // private rfiInspectionService: RfiInspectionService,
    public appDashboardService: AppDashboardService,
    // public gridsterSettingsService: GridsterSettingsService,
    private widgetListService: WidgetListService,
    private userServive: UserService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.dashboard = [];
    this.setGridSterHeight(1200);
    // this.options = this.gridsterSettingsService.getDashBoardOptions();
    // this.options.displayGrid = DisplayGrid.OnDragAndResize;
    // this.options.itemChangeCallback = (item) => {
    //   this.resizeEvent.emit(item);
    // };
    // this.options.itemResizeCallback = (item) => {
    //   this.resizeEvent.emit(item);
    // };
    this.getUser();
  }

  setGridSterHeight(data) {
    this.gridHeight = data || 1200;
    this.gridsterHeight = data || 1200;
  }

  getUser() {
    const userResponse = this.appDashboardService.getFromLocalStorage('userDet');
    this.user = userResponse.data;
    this.getAllWidgets();
  }

  getAllWidgets() {
    const componetList = this.widgetListService.getWidgestListOboject();
    this.userServive.getWidetsByuser(this.user._id).then((response) => {
      console.log('abc');
      console.log(response[0]);
      const widgetsData = response[0] || {};
      this.dashboard = [];
      this.setGridSterHeight(widgetsData.rowHeight);
      const widgets = widgetsData.widgets || [];
      widgets.forEach(element => {
        const data = { ...element };      
        console.log(data.componentName);  
        data.componentType = componetList[data.componentName];
        this.dashboard.push(data);
      });
      this.filterGridsterListData(this.dashboard);
    });
  }

  filterGridsterListData(data) {
    data = data || [];
    console.log('123');
    console.log(data);
    data = data.filter((element) => {
      return element.isActive && element.isActivatedUser && element.componentType && element.isShow;
    });
    this.filterDashboard = data;
    console.log(this.filterDashboard);
  }

  saveWidget() {
    const dashboard = this.postionMapeedDashboardData(this.dashboard);
    const data = {
      userId: this.user._id,
      widgets: dashboard,
      createdBy: this.user.name,
      rowHeight: this.gridsterHeight
    };
    this.userServive.updateWidgets(data).then((response) => {
      this.getAllWidgets();
    }, (err) => {
      console.log('updateWidgets failed');
    });
  }

  postionMapeedDashboardData(dashboard) {
    dashboard = dashboard || [];
    const filterDashboard = this.filterDashboard || [];
    dashboard = dashboard.map((e) => {
      const data = filterDashboard.find((element) => {
        return element.name === e.name && e.module === element.module;
      });
      if (data) {
        e = { ...data };
      }
      return e;
    });
    return dashboard;
  }

  openDialog() {
    let dashboard = [...this.dashboard];
    dashboard = this.postionMapeedDashboardData(dashboard);
    const dialogRef = this.dialog.open(DashboardAddWidgetsComponent, {
      width: '70%',
      data: dashboard
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      if (result) {
        result = result || [];
        const dashboardList = [...result];
        this.dashboard = [];
        dashboardList.forEach((element) => {
          this.dashboard.push({ ...element });
        });
        this.filterGridsterListData(this.dashboard);
      }
    });
  }

  onClickAddChartWidget() {
    this.openDialog();
  }

  removeWidget(widget) {
    const dashboard = this.dashboard;
    const i = this.filterDashboard.indexOf(widget);
    this.filterDashboard.splice(i, 1);
    const filterDashboard = this.filterDashboard.map(element => {
      return element;
    });
    this.filterDashboard = [];
    this.dashboard = [];
    // setTimeout(() => {
    //   this.filterDashboard = [...filterDashboard];
    //   dashboard.forEach((e) => {
    //     if (widget.name === e.name && widget.module === e.module) {
    //       e.isShow = false;
    //       e = this.gridsterSettingsService.resetRowValues(e);
    //     }
    //     this.dashboard.push({ ...e });
    //   });
    // }, 200);
  }


}
