import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { WidgetService } from '../../widget/widget.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-widget',
  templateUrl: './user-widget.component.html',
  styleUrls: ['./user-widget.component.css']
})
export class UserWidgetComponent implements OnInit {
  widgts: any[];
  userId: any;
  doFilter = false;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource;
  displayedColumns = ['module', 'name', 'componentName', 'description', 'active'];
  seletAll = false;
  constructor(private widgetService: WidgetService,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.userId = data.id;
      this.getWidgets();
    });
  }

  updateFilter(e) {
    this.dataSource.filter = e.target.value;
  }

  btnFilter() {
    this.doFilter = !this.doFilter;
    this.dataSource.filter = '';
  }

  seletAllWidgets(checked) {
    this.seletAll = checked;
    const widgets = this.dataSource.data || [];
    this.dataSource.data = widgets.map((e) => {
      e.isActivatedUser = checked;
      return e;
    });
  }

  getWidgets() {
    let userWidgets = [];
    this.widgetService.getRecord().then((reponse) => {
      this.getWidgetsByuser().then((result) => {
        if (result) {
          const widgetUser = result[0];
          if (widgetUser) {
            userWidgets = widgetUser.widgets || [];
          }
        }
        this.getMapWidgetData(reponse, userWidgets);
      });

    });
  }
  getMapWidgetData(reponse, widgetsUser) {
    if (reponse !== null) {
      const result = [];
      const data = reponse;
      data.forEach(function (row, index) {
        let mapdata = { ...row };
        if (row.isActive) {
          const el = widgetsUser.find(element => row.componentName === element.componentName &&
            row.componentName && row.module === element.module && row.name === element.name);
          if (el) {
            mapdata = { ...el };
          }
          result.push(mapdata);
        }
      });
      this.widgts = result;
    } else {
      this.widgts = [];
    }
    this.dataSource = new MatTableDataSource(this.widgts);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getWidgetsByuser() {
    const id = this.userId;
    return new Promise((reslove) => {
      this.userService.getWidetsByuser(id).then((reponse) => {
        reslove(reponse);
      });
    });

  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  async saveWidgets() {
    let user = this.getFromLocalStorage('userDet');
    user = user.data;
    const data = {
      userId: this.userId,
      widgets: this.dataSource.data,
      createdBy: user.name,
    };
    try {
      const response = await this.userService.updateWidgets(data);
      this.snackBar.open('widgets updated sucessfully', '', { duration: 2000, verticalPosition: "bottom" });
      this.router.navigate(['admin', 'userList']);
    } catch (e) {
      console.log(e);
      this.snackBar.open('widgets updation failed', '', { duration: 2000, verticalPosition: "bottom" });
    }
  }

}
