import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { RfiInspectionService } from '../rfi-inspection.service';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';

@Component({
  selector: 'app-rfi-inspection-equipment-popup',
  templateUrl: './rfi-inspection-equipment-popup.component.html',
  styleUrls: ['./rfi-inspection-equipment-popup.component.css']
})
export class RfiInspectionEquipmentPopupComponent implements OnInit {
  selectedRow: any;
  isOffline: boolean;

  constructor(private dialog: MatDialog, private router: Router, private rfiInspectionService: RfiInspectionService,
    private snackBar: MatSnackBar, private dialogRef: MatDialogRef<RfiInspectionEquipmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any, private offlineStorage: OfflineStorageService, 
    private ngZone:NgZone) {
        this.selectedRow = data;
    }

  ngOnInit() {
    let value = localStorage.getItem('isOffline');
    if (value == 'true') {
      this.isOffline = true;
    }
    else {
      this.isOffline = false;
    }
  }

  removeEquipment(){
    let tempRfiData = [];
    let data = {
      _id : this.selectedRow._id
    }

    if(this.isOffline == true){
      this.offlineStorage.rfiHandler.getRFIById(this, data, function (res) {
        let _this = res.self;
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let subData = JSON.parse(res.data.item(i).inspectionData);
            subData.equipment = {};
            tempRfiData.push(subData);
          }
        }

        _this.offlineStorage.rfiHandler.updateSelectedRFI(this, tempRfiData[0], function (res) {
          
          if (res) {
            _this.ngZone.run(() => {
            setTimeout(() => {
              _this.snackBar.open('Equipment data cleared','', {
                duration: 5000,
                verticalPosition: 'bottom',
              });
            }, 0);
          });
          }
          _this.dialogRef.close(true);
        });

      });
    }
    else{
        this.rfiInspectionService.removeRfiEquipment(data).subscribe(result => {
        this.snackBar.open('Equipment data cleared', '', { duration: 2000, verticalPosition: "bottom" });
        this.dialogRef.close(true);
      })
    }
  }

  btnClose() {
    this.dialogRef.close(false);
  }
}
