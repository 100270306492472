import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatPaginator,
  MatSort,
  MatSnackBar,
  MatTooltip,
  MatTableDataSource
}from '@angular/material';
import { ClientfeedbackService } from '../../clientfeedback/clientfeedback.service';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
//import { egretAnimations } from '../../../../shared/animations/egret-animations';

@Component({
  selector: 'app-clientfeedback-form',
  templateUrl: './clientfeedback-form.component.html',
  styleUrls: ['./clientfeedback-form.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ClientfeedbackFormComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  permissions = []; inputData: any;
  CategoryName = new FormControl('', [Validators.required]);
  description = new FormControl();
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumnslist = [
    'cate',
    'expriyDate'

  ];
  public getTemplateData: Subscription;
  templateData: any[];
  tempPer: any[];
  dataSource: any[];
  user: any;

  
  @Input('rating') public rating1: number = 0;
  @Input('rating') public rating2: number = 0;
  @Input('rating') public rating3: number = 0;
  @Input('rating') public rating4: number = 0;
  @Input('rating') public rating5: number = 0;
  @Input('rating') public rating6: number = 0;
  @Input('rating') public rating7: number = 0;
  @Input('rating') public rating8: number = 0;
  @Input('rating') public rating9: number = 0;
  @Input('rating') public rating10: number = 0;

  @Input('starCount') public starCount: number = 10;
  @Input('color') public color: string = 'accent';
  @Output() public ratingUpdated = new EventEmitter();
  public snackBarDuration: number = 2000;
  public ratingArr1 = [];
  public ratingArr2 = [];
  public ratingArr3 = [];
  public ratingArr4 = [];
  public ratingArr5 = [];
  public ratingArr6 = [];
  public ratingArr7 = [];
  public ratingArr8 = [];
  public ratingArr9 = [];
  public ratingArr10 = [];
  constructor(
    
    private fb: FormBuilder,

    @Inject(MAT_DIALOG_DATA) private data: any,
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private ClientFeedbackService: ClientfeedbackService,
    public dialogRef: MatDialogRef<ClientfeedbackFormComponent>
  ) {
  }
  ngOnInit() { 
  this.user = this.getFromLocalStorage('userDet');
  
  //this.isDisabled = false;
  this.getTemplatefeedback();
  if (this.data._id) {
    console.log(this.data)
    //this.bindData(this.data._id);
  }
  console.log("a "+this.starCount)
  
  for (let index = 0; index < this.starCount; index++) {
    this.ratingArr1.push(index);
    this.ratingArr2.push(index);
    this.ratingArr3.push(index);
    this.ratingArr4.push(index);
    this.ratingArr5.push(index);
    this.ratingArr6.push(index);
    this.ratingArr7.push(index);
    this.ratingArr8.push(index);
    this.ratingArr9.push(index);
    this.ratingArr10.push(index);

    console.log(index)
  }

  }
  
  getTemplatefeedback = () => {    
   this.ClientFeedbackService.getClientFeedback(this.data).subscribe(res => {  
         
    this.rating1=res.data[0].RFIRating[0].AvailabilityofRequestedEquipmentCrew;
    this.rating2=res.data[0].RFIRating[0].EquipmentConditionandPerformance;
    this.rating3=res.data[0].RFIRating[0].CrewProfessionalismandCompetency;
    this.rating4=res.data[0].RFIRating[0].AttitudeCommunication;
    this.rating5=res.data[0].RFIRating[0].DataQualityServiceReport;
    this.rating6=res.data[0].RFIRating[0].CompliancetoHSEQRequirements;
    this.rating7=res.data[0].RFIRating[0].CompletionofJobonTime;
    this.rating8=res.data[0].RFIRating[0].ManagementCommitmentResponse;
    this.rating9=res.data[0].RFIRating[0].Rectifyingdefectspromptlyandtoyoursatisfaction;
    this.rating10=res.data[0].RFIRating[0].OverallJobPerformance;
      //this.templateData = data.data.RFIRating;
      // if (this.permissions.length > 0) {
       
      // }
    });
  }
  showTemplate = () => {
  let temp = [];
  this.templateData = temp;
  this.tempPer = temp;
  this.dataSource = temp;
}
btnClose = () => {
  this.dialogRef.close();
}


  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  

  onClick(rating:number, row:number) {
    
    console.log(this.ratingArr1);
    this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
      duration: this.snackBarDuration
    });

    if(row===1)
    {
      this.ratingArr1.forEach(element => {
        if(element._id!=undefined && this.templateData.length > 0)
        {
          this.ratingUpdated.emit(rating);   
        }      
        this.rating1 = rating;  
        return false;
      });
    }
    else if(row===2)
    {
      this.ratingArr2.forEach(element => {
        if(element._id!=undefined && this.templateData.length > 0)
        {
          this.ratingUpdated.emit(rating);   
        }      
        this.rating2 = rating;  
        return false;
      });
    }
    else if(row===3)
    {
      this.ratingArr3.forEach(element => {
        if(element._id!=undefined && this.templateData.length > 0)
        {
          this.ratingUpdated.emit(rating);   
        }      
        this.rating3 = rating;  
        return false;
      });
    }
    else if(row===4)
    {
      this.ratingArr4.forEach(element => {
        if(element._id!=undefined && this.templateData.length > 0)
        {
          this.ratingUpdated.emit(rating);   
        }      
        this.rating4 = rating;  
        return false;
      });
    }
    else if(row===5)
    {
      this.ratingArr5.forEach(element => {
        if(element._id!=undefined && this.templateData.length > 0)
        {
          this.ratingUpdated.emit(rating);   
        }      
        this.rating5 = rating;  
        return false;
      });
    }
    else if(row===6)
    {
      this.ratingArr6.forEach(element => {
        if(element._id!=undefined && this.templateData.length > 0)
        {
          this.ratingUpdated.emit(rating);   
        }      
        this.rating6 = rating;  
        return false;
      });
    }
    else if(row===7)
    {
      this.ratingArr7.forEach(element => {
        if(element._id!=undefined && this.templateData.length > 0)
        {
          this.ratingUpdated.emit(rating);   
        }      
        this.rating7 = rating;  
        return false;
      });
    }
    else if(row===8)
    {
      this.ratingArr8.forEach(element => {
        if(element._id!=undefined && this.templateData.length > 0)
        {
          this.ratingUpdated.emit(rating);   
        }      
        this.rating8 = rating;  
        return false;
      });
    }
    else if(row===9)
    {
      this.ratingArr9.forEach(element => {
        if(element._id!=undefined && this.templateData.length > 0)
        {
          this.ratingUpdated.emit(rating);   
        }      
        this.rating9 = rating;  
        return false;
      });
    }
    else if(row===10)
    {
      this.ratingArr10.forEach(element => {
        if(element._id!=undefined && this.templateData.length > 0)
        {
          this.ratingUpdated.emit(rating);   
        }      
        this.rating10 = rating;  
        return false;
      });
    }
  }
  // showRatings() {
  //   this.templateData.forEach(element =>{
  //     if (element._id)
  //   })
  //   throw new Error('Method not implemented.');
  // }

  showIcon(index:number, row:number) {
    if(row===1)
    {
      if (this.rating1 >= index + 1) {
        return 'star';
      } else {
        return 'star_border';
      }
    }
    else if(row===2)
    {
      if (this.rating2 >= index + 1) {
        return 'star';
      } else {
        return 'star_border';
      }
    }
    else if(row===3)
    {
      if (this.rating3 >= index + 1) {
        return 'star';
      } else {
        return 'star_border';
      }
    }
    else if(row===4)
    {
      if (this.rating4 >= index + 1) {
        return 'star';
      } else {
        return 'star_border';
      }
    }
    else if(row===5)
    {
      if (this.rating5 >= index + 1) {
        return 'star';
      } else {
        return 'star_border';
      }
    }
    else if(row===6)
    {
      if (this.rating6 >= index + 1) {
        return 'star';
      } else {
        return 'star_border';
      }
    }
    else if(row===7)
    {
      if (this.rating7 >= index + 1) {
        return 'star';
      } else {
        return 'star_border';
      }
    }
    else if(row===8)
    {
      if (this.rating8 >= index + 1) {
        return 'star';
      } else {
        return 'star_border';
      }
    }
    else if(row===9)
    {
      if (this.rating9 >= index + 1) {
        return 'star';
      } else {
        return 'star_border';
      }
    }
    else if(row===10)
    {
      if (this.rating10 >= index + 1) {
        return 'star';
      } else {
        return 'star_border';
      }
    }
  }


  changeRead = (id, index, row) => {
    
    if(row===1)
    {
      if (this.ratingArr1 !== undefined && this.permissions.length > 0) {
        this.ratingArr1.forEach(element => {
          if (element._id === id) {          
            element.rating = index;         
          }
        });
      }
    }
    else if(row===2)
    {
      if (this.ratingArr2 !== undefined && this.permissions.length > 0) {
        this.ratingArr2.forEach(element => {
          if (element._id === id) {          
            element.rating = index;         
          }
        });
      }
    }
    else if(row===3)
    {
      if (this.ratingArr3 !== undefined && this.permissions.length > 0) {
        this.ratingArr3.forEach(element => {
          if (element._id === id) {          
            element.rating = index;         
          }
        });
      }
    }
    else if(row===4)
    {
      if (this.ratingArr4 !== undefined && this.permissions.length > 0) {
        this.ratingArr4.forEach(element => {
          if (element._id === id) {          
            element.rating = index;         
          }
        });
      }
    }
    else if(row===5)
    {
      if (this.ratingArr5 !== undefined && this.permissions.length > 0) {
        this.ratingArr5.forEach(element => {
          if (element._id === id) {          
            element.rating = index;         
          }
        });
      }
    }
    else if(row===6)
    {
      if (this.ratingArr6 !== undefined && this.permissions.length > 0) {
        this.ratingArr6.forEach(element => {
          if (element._id === id) {          
            element.rating = index;         
          }
        });
      }
    }
    else if(row===7)
    {
      if (this.ratingArr7 !== undefined && this.permissions.length > 0) {
        this.ratingArr7.forEach(element => {
          if (element._id === id) {          
            element.rating = index;         
          }
        });
      }
    }
    else if(row===8)
    {
      if (this.ratingArr8 !== undefined && this.permissions.length > 0) {
        this.ratingArr8.forEach(element => {
          if (element._id === id) {          
            element.rating = index;         
          }
        });
      }
    }
    else if(row===9)
    {
      if (this.ratingArr9 !== undefined && this.permissions.length > 0) {
        this.ratingArr9.forEach(element => {
          if (element._id === id) {          
            element.rating = index;         
          }
        });
      }
    }
    else if(row===10)
    {
      if (this.ratingArr10 !== undefined && this.permissions.length > 0) {
        this.ratingArr10.forEach(element => {
          if (element._id === id) {          
            element.rating = index;         
          }
        });
      }
    }
  }

  onRatingChanged(rating) {
    
    console.log(rating);
    this.rating1 = rating;
  }

  save() {
   console.log(this.data);
    this.inputData = {
      RFIId: this.data.data._id,
      RFIRating: [{
        AvailabilityofRequestedEquipmentCrew:this.rating1,
        EquipmentConditionandPerformance:this.rating2,
        CrewProfessionalismandCompetency:this.rating3,
        AttitudeCommunication:this.rating4,
        DataQualityServiceReport:this.rating5,
        CompliancetoHSEQRequirements:this.rating6,
        CompletionofJobonTime:this.rating7,
        ManagementCommitmentResponse:this.rating8,
        Rectifyingdefectspromptlyandtoyoursatisfaction:this.rating9,
        OverallJobPerformance:this.rating10
        
      }]
    };
    
      this.inputData.updatedBy = this.user.data.name;
      console.log(this.inputData);
      this.ClientFeedbackService.updateRFIRatings(this.inputData).subscribe(res => {
        console.log('sss');
          console.log(res['statusBool']);
        if (res['statusBool']) {
          
          this.snackBar.open(res['message'], '', {
            duration: this.snackBarDuration
          });
         
          this.dialogRef.close();
        }
      }, err => {
        if (err.error[0].statusHttp == 412) {
          this.snackBar.open(err.error[0]['message'], '', {
            duration: this.snackBarDuration
          });          
        }
      });
    
  }

}
export enum StarRatingColor {
  primary = "primary",
  accent = "accent",
  warn = "warn"
}





