import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting/setting.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class GeseedService {
  private apiUrl = '';  // URL to web api

  constructor(private http: HttpClient,
    private settingService:SettingService) {
  }

  getRecordBySeedName(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('geseed');
    const inputData = { data };
    return this.http.post<any[]>(`${this.apiUrl}/getRecordsBySeedName`, inputData, httpOptions)
      .pipe(
        tap()
      );
  }
  updateRecord(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('geseed');
    return this.http
      .post<any>(this.apiUrl + '/updateRecord', data, httpOptions)
      .pipe(tap());
  }
}