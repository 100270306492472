import { Component, Input, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
} from '@angular/material';
import { AuthService } from '../../../../auth/auth.service';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ScheduleInspectionService } from '../../../../master/scheduleInspection/schedule-inspection.service';
import * as moment from 'moment';

@Component({
  selector: 'app-audit-log-scheduled-inspection-form',
  templateUrl: './audit-log-scheduled-inspection-form.component.html',
  styleUrls: ['./audit-log-scheduled-inspection-form.component.css']
})
export class AuditLogScheduledInspectionFormComponent implements OnInit {
  
  selectedRow: any;
  additionalDetails: any;

  constructor(private dialog: MatDialog, private router: Router, private authService: AuthService,
    private ScheduleInspectionService: ScheduleInspectionService, @Inject(MAT_DIALOG_DATA) private data: any) 
    {
        this.selectedRow = data;
    }


  ngOnInit() {
    this.ScheduleInspectionService.getAllIsActiveRecords().subscribe(data => {
      if (!(data && this.selectedRow)) {
        return;
      }

      data.forEach(element => {
        if(element._id == (this.selectedRow._id || this.selectedRow.id)){
          this.additionalDetails = element.additionalDetails;
        }
      });
      this.dateFormat(this.additionalDetails);
    });
  }

  dateFormat(data){
    data.forEach(element => {
      element.inspectionDate = moment(element.inspectionDate).format('DD/MM/YYYY');
    });
  }

}
