import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-role-list',
  templateUrl: './user-role-list.component.html',
  styleUrls: ['./user-role-list.component.css']
})
export class UserRoleListComponent implements OnInit {
  user: any;
  permission: any;
  permissions: any;
  roles: any;
  constructor() { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.bindDummyRoles();
    /*
    this.rolesArr.push(this.user.permission);
    if(this.rolesArr.length>0) {
      this.rolesArr.forEach(element => {
        //if(element)
      });
    }
    */
  }

  bindDummyRoles = () => {
    /*
        this.permission = [{
          "id": 101,
          "name": "Victor",
          "address": "Mumbai",
          "order": [{
            "itemName": "Bulb",
            "quantity": 10
          },
          {
            "itemName": "Soap",
            "quantity": 3
          },
          {
            "itemName": "Towel",
            "quantity": 1
          }
          ]
        },
        {
          "id": 102,
          "name": "Francis",
          "address": "Nagpur",
          "order": [{
            "itemName": "Remote",
            "quantity": 1
          },
          {
            "itemName": "Bread",
            "quantity": 2
          }
          ]
        }
        ]
    */
    this.permission = [{
      name: 'hopper',
      read: true,
      write: true
    },
    {
      name: 'bin',
      read: false,
      write: false
    }];

    this.roles = [{
      roleName: 'support',
      module: [{
        name: 'hopper',
        read: true,
        write: true
      },
      {
        name: 'bin',
        read: false,
        write: false
      }]
    },
    {
      roleName: 'admin',
      module: [{
        name: 'hopper',
        read: false,
        write: false
      }, {
        name: 'bin',
        read: true,
        write: true
      }]
    }];
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
