import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SettingService } from '../../services/setting/setting.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  apiUrl: string;
  constructor(private http: HttpClient,
    private settingService:SettingService) {
  }

  createRecord(data: any) {
    this.apiUrl = this.settingService.getLocalStorageAppURL('widget');
    return this.http.post(this.apiUrl + '/dashboardSettings/create', data, httpOptions).toPromise();
  }
  getRecord() {
    this.apiUrl = this.settingService.getLocalStorageAppURL('widget');

    return this.http.get(this.apiUrl + '/dashboardSettings/getRecord', httpOptions).toPromise();
  }

  updateRecord(data: any) {
    this.apiUrl = this.settingService.getLocalStorageAppURL('widget');

    return this.http.post(this.apiUrl + '/dashboardSettings/update', data, httpOptions).toPromise();
  }
}
