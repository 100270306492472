import { Component, OnInit, NgZone, ViewChild, DebugElement } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ReportService } from '../../services/report/report.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../general/dateformat-form';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSnackBar, MatOption } from '@angular/material';
import * as moment from 'moment';
import { GeoptionService } from '../../services/geoption/geoption.service'
import * as _ from 'underscore';
import { environment } from '../../../environments/environment';
import { FileService } from '../../services/files/file.service';
import { ExcelService } from '../../excel.service';
import { UserService } from '../../admin/user/user.service';
import { TypeService } from '../../master/type/type.service';
import { RfiInspectionService } from '../../master/rfi-inspection/rfi-inspection.service';
import { PDFDocument } from 'pdf-lib'
@Component({
  selector: 'app-tracking-report',
  templateUrl: './tracking-report.component.html',
  styleUrls: ['./tracking-report.component.css']
})
export class TrackingReportComponent implements OnInit {
  reportForm: any;
  public response: any;
  public response1: any;
  public url: any;
  public safeSrc: SafeResourceUrl;
  src: any;
  image: any;
  showDownload = false;
  temp: any;
  companyData: any;
  countData: any;
  types: any;
  anotherTypes: any;
  typeName = '';
  inspectorList: any;
  anotherinspectorList: any;
  typeList: any;
  anothertypeList: any;
  statusList: any;
  filteredStatusList: any;
  isShow = false;
  @ViewChild('allSelectedComp', { static: true }) private allSelectedComp: MatOption;
  @ViewChild('allSelectedtype', { static: true }) private allSelectedtype: MatOption;
  @ViewChild('allSelectedStatus', { static: true }) private allSelectedStatus: MatOption;
  constructor(
    private fb: FormBuilder,
    private reportService: ReportService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private geoptionService: GeoptionService,
    private fileService: FileService,
    private excelService: ExcelService,
    private snackbar: MatSnackBar,
    private ngZone: NgZone,
    private inspectorService: UserService,
    private rfiInspectionService: RfiInspectionService,
    private typeService: TypeService
  ) {
    this.reportForm = fb.group({
      'fromDate': [null, Validators.compose([Validators.required])],
      'toDate': [null, Validators.compose([Validators.required])],
      'inspector': [null],
      'status': [null],


    });
  }

  ngOnInit() {
    this.getInspectors();
    this.getStatus();
  }

  get f() {
    return this.reportForm.controls;
  }



  tosslePerComp(all) {
    if (this.allSelectedComp.selected) {
      this.allSelectedComp.deselect();
      return false;
    }
    if (this.reportForm.controls.inspector.value.length === this.anotherinspectorList.length) {
      this.allSelectedComp.select();
    }

  }
  selectAllComp() {
    if (this.allSelectedComp.selected) {
      this.reportForm.controls.inspector
        .patchValue([...this.anotherinspectorList.map(item => item), 0]);
    } else {
      this.reportForm.controls.inspector.patchValue([]);
    }

  }

  getStatus() {
    this.rfiInspectionService.getAllStatus().subscribe(result => {
      this.statusList = result;
      this.filteredStatusList = this.statusList;
    });
  }

  selectAllStatus() {
    if (this.allSelectedStatus.selected) {
      this.reportForm.controls.status
        .patchValue([...this.filteredStatusList.map(item => item), 0]);
    } else {
      this.reportForm.controls.status.patchValue([]);
    }

  }
  getInspectors() {

    this.inspectorService.getInspectors().subscribe(result => {

      this.inspectorList = result;
      this.anotherinspectorList = this.inspectorList;
      // this.dropdownList=this.inspectorList;
    });
  }

  togglePerStatus() {
    if (this.allSelectedStatus.selected) {
      this.allSelectedStatus.deselect();
      return false;
    }
    if (this.reportForm.controls.status.value.length === this.filteredStatusList.length) {
      this.allSelectedStatus.select();
    }

  }
  getData() {
    this.showDownload = false;
    let fromDate = this.reportForm.controls.fromDate.value;
    let toDate = this.reportForm.controls.toDate.value;
    let exactDate = toDate;
    let inspector = this.reportForm.controls.inspector.value;
    let status = this.reportForm.controls.status.value;

    if (!fromDate) {
      this.snackBar.open('From date is required', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
    if (!toDate) {
      this.snackBar.open('To date is required', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
    toDate = toDate.setDate(toDate.getDate() + 1);
    toDate = new Date(toDate).toISOString();

    exactDate = exactDate.setDate(exactDate.getDate() - 1);
    exactDate = new Date(exactDate).toISOString();




    var data = {
      fromDate: fromDate,
      toDate: toDate,
      showDate: exactDate,
      inspector: inspector,
      status: status,
    }

    this.reportService.generateTrackingReportByInspectorInGrid(data).subscribe((res: any) => {
      if (res['statusBool']) {
        this.isShow = true;
        this.response = res.data.inspectionData || [];
        this.temp = res.data.inspectionData;
        this.companyData = res.data.reportData;
        this.countData = res.data.countData;

        this.showDownload = true;
        // const byteArray = new Uint8Array(atob(this.response).split('').map(char => char.charCodeAt(0)));
        // const blob = new Blob([byteArray], { type: 'application/pdf' });
        // this.url = window.URL.createObjectURL(blob);
        // this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        // this.src = this.url;
        // this.image = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      } else {
        this.isShow = true;

      }

    })
  }

  public downloadFn() {
    let fromDate = this.reportForm.controls.fromDate.value;
    let toDate = this.reportForm.controls.toDate.value;
    let exactDate = toDate;
    let inspector = this.reportForm.controls.inspector.value;
    let status = this.reportForm.controls.status.value;

    toDate = toDate.setDate(toDate.getDate() + 1);
    toDate = new Date(toDate).toISOString();

    exactDate = exactDate.setDate(exactDate.getDate() - 1);
    exactDate = new Date(exactDate).toISOString();

    var data = {
      fromDate: fromDate,
      toDate: toDate,
      showDate: exactDate,
      inspector: inspector,
      status: status,
    }

    this.reportService.generateTrackingReportByInspector(data).subscribe((res: any) => {
      this.response1 = res.data;
      var string1 = 'Tracking Report_';
      var string2 = moment(new Date(this.reportForm.controls.toDate.value)).format('DD_MM_YYYY');
      var fileName = string1 + string2 + '.pdf';

      var blob = this.fileService._base64ToArrayBuffer(this.response1, 'application/pdf');
      if (environment.mobileApp) {
        this.fileService.openFileOnMobile(blob, fileName, 'application/pdf')
      } else {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        } else {
          var objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
        }
      }
    });

  }

  exportExcel() {

    var data1 = [];
    var i = 1;
    this.companyData['trackingSystem'] = 'Tracking System';
    this.temp.forEach(element => {
      var val = {
        "SEQ no": i,
        "TEL no": element.tel,
        'Equipment owner': element.equOwner,
        'Equipment type': element.equType,
        'Equipment identification number': element.equIdNum,
        'Equipment serial number': element.equipmentSerialNumber,
        'Equipment location': element.equLoc,
        'Inspection type(P.I)': element.pi,
        'Inspection type(Re.I)': element.re,
        'Inspection type(I.I)': element.ii,
        'Load test(M)': element.m,
        'Load test(W)': element.w,
        'Load test(P)': element.p,
        'Inspection request received date': element.inspReqDate,
        'Inspection performed date': element.inspectionDate,
        'Next inspection due date': element.expiryDate,
        'Inspection result(P)': element.presult,
        'Inspection result(F)': element.fresult,
        'Inspector assigned': element.inspName,
        'Inspector certification number': element.certificateNo,
        'Sticker number': element.stickerNo,
        'QRCode': element.qrCode1,
        'Report number': element.reportNo,
        'RFI Number': element.inspectionSerialNumber,
      };
      i = i + 1;
      data1.push(val);
    });
    if (data1.length > 0) {
      this.excelService.saveAsExcelFileForTrackingByInspector(data1, this.companyData, this.countData, 'Tracking report');
    }
    else {
      this.ngZone.run(() => {
        setTimeout(() => {
          this.snackbar.open('No records found', '', {
            duration: 5000,
            verticalPosition: 'bottom',
          });
        }, 0);
      });
    }
    //}
  }

  async downloadReports(row) {
    this.rfiInspectionService.getReportsByRFIId(row).subscribe(async (res: any) => {
      if (res.length > 0) {
        const mergedPdf = await PDFDocument.create();
        for (const pdfCopyDoc of res) {
          if (pdfCopyDoc.content.includes('data:application')) {
            const pdfBytes = await this.base64ToArrayBuffer(pdfCopyDoc.content.split(',')[1]);
            var bytes = new Uint8Array(pdfBytes);
            const pdf = await PDFDocument.load(bytes);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => {
              mergedPdf.addPage(page);
            });
          }
        }
        const mergedPdfFile = await mergedPdf.save();
        this.downloadFile(mergedPdfFile);
      } else {
        this.snackbar.open('No records found.', '', {
          duration: 2000,
          verticalPosition: 'bottom',
        });
      }
    });
  }

  base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  downloadFile(data) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    if (environment.mobileApp) {
      this.fileService.openFileOnMobile(blob, 'Tracking Report', 'TrackingReport.pdf');
    } else {
      window.open(url);
    }

  }

}
