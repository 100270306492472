import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting/setting.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class ScheduleInspectionReportService {
  private apiUrl = '';  // URL to web api

  constructor(private http: HttpClient,
    private settingService: SettingService) {
  }

  save(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('scheduleReport');
    return this.http
      .post<any>(this.apiUrl + '/createRecord', data, httpOptions)
      .pipe(tap());
  }
  updateRecord(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('scheduleReport');
    return this.http
      .post<any>(this.apiUrl + '/updateRecord', data, httpOptions)
      .pipe(tap());
  }
  getScheduleReport(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('scheduleReport');
    return this.http
      .post<any>(this.apiUrl + '/getScheduleReport', data, httpOptions)
      .pipe(tap());
  }

  getResultByDate(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('scheduleReport');
    return this.http
      .post<any>(this.apiUrl + '/getResultByDate', data, httpOptions)

      .pipe(tap());
  }

  updateReceiverByScheduleID(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('scheduleReport');
    return this.http
      .post<any>(this.apiUrl + '/updateReceiverByScheduleID', data, httpOptions)

      .pipe(tap());
  }

  checkReceiverIsExist(receiverID: any): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('scheduleReport');
    return this.http
      .post<any>(
        this.apiUrl + '/checkReceiverIsExist',
        { "receiverID": receiverID },
        httpOptions
      )
      .pipe(
        tap()
      );
  }
}

