import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MatBottomSheet } from '@angular/material';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-app-url-form',
  templateUrl: './app-url-form.component.html',
  styleUrls: ['./app-url-form.component.css']
})
export class AppUrlFormComponent implements OnInit {

  settingURLForm: any;


  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AppUrlFormComponent>,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar

  ) {
    this.settingURLForm = fb.group({
      'appUrl': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    var localCacheData;
    localCacheData = localStorage.getItem('app_url');
    this.settingURLForm.patchValue({
      appUrl: localCacheData
    });
  }

  get f() {
    return this.settingURLForm.controls;
  }

  save() {
    let app_url = this.settingURLForm.controls.appUrl.value;
    localStorage.setItem('app_url', app_url);
    environment.apiURL = app_url;
    this.snackBar.open('Saved successfully', '', { duration: 2000, verticalPosition: "bottom" });
    this.dialogRef.close();
  }

  btnClose() {
    this.dialogRef.close();
  }
}

