import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { ChecklistService } from '../../../master/checklist/checklist.service';
import { RfiInspectionService } from '../../../master/rfi-inspection/rfi-inspection.service';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';
@Component({
  selector: 'app-rfi-inspection-checklist-popup',
  templateUrl: './rfi-inspection-checklist-popup.component.html',
  styleUrls: ['./rfi-inspection-checklist-popup.component.css']
})
export class RfiInspectionChecklistPopupComponent implements OnInit {
  checklistForm: any;
  checklists: any;
  anotherChecklists: any;
  data2: any;
  tempArray = [];
  isSave = false;
  isOffline: boolean;
  constructor(private fb: FormBuilder,
    private dialogRef: MatDialogRef<RfiInspectionChecklistPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private checklistService: ChecklistService,
    private rfiInspectionService: RfiInspectionService, private offlineStorage: OfflineStorageService,private ngZone:NgZone,
    private snackBar: MatSnackBar,) {
    this.checklistForm = fb.group({
      'checklist': [null]
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }
  ngOnInit() {
    let value = localStorage.getItem('isOffline');
    if (value == 'true') {
      this.isOffline = true;
    }
    else {
      this.isOffline = false;
    }
    var tempRfiData = [];
    var tempChecklistData = [];
    if (this.data) {
      if (this.data.data) {
        if (this.data.data.category) {
          if (this.data.data.category.ref) {
            let data = {
              _id: this.data.data.category.ref
            }
            var data1 = {
              _id: this.data.data._id
            }
            if (this.isOffline == true) {
              this.offlineStorage.rfiHandler.getRFIById(this, data1, function (res) {
                let _this = res.self;
                if (res && res.data && res.data.length > 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    tempRfiData.push(JSON.parse(res.data.item(i).inspectionData));
                  }
                }
                _this.data2 = tempRfiData[0].checkListDetails
              });
              this.offlineStorage.checklisthandler.getChecklists(this, function (res) {
                let _this = res.self;
                var checklistData = [];
                if (res && res.data && res.data.length > 0) {
                  for (let i = 0; i < res.data.length; i++) {
                    checklistData.push(JSON.parse(res.data.item(i).checklistData));
                  }
                }
                if (checklistData && checklistData.length > 0) {
                  for (var k = 0; k < checklistData.length; k++) {
                    if (checklistData[k].category) {
                      for (var m = 0; m < checklistData[k].category.length; m++) {
                        if (checklistData[k].category[m].ref === _this.data.data.category.ref) {
                          tempChecklistData.push(checklistData[k])
                        }

                      }


                    }

                  }
                }
                if (tempChecklistData) {
                  _this.checklists = tempChecklistData;
                  if (_this.data2.length > 0) {
                    for (var j = 0; j < _this.data2.length; j++) {
                      for (var i = _this.checklists.length - 1; i >= 0; i--) {

                        if (_this.checklists[i]._id === _this.data2[j].checklist.ref) {
                          _this.checklists.splice(i, 1);
                          _this.anotherChecklists = _this.checklists
                        }
                      }
                    }
                  } else {
                    _this.anotherChecklists = tempChecklistData;

                  }


                }

              });
            } else {
              this.rfiInspectionService.getRFIById(data1).subscribe(data => {
                if (data) {
                  if (data.length > 0) {
                    this.data2 = data[0].checkListDetails
                  }
                }
              });
              this.checklistService.checkCategoryIsExist(this.data.data.category.ref).subscribe(result => {
                if (result) {
                  this.checklists = result;
                  if (this.data2.length > 0) {
                    for (var i = this.checklists.length - 1; i >= 0; i--) {
                      for (var j = 0; j < this.data2.length; j++) {
                        if (this.checklists[i]._id === this.data2[j].checklist.ref) {
                          this.checklists.splice(i, 1);
                          this.anotherChecklists = this.checklists
                        }
                      }
                    }
                  } else {
                    this.anotherChecklists = result;

                  }


                }
              });
            }
          }
        }
      }
    }
  }
  get f() {
    return this.checklistForm.controls;
  }

  filterChecklist(val) {
    val = val.toLowerCase();
    this.anotherChecklists = this.checklists.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  save() {
    var data = {
      checklistID: this.checklistForm.controls.checklist.value,
      rfiID: this.data.data._id,
      checklistDetails: this.data2
    }
    var finalArr = [];
    if (this.isOffline == true) {
      this.offlineStorage.checklisthandler.getChecklists(this, function (res) {
        let _this = res.self;
        var checklistData = [];
        if (res && res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            checklistData.push(JSON.parse(res.data.item(i).checklistData));
          }
        }
        for (var l = 0; l < checklistData.length; l++) {
          if (data.checklistID && data.checklistID.length > 0) {
            for (var n = 0; n < data.checklistID.length; n++) {
              if (checklistData[l]._id === data.checklistID[n]) {
                _this.getExistingChecklist(checklistData[l], data, n, finalArr)
              }
            }
          }
        }
      });
    } else {


      this.rfiInspectionService.updateChecklistById(data).subscribe(result => {
        this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        this.dialogRef.close();
      })
    }
  }
  getExistingChecklist(result, data, n, finalArr) {
    var checkListDetails = [];
    var checkListDetails1 = [];
    var checklist = [];
    var checkListDetails = [];
    var categoryDetails = [];
    var tempReferenceDocumntsList = [];



    if (result && result !== undefined && result !== null) {
      checkListDetails1.push({ 'ref': result._id, 'name': result.name })
      for (var j = 0; j < result.categoryDetails.length; j++) {
        categoryDetails.push(result.categoryDetails[j])
      }
      for (var m = 0; m < result.referenceDocuments.length; m++) {
        tempReferenceDocumntsList.push({ 'ref': result.referenceDocuments[m]._id, 'name': result.referenceDocuments[m].name })
      }
      var obj = [];
      obj.push({
        "ref": checkListDetails1[0].ref, "name": checkListDetails1[0].name,
        categoryDetails: categoryDetails,
        referenceDocuments: tempReferenceDocumntsList
      });
      checklist = obj[0];
      checkListDetails.push({ 'checklist': checklist })


    }

    checkListDetails = checkListDetails[0];
    finalArr.push(checkListDetails)
    if (n + 1 == finalArr.length) {
      this.updateChecklistDetails(data, finalArr);
    }
  }
  updateChecklistDetails(data, finalArr) {
    var finalData = [];
    for (var k = 0; k < data.checklistDetails.length; k++) {
      finalArr.push(data.checklistDetails[k])
    }
    var data1 = {
      _id: data.rfiID,
    }
    this.offlineStorage.rfiHandler.getRFIById(this, data1, function (res) {
      let _this = res.self;

      if (res && res.data && res.data.length > 0) {
        for (let i = 0; i < res.data.length; i++) {
          finalData.push(JSON.parse(res.data.item(i).inspectionData));
        }
        _this.bindValue(finalData, finalArr)

      }

    });

  }
  bindValue(finalData, finalArr) {
    finalData[0].checkListDetails = finalArr;
    this.offlineStorage.rfiHandler.updateSelectedRFI(this, finalData[0], function (res) {
      let _this = res.self;
      _this.ngZone.run(() => {
        setTimeout(() => {
          _this.snackBar.open('Updated successfully','', {
            duration: 5000,
            verticalPosition: 'bottom',
          });
        }, 0);
      });
      // _this.snackBar.open('Updated successfully', '', { duration: 2000, verticalPosition: "bottom" });
      _this.dialogRef.close();
    });
  }
  btnClose() {
    this.dialogRef.close();
  }


}
