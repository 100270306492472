import { Component, OnInit, ViewChild,ElementRef } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet
} from "@angular/material";
import { RoleManagementService } from '../role-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDeleteComponent } from '../../../master/confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-role-management-view',
  templateUrl: './role-management-view.component.html',
  styleUrls: ['./role-management-view.component.css']
})
export class RoleManagementViewComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild('search',{static:false}) searchElement: ElementRef;
  dataSource; user: any;

  displayedColumns = ["edit", "name", "description",  "createdBy", "createdDate", "updatedBy", "updatedDate"];

  temp = [];
  temp1 = [];
  doFilter: Boolean;
  permission: any;
  isWrite = false;
  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private RoleManagementService: RoleManagementService,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;

    if (this.permission && this.permission.admin && this.permission.admin.role && this.permission.admin.role.write) {
      this.isWrite = true;
    } else {
      this.displayedColumns = ["name", "description",  "createdBy", "createdDate", "updatedBy", "updatedDate"];
      
    }
    this.RoleManagementService.getRecord().subscribe(results => {
      if(!results) {
        return
      }
this.temp=results;
this.temp1=this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  btnAddNewClick=()=> {
    this.router.navigateByUrl('role/create');
  }

  getTable =(row) => {
    this.router.navigateByUrl('role/edit/'+row._id);
  }

  deleteTable=(item)=>{
    let bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
      data: { deleteItem: true },
      disableClose: false,
      hasBackdrop: false
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        item.isActive = false;
        item.updatedBy = this.user.data.name;
        this.RoleManagementService.updateRecord(item).subscribe(res => {
          this.ngOnInit();
        });
      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  
  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.name = d.name ? d.name : '';
      d.description = d.description ? d.description : '';
      d.createdBy = d.createdBy ? d.createdBy : '';
      d.createdDate = d.createdDate ? d.createdDate : '';
      d.updatedBy = d.updatedBy ? d.updatedBy : '';
      d.updatedDate = d.updatedDate ? d.updatedDate : '';
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.description.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedDate.toLowerCase().indexOf(val) !== -1 || !val);
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

}
