import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppNavigationComponent } from './app-navigation/app-navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CordovaService } from './cordova.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material'; 

import { StyleUtils, StylesheetMap, MediaMarshaller, ɵMatchMedia, BreakPointRegistry, PrintHook, LayoutStyleBuilder, FlexStyleBuilder, ShowHideStyleBuilder, FlexOrderStyleBuilder } from "@angular/flex-layout";
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatFormFieldModule
} from '@angular/material';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { MasterModule } from './master/master.module';
// import { GoodsModule } from './goods/goods.module';
// import { OperationModule } from './operation/operation.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
// import { ReportsModule } from './reports/reports.module';
import { LoginComponent } from './auth/login/login.component';
import { MobileModule } from './mobile/mobile.module';
import { AppDashboardModule } from './app-dashboard/app-dashboard.module';
import { AdminModule } from './admin/admin.module';
import { SpinnerComponent } from './general/spinner/spinner.component';
import { InspectionPopupComponent } from './general/inspection-popup/inspection-popup.component';
import { WeeklyReportComponent } from './reports/weekly-report/weekly-report.component';
import { OfflineInspectionComponent } from './inspections/offline-inspection/offline-inspection.component';
import { RfiInspectionListComponent } from './master/rfi-inspection/rfi-inspection-list/rfi-inspection-list.component';
import { ScheduledRfidataPopupComponent } from './inspections/scheduled-rfidata-popup/scheduled-rfidata-popup.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InternalEntryReportComponent } from './reports/internal-entry-report/internal-entry-report.component';
import { InternalEntryReportFormComponent } from './reports/internal-entry-report-form/internal-entry-report-form.component';
import { CommandPopupComponent } from './general/command-popup/command-popup.component';
import { OfflineDeleteDialogComponent } from './general/offline-delete-dialog/offline-delete-dialog.component';
import { HttpConfigInterceptor } from './services/interceptor/httpconfig.interceptor';
import { ReceiverDetailDialogComponent } from './general/receiver-detail-dialog/receiver-detail-dialog.component';
import { CustomerFeedbackEvaluationReportComponent } from './reports/customer-feedback-evaluation-report/customer-feedback-evaluation-report.component';
import { WeeklyReportByInspectorComponent } from './reports/weekly-report-by-inspector/weekly-report-by-inspector.component';
import { ConfirmationDialogComponent } from './general/confirmation-dialog/confirmation-dialog.component';
import { BackButtonDialogComponent } from './general/back-button-dialog/back-button-dialog.component';
import { AppComfirmComponent } from './general/app-confirm/app-confirm.component';
import { AppConfirmService } from './general/app-confirm/app-confirm.service';
import { TrackingReportComponent } from './reports/tracking-report/tracking-report.component';
import { NotesPopupComponent } from './general/notes-popup/notes-popup.component';
@NgModule({
  declarations: [AppComponent, AppNavigationComponent, LoginComponent, SpinnerComponent, InspectionPopupComponent, WeeklyReportComponent, InternalEntryReportComponent, InternalEntryReportFormComponent,
    RfiInspectionListComponent, OfflineInspectionComponent, ScheduledRfidataPopupComponent, CommandPopupComponent, OfflineDeleteDialogComponent, ReceiverDetailDialogComponent, CustomerFeedbackEvaluationReportComponent, WeeklyReportByInspectorComponent, ConfirmationDialogComponent, BackButtonDialogComponent, AppComfirmComponent, TrackingReportComponent, NotesPopupComponent],

  imports: [ 
    BrowserModule,
    BrowserAnimationsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatButtonModule,
    MatCheckboxModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    AppRoutingModule,
    MasterModule,
    MatSnackBarModule,
    MatRadioModule,
    FlexLayoutModule,
    HttpClientModule,
    // GoodsModule,
    // OperationModule,
    AuthModule,
    MobileModule,
    AppDashboardModule,
    AdminModule,
    PdfViewerModule,
    // ReportsModule,
    NgxDaterangepickerMd
  ],
  providers: [CordovaService, StyleUtils, StylesheetMap, MediaMarshaller, ɵMatchMedia, BreakPointRegistry, PrintHook, LayoutStyleBuilder, FlexStyleBuilder, ShowHideStyleBuilder, FlexOrderStyleBuilder, AppConfirmService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }],
  bootstrap: [AppComponent],
  entryComponents: [InspectionPopupComponent, InternalEntryReportFormComponent, CommandPopupComponent, OfflineDeleteDialogComponent, ReceiverDetailDialogComponent, CustomerFeedbackEvaluationReportComponent, ConfirmationDialogComponent, BackButtonDialogComponent, AppComfirmComponent,NotesPopupComponent]
})
export class AppModule { }
