import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { RfiInspectionService } from '../../master/rfi-inspection/rfi-inspection.service';

@Component({
  selector: 'app-inspection-popup',
  templateUrl: './inspection-popup.component.html',
  styleUrls: ['./inspection-popup.component.css']
})
export class InspectionPopupComponent implements OnInit {

  isDisable = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<InspectionPopupComponent>,
    private rfiInspectionService: RfiInspectionService,
    private snackBar: MatSnackBar,

  ) { }

  ngOnInit() {

  }

  approveInspection() {
    this.rfiInspectionService.approveForInspection(this.data).subscribe(result => {
      if (result['statusBool']) {
        this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        this.isDisable = true;
        this.dialogRef.close();
      } else {
        this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        this.isDisable = true;
      }
    }, err => {
      if (err.error.statusHttp === 412) {
        this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
        this.isDisable = true;
      }
    });
  }

  btnClose = () => {
    this.dialogRef.close(false);
  }

}

