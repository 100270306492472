import { Component, ElementRef, Input, OnInit,ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { AuditLogService } from '../audit-log.service';
import { ScheduleInspectionService } from '../../../master/scheduleInspection/schedule-inspection.service';
import { AuditLogScheduledInspectionFormComponent } from '../audit-log-list/audit-log-scheduled-inspection-form/audit-log-scheduled-inspection-form.component';
import { AuditLogChecklistComponent } from '../audit-log-checklist/audit-log-checklist.component';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MatBottomSheet, MatSnackBar } from '@angular/material';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-audit-log-list',
  templateUrl: './audit-log-list.component.html',
  styleUrls: ['./audit-log-list.component.css']
})
export class AuditLogListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;

  title = 'angular8chartjs';
  toSearch: any;
  isShow = true;
  user: any;
  canvas: any;
  barChart: any;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  alwaysShowCalendars: boolean;
  usersData: any;
  users: any;
  dateRange = new FormControl('');
  inspector = new FormControl('');
  module = new FormControl('');
  action = new FormControl('');
  dataSource;
  list1 = 0;
  list2 = 0;
  inspectorColumn = false;
  date = new Date();
  a = moment().startOf('day').subtract(1, 'month');
  b = moment().endOf('day');

  actions = [{ value: 'A', name: 'All' }, { value: 'I', name: 'Insert only' }, { value: 'U', name: 'Update only' }, { value: 'D', name: 'Deleted only' }]
  modules: any;
  filteredModules: any;
  isButton: Boolean = false;
  width: string;
  pageName: string;
  displayedColumns = [];
  displayName = [];
  checklistPage = "Master - Inspection Checklist";
  inspectionPage = "Inspections - Schedule Inspection";
  status: Boolean = false;
  temp = [];
  temp1 = [];
  doFilter: Boolean;

  constructor( private fb: FormBuilder, private auditLogService: AuditLogService, 
    private dialog: MatDialog, private ScheduleInspectionService: ScheduleInspectionService) {

  }

  ngOnInit() {
    this.dateRange.setValue({
      start: moment(this.b).format('MM/DD/YYYY'),
      end: moment(this.b).format('MM/DD/YYYY')
    });
    this.action.setValue(this.actions[0]);
    this.getAllModules();
  }

  getAllModules() {
    this.auditLogService.getAuditTables().subscribe(results => {
      if (results['statusBool']) {
        if (results['data'].length > 0) {
          this.modules = results['data'];
          this.filteredModules = this.modules;
          this.module.setValue(this.modules[0]);
          this.getResults();
        }
      }
    })
  }

  filterModule(val) {
    val = val.toLowerCase();

    this.filteredModules = this.modules.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }

  getResults() {
    this.displayedColumns = [];
    this.displayName = [];
    var inputData = {
      page: this.module.value,
      date: {
        fromDate: this.dateRange.value.start,
        toDate: this.dateRange.value.end
      },
      action: this.action.value
    }

    // check popup screen is available for this audit log page
    if(this.module && this.module.value){
      if(this.module.value.isButton){
        this.isButton = this.module.value.isButton;
        this.pageName = this.module.value.name;
      }
      else{
        this.isButton = false;
        this.pageName = "";
      }
      
      if(this.module.value.width){
        this.width = this.module.value.width;
      }
      else{
        this.width = "";
      }
    }
    //----END----//

    if(this.pageName && (this.pageName == this.checklistPage || this.pageName == this.inspectionPage)){
      this.status = true;
    }
    else{
      this.status = false
    }

    this.auditLogService.getAudit(inputData).subscribe(result => {
      if (result['statusBool']) {
        this.temp = result['data'].data;
        if(this.temp.length > 0){
          this.removeDuplicate(this.temp);
          this.checkUpdateDetails(this.temp);
          this.dateFormat(this.temp);
        }
        if (result['data'].colDef && result['data'].colDef.length > 0) {
          this.displayName = result['data'].colDef;
          this.temp1 = this.temp;
          this.dataSource = new MatTableDataSource(this.temp);
          this.displayedColumns = this.displayName.map(c => c.field);
        }
      }
    })
  }

  removeDuplicate(data){
    let index = [];
    for(let i = 0; i < data.length; i++){
      if(data[i].mode == "Delete"){
        for(let j = i+1; j < data.length; j++){
          if((data[j].mode == "Delete") && (data[i]._id == data[j]._id))
            index.push(j);
        }
      }
    }
    for(let i = 0; i < index.length; i++){
      this.temp.splice((index[i]-i),1);
    }
  }

  checkUpdateDetails(auditData){
    if(this.pageName == this.checklistPage){
      for(let i = 0; i < auditData.length; i++){
        if((auditData[i].mode == "Update") && !auditData[i].name){
          for(let j = 0; j < auditData.length; j++){
            if((i!=j) && auditData[j].name && ((auditData[i]._id || auditData[i].id) == (auditData[j]._id || auditData[j].id))){
              if(!auditData[i].name){
                auditData[i]['name'] = auditData[i]['name'] ? auditData[i]['name'] : auditData[j].name;
                auditData[i]['saicNo'] = auditData[i]['saicNo'] ? auditData[i]['saicNo'] : auditData[j].saicNo;
                auditData[i]['category'] = auditData[i]['category'] ? auditData[i]['category'] : auditData[j].category;
                auditData[i]['discipline'] = auditData[i]['discipline'] ? auditData[i]['discipline'] : auditData[j].discipline;
                auditData[i]['issueDate'] = auditData[i]['issueDate'] ? auditData[i]['issueDate'] : auditData[j].issueDate;
                auditData[i]['isChanged'] = true;
              }
            }
          }
        }
      }
    }
    else if(this.pageName == this.inspectionPage){
      for(let i = 0; i < auditData.length; i++){
        if(auditData[i].mode == "Update"){
          for(let j = i+1; j < auditData.length; j++){
            if((i!=j) && auditData[j].mode == "Update" && ((auditData[i]._id || auditData[i].id) == (auditData[j]._id || auditData[j].id))){
              if((auditData[i].additionalDetails.length > 0) && (auditData[j].additionalDetails.length > 0)){
                if(auditData[i].additionalDetails.length != auditData[j].additionalDetails.length){
                    auditData[j]['isChanged'] = true;
                    j = auditData.length;
                }
                else{
                  for(let k = 0; k < auditData[i].additionalDetails.length; k++){
                    let array1 = auditData[i].additionalDetails[k];
                    let array2 = auditData[j].additionalDetails[k];
                    if((array1.category.categoryName != array2.category.categoryName) ||
                        (array1.inspector.inspectorName != array2.inspector.inspectorName) ||
                        (array1.quantity != array2.quantity) ||
                        (array1.inspectionDate != array2.inspectionDate)){
                            auditData[j]['isChanged'] = true; 
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  dateFormat(auditData){
    auditData.forEach(data => {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if((key != "updatedBy") && ((key.toLowerCase()).indexOf('date')!==-1)){
          let date = new Date(data[key]);
          data[key] = moment(date).format('DD/MM/YYYY');
        }
      })
    });
  }
  
  showDetails(row){
    if(this.isButton){
      if(this.pageName == "Master - Inspection Checklist" && row && row.mode == "Update" && row.isChanged){
        this.dialog.open(AuditLogChecklistComponent, {
          data: row,
          disableClose: false,
          hasBackdrop: true
        });
      }
      if(this.pageName == "Inspections - Schedule Inspection" && row && row.mode == "Update" && row.isChanged){
        this.dialog.open(AuditLogScheduledInspectionFormComponent, {
          data: row,
          disableClose: false,
          hasBackdrop: true
        });
      }
    }
  }

  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    let found = false;
    this.temp1 = this.temp.filter(function (data) {
      let keys = Object.keys(data);
      for(let i = 0; i < keys.length; i++){
        data[keys[i]] = data[keys[i]] ? data[keys[i]] : '';
        let keyValue = data[keys[i]];
        console.log(keyValue)
        if(keyValue.toString().toLowerCase().indexOf(val) !== -1 || !val)
          found = true;
      }
      return found
    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

}
