import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ForgetChangePasswordComponent } from './forget-change-password/forget-change-password.component';
import {
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatIconModule,
  MatSelectModule,
  MatOptionModule,
  MatDatepickerModule,
  MatBottomSheetModule,
  MatListModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatStepperModule,
  MatDialogModule,
  MatRadioModule,
  MatCardModule,
  MatToolbarModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AppUrlFormComponent } from './app-url-form/app-url-form.component';


@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatBottomSheetModule,
    MatListModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDialogModule,
    MatCardModule,
    MatToolbarModule,
    MatProgressSpinnerModule
  ],
  declarations: [ForgetPasswordComponent, ForgetChangePasswordComponent, ChangePasswordComponent, AppUrlFormComponent],
  entryComponents:[AppUrlFormComponent]
})
export class AuthModule { }
