import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ProjectService } from '../project.service';
import { ClientService } from '../../client/client.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource,
  MatRadioGroup, MatRadioButton
} from '@angular/material';

import { FormControl, Validators } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import * as _ from 'underscore';
import { GeoptionService } from '../../../services/geoption/geoption.service'
@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.css']
})
export class ProjectFormComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  inputData: any;
  user: any;
  clients:any;
  ClientData:any;
  projectNo = new FormControl('', [Validators.required]);
  projectName = new FormControl('', [Validators.required]);
  clientName = new FormControl('');
  type = new FormControl('');

  types:any;
  anotherTypes:any;
  _id: string;
  constructor(
    private dialog: MatDialog,
    private projectService: ProjectService,
    private clientService: ClientService,
    private geoptionService: GeoptionService,
    private dialogRef: MatDialogRef<ProjectFormComponent>,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    if (Object.keys(this.data).length !== 0) {
      this.projectNo.setValue(this.data.projectNo);
      this.projectName.setValue(this.data.projectName);
      this.clientName.setValue(this.data.client.ref);
      if(this.data.type){
        if(this.data.type.ref){
          this.type.setValue(this.data.type.ref);
        }
      }
    }else{
      this.data;

    }
    this.getClient();
    this.getTypes();
  }
  getClient(){
  this.clientService.getClient().subscribe(data => {
    this.clients=data;
    this.ClientData=this.clients;
  });
}
  save(
    
    projectNo: String,
    projectName: String,
    clientName: String,
    type:String
  ): void {
    if (!projectNo || !projectName) {
      return;
    }
    var selectedClient=[];
    selectedClient = _.filter(this.clients, ele => { return ele._id == this.clientName.value });
    var name = selectedClient[0].name;
    var id = selectedClient[0]._id;

    var selectedType=[];
    selectedType = _.filter(this.types, ele => { return ele._id == this.type.value });
    var typeName = selectedType[0].optionValue;
    var typeId = selectedType[0]._id;
    this.inputData = {
      
      projectNo: projectNo,
      projectName: projectName,
      clientName: clientName,
      // type :type
    };
    this.inputData.clientName = name;
    this.inputData.client = id;
    this.inputData.type=typeName;
    this.inputData.typeId=typeId;
    if (Object.keys(this.data).length !== 0) {
      this.inputData._id = this.data._id;
      this.inputData.updatedBy = this.user.data.name;
      this.projectService.updateRecord(this.inputData).subscribe(res => {
        if (res[0]['statusBool']) {
          this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } 
      }, err => {
        if (err.error[0].statusHttp == 412) {
          this.snackBar.open(err.error[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else {
      this.inputData.createdBy = this.user.data.name;
      this.projectService.save(this.inputData).subscribe(res => {
        if (res['statusBool']) {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } 
      }, err => {
        if (err.error.statusHttp == 412) {
          this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }
  }

  getErrorProjectNo() {
    return this.projectNo.hasError('required')
      ? 'Project No is required'
      : '';
  }
  
  getErrorProjectName() {
    return this.projectName.hasError('required')
      ? 'Project name is required'
      : '';
  }
  getTypes() {

    this.geoptionService.getRecordByOptionName('ContractType').subscribe(res => {
      this.types = res[0].option;
      this.anotherTypes= res[0].option;
    });
  }
  btnClose() {
    if (
      this.projectNo.dirty ||
      this.projectName.dirty ||
      this.clientName.dirty
    ) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: false,
        hasBackdrop: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  filterType(val) {
    val = val.toLowerCase();
    this.ClientData = this.clients.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
}
