import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { ScheduleInspectionService } from '../schedule-inspection.service';
import { ProjectService } from '../../../master/project/project.service';
import { UserService } from '../../../admin/user/user.service';
import { EquipmentService } from '../../../master/equipment/equipment.service';
import { GeseedService } from '../../../services/geseed/geseed.service';
import { CategoryService } from '../../../master/category/category.service';
import { SiteLocationService } from '../../../master/siteLocation/site-location.service';
import * as _ from 'underscore';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { filter } from 'lodash';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../general/dateformat-form';
import { GeoptionService } from '../../../services/geoption/geoption.service';
import { SettingService } from '../../../services/setting/setting.service';
import { ReceiverFormComponent } from '../../receiver/receiver-form/receiver-form.component';
import { SelectReceiverComponent } from '../../scheduleInspection/select-receiver/select-receiver.component';
import { ReceiverService } from '../../receiver/receiver.service';
import { ReceiverDetailDialogComponent } from '../../../general/receiver-detail-dialog/receiver-detail-dialog.component';
@Component({
  selector: 'app-schedule-inspection-form',
  templateUrl: './schedule-inspection-form.component.html',
  styleUrls: ['./schedule-inspection-form.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class ScheduleInspectionFormComponent implements OnInit {
  @ViewChild('filterText', { static: false }) filterValue: ElementRef;

  scheduleInspectorForm: any;
  inputData: any;
  projects: any;
  anotherProjects: any;
  inspector: any;
  anotherInspector: any;
  equipment: any;
  anotherEquipment: any;
  email: any;
  anothertypes: any;
  user: any;
  startingNo: any;
  len: any;
  prefix: any;
  key: any;
  RptNo: any;
  inspections: string[] = ['NA', 'P.I. - Periodic inspection', 'Re.I. - Reinspection', 'I.I. - Initial inspection'];
  loadTests: string[] = ['NA', 'M - Manufacturer', 'W - Witness', 'P - Perform'];
  types: string[] = ['On-Shore', 'Off-Shore'];
  siteLocation: Boolean;
  anotherLocations: any;
  locations: any;
  // For Save
  projectName = null;
  projectNumber = null;
  inspectorName = null;
  equipmentNumber = null;
  typeName = null;
  locationName = null;
  rowId = null;
  isSave = false;
  isUpdate = false;
  categoryName = null;
  categories: any;
  additionalDetails = [];
  categoty = null;
  insector = null;
  selectedVal = 'On-Shore';
  isApproved = false;
  groupName = '';
  controlReportNo = '';
  costCode = '';

  currentYear: any;
  currentDate = new Date()
  suffix: any;
  seperator: any;
  isAramcoDirect = true;
  isAramcoContract = false;
  poString = 'RPO number';
  companyName: any;
  receiverId: any;

  isGroupNameColumn = false;
  isControlReportNoColumn = false;
  isCostCodeColumn = false;
  isinspectorschedulevalidation = false;
  isRequesterDateRequired = false;
  requesterDateLabelName = "Requester Date";
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ScheduleInspectionService>,
    private projectService: ProjectService,
    private inspectorsService: UserService,
    private EquipmentService: EquipmentService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private ScheduleInspectionService: ScheduleInspectionService,
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private geseedService: GeseedService,
    private categoryService: CategoryService,
    private siteLocationService: SiteLocationService, private dialog: MatDialog, private receiverService: ReceiverService,
    private geoptionService: GeoptionService, private settingService: SettingService
  ) {
    this.scheduleInspectorForm = fb.group({
      'project': [null, Validators.compose([Validators.required])],
      'reqEmail': [null, Validators.compose([Validators.email, Validators.required])],
      'reqTelephone': [null],
      'reqMobile': [null],
      'location': [null, Validators.compose([Validators.required])],
      'inspectionType': [null],
      'loadTest': [null],
      'cerName': [null],
      'cerMobile': [null],
      'cerBatchNo': [null],
      'cerTelephone': [null],
      'cerEmail': [null, Validators.compose([Validators.email])],
      'verName': [null],
      'verMobile': [null],
      'verBatchNo': [null],
      'verTelephone': [null],
      'verEmail': [null, Validators.compose([Validators.email])],
      'rpoNo': [null],
      'woNo': [null],
      'cntrlReportNo': [null],
      'siteLocationType': [false],
      // 'inspectionDate': [null, Validators.compose([Validators.required])],
      'groupName': [null],
      'costCode': [null],
      'siteContactPersonName': [null],
      'siteContactPersonContactNo': [null],
      "reqName": [null, Validators.compose([Validators.required])],
      // "technicalReviewerName": [null, Validators.compose([Validators.required])],
      // "technicalReviewerBatchNo": [null, Validators.compose([Validators.required])],
      // "technicalReviewerEmail": [null, Validators.compose([Validators.email, Validators.required])],
      // "technicalReviewerMobile": [null],
      "reqDate": [null]
    });
  }

  getValues() {
    this.geseedService.getRecordBySeedName('Inspection').subscribe(res => {
      this.prefix = res[0].seedConfig.prefix;
      this.suffix = res[0].seedConfig.suffix;
      this.seperator = res[0].seedConfig.seperator;
      this.currentYear = this.currentDate.getFullYear();
      this.startingNo = res[0].seedConfig.nextValue;
      this.len = res[0].seedConfig.minValLen;
      this.key = this.pad(this.startingNo, this.len);
      this.RptNo = this.prefix + this.seperator + this.suffix + this.seperator + this.key;
    });
  }

  pad(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  checkType() {
    var project = this.scheduleInspectorForm.controls.project.value;
    var type;
    if (project) {
      let filterProject = [];
      filterProject = _.filter(this.projects, ele => { return ele._id === project });
      if (filterProject.length > 0) {
        type = filterProject[0].type.optionName.toLowerCase();
        if (type === 'aramco direct') {
          this.isAramcoDirect = true;
          this.isAramcoContract = false;
          this.poString = 'RPO number';
        } else {
          this.isAramcoDirect = false;
          this.isAramcoContract = true;
          this.poString = 'PO number';
        }
      }

    }
  }

  save() {

    if (this.additionalDetails.length > 0) {
      for (var j = 0; j < this.additionalDetails.length; j++) {
        if (this.additionalDetails[j].category === '' || this.additionalDetails[j].category === null || this.additionalDetails[j].category === undefined) {
          this.snackBar.open('Enter category', '', { duration: 2000 });
          return '';
        }
        if (this.additionalDetails[j].inspector === '' || this.additionalDetails[j].inspector === null || this.additionalDetails[j].inspector === undefined) {
          this.snackBar.open('Enter inspector', '', { duration: 2000 });
          return '';
        }
        if (this.additionalDetails[j].quantity === '' || this.additionalDetails[j].quantity === null || this.additionalDetails[j].quantity === undefined) {
          this.snackBar.open('Enter number of equipment', '', { duration: 2000 });
          return '';
        }
        if (this.additionalDetails[j].inspectionDate === '' || this.additionalDetails[j].inspectionDate === null || this.additionalDetails[j].inspectionDate === undefined) {
          this.snackBar.open('Enter inspection date', '', { duration: 2000 });
          return '';
        }
        debugger;

        var data = {
          inspectorID: this.additionalDetails[j].inspector,
          date: this.additionalDetails[j].inspectionDate
        }
        this.ScheduleInspectionService.checkInspectorAlreadyAssigned(data).subscribe(data => {
          if (data) {
            if (data && data.length > 0) {
              //  this.additionalDetails[j].inspectionDate = '';
              this.snackBar.open('Inspector already accepted schedule for this date.', '', { duration: 4000 });
              return;
            }
          }
        });
      }
    }
    if (this.isAramcoDirect == true) {
      if (this.scheduleInspectorForm.controls.rpoNo.value == undefined || this.scheduleInspectorForm.controls.rpoNo.value == null || this.scheduleInspectorForm.controls.rpoNo.value == '') {
        this.snackBar.open('Enter RPO number is required', '', { duration: 2000, verticalPosition: "bottom" });
        return '';
      }
    }
    if (this.isControlReportNoColumn == true) {
      if (this.scheduleInspectorForm.controls.cntrlReportNo.value == undefined || this.scheduleInspectorForm.controls.cntrlReportNo.value == null || this.scheduleInspectorForm.controls.cntrlReportNo.value == '') {
        this.snackBar.open('Enter Control report no is required', '', { duration: 2000, verticalPosition: "bottom" });
        return '';
      }

    }

    if (this.companyName == 'AMPS') {
      if (this.scheduleInspectorForm.controls.woNo.value == undefined || this.scheduleInspectorForm.controls.woNo.value == null || this.scheduleInspectorForm.controls.woNo.value == '') {
        this.snackBar.open('Enter WoNo is required', '', { duration: 2000, verticalPosition: "bottom" });
        return '';
      }

    }

    this.inputData = {
      project: this.scheduleInspectorForm.controls.project.value,
      location: this.scheduleInspectorForm.controls.location.value,
      // inspectionDate: this.scheduleInspectorForm.controls.inspectionDate.value,
      groupName: this.scheduleInspectorForm.controls.groupName.value,
      costCode: this.scheduleInspectorForm.controls.costCode.value,
      projectName: null,
      projectNumber: null,
      inspectorName: null,
      locationName: null,
      equipmentNumber: null,
      createdBy: null,
      updatedBy: null,
      KeyNo: null,
      rowId: null,
      categoryName: null,
      category: '',
      inspector: '',
      reqEmail: this.scheduleInspectorForm.controls.reqEmail.value,
      reqTelephone: this.scheduleInspectorForm.controls.reqTelephone.value,
      reqMobile: this.scheduleInspectorForm.controls.reqMobile.value,
      siteLocationType: this.scheduleInspectorForm.controls.siteLocationType.value,
      inspectionType: this.scheduleInspectorForm.controls.inspectionType.value,
      loadTest: this.scheduleInspectorForm.controls.loadTest.value,
      cerName: this.scheduleInspectorForm.controls.cerName.value,
      cerMobile: this.scheduleInspectorForm.controls.cerMobile.value,
      cerBatchNo: this.scheduleInspectorForm.controls.cerBatchNo.value,
      cerTelephone: this.scheduleInspectorForm.controls.cerTelephone.value,
      cerEmail: this.scheduleInspectorForm.controls.cerEmail.value,
      verName: this.scheduleInspectorForm.controls.verName.value,
      verMobile: this.scheduleInspectorForm.controls.verMobile.value,
      verBatchNo: this.scheduleInspectorForm.controls.verBatchNo.value,
      verTelephone: this.scheduleInspectorForm.controls.verTelephone.value,
      verEmail: this.scheduleInspectorForm.controls.verEmail.value,
      rpoNo: this.scheduleInspectorForm.controls.rpoNo.value,
      woNo: this.scheduleInspectorForm.controls.woNo.value,
      cntrlReportNo: this.scheduleInspectorForm.controls.cntrlReportNo.value,
      siteContactPersonName: this.scheduleInspectorForm.controls.siteContactPersonName.value,
      siteContactPersonContactNo: this.scheduleInspectorForm.controls.siteContactPersonContactNo.value,
      reqName: this.scheduleInspectorForm.controls.reqName.value,
      reqDate: this.scheduleInspectorForm.controls.reqDate.value,
      createdUser: this.user.data._id,
      receiverId: this.receiverId
    }

    if (this.inputData.siteLocationType === false) {
      this.inputData.siteLocationType = 'On-Shore'
    } else if (this.inputData.siteLocationType === true) {
      this.inputData.siteLocationType = 'Off-Shore'
    }
    if (this.inputData.project) {
      let filteredProject = this.projects.filter(ele => ele._id === this.inputData.project);
      if (filteredProject.length > 0) {
        this.projectName = filteredProject[0].projectName;
        this.projectNumber = filteredProject[0].projectNo;
      }
    } else { this.inputData.project = null }
    if (this.additionalDetails[0].inspector) {
      let filterInspector = this.inspector.filter(ele => ele._id === this.additionalDetails[0].inspector);
      if (filterInspector.length > 0) {
        this.inspectorName = filterInspector[0].name;
      }
    } else { this.additionalDetails[0] = null }
    if (this.inputData.equipment) {
      let filterEquipment = this.equipment.filter(ele => ele._id === this.inputData.equipment);
      if (filterEquipment.length > 0) {
        this.equipmentNumber = filterEquipment[0].equipmentNumber;
      }
    } else { this.inputData.equipment = null }
    if (this.inputData.location) {
      let filteredLocation = this.locations.filter(ele => ele._id === this.inputData.location);
      if (filteredLocation.length > 0) {
        this.locationName = filteredLocation[0].name;
      }
    } else { this.inputData.location = null }
    this.inputData.projectName = this.projectName;
    this.inputData.projectNumber = this.projectNumber;
    this.inputData.inspectorName = this.inspectorName;
    this.inputData.equipmentNumber = this.equipmentNumber;
    this.inputData.categoryName = this.categoryName;
    this.inputData.locationName = this.locationName;
    this.inputData.createdBy = this.user.data.name;
    this.inputData.updatedBy = this.user.data.name;
    this.inputData.KeyNo = this.RptNo;

    var tempAdditiondetails = [];
    if (this.additionalDetails[0] !== undefined && this.additionalDetails[0] !== null) {

      for (var k = 0; k < this.additionalDetails.length; k++) {
        if (this.additionalDetails[k].category) {
          let filteredCategory = this.categories.filter(ele => ele._id === this.additionalDetails[k].category);
          if (filteredCategory.length > 0) {
            this.categoryName = filteredCategory[0].name;
          }
        } else { this.additionalDetails[k].category = null }
        if (this.additionalDetails[k].inspector) {
          let filterInspector = this.inspector.filter(ele => ele._id === this.additionalDetails[k].inspector);
          if (filterInspector.length > 0) {
            this.inspectorName = filterInspector[0].name;
          }
        } else { this.additionalDetails[0] = null }
        var val = {
          "quantity": this.additionalDetails[k].quantity,
          "categoryName": this.categoryName,
          "category": this.additionalDetails[k].category,
          "inspector": this.additionalDetails[k].inspector,
          "inspectorName": this.inspectorName,
          "inspectionDate": this.additionalDetails[k].inspectionDate,
        };
        tempAdditiondetails.push(val)
      }

    } else {
      tempAdditiondetails = [{
        category: "",
        categoryDetails: [],
        inspector: "",
        quantity: "",
        inspectionDate: "",
        "addNew": true,
        "remove": false,
        "isActive": true,
        allInspectors: []
      }];
    }
    this.inputData.additionalDetails = tempAdditiondetails;
    if (this.rowId) {
      this.inputData.rowId = this.rowId;
      this.ScheduleInspectionService.updateRecord(this.inputData).subscribe(result => {
        if (result['statusBool']) {
          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } else {
          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      }, err => {
        if (err.error.statusHttp === 412) {
          this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });

    } else {
      this.ScheduleInspectionService.save(this.inputData).subscribe(result => {
        if (result['statusBool']) {
          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } else {
          this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      }, err => {
        if (err.error.statusHttp === 412) {
          this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
      var seedData = {
        "module": 'Inspection',
        "nextValue": this.startingNo + 1,
        "updatedBy": this.user.data.name
      }
      this.geseedService.updateRecord(seedData).subscribe(res => {
      });
    }
  }

  get f() {
    return this.scheduleInspectorForm.controls;
  }

  getFieldName() {
    this.geoptionService.getRecordByOptionName('Cost code').subscribe(result => {
      if (result.length > 0) {
        if (result[0].option && result[0].option.length > 0) {
          this.costCode = result[0].option[0].optionValue;
        }
      }
    });
    this.geoptionService.getRecordByOptionName('Control report no').subscribe(result => {
      if (result.length > 0) {
        if (result[0].option && result[0].option.length > 0) {
          this.controlReportNo = result[0].option[0].optionValue;
        }
      }
    })
    this.geoptionService.getRecordByOptionName('Group name').subscribe(result => {
      if (result.length > 0) {
        if (result[0].option && result[0].option.length > 0) {
          this.groupName = result[0].option[0].optionValue;
        }
      }
    })
  }

  ngOnInit() {

    this.getFieldName();
    this.getClientName();
    this.getGroupName();
    this.getRequesterDateRequired();
    this.getInspectorScheduleValidation();
    this.isApproved = this.data.isApproved;

    if (this.data._id) {
      this.rowId = this.data._id;
      this.isUpdate = true;
      this.patchValue();
    } else {
      this.getValues();
      this.isSave = true;
      this.additionalDetails = [{
        category: "",
        categoryDetails: [],
        inspector: "",
        quantity: "",
        inspectionDate: "",
        "addNew": true,
        "remove": false,
        "isActive": true,
        allInspectors: []

      }];
      this.scheduleInspectorForm.patchValue({ quantity: 1 });
      if (this.selectedVal == 'On-Shore') {
        this.getSiteLocationType(this.selectedVal);
      }
    }

    this.user = this.getFromLocalStorage('userDet');
    this.getProjects();
    this.getCategory();
  }
  getClientName() {
    this.settingService.getByKey('client').subscribe(result => {
      if (result.length > 0) {
        if (result[0].value) {
          this.companyName = result[0].value;
        }
      }
    })
  }
  getRequesterDateRequired(){
    this.settingService.getByKey('requesterDateIsRequired').subscribe(result => {
      if (result.length > 0) {
        if (result[0].value) {
          if(result[0].value === 'true' || result[0].value === true){
            this.isRequesterDateRequired = true;
            this.scheduleInspectorForm.controls['reqDate'].setValidators([Validators.required]);
            this.scheduleInspectorForm.controls['reqDate'].updateValueAndValidity();
            this.requesterDateLabelName =  "Requester Date *";

          }
        }
      }
    })
  }
  getInspectorScheduleValidation() {
    this.settingService.getByKey('inspector schedule validation').subscribe(result => {
      if (result.length > 0) {
        if (result[0].value.toLowerCase() === 'true') {
          this.isinspectorschedulevalidation = true;
        }
      }
    })
  }
  getGroupName() {
    this.settingService.getByKey('groupName').subscribe(result => {
      if (result.length > 0) {
        if (result[0].value == "true") {
          this.isGroupNameColumn = true;
        }
      }

      this.settingService.getByKey('controlReportNo').subscribe(result => {
        if (result.length > 0) {
          if (result[0].value == "true") {
            this.isControlReportNoColumn = true;
          }
        }

        this.settingService.getByKey('costCode').subscribe(result => {
          if (result.length > 0) {
            if (result[0].value == "true") {
              this.isCostCodeColumn = true;
            }
          }
        })
      })
    })
  }
  addRow(additionalDetails, index) {
    if (this.isinspectorschedulevalidation) {
      var data = {
        inspectorID: additionalDetails.inspector,
        date: additionalDetails.inspectionDate
      }
      this.ScheduleInspectionService.checkInspectorAlreadyAssigned(data).subscribe(data => {
        if (data) {
          if (data && data.length > 0) {
            additionalDetails.inspectionDate = '';
            this.snackBar.open('Inspector already accepted schedule for this date.', '', { duration: 4000 });
            return;
          } else {
            if (additionalDetails !== undefined && additionalDetails !== null && additionalDetails !== '' &&
              additionalDetails.category !== undefined && additionalDetails.category !== null && additionalDetails.category !== '' &&
              additionalDetails.inspector !== undefined && additionalDetails.inspector !== null && additionalDetails.inspector !== '' &&
              additionalDetails.quantity !== undefined && additionalDetails.quantity !== null && additionalDetails.quantity !== '' &&
              additionalDetails.inspectionDate !== undefined && additionalDetails.inspectionDate !== null && additionalDetails.inspectionDate !== '') {

              this.additionalDetails.push({
                category: "",
                categoryDetails: [],
                inspector: "",
                quantity: "",
                inspectionDate: "",
                "addNew": false,
                "remove": false,
                "isActive": true,
                allInspectors: []
              });
              this.additionalDetails.forEach(function addData(row, index) {
                row.addNew = false;
                row.remove = true;
              });
              var last_element = this.additionalDetails[this.additionalDetails.length - 1];
              last_element.addNew = true;
              last_element.remove = true;
            }
            else {
              this.snackBar.open('Please enter the inspection details', '', { duration: 2000 });
              return '';
            }
            this.getCategory();
          }
        }
      });
    }
    else {
      if (additionalDetails !== undefined && additionalDetails !== null && additionalDetails !== '' &&
        additionalDetails.category !== undefined && additionalDetails.category !== null && additionalDetails.category !== '' &&
        additionalDetails.inspector !== undefined && additionalDetails.inspector !== null && additionalDetails.inspector !== '' &&
        additionalDetails.quantity !== undefined && additionalDetails.quantity !== null && additionalDetails.quantity !== '' &&
        additionalDetails.inspectionDate !== undefined && additionalDetails.inspectionDate !== null && additionalDetails.inspectionDate !== '') {

        this.additionalDetails.push({
          category: "",
          categoryDetails: [],
          inspector: "",
          quantity: "",
          inspectionDate: "",
          "addNew": false,
          "remove": false,
          "isActive": true,
          allInspectors: []
        });
        this.additionalDetails.forEach(function addData(row, index) {
          row.addNew = false;
          row.remove = true;
        });
        var last_element = this.additionalDetails[this.additionalDetails.length - 1];
        last_element.addNew = true;
        last_element.remove = true;
      }
      else {
        this.snackBar.open('Please enter the inspection details', '', { duration: 2000 });
        return '';
      }
      this.getCategory();
    }



  };

  validateRow(additionalDetails, index) {
    if (this.isinspectorschedulevalidation) {
      if (additionalDetails.inspectionDate === null || additionalDetails.inspectionDate === undefined || additionalDetails.inspector === null || additionalDetails.inspector === undefined) {
        return;
      }

      var data = {
        inspectorID: additionalDetails.inspector,
        date: additionalDetails.inspectionDate
      }
      this.ScheduleInspectionService.checkInspectorAlreadyAssigned(data).subscribe(data => {
        if (data) {
          if (data && data.length > 0) {
            additionalDetails.inspectionDate = '';
            this.snackBar.open('Inspector already accepted schedule for this date.', '', { duration: 4000 });
            return;
          }
        }
      });
    }

  };

  removeRow(additionalDetails, index) {
    this.additionalDetails[index].isActive = false;
    this.additionalDetails.splice(index, 1);
    var last_element = this.additionalDetails[this.additionalDetails.length - 1];
    last_element.addNew = true;
    last_element.remove = true;
    if (this.additionalDetails.length == 1) {
      last_element.remove = false;
    }
  };
  getSiteLocationType(data) {
    this.siteLocationService.getRecordByType(data).subscribe(result => {
      if (result.length > 0) {
        this.siteLocation = true;
        this.locations = result;
        this.anotherLocations = this.locations;
      }
    });
  }
  changeType(event) {
    var selectType;
    if (event.checked == true || event == 'Off-Shore') {
      selectType = 'Off-Shore'
      this.selectedVal = 'Off-Shore'
      this.scheduleInspectorForm.patchValue({ siteLocationType: true });
    } else if (event.checked == false || event == 'On-Shore') {
      selectType = 'On-Shore'
      this.selectedVal = 'On-Shore'
      this.scheduleInspectorForm.patchValue({ siteLocationType: false });
    }
    this.siteLocationService.getRecordByType(selectType).subscribe(result => {
      if (result.length > 0) {
        this.siteLocation = true;
        this.locations = result;
        this.anotherLocations = this.locations;
      }
    });

  }
  patchValue() {
    if (this.data.project) {
      if (this.data.project.ref) {
        this.scheduleInspectorForm.patchValue({ project: this.data.project.ref });
      }
    }
    if (this.data.inspector) {
      if (this.data.inspector.ref) {
        this.scheduleInspectorForm.patchValue({ inspector: this.data.inspector.ref });
      }
    }
    if (this.data.siteLocationType) {
      this.siteLocation = true;
      this.changeType(this.data.siteLocationType);
      if (this.data.siteLocation) {
        if (this.data.siteLocation.ref) {
          this.scheduleInspectorForm.patchValue({ location: this.data.siteLocation.ref });
        }
      }
    }
    if (this.data.additionalDetails != undefined && this.data.additionalDetails.length > 0) {
      for (var i = 0; i < this.data.additionalDetails.length; i++) {
        this.data.additionalDetails[i].addNew = false;
        this.data.additionalDetails[i].remove = true;
        this.data.additionalDetails[i].isActive = true;

        this.getPatchInspectors(this.data.additionalDetails[i].category.ref, i);

        this.additionalDetails.push({
          "category": this.data.additionalDetails[i].category.ref,
          "categoryDetails": this.categories,
          "inspector": this.data.additionalDetails[i].inspector.ref,
          "quantity": this.data.additionalDetails[i].quantity,
          "inspectionDate": this.data.additionalDetails[i].inspectionDate,
          allInspectors: []
        });
      }

      var last_element = this.additionalDetails[this.additionalDetails.length - 1];
      last_element.addNew = true;
      last_element.remove = true;
      if (this.additionalDetails.length == 1) {
        last_element.remove = false;
      }
    }
    if (this.data.receiverId) {
      this.bindReceiverDetail(this.data.receiverId)
    }
    this.scheduleInspectorForm.patchValue({
      // inspectionDate: this.data.inspectionDate,
      groupName: this.data.groupName,
      costCode: this.data.costCode,
      quantity: this.data.additionalDetails[0].quantity,
      reqEmail: this.data.reqEmail,
      reqTelephone: this.data.reqTelephone,
      reqMobile: this.data.reqMobile,
      siteLocationType: this.data.siteLocationType,
      inspectionType: this.data.inspectionType,
      loadTest: this.data.loadTest,
      cerName: this.data.cerName,
      cerMobile: this.data.cerMobile,
      cerBatchNo: this.data.cerBatchNo,
      cerTelephone: this.data.cerTelephone,
      cerEmail: this.data.cerEmail,
      verName: this.data.verName,
      verMobile: this.data.verMobile,
      verBatchNo: this.data.verBatchNo,
      verTelephone: this.data.verTelephone,
      verEmail: this.data.verEmail,
      techName: this.data.techName,
      techMobile: this.data.techMobile,
      techTelephone: this.data.techTelephone,
      techEmail: this.data.techEmail,
      rpoNo: this.data.rpoNo,
      woNo: this.data.woNo,
      cntrlReportNo: this.data.cntrlReportNo,
      siteContactPersonName: this.data.siteContactPersonName,
      siteContactPersonContactNo: this.data.siteContactPersonContactNo,
      reqName: this.data.reqName,
      reqDate: this.data.reqDate
    })
    this.RptNo = this.data.keyNo;
    this.checkType();
  }

  convertISO(string) {
    if (string) {
      var date
      date = string.split("/");    // ["29", "1", "2016"]
      return new Date(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]));
    }
    else {
      return null;
    }
  }
  bindReceiverDetail(id) {
    var data1 = {
      _id: id
    }

    this.receiverService.getRecordsById(data1).subscribe(data => {
      if (data && data.length > 0) {
        this.scheduleInspectorForm.patchValue({
          technicalReviewerName: data[0].name,
          technicalReviewerBatchNo: data[0].badgeNo,
          technicalReviewerEmail: data[0].emailID,
          technicalReviewerMobile: data[0].telephone
        });
        this.receiverId = data[0]._id;
      }
    });

  }
  getProjects() {
    this.projectService.getProjects().subscribe(result => {
      this.projects = result;
      this.anotherProjects = this.projects;
      this.checkType();
    });
  }

  filterProject(val) {
    val = val.toLowerCase();
    this.anotherProjects = this.projects.filter(function (d) {
      return (d.projectName.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.projectNo.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  clearReceiverDetails() {
    if (this.scheduleInspectorForm.controls.technicalReviewerName.value) {
      let dialogRef = this.dialog.open(ReceiverDetailDialogComponent, {
        data: this.data,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.scheduleInspectorForm.controls['technicalReviewerBatchNo'].reset();
          this.scheduleInspectorForm.controls['technicalReviewerEmail'].reset();
          this.scheduleInspectorForm.controls['technicalReviewerName'].reset();
          this.scheduleInspectorForm.controls['technicalReviewerMobile'].reset();
        }
      });
    }
  }

  // filterInspector(val,data) {
  //   val = val.toLowerCase();
  //   this.anotherInspector = this.inspector.filter(function (d) {
  //     return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
  //   });
  // }

  getCategory() {
    this.categoryService.getCategory().subscribe(result => {
      this.categories = result;
      this.additionalDetails.forEach(element => {
        element["categoryDetails"] = this.categories;
      });
    });
  }

  filterCategory(val, i) {
    val = val.toLowerCase();
    this.additionalDetails[i].categoryDetails = this.categories.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }

  filterLocation(val) {
    val = val.toLowerCase();
    this.anotherLocations = this.locations.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }

  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  btnClose() {
    if (this.scheduleInspectorForm.dirty) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: true,
        hasBackdrop: true
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  onChangecat(value, iteration) {
    this.getInspectors(value.value, iteration);
    this.filterValue.nativeElement.value = "";
  }

  getPatchInspectors(value, iteration) {
    var data = {
      categoryID: value
    }
    this.inspectorsService.getUserByCategory(data).subscribe(result => {
      this.inspector = result;
      for (let i = 0; i < this.inspector.length; i++) {
        this.additionalDetails[iteration].allInspectors.push(this.inspector[i]);
      }
    })
  }

  getInspectors(value, iteration) {
    this.additionalDetails[iteration].allInspectors = [];
    var data = {
      categoryID: value
    }
    this.inspectorsService.getUserByCategory(data).subscribe(result => {
      this.inspector = result;
      for (let i = 0; i < this.inspector.length; i++) {
        this.additionalDetails[iteration].allInspectors.push(this.inspector[i]);
      }
    })
  }
  btnAddNewReceiver() {
    const dialogRef = this.dialog.open(ReceiverFormComponent, {
      data: { data: this.data.data, },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        this.patchReceiverValue(result.data)
      }
    });
  }

  patchReceiverValue(data) {
    if (data) {
      this.scheduleInspectorForm.patchValue({
        technicalReviewerName: data.name,
        technicalReviewerBatchNo: data.badgeNo,
        technicalReviewerEmail: data.emailID,
        technicalReviewerMobile: data.telephone,
      })
      this.receiverId = data._id;
    }

  }

  selectFromExisting() {
    const dialogRef = this.dialog.open(SelectReceiverComponent, {
      data: { data: this.data.data, },
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.patchReceiverValue(result);
    });
  }
}



