import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet, MatDialog, MatSnackBar } from '@angular/material';
import { OfflineStorageService } from '../../mobile-native-interface/offline-storage.service.service';
@Component({
  selector: 'app-offline-delete-dialog',
  templateUrl: './offline-delete-dialog.component.html',
  styleUrls: ['./offline-delete-dialog.component.css']
})
export class OfflineDeleteDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<OfflineDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private snackBar: MatSnackBar, private offlineStorageService: OfflineStorageService
  ) { }

  ngOnInit() {
  }
  Delete() {
    this.dialogRef.close(true)
  }
  btnClose() {
    this.dialogRef.close(false)
  }
}
