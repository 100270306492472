import { Injectable } from '@angular/core';
import { RfiInspectionService } from '../master/rfi-inspection/rfi-inspection.service';
import { ReceiverService } from '../master/receiver/receiver.service';
import { OfflineStorageService } from './offline-storage.service.service';
import { LocationService } from '../master/location/location.service';
import { ManufactureService } from '../master/manufacture/manufacture.service';
import { TypeService } from '../master/type/type.service';
import de from 'date-fns/esm/locale/de/index.js';

@Injectable({
  providedIn: 'root'
})
export class Send {

  id;
  id1;
  intervalDuration = 0.25; // IN MINUTES
  indx= 0;
  constructor(
    private rfiInspectionService: RfiInspectionService,
    private offlineStorage: OfflineStorageService,
    private receiverService: ReceiverService,
    private locationService: LocationService,
    private manufactureService: ManufactureService,
    private typeService: TypeService,
  ) { }

  sendServiceInit() {
    console.log('Send Service started!!!');
    this.id = setInterval(() => {
      this.sendServiceInitCollection(this.indx);
    }, 5000);
  }
  sendServiceInitCollection(x) {
    if (x === 0) {
      this.sendServiceToCollectionRFI(x);
    }else{
      this.sendService();
    }


  }
  sendServiceToCollectionRFI(x) {
    this.offlineStorage.syncRFIHandler.getAllRFIToSend(this, async function (result) {
      let _this = await result.self;
      if (result && result.data && result.data.length > 0) {
        let inspectionData = [];
        for (let i = 0; i < result.data.length; i++) {
          inspectionData.push(JSON.parse(result.data.item(i).inspectionData));
        }

        var data = {
          module: 'RFI Inspection',
          data: inspectionData
        }
        _this.rfiInspectionService.mobileLogDataToCollection(data).subscribe(results => {
          if (results['statusBool']) {
            _this.sendServiceToCollectionType(x)
          }
        });
      } else {
        _this.sendServiceToCollectionType(x)
      }
    });
  }
  sendServiceToCollectionType(x) {
    this.offlineStorage.typehandler.getAllTypeToSend(this, async function (result) {
      let _this = await result.self;
      if (result && result.data && result.data.length > 0) {
        let typeData = [];
        for (let i = 0; i < result.data.length; i++) {
          typeData.push(JSON.parse(result.data.item(i).typeData));
        }
        var data = {
          module: 'Type',
          data: typeData
        }
        _this.rfiInspectionService.mobileLogDataToCollection(data).subscribe(results => {
          if (results['statusBool']) {
            _this.sendServiceToCollectionManufacture(x);
          }
        });
      } else {
        _this.sendServiceToCollectionManufacture(x);
      }
    });
  }
  sendServiceToCollectionManufacture(x) {
    this.offlineStorage.manufacturehandler.getAllManufactureToSend(this, async function (result) {
      let _this = await result.self;
      if (result && result.data && result.data.length > 0) {
        let manufactureData = [];
        for (let i = 0; i < result.data.length; i++) {
          manufactureData.push(JSON.parse(result.data.item(i).manufactureData));
        }
        var data = {
          module: 'Manufacture',
          data: manufactureData
        }
        _this.rfiInspectionService.mobileLogDataToCollection(data).subscribe(results => {
          if (results['statusBool']) {
            _this.sendServiceToCollectionLocation(x);
          }
        });
      } else {
        _this.sendServiceToCollectionLocation(x);

      }
    });
  }
  sendServiceToCollectionLocation(x) {
    this.offlineStorage.locationhandler.getAllLocationToSend(this, async function (res) {
      let _this = await res.self;
      var locationData = [];
      if (res && res.data && res.data.length > 0) {
        for (let i = 0; i < res.data.length; i++) {
          locationData.push(JSON.parse(res.data.item(i).locationData));
        }
        var data = {
          module: 'Location',
          data: locationData
        }
        _this.rfiInspectionService.mobileLogDataToCollection(data).subscribe(results => {
          if (results['statusBool']) {
            _this.sendServiceToCollectionEquipment(x);
          }
        });
      } else {
        _this.sendServiceToCollectionEquipment(x);
      }
    });
  }
  sendServiceToCollectionEquipment(x) {
    this.offlineStorage.equipmentHandler.getAllEquipmentToSend(this, async function (result) {
      let _this = await result.self;
      if (result && result.data && result.data.length > 0) {
        let equipmentData = [];
        for (let i = 0; i < result.data.length; i++) {
          equipmentData.push(JSON.parse(result.data.item(i).equipmentData));
        }
        var data = {
          module: 'Equipment',
          data: equipmentData
        }
        _this.rfiInspectionService.mobileLogDataToCollection(data).subscribe(results => {
          if (results['statusBool']) {
            _this.sendServiceToCollectionReceiver(x);
          }
        });
      } else {
        _this.sendServiceToCollectionReceiver(x);
      }
    });
  }
  sendServiceToCollectionReceiver(x) {
    this.offlineStorage.receiverHandler.getAllReceiverToSend(this, async function (result) {
      let _this = await result.self;
      if (result && result.data && result.data.length > 0) {
        let receiverData = [];
        for (let i = 0; i < result.data.length; i++) {
          receiverData.push(JSON.parse(result.data.item(i).receiverData));
        }
        var data = {
          module: 'Receiver',
          data: receiverData
        }
        _this.rfiInspectionService.mobileLogDataToCollection(data).subscribe(results => {
          if (results['statusBool']) {
            _this.sendServiceToCollectionReport(x);
          }
        });
      } else {
        _this.sendServiceToCollectionReport(x);

      }
    });
  }
  sendServiceToCollectionReport(x) {
    this.offlineStorage.rfiReporthandler.getAllRFIReportToSend(this, async function (result) {
      let _this = await result.self;
      if (result && result.data && result.data.length > 0) {
        let rfiReportData = [];
        for (let i = 0; i < result.data.length; i++) {
          rfiReportData.push(JSON.parse(result.data.item(i).rfiReportData));
        }
        var data = {
          module: 'RFI Report',
          data: rfiReportData
        }
        _this.rfiInspectionService.mobileLogDataToCollection(data).subscribe(results => {
          if (results['statusBool']) {
            _this.sendServiceToCollectionSignature(x);
          }
        });
      } else {
        _this.sendServiceToCollectionSignature(x);
      }
    });
  }
  sendServiceToCollectionSignature(x) {
    this.offlineStorage.attachmentHandler.getAllSignaturetoSend(this, async function (result) {
      let _this = await result.self;
      if (result && result.data && result.data.length > 0) {
        let attachmentData = [];
        for (let i = 0; i < result.data.length; i++) {
          attachmentData.push(JSON.parse(result.data.item(i).attachmentData));
        }
        var data = {
          module: 'Signature',
          data: attachmentData
        }
        _this.rfiInspectionService.mobileLogDataToCollection(data).subscribe(results => {
          if (results['statusBool']) {
            x++;
            _this.sendServiceInitCollection(x);
          }
        });
      } else{
        x++;
        _this.sendServiceInitCollection(x);
      }
    });
  }
  // sendServiceToCollectionWorkflow(x) {
  //   this.offlineStorage.workflowSyncHandler.getAllWorkflowSynctoSend(this, async function (result) {
  //     let _this = await result.self;
  //     if (result && result.data && result.data.length > 0) {
  //       let workflowSyncData = [];
  //       for (let i = 0; i < result.data.length; i++) {
  //         workflowSyncData.push(JSON.parse(result.data.item(i).workflowSyncData));
  //       }
  //       var data = {
  //         module: 'Workflow',
  //         data: workflowSyncData
  //       }
  //       _this.rfiInspectionService.mobileLogDataToCollection(data).subscribe(results => {
  //         if (results['statusBool']) {
  //           x++;
  //           _this.sendServiceInitCollection(x);

  //         }
  //       });
  //     }
  //   });
  // }


  sendService() {
    //GET UNSYNCED DATA FROM OFFLINE
    this.offlineStorage.syncRFIHandler.getSelectedRFIToSend(this, async function (result) {
      let _this = await result.self;
      if (result && result.data && result.data.length > 0) {
        var subData = JSON.parse(result.data.item(0).inspectionData);
        if (subData.equipment) {
          if (subData.equipment.ref && typeof subData.equipment.ref !== 'string') {
            var data1 = { _id: subData.equipment.ref }
            _this.offlineStorage.equipmentHandler.getEquipmentById(_this, data1, async function (ress) {
              let _this = await ress.self;
              if (ress && ress.data && ress.data.length > 0) {
                var inputData = JSON.parse(ress.data.item(0).equipmentData);
                if (inputData) {
                  // var data2 = { _id: inputData.location.ref }
                  _this.offlineStorage.locationhandler.getLocations(_this, async function (res) {
                    let _this = res.self;
                    let locationData = [];
                    if (res && res.data && res.data.length > 0) {
                      for (let i = 0; i < res.data.length; i++) {
                        locationData.push(JSON.parse(res.data.item(i).locationData));
                      }
                      if (locationData && locationData.length > 0) {
                        var resultData = locationData.find(element => element.name === inputData.location.name);
                        let location = 'New';
                        if (inputData.location.ref !== undefined && inputData.location.ref !== null) {
                          if (inputData.location.ref && typeof inputData.location.ref !== 'string') {
                            location = 'New'
                          } else {
                            location = 'Old'
                          }
                        } else {
                          location = 'No Entry'

                        }
                        var inputData4 = {};
                        if (location === "New" || location === 'Old') {
                          inputData4 = { "name": resultData.name, "description": resultData.description, "createdBy": resultData.createdBy, "createdDate": new Date(), "location": location }
                        } else {
                          inputData4 = { "location": location }
                        }
                        _this.locationService.offlineInsertLocation(inputData4).subscribe(results => {
                          if (results['statusBool']) {
                            var data1 = {}
                            if (location === "New" || location === 'Old') {
                              data1 = { "name": resultData.name, "description": resultData.description, "createdBy": resultData.createdBy, "_id": results.data._id, "isData": true }
                            } else {
                              data1 = { "isData": false }
                            }
                            if (location === 'New') {
                              inputData.location.ref = results.data._id;
                            } else if (location === 'No Entry') {
                              inputData.location = null;
                            }
                            _this.offlineStorage.locationhandler.updateSelectedLocationSyncStatus(results, res.data.item(0), data1, async function (res) {
                              if (inputData) {
                                // var data3 = { _id: inputData.manufacture.ref }
                                _this.offlineStorage.manufacturehandler.getManufacture(_this, async function (res) {
                                  let _this = res.self;
                                  let manufactureData = [];
                                  if (res && res.data && res.data.length > 0) {
                                    for (let i = 0; i < res.data.length; i++) {
                                      manufactureData.push(JSON.parse(res.data.item(i).manufactureData));
                                    }
                                    if (manufactureData && manufactureData.length > 0) {
                                      var resultData = manufactureData.find(element => element.name === inputData.manufacture.name);
                                      let manufacture = 'New';
                                      if (inputData.manufacture.ref !== undefined && inputData.manufacture.ref !== null) {
                                        if (inputData.manufacture.ref && typeof inputData.manufacture.ref !== 'string') {
                                          manufacture = 'New'
                                        } else {
                                          manufacture = 'Old'
                                        }
                                      } else {
                                        manufacture = 'No Entry'

                                      }
                                      var inputData1 = {};
                                      if (manufacture === "New" || manufacture === 'Old') {
                                        inputData1 = { "name": resultData.name, "description": resultData.description, "createdBy": resultData.createdBy, "createdDate": new Date(), "manufacture": manufacture }
                                      } else {
                                        inputData1 = { "manufacture": manufacture }
                                      }
                                      _this.manufactureService.offlineInsertManufacture(inputData1).subscribe(results => {
                                        if (results['statusBool']) {
                                          var data1 = {};
                                          if (manufacture === "New" || manufacture === 'Old') {
                                            data1 = { "name": resultData.name, "description": resultData.description, "createdBy": resultData.createdBy, "_id": results.data._id, "isData": true }
                                          } else {
                                            data1 = { "isData": false }
                                          }
                                          if (manufacture === 'New') {
                                            inputData.manufacture.ref = results.data._id;
                                          } else if (manufacture === 'No Entry') {
                                            inputData.manufacture = null;

                                          }
                                          _this.offlineStorage.manufacturehandler.updateSelectedManufactureSyncStatus(results, res.data.item(0), data1, async function (res) {
                                            if (inputData.type) {
                                              // var data5 = { _id: inputData.type.ref }
                                              _this.offlineStorage.typehandler.getTypes(_this, async function (res) {
                                                let _this = res.self;
                                                let typeData = [];
                                                if (res && res.data && res.data.length > 0) {
                                                  for (let i = 0; i < res.data.length; i++) {
                                                    typeData.push(JSON.parse(res.data.item(i).typeData));
                                                  }
                                                  if (typeData && typeData.length > 0) {
                                                    var resultData = typeData.find(element => element.name === inputData.type.name);
                                                    let type = 'New';
                                                    if (inputData.type.ref !== undefined && inputData.type.ref !== null) {
                                                      if (inputData.type.ref && typeof inputData.type.ref !== 'string') {
                                                        type = 'New'
                                                      } else {
                                                        type = 'Old'
                                                      }
                                                    } else {
                                                      type = 'No Entry'
                                                    }
                                                    var inputData2 = {};
                                                    if (type === "New" || type === 'Old') {
                                                      inputData2 = { "name": resultData.name, "description": resultData.description, "createdBy": resultData.createdBy, "createdDate": new Date(), "type": type }
                                                    } else {
                                                      inputData2 = { "type": type }
                                                    }
                                                    _this.typeService.offlineInsertType(inputData2).subscribe(results => {
                                                      if (results['statusBool']) {
                                                        var data1 = {};
                                                        if (type === "New" || type === 'Old') {
                                                          data1 = { "name": resultData.name, "description": resultData.description, "createdBy": resultData.createdBy, "_id": results.data._id, "isData": true }
                                                        } else {
                                                          data1 = { "isData": false }
                                                        }
                                                        if (type === 'New') {
                                                          inputData.type.ref = results.data._id;
                                                        } else if (type === 'No Entry') {
                                                          inputData.type = null;
                                                        }
                                                        _this.offlineStorage.typehandler.updateSelectedTypeSyncStatus(results, res.data.item(0), data1, async function (res) {
                                                          _this.rfiInspectionService.updateOfflineEquipment(inputData).subscribe(results => {
                                                            if (results['statusBool']) {
                                                              inputData._id = results.data._id;
                                                              // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                              _this.offlineStorage.equipmentHandler.updateSelectedEquipmentSyncStatus(results, ress.data.item(0), inputData, async function (res) {
                                                                subData.equipment.ref = results['data']._id;
                                                                subData.equipment.equipmentNumber = results['data'].equipmentNumber;
                                                                _this.offlineStorage.rfiHandler.updateSelectedRFI(_this, subData, async function (result3) {
                                                                  _this.offlineStorage.syncRFIHandler.getSelectedRFIToSend(_this, async function (res) {
                                                                    let _this = await res.self;
                                                                    if (res && res.data && res.data.length > 0) {
                                                                      var inputData = JSON.parse(res.data.item(0).inspectionData);
                                                                      _this.rfiInspectionService.updateOfflineRFI(inputData).subscribe(result => {
                                                                        if (result['statusBool']) {
                                                                          if (result.data && result.data._id) {
                                                                            // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                                            _this.offlineStorage.syncRFIHandler.updateSelectedRFISyncStatus(_this, result.data, async function (res) {
                                                                              _this.offlineStorage.workflowSyncHandler.getWorkflowSynctoSend(_this, async function (res) {
                                                                                let _this = await res.self;
                                                                                if (res && res.data && res.data.length > 0) {
                                                                                  var inputData = JSON.parse(res.data.item(0).workflowSyncData);
                                                                                  if (inputData.toUpdateStatus === 'Schedule rejected') {
                                                                                    _this.rfiInspectionService.updateStatusRejected(inputData).subscribe(result => {
                                                                                      if (result['statusBool']) {
                                                                                        // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                                                        _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                                                                                        });
                                                                                      }
                                                                                    })
                                                                                  } else if (inputData.toUpdateStatus === 'Cancelled') {
                                                                                    _this.rfiInspectionService.updateStatusCancelled(inputData).subscribe(result => {
                                                                                      if (result['statusBool']) {
                                                                                        // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                                                        _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                                                                                        });
                                                                                      }
                                                                                    })
                                                                                  } else {
                                                                                    _this.rfiInspectionService.updateStatus(inputData).subscribe(result => {
                                                                                      if (result['statusBool']) {
                                                                                        // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                                                        _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                                                                                          _this.offlineStorage.rfiReporthandler.getSelectedRFIReportToSend(_this, async function (resR) {
                                                                                            let _this = await resR.self;
                                                                                            if (resR && resR.data && resR.data.length > 0) {
                                                                                              var inputData = JSON.parse(resR.data.item(0).rfiReportData);

                                                                                              _this.offlineStorage.receiverHandler.getReceiver(_this, async function (res1) {
                                                                                                let _this = await res1.self;
                                                                                                let receiverData = [];
                                                                                                if (res1 && res1.data && res1.data.length > 0) {
                                                                                                  for (let i = 0; i < res1.data.length; i++) {
                                                                                                    receiverData.push(JSON.parse(res1.data.item(i).receiverData));
                                                                                                  }
                                                                                                }
                                                                                                if (receiverData && receiverData.length > 0) {
                                                                                                  if (inputData.receiverId === undefined || inputData.receiverId) {
                                                                                                    const resultData = receiverData.find(element => element.name === inputData.technicalReviewer.technicalReviewerName);
                                                                                                    let receiver = 'New';
                                                                                                    if (inputData.receiverId === undefined || typeof inputData.receiverId !== 'string') {
                                                                                                      receiver = 'New'
                                                                                                    } else {
                                                                                                      receiver = 'Old'
                                                                                                    }
                                                                                                    resultData.receiver = receiver;
                                                                                                    _this.receiverService.offlineInsertReceiver(resultData).subscribe(result => {
                                                                                                      if (result['statusBool']) {
                                                                                                        let clientId = [];
                                                                                                        var data1 = { "name": resultData.name, "badgeNo": resultData.badgeNo, "emailID": resultData.emailID, "telephone": resultData.telephone, "createdBy": resultData.createdBy, "_id": result.data._id }
                                                                                                        clientId.push(resultData.client)
                                                                                                        data1['clientId'] = clientId;
                                                                                                        if (receiver === "New") {
                                                                                                          inputData.receiverId = result.data._id;

                                                                                                        }

                                                                                                        _this.offlineStorage.receiverHandler.updateSelectedReceiverRecord(result, res1.data.item(0), data1, async function (res) {
                                                                                                          _this.rfiInspectionService.updateRFIReport(inputData).subscribe(result => {
                                                                                                            if (result['statusBool']) {
                                                                                                              // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                                                                              _this.offlineStorage.rfiReporthandler.updateSelectedRFIReportSyncStatus(_this, resR.data.item(0), async function (res) {
                                                                                                                _this.offlineStorage.attachmentHandler.getSignaturetoSend(_this, async function (res2) {
                                                                                                                  let _this = await res2.self;
                                                                                                                  if (res2 && res2.data && res2.data.length > 0) {
                                                                                                                    var inputData = JSON.parse(res2.data.item(0).attachmentData);
                                                                                                                    _this.rfiInspectionService.updateSignature(inputData).subscribe(result => {
                                                                                                                      if (result['statusBool']) {
                                                                                                                        // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                                                                                        _this.offlineStorage.attachmentHandler.updateSignatureStatus(_this, res2.data.item(0), async function (res) {

                                                                                                                        });
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                });
                                                                                                              });
                                                                                                            }
                                                                                                          })
                                                                                                        });
                                                                                                      }
                                                                                                    });
                                                                                                  }
                                                                                                }

                                                                                              });
                                                                                            }
                                                                                            else {
                                                                                              _this.offlineStorage.attachmentHandler.getSignaturetoSend(_this, async function (res2) {
                                                                                                let _this = await res2.self;
                                                                                                if (res2 && res2.data && res2.data.length > 0) {
                                                                                                  var inputData = JSON.parse(res2.data.item(0).attachmentData);
                                                                                                  _this.rfiInspectionService.updateSignature(inputData).subscribe(result => {
                                                                                                    if (result['statusBool']) {
                                                                                                      // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                                                                      _this.offlineStorage.attachmentHandler.updateSignatureStatus(_this, res2.data.item(0), async function (res) {

                                                                                                      });
                                                                                                    }
                                                                                                  })
                                                                                                }
                                                                                              });
                                                                                            }

                                                                                          });
                                                                                        });
                                                                                      }
                                                                                    })
                                                                                  }
                                                                                }
                                                                              });
                                                                            });
                                                                          }
                                                                        }
                                                                      })
                                                                    }
                                                                  })
                                                                })
                                                              });
                                                            }
                                                          });

                                                        });
                                                      }
                                                    });
                                                  }
                                                }
                                              });
                                            }
                                          });
                                        }
                                      });
                                    }
                                  }
                                });
                              }
                            });
                          }
                        });

                      }

                    }
                  });
                }
              }
            });

          } else {
            _this.offlineStorage.syncRFIHandler.getSelectedRFIToSend(_this, async function (res) {
              let _this = await res.self;
              if (res && res.data && res.data.length > 0) {
                var inputData = JSON.parse(res.data.item(0).inspectionData);
                _this.rfiInspectionService.updateOfflineRFI(inputData).subscribe(result => {
                  if (result['statusBool']) {
                    if (result.data && result.data._id) {
                      // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                      _this.offlineStorage.syncRFIHandler.updateSelectedRFISyncStatus(_this, result.data, async function (res) {
                        _this.offlineStorage.workflowSyncHandler.getWorkflowSynctoSend(_this, async function (res) {
                          let _this = await res.self;
                          if (res && res.data && res.data.length > 0) {
                            var inputData = JSON.parse(res.data.item(0).workflowSyncData);
                            if (inputData.toUpdateStatus === 'Schedule rejected') {
                              _this.rfiInspectionService.updateStatusRejected(inputData).subscribe(result => {
                                if (result['statusBool']) {
                                  // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                  _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                                  });
                                }
                              })
                            } else if (inputData.toUpdateStatus === 'Cancelled') {
                              _this.rfiInspectionService.updateStatusCancelled(inputData).subscribe(result => {
                                if (result['statusBool']) {
                                  // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                  _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                                  });
                                }
                              })
                            } else {
                              _this.rfiInspectionService.updateStatus(inputData).subscribe(result => {
                                if (result['statusBool']) {
                                  // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                  _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                                    _this.offlineStorage.rfiReporthandler.getSelectedRFIReportToSend(_this, async function (resR) {
                                      let _this = await resR.self;
                                      if (resR && resR.data && resR.data.length > 0) {
                                        var inputData = JSON.parse(resR.data.item(0).rfiReportData);

                                        _this.offlineStorage.receiverHandler.getReceiver(_this, async function (res1) {
                                          let _this = await res1.self;
                                          let receiverData = [];
                                          if (res1 && res1.data && res1.data.length > 0) {
                                            for (let i = 0; i < res1.data.length; i++) {
                                              receiverData.push(JSON.parse(res1.data.item(i).receiverData));
                                            }
                                          }
                                          if (receiverData && receiverData.length > 0) {
                                            if (inputData.receiverId === undefined || inputData.receiverId) {
                                              const resultData = receiverData.find(element => element.name === inputData.technicalReviewer.technicalReviewerName);
                                              let receiver = 'New';
                                              if (inputData.receiverId === undefined || typeof inputData.receiverId !== 'string') {
                                                receiver = 'New'
                                              } else {
                                                receiver = 'Old'
                                              }
                                              resultData.receiver = receiver;
                                              _this.receiverService.offlineInsertReceiver(resultData).subscribe(result => {
                                                if (result['statusBool']) {
                                                  let clientId = [];
                                                  var data1 = { "name": resultData.name, "badgeNo": resultData.badgeNo, "emailID": resultData.emailID, "telephone": resultData.telephone, "createdBy": resultData.createdBy, "_id": result.data._id }
                                                  clientId.push(resultData.client)
                                                  data1['clientId'] = clientId;
                                                  if (receiver === "New") {
                                                    inputData.receiverId = result.data._id;

                                                  }

                                                  _this.offlineStorage.receiverHandler.updateSelectedReceiverRecord(result, res1.data.item(0), data1, async function (res) {
                                                    _this.rfiInspectionService.updateRFIReport(inputData).subscribe(result => {
                                                      if (result['statusBool']) {
                                                        // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                        _this.offlineStorage.rfiReporthandler.updateSelectedRFIReportSyncStatus(_this, resR.data.item(0), async function (res) {
                                                          _this.offlineStorage.attachmentHandler.getSignaturetoSend(_this, async function (res2) {
                                                            let _this = await res2.self;
                                                            if (res2 && res2.data && res2.data.length > 0) {
                                                              var inputData = JSON.parse(res2.data.item(0).attachmentData);
                                                              _this.rfiInspectionService.updateSignature(inputData).subscribe(result => {
                                                                if (result['statusBool']) {
                                                                  // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                                  _this.offlineStorage.attachmentHandler.updateSignatureStatus(_this, res2.data.item(0), async function (res) {

                                                                  });
                                                                }
                                                              })
                                                            }
                                                          });
                                                        });
                                                      }
                                                    })
                                                  });
                                                }
                                              });
                                            }
                                          }

                                        });
                                      } else {
                                        _this.offlineStorage.attachmentHandler.getSignaturetoSend(_this, async function (res2) {
                                          let _this = await res2.self;
                                          if (res2 && res2.data && res2.data.length > 0) {
                                            var inputData = JSON.parse(res2.data.item(0).attachmentData);
                                            _this.rfiInspectionService.updateSignature(inputData).subscribe(result => {
                                              if (result['statusBool']) {
                                                // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                _this.offlineStorage.attachmentHandler.updateSignatureStatus(_this, res2.data.item(0), async function (res) {

                                                });
                                              }
                                            })
                                          }
                                        });
                                      }
                                    });
                                  });
                                }
                              })
                            }
                          }
                        });
                      });
                    }
                  }
                })
              }
            })
          }
        } else {
          _this.offlineStorage.syncRFIHandler.getSelectedRFIToSend(_this, async function (res) {
            let _this = await res.self;
            if (res && res.data && res.data.length > 0) {
              var inputData = JSON.parse(res.data.item(0).inspectionData);
              _this.rfiInspectionService.updateOfflineRFI(inputData).subscribe(result => {
                if (result['statusBool']) {
                  if (result.data && result.data._id) {
                    // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                    _this.offlineStorage.syncRFIHandler.updateSelectedRFISyncStatus(_this, result.data, async function (res) {
                      _this.offlineStorage.workflowSyncHandler.getWorkflowSynctoSend(_this, async function (res) {
                        let _this = await res.self;
                        if (res && res.data && res.data.length > 0) {
                          var inputData = JSON.parse(res.data.item(0).workflowSyncData);
                          if (inputData.toUpdateStatus === 'Schedule rejected') {
                            _this.rfiInspectionService.updateStatusRejected(inputData).subscribe(result => {
                              if (result['statusBool']) {
                                // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                                });
                              }
                            })
                          } else if (inputData.toUpdateStatus === 'Cancelled') {
                            _this.rfiInspectionService.updateStatusCancelled(inputData).subscribe(result => {
                              if (result['statusBool']) {
                                // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                                });
                              }
                            })
                          } else {
                            _this.rfiInspectionService.updateStatus(inputData).subscribe(result => {
                              if (result['statusBool']) {
                                // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                                  _this.offlineStorage.rfiReporthandler.getSelectedRFIReportToSend(_this, async function (resR) {
                                    let _this = await resR.self;
                                    if (resR && resR.data && resR.data.length > 0) {
                                      var inputData = JSON.parse(resR.data.item(0).rfiReportData);

                                      _this.offlineStorage.receiverHandler.getReceiver(_this, async function (res1) {
                                        let _this = await res1.self;
                                        let receiverData = [];
                                        if (res1 && res1.data && res1.data.length > 0) {
                                          for (let i = 0; i < res1.data.length; i++) {
                                            receiverData.push(JSON.parse(res1.data.item(i).receiverData));
                                          }
                                        }
                                        if (receiverData && receiverData.length > 0) {
                                          if (inputData.receiverId === undefined || inputData.receiverId) {
                                            const resultData = receiverData.find(element => element.name === inputData.technicalReviewer.technicalReviewerName);
                                            let receiver = 'New';
                                            if (inputData.receiverId === undefined || typeof inputData.receiverId !== 'string') {
                                              receiver = 'New'
                                            } else {
                                              receiver = 'Old'
                                            }
                                            resultData.receiver = receiver;
                                            _this.receiverService.offlineInsertReceiver(resultData).subscribe(result => {
                                              if (result['statusBool']) {
                                                let clientId = [];
                                                var data1 = { "name": resultData.name, "badgeNo": resultData.badgeNo, "emailID": resultData.emailID, "telephone": resultData.telephone, "createdBy": resultData.createdBy, "_id": result.data._id }
                                                clientId.push(resultData.client)
                                                data1['clientId'] = clientId;
                                                if (receiver === "New") {
                                                  inputData.receiverId = result.data._id;

                                                }

                                                _this.offlineStorage.receiverHandler.updateSelectedReceiverRecord(result, res1.data.item(0), data1, async function (res) {
                                                  _this.rfiInspectionService.updateRFIReport(inputData).subscribe(result => {
                                                    if (result['statusBool']) {
                                                      // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                      _this.offlineStorage.rfiReporthandler.updateSelectedRFIReportSyncStatus(_this, resR.data.item(0), async function (res) {
                                                        _this.offlineStorage.attachmentHandler.getSignaturetoSend(_this, async function (res2) {
                                                          let _this = await res2.self;
                                                          if (res2 && res2.data && res2.data.length > 0) {
                                                            var inputData = JSON.parse(res2.data.item(0).attachmentData);
                                                            _this.rfiInspectionService.updateSignature(inputData).subscribe(result => {
                                                              if (result['statusBool']) {
                                                                // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                                _this.offlineStorage.attachmentHandler.updateSignatureStatus(_this, res2.data.item(0), async function (res) {

                                                                });
                                                              }
                                                            })
                                                          }
                                                        });
                                                      });
                                                    }
                                                  })
                                                });
                                              }
                                            });
                                          }
                                        }

                                      });
                                    } else {
                                      _this.offlineStorage.attachmentHandler.getSignaturetoSend(_this, async function (res2) {
                                        let _this = await res2.self;
                                        if (res2 && res2.data && res2.data.length > 0) {
                                          var inputData = JSON.parse(res2.data.item(0).attachmentData);
                                          _this.rfiInspectionService.updateSignature(inputData).subscribe(result => {
                                            if (result['statusBool']) {
                                              // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                              _this.offlineStorage.attachmentHandler.updateSignatureStatus(_this, res2.data.item(0), async function (res) {

                                              });
                                            }
                                          })
                                        }
                                      });
                                    }
                                  });
                                });
                              }
                            })
                          }
                        }
                      });
                    });
                  }
                }
              })
            }
          })
        }
      }
      else {
        _this.offlineStorage.rfiReporthandler.getSelectedRFIReportToSend(_this, async function (resR) {
          let _this = await resR.self;
          if (resR && resR.data && resR.data.length > 0) {
            var inputData = JSON.parse(resR.data.item(0).rfiReportData);

            _this.offlineStorage.receiverHandler.getReceiver(_this, async function (res1) {
              let _this = await res1.self;
              let receiverData = [];
              if (res1 && res1.data && res1.data.length > 0) {
                for (let i = 0; i < res1.data.length; i++) {
                  receiverData.push(JSON.parse(res1.data.item(i).receiverData));
                }
              }
              if (receiverData && receiverData.length > 0) {
                if (inputData.receiverId === undefined || inputData.receiverId) {
                  const resultData = receiverData.find(element => element.name === inputData.technicalReviewer.technicalReviewerName);
                  let receiver = 'New';
                  if (inputData.receiverId === undefined || typeof inputData.receiverId !== 'string') {
                    receiver = 'New'
                  } else {
                    receiver = 'Old'
                  }
                  resultData.receiver = receiver;
                  _this.receiverService.offlineInsertReceiver(resultData).subscribe(result => {
                    if (result['statusBool']) {
                      let clientId = [];
                      var data1 = { "name": resultData.name, "badgeNo": resultData.badgeNo, "emailID": resultData.emailID, "telephone": resultData.telephone, "createdBy": resultData.createdBy, "_id": result.data._id }
                      clientId.push(resultData.client)
                      data1['clientId'] = clientId;
                      if (receiver === "New") {
                        inputData.receiverId = result.data._id;

                      }
                      _this.offlineStorage.receiverHandler.updateSelectedReceiverRecord(result, res1.data.item(0), data1, async function (res) {
                        _this.rfiInspectionService.updateRFIReport(inputData).subscribe(result => {
                          if (result['statusBool']) {
                            // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                            _this.offlineStorage.rfiReporthandler.updateSelectedRFIReportSyncStatus(_this, resR.data.item(0), async function (res) {
                              _this.offlineStorage.attachmentHandler.getSignaturetoSend(_this, async function (res2) {
                                let _this = await res2.self;
                                if (res2 && res2.data && res2.data.length > 0) {
                                  var inputData = JSON.parse(res2.data.item(0).attachmentData);
                                  _this.rfiInspectionService.updateSignature(inputData).subscribe(result => {
                                    if (result['statusBool']) {
                                      // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                      _this.offlineStorage.attachmentHandler.updateSignatureStatus(_this, res2.data.item(0), async function (res) {

                                      });
                                    }
                                  })
                                }
                              });
                            });
                          }
                        })
                      });
                    }
                  });
                }
              }

            });
          } else {
            _this.offlineStorage.attachmentHandler.getSignaturetoSend(_this, async function (res2) {
              let _this = await res2.self;
              if (res2 && res2.data && res2.data.length > 0) {
                var inputData = JSON.parse(res2.data.item(0).attachmentData);
                _this.rfiInspectionService.updateSignature(inputData).subscribe(result => {
                  if (result['statusBool']) {
                    // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                    _this.offlineStorage.attachmentHandler.updateSignatureStatus(_this, res2.data.item(0), async function (res) {

                    });
                  }
                })
              } else {
                _this.offlineStorage.workflowSyncHandler.getWorkflowSynctoSend(_this, async function (res) {
                  let _this = await res.self;
                  if (res && res.data && res.data.length > 0) {
                    var inputData = JSON.parse(res.data.item(0).workflowSyncData);
                    if (inputData.toUpdateStatus === 'Schedule rejected') {
                      _this.rfiInspectionService.updateStatusRejected(inputData).subscribe(result => {
                        if (result['statusBool']) {
                          // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                          _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                          });
                        }
                      })
                    } else if (inputData.toUpdateStatus === 'Cancelled') {
                      _this.rfiInspectionService.updateStatusCancelled(inputData).subscribe(result => {
                        if (result['statusBool']) {
                          // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                          _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                          });
                        }
                      })
                    } else {
                      _this.rfiInspectionService.updateStatus(inputData).subscribe(result => {
                        if (result['statusBool']) {
                          // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                          _this.offlineStorage.workflowSyncHandler.updateSelectedWorkflowSyncStatus(_this, res.data.item(0), async function (res) {
                            _this.offlineStorage.rfiReporthandler.getSelectedRFIReportToSend(_this, async function (resR) {
                              let _this = await resR.self;
                              if (resR && resR.data && resR.data.length > 0) {
                                var inputData = JSON.parse(resR.data.item(0).rfiReportData);

                                _this.offlineStorage.receiverHandler.getReceiver(_this, async function (res1) {
                                  let _this = await res1.self;
                                  let receiverData = [];
                                  if (res1 && res1.data && res1.data.length > 0) {
                                    for (let i = 0; i < res1.data.length; i++) {
                                      receiverData.push(JSON.parse(res1.data.item(i).receiverData));
                                    }
                                  }
                                  if (receiverData && receiverData.length > 0) {
                                    if (inputData.receiverId === undefined || inputData.receiverId) {
                                      const resultData = receiverData.find(element => element.name === inputData.technicalReviewer.technicalReviewerName);
                                      let receiver = 'New';
                                      if (inputData.receiverId === undefined || typeof inputData.receiverId !== 'string') {
                                        receiver = 'New'
                                      } else {
                                        receiver = 'Old'
                                      }
                                      resultData.receiver = receiver;
                                      _this.receiverService.offlineInsertReceiver(resultData).subscribe(result => {
                                        if (result['statusBool']) {
                                          let clientId = [];
                                          var data1 = { "name": resultData.name, "badgeNo": resultData.badgeNo, "emailID": resultData.emailID, "telephone": resultData.telephone, "createdBy": resultData.createdBy, "_id": result.data._id }
                                          clientId.push(resultData.client)
                                          data1['clientId'] = clientId;
                                          if (receiver === "New") {
                                            inputData.receiverId = result.data._id;

                                          }

                                          _this.offlineStorage.receiverHandler.updateSelectedReceiverRecord(result, res1.data.item(0), data1, async function (res) {
                                            _this.rfiInspectionService.updateRFIReport(inputData).subscribe(result => {
                                              if (result['statusBool']) {
                                                // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                _this.offlineStorage.rfiReporthandler.updateSelectedRFIReportSyncStatus(_this, resR.data.item(0), async function (res) {
                                                  _this.offlineStorage.attachmentHandler.getSignaturetoSend(_this, async function (res2) {
                                                    let _this = await res2.self;
                                                    if (res2 && res2.data && res2.data.length > 0) {
                                                      var inputData = JSON.parse(res2.data.item(0).attachmentData);
                                                      _this.rfiInspectionService.updateSignature(inputData).subscribe(result => {
                                                        if (result['statusBool']) {
                                                          // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                                          _this.offlineStorage.attachmentHandler.updateSignatureStatus(_this, res2.data.item(0), async function (res) {

                                                          });
                                                        }
                                                      })
                                                    }
                                                  });
                                                });
                                              }
                                            })
                                          });
                                        }
                                      });
                                    }
                                  }

                                });
                              }
                              else {
                                _this.offlineStorage.attachmentHandler.getSignaturetoSend(_this, async function (res2) {
                                  let _this = await res2.self;
                                  if (res2 && res2.data && res2.data.length > 0) {
                                    var inputData = JSON.parse(res2.data.item(0).attachmentData);
                                    _this.rfiInspectionService.updateSignature(inputData).subscribe(result => {
                                      if (result['statusBool']) {
                                        // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
                                        _this.offlineStorage.attachmentHandler.updateSignatureStatus(_this, res2.data.item(0), async function (res) {

                                        });
                                      }
                                    })
                                  }
                                });
                              }

                            });
                          });
                        }
                      })
                    }
                  }
                });
              }
            });
          }
        });
      }
    })
    this.offlineStorage.attachmentHandler.getSignaturetoSend(this, async function (res2) {
      let _this = await res2.self;
      if (res2 && res2.data && res2.data.length > 0) {
        var inputData = JSON.parse(res2.data.item(0).attachmentData);
        _this.rfiInspectionService.updateSignature(inputData).subscribe(result => {
          if (result['statusBool']) {
            // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
            _this.offlineStorage.attachmentHandler.updateSignatureStatus(_this, res2.data.item(0), async function (res) {

            });
          }
        })
      }else{
        _this.updateEquipmentCompany()
      }
    });
  }
  updateEquipmentCompany(){
    this.offlineStorage.equipmentHandler.getSelectedEquipmentToSend(this, async function (resss) {
      let _this = await resss.self;
      if (resss && resss.data && resss.data.length > 0) {
        var inputData = JSON.parse(resss.data.item(0).equipmentData);
        if (inputData._id) {
          // var data2 = { _id: inputData.location.ref }
          _this.rfiInspectionService.updateOfflineEquipment(inputData).subscribe(results => {
            if (results['statusBool']) {
              inputData._id = results.data._id;
              // UPDATE SYNCED STATUS ON OFFLINE AGAINST THAT RECORD
              _this.offlineStorage.equipmentHandler.updateSelectedEquipmentSyncStatus(results, resss.data.item(0), inputData, async function (res) {
              });
            }
          })
         
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
    if (this.id1) {
      clearInterval(this.id1);
    }
  }
}


