import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ChecklistService } from '../../../master/checklist/checklist.service';
import { InspectorService } from '../inspector.service';
import { UserService } from '../../../admin/user/user.service';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatPaginator,
  MatSort,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { filter, result } from 'lodash';
import * as moment from 'moment';
import * as _ from 'underscore';

@Component({
  selector: 'app-inspector-from',
  templateUrl: './inspector-from.component.html',
  styleUrls: ['./inspector-from.component.css']
})
export class InspectorFromComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  InspectorFromComponent: any;
  checklists: any;
  anotherchecklists: any;
  user: any;
  rowID: any;
  checklistID: any;


  // For Save
  Checklist = null;
  ChecklistName = null;
  isSave = false;
  isDelete = false;
  Exist = false;

  dataSourcelist;
  dataSourceCategorylist;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumnslist = [
    'checkListName',
    'expriyDate'

  ];
  displayedColumnsCategorylist = [
    'categoryName'

  ];
  temp = [];
  temp1 = [];
  temp2 = [];
  tempCategory = [];
  categoryDetails = [];
  categoryDetailLatest = [];
  checklistDetailLatest = [];
  CheckExistRecord = [];
  CheckExist = [];
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<InspectorService>,
    private ChecklistService: ChecklistService,
    private UserService: UserService,

    @Inject(MAT_DIALOG_DATA) private data: any,
    private InspectorService: InspectorService,
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,

  ) {
    this.InspectorFromComponent = fb.group({
      'checklist': [null, Validators.compose([Validators.required])],
      'expriyDate': [null, Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
    this.rowID = this.data.data._id;
    this.user = this.getFromLocalStorage('userDet');
    this.getChecklist();
    // if (this.isSave == false) {
    // this.getTableData();
    // }

  }


  getTableData() {
    this.temp = [];
    this.temp1 = [];
    this.categoryDetails = [];
    this.checklistDetailLatest = [];
    this.categoryDetailLatest = [];
    this.dataSourcelist = [];
    this.dataSourceCategorylist = [];
    this.InspectorService.getInspectorCheckList(this.rowID).subscribe(data => {
      if (!data) {
        return;
      }
      this.temp = [];
      this.temp1 = [];
      this.categoryDetails = [];
      this.checklistDetailLatest = [];
      this.categoryDetailLatest = [];
      this.dataSourcelist = [];
      this.dataSourceCategorylist = [];
      this.temp = data;
      if (this.temp.length > 0) {
        this.temp1 = this.temp[0].checklist;
        this.categoryDetails = this.temp[0].category;
      }

      for (let i = 0; i < this.temp1.length; i++) {
        if (this.temp1[i].isActive === true) {
          this.checklistDetailLatest.push(this.temp1[i])
        }
      }
      for (let i = 0; i < this.categoryDetails.length; i++) {
        if (this.categoryDetails[i].isActive === true) {
          this.categoryDetailLatest.push(this.categoryDetails[i])
        }
      }
      debugger
      for (let i = 0; i < this.checklistDetailLatest.length; i++) {
        if (this.checklistDetailLatest[i].checkListName !== undefined) {

          let tempData = _.filter(this.checklists, ele => ele.isActive === true && ele._id === this.checklistDetailLatest[i].ref);
          if (tempData && tempData.length > 0) {
            this.checklistDetailLatest[i].checkListName = tempData[0].name;
          }else{
            this.checklistDetailLatest[i].checkListName = this.checklistDetailLatest[i].checkListName;

          }
        }
        if (this.checklistDetailLatest[i].expriyDate) { this.checklistDetailLatest[i].expriyDate = moment(new Date(this.checklistDetailLatest[i].expriyDate)).format('DD/MM/YYYY'); }

      }
      for (let i = 0; i < this.categoryDetailLatest.length; i++) {
        if (this.categoryDetailLatest[i].categoryName !== undefined) {
          this.categoryDetailLatest[i].categoryName = this.categoryDetailLatest[i].categoryName;
        }

      }
      //this.temp1 = this.temp;
      this.dataSourcelist = new MatTableDataSource(this.checklistDetailLatest);
      this.dataSourcelist.paginator = this.paginator;
      this.dataSourcelist.sort = this.sort;

      this.dataSourceCategorylist = new MatTableDataSource(this.categoryDetailLatest);
      this.dataSourceCategorylist.paginator = this.paginator;
      this.dataSourceCategorylist.sort = this.sort;

    });
  }
  ChekExistandSave() {
    this.Exist = false;
    this.CheckExistRecord = [];
    this.CheckExist = [];
    this.checklistID = this.InspectorFromComponent.controls.checklist.value;
    this.InspectorService.getInspectorCheckList(this.rowID).subscribe(data => {
      if (!data) {
        return;
      }
      this.CheckExist = data;
      if (this.CheckExist.length > 0) {
        this.CheckExistRecord = this.CheckExist[0].checklist;
      }

      for (let i = 0; i < this.CheckExistRecord.length; i++) {
        if (this.CheckExistRecord[i].isActive === true && this.CheckExistRecord[i].ref === this.checklistID) {
          this.snackBar.open('Duplicate entry', '', { duration: 2000, verticalPosition: "bottom" });
          this.Exist = true;
        }

      }
      if (this.Exist == false) {
        this.save();
      }
    });
  }

  save() {
    this.Exist = false;
    this.checklistID = this.InspectorFromComponent.controls.checklist.value;
    this.ChecklistService.getCheckListCategory(this.checklistID).subscribe(data => {
      if (!data) {
        return;
      }
      this.temp2 = data;
      this.tempCategory = this.temp2[0].category;
      for (let i = 0; i < this.tempCategory.length; i++) {
        if (this.tempCategory[i].name !== undefined) {
          this.tempCategory[i].name = this.tempCategory[i].name;
          this.tempCategory[i]._id = this.tempCategory[i]._id;
        }
      }
    });

    var inputData = {
      Id: this.rowID,
      checklist: this.InspectorFromComponent.controls.checklist.value,
      expriyDate: this.InspectorFromComponent.controls.expriyDate.value,
      checklistName: null,
      createdBy: null,

    }

    if (inputData.checklist) {
      let filteredChecklist = this.checklists.filter(ele => ele._id === inputData.checklist);
      if (filteredChecklist.length > 0) {
        this.ChecklistName = filteredChecklist[0].name;
      }
    } else { inputData.checklist = null }

    inputData.createdBy = this.user.data.name;
    inputData.checklistName = this.ChecklistName;

    this.InspectorService.save(inputData).subscribe(result => {
      if (result['statusBool']) {
        for (let i = 0; i < this.tempCategory.length; i++) {
          if (this.tempCategory[i].name !== undefined) {
            this.tempCategory[i].name = this.tempCategory[i].name;
            this.tempCategory[i]._id = this.tempCategory[i]._id;
          }

          var inputData1 = {
            Id: this.rowID,
            categoryName: this.tempCategory[i].name,
            categoryId: this.tempCategory[i]._id,
            checklist: this.InspectorFromComponent.controls.checklist.value,
            createdBy: null,

          }
          inputData1.createdBy = this.user.data.name;
          this.InspectorService.categoryInsert(inputData1).subscribe(result => {
            if (result['statusBool']) {
              this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
              this.ngOnInit();
            } else {
              this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
            }
          });
        }
        this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        this.isSave = true;
        this.ngOnInit();

      } else {
        this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
      }
    }, err => {
      if (err.error.statusHttp === 412) {
        this.snackBar.open(err.error['message'], '', { duration: 2000, verticalPosition: "bottom" });
      }
    });
  }
  get f() {
    return this.InspectorFromComponent.controls;
  }

  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }

  deleteTable(item) {
    const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
      data: { deleteItem: true },
      disableClose: false,
      hasBackdrop: true
    });
    var data = {
      checklistID: item.ref,
      Id: this.rowID,
    }
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        this.InspectorService.deleteChecklistById(data).subscribe(res => {
          if (res['statusBool']) {
            //this.isDelete=true;
            //this.getTableData();
            this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
        });
        this.InspectorService.deleteCategoryId(data).subscribe(res => {
          if (res['statusBool']) {
            this.isDelete = true;
            this.getTableData();
            this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
        });

      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }

  convertISO(string) {
    if (string) {
      var date
      date = string.split("/");    // ["29", "1", "2016"]
      return new Date(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]));
    }
    else {
      return null;
    }
  }

  getChecklist() {
    this.ChecklistService.getChecklist().subscribe(result => {
      this.checklists = result;
      this.anotherchecklists = this.checklists;
      this.getTableData() 
    });
  }

  filterChecklist(val) {
    val = val.toLowerCase();
    this.anotherchecklists = this.checklists.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val);
    });
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  btnClose() {
    if (this.InspectorFromComponent.dirty) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: true,
        hasBackdrop: true
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

}




