import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { RfiInspectionService } from '../rfi-inspection.service';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { timer } from 'rxjs';
import { ReportService } from '../../../services/report/report.service';
import { ReceiverService } from '../../../master/receiver/receiver.service';
import { FileService } from '../../../services/files/file.service';
import { OfflineStorageService } from 'src/app/mobile-native-interface/offline-storage.service.service';
import { CommandPopupComponent } from '../../../general/command-popup/command-popup.component';
import { SettingService } from '../../../services/setting/setting.service';

@Component({
  selector: 'app-workflow-popup',
  templateUrl: './workflow-popup.component.html',
  styleUrls: ['./workflow-popup.component.css']
})
export class WorkflowPopupComponent implements OnInit {

  htmlText = '';
  htmlText2 = '';
  status = '';
  user: any;
  technicalReviwerEmail = '';
  technicalReviwerMobile = '';
  isOTP = false;
  otpForm: any;
  interval;
  timeLeft = 300;
  showResendText = false;
  isOTPButton = true;
  attachment = [{ attachment: '', filename: '' }];
  isOffline = false;
  isDisable = false;
  timer: any;
  popupCount: any = 0;
  SMS = '';
  companyName = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<WorkflowPopupComponent>,
    private rfiInspectionService: RfiInspectionService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private reportService: ReportService,
    private receiverService: ReceiverService,
    private fileService: FileService,
    private offlineStorage: OfflineStorageService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private settingService: SettingService,

  ) {
    this.otpForm = fb.group({
      'otp': [null]
    });
  }

  ngOnInit() {
    this.isOffline = this.data.isOfflineInspection;
    this.user = this.getFromLocalStorage('userDet');
    this.status = this.data.activatedStatusName;
    this.getMobileOtp();
    this.getStatus();
    this.getClientName();
    if (this.status === 'Review completed. Waiting for OTP approval') {
      this.getTechnicalReviewer();
      this.startTimer();
    }

  }

  startTimer() {
    this.interval = setInterval(() => {
      let minutes, seconds;

      if (this.timeLeft > 0) {
        minutes = (this.timeLeft / 60);
        seconds = (this.timeLeft % 60);
        minutes = parseInt(minutes);

        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        this.timer = minutes + " Minutes " + seconds + " Seconds left ....";

        this.timeLeft--;
        if (this.timeLeft === 1) {
          this.updateotp();
        }
        this.showResendText = false;
      } else {
        this.showResendText = true;
      }
    }, 1000)
  }

  updateotp() {
    var data = {
      _id: this.data._id
    }
    this.rfiInspectionService.otpTimerOut(data).subscribe(result => {
      console.log(result);
    })
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  getMobileOtp() {
    this.settingService.getByKey('SMS').subscribe(result => {
      if (result.length > 0) {
        this.SMS = result[0].value;
      }
    })

  }

  getClientName() {
    this.companyName = localStorage.getItem('ClientName');

  }
  approveSchedule() {
    console.log(this.companyName)
    this.isDisable = true;
    if (this.status === "Reject" && this.popupCount == 0 && this.companyName === 'SGS') {
      this.htmlText = 'ARE YOU SURE YOU WANT TO REJECT ?'
      this.isDisable = false;;
      this.popupCount = this.popupCount + 1;
      return;
    }

    if (this.data && this.data.isOfflineInspection) {
      if (this.data.activatedStatusName === 'Inspection scheduled') {
        this.data.toUpdateStatus = 'Schedule accepted';
      } else if (this.data.activatedStatusName === 'Schedule accepted') {
        this.data.toUpdateStatus = 'Inspection completed. Send for review';
      } else if (this.data.activatedStatusName === 'Inspection completed. Send for review') {
        this.data.toUpdateStatus = 'Waiting for review'
      } else if (this.data.activatedStatusName === 'Waiting for review') {
        this.data.toUpdateStatus = 'Review completed. Waiting for OTP approval'
      }
      else if (this.data.activatedStatusName === 'Review completed. Waiting for OTP approval') {
        this.data.toUpdateStatus = 'Approved'
      }

      if (this.status === 'Reject') {
        this.data.toUpdateStatus = 'Schedule rejected';
      } else if (this.status === 'Cancel') {
        this.data.toUpdateStatus = 'Cancelled';
      }

      this.offlineStorage.workflowHandler.getWorkflowByRFIId(this, this.data._id, function (res) {
        let _this = res.self;
        let resultData = [];
        if (res.data.rows.length > 0) {
          for (var i = 0; i < res.data.rows.length; i++) {
            resultData.push(res.data.rows.item(i));
          }
        }

        var toUpdateData;

        if (resultData.length > 0) {
          for (var i = 0; i < resultData.length; i++) {
            var parsedData = JSON.parse(resultData[i].workflowData);
            if (parsedData.status == _this.data.toUpdateStatus) {
              _this.data.workFlowId = parsedData._id;
              _this.data.workFlowStatusName = parsedData.status;
              toUpdateData = parsedData
            }

          }
          toUpdateData.isActive = true;
          toUpdateData.updatedBy = _this.user.data.name;
        }
        _this.offlineStorage.workflowSyncHandler.insertWorkFlowHandler(_this, _this.data, function (result) {

          _this.offlineStorage.workflowHandler.updateWorkflow(_this, toUpdateData, function (res) {
            if (res.status) {
              _this.offlineStorage.rfiHandler.getRFIById(_this, _this.data, function (res) {
                var rfiData;

                let resultData = [];
                if (res.data.length > 0) {
                  for (var j = 0; j < res.data.length; j++) {
                    resultData.push(res.data.item(j));
                  }
                }

                if (resultData.length > 0) {
                  rfiData = JSON.parse(resultData[0].inspectionData);
                  rfiData.activatedStatus.ref = _this.data.workFlowId;
                  rfiData.activatedStatus.status = _this.data.workFlowStatusName;
                }
                _this.offlineStorage.rfiHandler.updateSelectedRFI(_this, rfiData, function (res) {
                  let _this = res.self;
                  _this.snackBar.open(res.data, '', { duration: 2000, verticalPosition: "bottom" });
                  _this.dialogRef.close();
                  _this.dummyClickEventForPageRender();
                });

              })
            }
          })
        })

      })
    } else {
      this.data.updatedBy = this.user.data.name;
      if (this.status === 'Reject') {
        this.updateCommand('Reject');
      } else if (this.status === 'Cancel') {
        this.updateCommand('Cancel');
      } else if (this.status === 'ReInspect') {
        this.updateCommand('ReInspect');
      } else {
        this.rfiInspectionService.updateStatus(this.data).subscribe(result => {
          if (result['statusBool']) {
            this.dialogRef.close();
            this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
        })
      }
    }
  }


  updateCommand(status) {
    this.data.moduleStatus = status;
    this.dialogRef.updateSize("0px", "0px");
    let dialogRef = this.dialog.open(CommandPopupComponent, {
      data: this.data,
      disableClose: true
    });



    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.isOffline = this.data.isReadyToInspection;
        if (this.isOffline) {
          if (this.status === 'Reject') {
            this.data.toUpdateStatus = 'Schedule rejected';
          } else if (this.status === 'Cancel') {
            this.data.toUpdateStatus = 'Cancelled';
          }
          this.offlineStorage.workflowHandler.getWorkflowByRFIId(this, this.data._id, function (res) {
            let _this = res.self;
            let resultData = [];
            if (res.data.rows.length > 0) {
              for (var i = 0; i < res.data.rows.length; i++) {
                resultData.push(res.data.rows.item(i));
              }
            }
            // let resultData = res.data.rows;
            var toUpdateData;
            var finalData = [];
            if (resultData.length > 0) {
              for (var i = 0; i < resultData.length; i++) {
                var parsedData = JSON.parse(resultData[i].workflowData);
                if (parsedData.status == _this.data.toUpdateStatus) {
                  _this.data.workFlowId = parsedData._id;
                  _this.data.workFlowStatusName = parsedData.status;
                  toUpdateData = parsedData
                }
              }
              toUpdateData.isActive = true;
              toUpdateData.updatedBy = _this.user.data.name;
            }
            _this.offlineStorage.rfiHandler.getRFIById(_this, _this.data, function (res) {
              if (res && res.data && res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                  finalData.push(JSON.parse(res.data.item(i).inspectionData));
                }

              }
              if (finalData.length > 0) {
                _this.data["comment"] = finalData[0].comment
              }


              _this.offlineStorage.workflowSyncHandler.insertWorkFlowHandler(_this, _this.data, function (result) {


                _this.offlineStorage.workflowHandler.updateWorkflow(_this, toUpdateData, function (res) {
                  if (res.status) {
                    _this.offlineStorage.rfiHandler.getRFIById(_this, _this.data, function (res) {
                      var rfiData;
                      if (res.data.length > 0) {
                        rfiData = JSON.parse(res.data.item(0).inspectionData);
                        rfiData.activatedStatus.ref = _this.data.workFlowId;
                        rfiData.activatedStatus.status = _this.data.workFlowStatusName;
                      }
                      _this.offlineStorage.rfiHandler.updateSelectedRFI(_this, rfiData, function (res) {
                        let _this = res.self;
                        _this.snackBar.open(res.data, '', { duration: 2000, verticalPosition: "bottom" });
                        _this.dialogRef.close();
                        _this.dummyClickEventForPageRender();
                      });

                    })
                  }
                })
              })
            });
          })
        } else {
          if (this.status === 'Cancel') {
            this.rfiInspectionService.updateStatusCancelled(this.data).subscribe(result => {
              if (result['statusBool']) {
                this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
                this.dialogRef.close();
                this.ngOnInit();
              }
            })
          } else if (this.status === 'Reject') {
            this.rfiInspectionService.updateStatusRejected(this.data).subscribe(result => {
              if (result['statusBool']) {
                this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
                this.dialogRef.close();
                this.ngOnInit();
              }
            })
          } else if (this.status === 'ReInspect') {
            this.rfiInspectionService.updateStatusReInspect(this.data).subscribe(result => {
              if (result['statusBool']) {
                this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
                this.dialogRef.close();
                this.ngOnInit();
              }
            })
          }
        }

      } else {
        this.dialogRef.close();
      }
    });
  }

  verifyOTP() {
    this.isOTPButton = false;
    var data = {
      _id: this.data._id,
      givenOTP: this.otpForm.controls.otp.value
    }
    this.rfiInspectionService.verifyOTP(data).subscribe(result => {
      if (result['statusBool']) {
        this.snackBar.open('OTP approved', '', { duration: 2000, verticalPosition: "bottom" });
        this.isOTPButton = true;
        this.dialogRef.close();
        // this.data.createdBy = this.user.data.name;
        // this.reportService.generateRFIReport(this.data).subscribe(result => {
        //   if (result['statusBool']) {
        //     const inputData = {
        //       filetype: "application/pdf",
        //       filename: this.data.reportNo + ".pdf",
        //       base64: "data:application/pdf;base64," + result['data'],
        //       module: 'RFI',
        //       refID: this.data._id,
        //       createdBy: this.user.data.name
        //     };
        //     this.fileService.uploadFileForRFIReport(inputData).subscribe(result => {
        //       if (result['statusBool']) {
        //         if (this.data.checkListDetails && this.data.checkListDetails.length > 0) {
        //           this.attachment = [];
        //           var data = {
        //             rfiData: this.data,
        //             row: this.data.checkListDetails,
        //             createdBy: this.user.data.name
        //           }
        //           this.reportService.generateChecklistReports(data).subscribe(res => {
        //             if (res['statusBool']) {
        //               this.snackBar.open('OTP approved', '', { duration: 2000, verticalPosition: "bottom" });
        //               this.isOTPButton = true;
        //               this.dialogRef.close();
        //             }
        //           })
        //         } else {
        //           this.snackBar.open('OTP approved', '', { duration: 2000, verticalPosition: "bottom" });
        //           this.isOTPButton = true;
        //           this.dialogRef.close();
        //         }
        //       }
        //     })
        //   } else {
        //     this.isOTPButton = true;
        //     this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
        //   }
        // })

        // this.snackBar.open('OTP approved', '', { duration: 2000, verticalPosition: "bottom" });
        // this.isOTPButton = true;
        // this.dialogRef.close();
      } else {
        this.isOTPButton = true;
        this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
      }
    })
  }

  getTechnicalReviewer() {
    var data = {
      _id: this.data._id
    }
    this.rfiInspectionService.getReportDataByScheduleId(data).subscribe(result => {
      if (result.length > 0) {
        if (result[0].receiverId) {
          var data1 = {
            _id: result[0].receiverId
          }
          this.receiverService.getRecordsById(data1).subscribe(result1 => {
            if (result1 && result1.length > 0) {
              this.technicalReviwerEmail = result1[0].emailID;
              this.technicalReviwerMobile = result1[0].telephone;
              this.getStatus();
              this.sendOTP();
            }
          });
        } else {

          if (this.data && this.data.scheduleInspectionDetail) {
            if (this.data.scheduleInspectionDetail.receiverId) {
              var data1 = {
                _id: this.data.scheduleInspectionDetail.receiverId
              }
              this.receiverService.getRecordsById(data1).subscribe(result1 => {
                if (result1 && result1.length > 0) {
                  this.technicalReviwerEmail = result1[0].emailID;
                  this.technicalReviwerMobile = result1[0].telephone;
                  this.getStatus();
                  this.sendOTP();
                }
              });
            }
          }
        }

      } else {
        this.getStatus();
      }
    })
  }

  sendOTP() {
    var data = {
      _id: this.data._id,
      technicalEmail: this.technicalReviwerEmail,
      reportNo: this.data.reportNo
    }
    this.rfiInspectionService.sendOTPToMail(data).subscribe(result => {
      // this.snackBar.open(result['message'], '', { duration: 2000, verticalPosition: "bottom" });
    })
  }

  resendOTP() {
    this.sendOTP();
    this.showResendText = false;
    this.timeLeft = 300;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft === 1) {
          this.updateotp();
        }
        this.showResendText = false;
      } else {
        console.log(this.timeLeft);
        this.showResendText = true;
      }
    }, 3000)
  }

  getStatus() {
    this.status = this.data.activatedStatusName;
    if (this.status) {
      if (this.status === 'Inspection scheduled') {
        this.htmlText = 'Do you want to accept the schedule ?';
      } else if (this.status === 'Inspection completed. Send for review') {
        this.htmlText = 'Do you want to send for review ?'
      } else if (this.status === 'Waiting for review') {
        this.htmlText = 'Do you want to send for OTP approval ?'
      } else if (this.status === 'Review completed. Waiting for OTP approval') {
        this.isOTP = true;
        if (this.SMS == 'Yes') {
          this.htmlText = 'A OTP (One Time Password) has been sent to this mail "' + this.technicalReviwerEmail + '" &  mobile number "' + this.technicalReviwerMobile + '"';
        }
        else {
          this.htmlText = 'A OTP (One Time Password) has been sent to ' + this.technicalReviwerEmail;
        }
        this.htmlText2 = 'Please enter the OTP in the field below to verify.'
      }
    }

    if (this.data.status) {
      if (this.data.status === 'Reject') {
        this.status = 'Reject';
        this.htmlText = 'Do you want to reject ?';
      } else if (this.data.status === 'Cancel') {
        this.status = 'Cancel';
        this.htmlText = 'Do you want to cancel ?';
      } else if (this.data.status === 'ReInspect') {
        this.status = 'ReInspect';
        this.htmlText = 'Do you want to Re-inspect?';
      }
    }
  }

  btnClose = () => {
    this.dialogRef.close(false);
  }

  dummyClickEventForPageRender() {
    let element: HTMLElement = document.getElementById('auto_trigger') as HTMLElement;
    element.click();
  }
  editModal(param) {
    console.log(param);
  }

}
