import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
  MatDialogConfig,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material';
import { ClientFormComponent } from '../client-form/client-form.component';
import { ClientService } from '../client.service';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { ExcelService } from '../../../excel.service';
import { AuthService } from '../../../auth/auth.service';
import { ProjectService } from '../../../master/project/project.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  isLoggedIn$: Observable<boolean>;
  user: any;
  show: boolean;
  canRead = false;
  canWrite = false;
  // dataSource: CompanyListDataSource;
  dataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'edit',
    'name',
    'email',
    'description',
    'createdBy',
    'createdDate',
    'updatedBy',
    'updatedDate'

  ];
  temp = [];
  temp1 = [];
  doFilter: Boolean;
  permission: any;
  isWrite = false;
  constructor(
    private dialog: MatDialog,
    private clientService: ClientService, private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet, private excelService: ExcelService, private authService: AuthService,
    private ProjectService: ProjectService
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;
    this.getTableData();
    if (this.permission && this.permission.master && this.permission.master.contractor && this.permission.master.contractor.write) {
      this.isWrite = true;
    } else {
      this.displayedColumns = ['name', 'email',
        , 'description', 'createdBy', 'createdDate', 'updatedBy', 'updatedDate'];
    }
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  getTableData() {
    this.clientService.getClient().subscribe(data => {
      if (!data) {
        return;
      }
      this.temp = data;
      for (let i = 0; i < this.temp.length; i++) {
        if (this.temp[i].createdDate) { this.temp[i].createdDate = moment(new Date(this.temp[i].createdDate)).format('DD/MM/YYYY'); }
        if (this.temp[i].updatedDate) { this.temp[i].updatedDate = moment(new Date(this.temp[i].updatedDate)).format('DD/MM/YYYY'); }
      }
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });
  }
  btnAddNewClick() {
    const dialogRef = this.dialog.open(ClientFormComponent, {
      data: {},
      disableClose: false,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  deleteTable(item) {
    const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
      data: { deleteItem: true },
      disableClose: false,
      hasBackdrop: true
    });

    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        this.ProjectService.checkClientIsExist(item._id).subscribe(data => {
          if (data.length > 0) {
            if (data.length > 0) {
              this.snackBar.open('Client exist in project.', '', { duration: 4000 });
              return;
            }
          }
          else {
            this.clientService.deleteById(item).subscribe(res => {
              if (res[0]['statusBool']) {
                this.snackBar.open(res[0]['message'], '', { duration: 2000, verticalPosition: "bottom" });
              }
              this.ngOnInit();
            });
          }
          if (result === false) {
            this.ngOnInit();
          }
        });
      }
    });
  }
  getTable(row) {
    this.clientService.getRecordById(row).subscribe(res => {
      const dialogRef = this.dialog.open(ClientFormComponent, {
        data: res[0],
      });
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });
    });
  }

  exportExcel() {
    var data1 = [];
    var i = 1;
    this.temp1.forEach(element => {
      var val = {
        "Name": element.name,
        "Email": element.email,
        "Description": element.description,
        'Created By': element.createdBy,
        'Created Date': element.createdDate,
        'Updated By': element.updatedBy,
        'Updated Date': element.updatedDate
      };
      i = i + 1;
      data1.push(val);
    });
    this.excelService.saveAsExcelFile(data1, 'ContractorMaster');
  }
  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0)
  }

  updateFilter(event) {  // Filter Function
    const val = event.target.value.toLowerCase();
    this.temp1 = this.temp.filter(function (d) {
      d.name = d.name ? d.name : '';
      d.email = d.email ? d.email : '';
      d.description = d.description ? d.description : '';
      d.createdBy = d.createdBy ? d.createdBy : '';
      d.createdDate = d.createdDate ? d.createdDate : '';
      d.updatedBy = d.updatedBy ? d.updatedBy : '';
      d.updatedDate = d.updatedDate ? d.updatedDate : '';
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.email.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.description.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdDate.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.createdBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedBy.toLowerCase().indexOf(val) !== -1 || !val) ||
        (d.updatedDate.toLowerCase().indexOf(val) !== -1 || !val);

    });
    this.dataSource = new MatTableDataSource(this.temp1);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

}
