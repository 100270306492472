import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar
} from '@angular/material';
import { UserFormComponent } from '../user-form/user-form.component';
import { ConfirmDeleteComponent } from '../../../master/confirm-delete/confirm-delete.component';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { UserService } from '../user.service';
import { LockConfigComponent } from '../../../general/lock-config/lock-config.component';
import { FormControl, Validators } from '@angular/forms';
import { AlertDialogComponent } from '../../../general/alert-dialog/alert-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource;
  user: any; users: any; emailIds: any;
  doFilter: Boolean; filterData: any; RESULTS: any;
  filteredRecords: any;

  seUsername = new FormControl('');
  seEmail = new FormControl('');
  permission: any;
  isWrite = false;

  displayedColumns = ['edit', 'name', 'role', 'emailId', 'phoneNo', 'certificateNo',
    'expiryDate', 'isLocked', 'resetPassword', 'lastLocked',
    'lastPasswordChanged', 'lastLogin'];
  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    this.permission = this.user.data.permission;

    if (this.permission && this.permission.admin && this.permission.admin.user && this.permission.admin.user.write) {
      this.isWrite = true;
    } else {
      this.displayedColumns = ['name', 'role', 'emailId', 'phoneNo',
        'certificateNo', 'expiryDate', 'isLocked', 'resetPassword', 'lastLocked',
        'lastPasswordChanged', 'lastLogin'];

    }
    this.userService.getRecord().subscribe(results => {
      if (!results) {
        return;
      }
      this.RESULTS = results;
      this.bindDataTable(results);
      this.dataSource = new MatTableDataSource(results);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  bindDataTable = (results) => {
    const users = []; const emailIds = [];
    if (results !== undefined && results.length > 0) {
      results.forEach(element => {
        const arr = [];
        // element.isClient = element.isClient ? "yes" : "";
        // element.isVendor = element.isVendor ? "yes" : "";
        if (element.role && element.role !== undefined && element.role.length > 0) {
          let str = '';
          element.role.forEach(el => {
            if (el.isActive) {
              if (str === '') {
                str = el.name + ', ';
              } else {
                str = str + el.name + ', ';
              }
            }
          });
          element.role = str.replace(/,\s*$/, '');;
        }
        if (element.name !== undefined && element.name !== null && element.name.length > 0) {
          users.push(element.name);
        }
        if (element.emailId !== undefined && element.emailId !== null && element.emailId.length > 0) {
          emailIds.push(element.emailId);
        }
        if (element.phoneNo !== undefined && element.phoneNo === '+0-0') {
          element.phoneNo = '';
        }
      });
    }
    this.users = users; //to bind it in filter
    this.emailIds = emailIds; //to bind it in filter
  }

  btnAddNewClick() {
    const dialogRef = this.dialog.open(UserFormComponent, {
      data: {},
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  getTable(row) {
    let splitPhNo = '';
    if (row.phoneNo === null || row.phoneNo === '' || row.phoneNo === undefined) {
      row.contactCode = undefined;
      row.contactNo = undefined;
    } else {
      splitPhNo = row.phoneNo.split('+');

      if (splitPhNo.length === 2) {
        splitPhNo = splitPhNo[1].toString();
        const seperateCode = splitPhNo.split('-');
        row.contactCode = seperateCode[0].toString();
        row.contactNo = seperateCode[1].toString();
        row.contactCode = Number(row.contactCode);
        row.contactNo = Number(row.contactNo);
      }
    }
    const dialogRef = this.dialog.open(UserFormComponent, {
      data: row,
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  deleteTable(item) {
    const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
      data: { deleteItem: true },
      disableClose: false,
      hasBackdrop: false
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result === true) {
        item.isActive = false;
        item.updatedBy = this.user.data.name;
        this.userService.updateRecord(item).subscribe(res => {
          if (res['statusBool']) {
            this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
            this.ngOnInit();
          } else {
            this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          }
        });
      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }

  resetPassword = (item) => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.userService.resetPassword(item).subscribe(res => {
          this.snackBar.open('Password reseted successfully', '', { duration: 2000, verticalPosition: "bottom" });
          this.ngOnInit();
        });
      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }

  manageLockAccount = (row) => {
    const dialogRef = this.dialog.open(LockConfigComponent, {
      data: row.isLocked,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        row.updatedBy = this.user.data.name;
        this.userService.updateLock(row).subscribe(res => {
          if (row.isLocked) {
            this.snackBar.open('User unlocked successfully', '', { duration: 2000, verticalPosition: "bottom" });
          } else {
            this.snackBar.open('User locked successfully', '', { duration: 2000, verticalPosition: "bottom" });

          }
          this.ngOnInit();
        });
      }
      if (result === false) {
        this.ngOnInit();
      }
    });
  }

  btnFilter = () => {
    this.doFilter = (this.doFilter) ? false : true;
    if (!this.doFilter) {
      this.btnClear();
    }
  }

  getFilterDataByName = (item) => {
    if (this.RESULTS && this.RESULTS.length > 0) {
      const sel_ = [];
      this.RESULTS.forEach(element => {
        if (element.name === item) {
          if (element.emailId !== undefined && element.emailId !== null) {
            this.seEmail.setValue(element.emailId);
          }
          sel_.push(element);
        }
      });
      this.filteredRecords = sel_;
    }
  }
  getFilterDataByEmailId = (item) => {
    if (this.RESULTS && this.RESULTS.length > 0) {
      const sel_ = [];
      this.RESULTS.forEach(element => {
        if (element.emailId === item) {
          if (element.name !== undefined && element.name !== null) {
            this.seUsername.setValue(element.name);
          }
          sel_.push(element);
        }
      });
      this.filteredRecords = sel_;
    }
  }

  btnSearch = (name, emailId) => {
    if (name === '' && emailId === '') {
      const res = {
        statusBool: false,
        message: 'Select atleast one field'
      };
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data: { res },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });
    } else {
      this.dataSource = new MatTableDataSource(this.filteredRecords);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  btnClear = () => {
    this.seUsername.setValue('');
    this.seEmail.setValue('');
    this.getUsersAndEmailIds();
    this.ngOnInit();
    this.filteredRecords = [];
  }

  getUsersAndEmailIds = () => {
    if (this.RESULTS !== undefined && this.RESULTS.length > 0) {
      const users = []; const emailIds = [];
      this.RESULTS.forEach(element => {
        if (element.name !== undefined && element.name !== null) {
          users.push(element.name);
        }
        if (element.emailId !== undefined && element.emailId !== null) {
          emailIds.push(element.emailId);
        }
      });
      this.users = users;
      this.emailIds = emailIds;
    }
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  gotoWidgets(row) {
    this.router.navigate(['admin', 'user', row._id, 'widgets']);
  }

}
