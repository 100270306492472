import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ScheduleInspectionService } from '../../master/scheduleInspection/schedule-inspection.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../general/dateformat-form';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment';
import { InternalEntryReportFormComponent } from '../internal-entry-report-form/internal-entry-report-form.component';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource,
  MatBottomSheet,
  MatSnackBar,
  MatDialogConfig,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material';
import { GeoptionService } from '../../services/geoption/geoption.service'
import { Observable } from 'rxjs';
import * as _ from 'underscore';
import { ExcelService } from '../../excel.service';

@Component({
  selector: 'app-internal-entry-report',
  templateUrl: './internal-entry-report.component.html',
  styleUrls: ['./internal-entry-report.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class InternalEntryReportComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  reportForm: any;
  temp = [];
  temp1 = [];
  types: any;
  type1: any;
  table1 = true;
  table2 = false;
  anotherTypes: any;
  dataSource;
  dataSource1;
  typeId: any;
  displayedColumns = [
    'edit',
    'keyNo',
    'inspectionDate',
    'projectName',
    'rpoNo',
    'woNo',
    'inspectionType',
    'siteLocationType',
    'esvNo',
    'sesNo',
    'invoiceNo',
    'invoiceValue',
    'paymentStatus'

  ];
  displayedColumns1 = [
    'edit',
    'keyNo',
    'inspectionDate',
    'projectName',
    'rpoNo',
    'woNo',
    'inspectionType',
    'siteLocationType',
    'invoiceNo',
    'invoiceValue'

  ];

  constructor(
    private fb: FormBuilder,
    private scheduleInspectionService: ScheduleInspectionService,
    private geoptionService: GeoptionService,
    private snackBar: MatSnackBar, private dialog: MatDialog,
    private excelService: ExcelService
  ) {
    this.reportForm = fb.group({
      'fromDate': [null, Validators.compose([Validators.required])],
      'toDate': [null, Validators.compose([Validators.required])],
      'type': [null, Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
    this.getTypes();
  }

  get f() {
    return this.reportForm.controls;
  }
  getTypes() {

    this.geoptionService.getRecordByOptionName('ContractType').subscribe(res => {
      this.types = res[0].option;
      this.anotherTypes = res[0].option;
      this.reportForm.patchValue({ type: this.types[0].optionValue });
    });
  }


  getDatas() {
    let type = this.reportForm.controls.type.value.toLowerCase();
    if (type === 'aramco direct') {
      this.button1();
    } else {
      this.button2();
    }
  }

  getDatasr(types) {
    let type = types.value.toLowerCase();
    if (type === 'aramco direct') {
      this.button1();
    } else {
      this.button2();
    }
  }


  button1() {
    this.table1 = true;
    this.table2 = false;
    let fromDate = this.reportForm.controls.fromDate.value;
    let toDate = this.reportForm.controls.toDate.value;

    if (!fromDate) {
      this.snackBar.open('From date is required', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
    if (!toDate) {
      this.snackBar.open('To date is required', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
    toDate = toDate.setDate(toDate.getDate() + 1);
    toDate = new Date(toDate).toISOString();


    this.type1 = 'Aramco Direct';
    var selectedType = [];
    selectedType = _.filter(this.types, ele => { return ele.optionValue == this.type1 });
    this.typeId = selectedType[0]._id;
    var data = {
      fromDate: fromDate,
      toDate: toDate,
      type: this.typeId
    }
    this.getData(data)
  }
  button2() {
    this.table1 = false;
    this.table2 = true;
    let fromDate = this.reportForm.controls.fromDate.value;
    let toDate = this.reportForm.controls.toDate.value;

    if (!fromDate) {
      this.snackBar.open('From date is required', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
    if (!toDate) {
      this.snackBar.open('To date is required', '', { duration: 2000, verticalPosition: "bottom" });
      return '';
    }
    toDate = toDate.setDate(toDate.getDate() + 1);
    toDate = new Date(toDate).toISOString();


    this.type1 = 'Aramco Contract';
    var selectedType = [];
    selectedType = _.filter(this.types, ele => { return ele.optionValue == this.type1 });
    this.typeId = selectedType[0]._id;
    var data = {
      fromDate: fromDate,
      toDate: toDate,
      type: this.typeId
    }
    this.getData1(data)
  }
  getTable(row, data) {
    row.table = 'table1';
    let dialogRef = this.dialog.open(InternalEntryReportFormComponent, {
      data: row,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.ngOnInit();
      this.button1();
    });
  }

  getTable1(row, data) {
    row.table = 'table2';
    let dialogRef = this.dialog.open(InternalEntryReportFormComponent, {
      data: row,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.button2();
    });
  }

  getData(data) {
    this.scheduleInspectionService.getInternalEntryData(data).subscribe(res => {
      if (res) {
        this.temp = res;
        for (var i = 0; i < this.temp.length; i++) {
          if (this.temp[i].project) {
            if (this.temp[i].project.name) {
              this.temp[i].projectName = this.temp[i].project.name;
            }
          }
          if (this.temp[i].inspectionDate) { this.temp[i].inspectionDate = moment(new Date(this.temp[i].inspectionDate)).format('DD/MM/YYYY'); }
        }
        this.temp1 = this.temp;
        this.dataSource = new MatTableDataSource(this.temp);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }


    })
  }
  getData1(data) {
    this.scheduleInspectionService.getInternalEntryData(data).subscribe(res => {
      if (res) {
        this.temp = res;
        for (let i = 0; i < this.temp.length; i++) {
          if (this.temp[i].project) {
            if (this.temp[i].project.name) {
              this.temp[i].projectName = this.temp[i].project.name;
            }
          }
          if (this.temp[i].inspectionDate) { this.temp[i].inspectionDate = moment(new Date(this.temp[i].inspectionDate)).format('DD/MM/YYYY'); }
        }
        this.temp1 = this.temp;
        this.dataSource1 = new MatTableDataSource(this.temp);
        this.dataSource1.paginator = this.paginator;
        this.dataSource1.sort = this.sort;
      }

    })
  }

  exportExcel() {
    if (this.table1) {
      var data1 = [];
      var i = 1;
      this.temp1.forEach(element => {
        var val = {
          "Key no": element.keyNo,
          "Inspection date": element.inspectionDate,
          'Project name': element.projectName,
          'RPO no': element.rpoNo,
          'WO no': element.woNo,
          'Inspection type': element.inspectionType,
          'Site location type': element.siteLocationType,
          'ESV no': element.esvNo,
          'SES no': element.sesNo,
          'Invoice no': element.invoiceNo,
          'Invoice value': element.invoiceValue,
          'Payment Status': element.paymentStatus
        };
        i = i + 1;
        data1.push(val);
      });
      this.excelService.saveAsExcelFile(data1, 'Internal entry report');
    } else {
      var data1 = [];
      var i = 1;
      this.temp1.forEach(element => {
        var val = {
          "Key no": element.keyNo,
          "Inspection date": element.inspectionDate,
          'Project name': element.projectName,
          'RPO no': element.rpoNo,
          'WO no': element.woNo,
          'Inspection type': element.inspectionType,
          'Site location type': element.siteLocationType,
          'Invoice no': element.invoiceNo,
          'Invoice value': element.invoiceValue
        };
        i = i + 1;
        data1.push(val);
      });
      this.excelService.saveAsExcelFile(data1, 'Internal entry report');
    }

  }

}
