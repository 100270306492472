import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {
  MatBottomSheet,
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef
} from '@angular/material';

@Component({
  selector: 'app-confirm-submit',
  templateUrl: './confirm-submit.component.html',
  styleUrls: ['./confirm-submit.component.css']
})
export class ConfirmSubmitComponent implements OnInit {
  submitItem: boolean;
  private result;
  constructor(private bottomSheetRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: any) { }

  ngOnInit() {
    this.submitItem = this.data.submitItem;
  }

  btnClose() {
    const confirmSubmit = false;
    this.result = confirmSubmit;
    this.bottomSheetRef.dismiss(this.result);
  }

  confirmSubmit() {
    const confirmSubmit = true;
    this.result = confirmSubmit;
    this.bottomSheetRef.dismiss(this.result);
  }
}
