import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatFormFieldModule,
} from '@angular/material';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AppDashboardComponent } from './app-dashboard.component';
// import { GridsterModule } from 'angular-gridster2';
import { DashboardAddWidgetsComponent } from './dashboard-add-widgets/dashboard-add-widgets.component';

import { InspectionStatusPchartComponent } from './inspection-status-pchart/inspection-status-pchart.component';
import { StatusBarchartComponent } from './status-barchart/status-barchart.component';
import { InspectionOverallSummaryComponent } from './inspection-overall-summary/inspection-overall-summary.component';
import { InspectorEquipmentBarchartComponent } from './inspector-equipment-barchart/inspector-equipment-barchart.component';
import { TotalEquipmentInspectedComponent } from './total-equipment-inspected/total-equipment-inspected.component';
import { TotalLoadtestInspectedComponent } from './total-loadtest-inspected/total-loadtest-inspected.component';
import { TotalLoadtestComponent } from './total-loadtest/total-loadtest.component';
import { ReviewerRejectionStatusComponent } from './reviewer-rejection-status/reviewer-rejection-status.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatFormFieldModule,
    // GridsterModule,
    NgxDaterangepickerMd
  ],
  declarations: [
    AppDashboardComponent,
    DashboardAddWidgetsComponent,
    InspectionStatusPchartComponent,
    StatusBarchartComponent,
    InspectionOverallSummaryComponent,
    InspectorEquipmentBarchartComponent,
    TotalEquipmentInspectedComponent,
    TotalLoadtestInspectedComponent,
    TotalLoadtestComponent,
    ReviewerRejectionStatusComponent
  ],
  entryComponents: [
    DashboardAddWidgetsComponent,
    InspectionStatusPchartComponent,
    StatusBarchartComponent,
    InspectionOverallSummaryComponent,
    InspectorEquipmentBarchartComponent,TotalEquipmentInspectedComponent,TotalLoadtestInspectedComponent,
    TotalLoadtestComponent,ReviewerRejectionStatusComponent
  ]
})
export class AppDashboardModule { }
