import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting/setting.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class InspectorService {

  private apiUrl = '';

  constructor(private http: HttpClient,
    private settingService:SettingService) {
  }

  getInspectors(): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('inspector');
    return this.http
      .post<any>(this.apiUrl + '/getInspectors', httpOptions)
      .pipe(tap());
  }


  getInspectorCheckList(rowID: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('inspector');
    return this.http
      .post<any>(this.apiUrl + '/getInspectorCheckList', { "rowID": rowID }, httpOptions)
      .pipe(tap());
  }

  save(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('inspector');
    return this.http
      .post<any>(this.apiUrl + '/updateUserChecklistRecord', data, httpOptions)
      .pipe(tap());
  }

  categoryInsert(data: any): Observable<any[]> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('inspector');
    return this.http
      .post<any>(this.apiUrl + '/updateUserChecklistCategory', data, httpOptions)
      .pipe(tap());
  }


  deleteChecklistById(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('inspector');
    return this.http
      .post<any>(
        this.apiUrl + '/deleteCheckListRecord'
        , data,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

  deleteCategoryId(data): Observable<any> {
    this.apiUrl = this.settingService.getLocalStorageAppURL('inspector');
    return this.http
      .post<any>(
        this.apiUrl + '/deletecategoryRecord',
        data,
        httpOptions
      )
      .pipe(
        tap()
      );
  }

}

