import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { UserService } from '../../admin/user/user.service'

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatSort,
  MatPaginator,
  MatSnackBar,
  MatTableDataSource
} from '@angular/material';
import { RfiInspectionService } from '../../master/rfi-inspection/rfi-inspection.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-reviewer-rejection-status',
  templateUrl: './reviewer-rejection-status.component.html',
  styleUrls: ['./reviewer-rejection-status.component.css']
})
export class ReviewerRejectionStatusComponent implements OnInit {

  displayedColumns = ['title', 'count'];

  temp = [];
  temp1 = [];
  dataSource: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  isLoggedIn$: Observable<boolean>;
  currentPermissions: any;
  toSearch: any;
  isShow = true;
  isInspector = true;
  WithCount=0;
  WithoutCount=0;
  user: any;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  alwaysShowCalendars: boolean;
  usersData: any;
  users: any;
  userName: any;
  inspectionDate = new FormControl('');
  inspector = new FormControl('');

  list1 = 0;
  list2 = 0;
  list3 = 0;
  list4 = 0;
  list5 = 0;
  list6 = 0;
  list7 = 0;
  list8 = 0;
  list9 = 0;
  list1Name = ''
  list2Name = ''
  list3Name = ''
  list4Name = ''
  list5Name = ''
  list6Name = ''
  list7Name = ''
  list8Name = ''
  list9Name = ''
  tempTotalCount = 0;
  finalList1 = '';
  finalList2 = '';
  finalList3 = '';
  finalList4 = '';
  finalList5 = '';
  finalList6 = '';
  finalList7 = '';
  finalList8 = '';
  finalList9 = '';
  inspectorColumn = false;
  width: Number;

  date = new Date();
  a = moment().startOf('day').subtract(1, 'month');
  b = moment().endOf('day')

  constructor(private userService: UserService, private snackBar: MatSnackBar,
    private rfiInspectionService: RfiInspectionService) { }



  ngOnInit() {
    this.user = this.getFromLocalStorage('userDet');
    if (this.user.data.role.length > 0) {
      for (var i = 0; i < this.user.data.role.length; i++) {
        if (this.user.data.role[i].name == 'Inspector' && this.user.data.role[i].isActive === true) {
          this.inspector.setValue(this.user.data._id)
          this.inspectorColumn = true;
        } else {
          this.inspector.setValue('All');
          this.inspectorColumn = false;
        }
      }
    }
    this.inspectionDate.setValue({
      start: moment(this.a).format('MM/DD/YYYY'),
      end: moment(this.b).format('MM/DD/YYYY')
    });
    this.width = screen.width;
    this.getUsers();
    this.getData(this.inspectionDate.value, this.inspector.value);
  }
  onResize(event) {
    this.width = event.target.innerWidth;
  }
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  getUsers = () => {
    this.userService.getInspectors().subscribe(res => {
      this.users = res;
      this.usersData = this.users;

    });
  }
  filterInspector(val) {
    val = val.toLowerCase();
    this.usersData = this.users.filter(function (d) {
      return (d.name.toLowerCase().indexOf(val) !== -1 || !val)
    });
  }
  stopPropagation(e) {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }
  getData(inspectionDate, inspector) {
    if (inspectionDate === null || inspectionDate.start === null) {
      inspectionDate = {
        startDate: null,
        endDate: null
      };

    } else {
      if (inspectionDate.start == undefined) {
        this.snackBar.open('Select date range', '', { duration: 2000, verticalPosition: "bottom" });
        return;
      }
      if (this.inspectionDate.value.start._d) {
        inspectionDate = {
          startDate: this.inspectionDate.value.start._d,
          endDate: this.inspectionDate.value.end._d
        };
      } else {
        inspectionDate = {
          startDate: this.inspectionDate.value.start,
          endDate: this.inspectionDate.value.end
        };
      }

    }
    this.toSearch = {
      inspectionDate: inspectionDate,
      inspector: inspector,
    };
    this.isShow = true;
    var resultS=[];
    this.WithoutCount =0;
    this.WithCount=0;
    this.rfiInspectionService.getRejectionStatusByDate(this.toSearch).subscribe(result => {
      if (!result) {
        return
      }
      
      this.temp = result;

     
      console.log(this.temp.length);
      /* for(var i=0;i<this.temp.length;i++)
      {
        console.log(this.temp[i]);
        this.rfiInspectionService.getRejectedRFIWorkflow(this.temp[i]).subscribe(resultD => {
          
          if(resultD.length>0)
          {          
            this.WithCount=this.WithCount+1;
          }
          else
          {          
            this.WithoutCount = this.WithoutCount+1;
          }
                
          resultS=
          [
          {Title:"Inspection with rejection", count: this.WithCount},
          {Title:"Inspection without rejection", count: this.WithoutCount}];
          
            
           

        });
      } */
      //this.temp = resultS;
      this.temp1 = this.temp;
      this.dataSource = new MatTableDataSource(this.temp);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      /* console.log('aaa');
      console.log(this.WithoutCount);
      

      resultS.push({Title:"Inspection with rejection", count: this.WithCount},
      {Title:"Inspection without rejection", count: this.WithoutCount}
      );

        this.temp = resultS;
        this.temp1 = this.temp;
        this.dataSource = new MatTableDataSource(this.temp);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator; */

    });

    

  
    }
}
