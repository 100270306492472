import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatBottomSheet,
  MatSnackBar
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../../master/confirm-delete/confirm-delete.component';
import { UserService } from '../user.service';
import { RoleManagementService } from '../../role/role-management.service';
import { AlertDialogComponent } from '../../../general/alert-dialog/alert-dialog.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../general/dateformat-form';
@Component({
  selector: 'app--user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css'], providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class UserFormComponent implements OnInit {
  hide = true;
  inputData: any;

  role = new FormControl('', [Validators.required]);
  name = new FormControl('', [Validators.required]);
  emailId = new FormControl('', [Validators.required, Validators.email]);
  remarks = new FormControl();
  contactCode = new FormControl('');
  contactNo = new FormControl('');
  certificateNo = new FormControl('');
  expiryDate = new FormControl('');
  roles = []; ROLES = [];
  selectedRoles = [];
  selected = ['Admin'];
  color = 'primary';
  disabled = false;
  user: any;

  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<UserFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private userService: UserService,
    private roleService: RoleManagementService,
  ) { }

  ngOnInit() {
    this.getRoles();
    this.user = this.getFromLocalStorage('userDet');
    if (Object.keys(this.data).length !== 0) {
      this.bindRoles(this.data.role);
      this.name.setValue(this.data.name);
      this.emailId.setValue(this.data.emailId);
      this.role.setValue(this.selected);
      this.remarks.setValue(this.data.remarks);
      this.contactCode.setValue(this.data.contactCode);
      this.contactNo.setValue(this.data.contactNo);
      this.certificateNo.setValue(this.data.certificateNo);
      this.expiryDate.setValue(this.data.expiryDate);
    }
  }

  getRoles = () => {
    this.roleService.getRecord().subscribe(res => {
      this.ROLES = res;
      if (this.ROLES.length > 0) {
        this.ROLES.forEach(element => {
          this.roles.push(element.name);
        });
      }
    });
  }


  bindRoles = (roles) => {
    if (roles !== undefined && roles.length > 0) {
      const arr = []; let x;
      x = roles.split(',');
      x.forEach(element => {
        element = element.trim();
        if (element !== undefined) {
          arr.push(element);
        }
      });
      this.selected = arr;
    }
  }


  save(
    name: String,
    emailId: String,
    contactCode: Number,
    contactNo: Number,
    role: String,
    // remarks: String,
    certificateNo: String,
    expiryDate: Date
    // _id: String
  ): void {
    if (!name || !emailId || !role) {
      return;
    }
    this.inputData = {
      name: name,
      emailId: emailId,
      contactCode: contactCode,
      contactNo: contactNo,
      role: role,
      // remarks: remarks,
      certificateNo: certificateNo,
      expiryDate: expiryDate
    };
    this.prepareRoles(this.inputData.role);
    this.inputData.role = this.selectedRoles;



    if (Object.keys(this.data).length !== 0) {
      this.inputData._id = this.data._id;
      this.inputData.updatedBy = this.user.data.name;
      this.userService.updateRecord(this.inputData).subscribe(res => {
        // this.showAlert(res);
        if (res['statusBool']) {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } else {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    } else {
      this.inputData.createdBy = this.user.data.name;
      this.userService.createRecord(this.inputData).subscribe(res => {
        // this.showAlert(res);
        if (res['statusBool']) {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
          this.dialogRef.close();
        } else {
          this.snackBar.open(res['message'], '', { duration: 2000, verticalPosition: "bottom" });
        }
      });
    }
  }

  showAlert = (res) => {
    if (!res.statusBool) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data: { res },
        disableClose: false
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
          this.data = {};
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  prepareRoles = (selectedRoles) => {
    if (this.ROLES !== undefined && this.ROLES.length > 0) {
      if (selectedRoles !== undefined && selectedRoles.length > 0) {
        const arr = [];
        selectedRoles.forEach(element => {
          this.ROLES.forEach(row => {
            if (element === row.name) {
              arr.push({
                'name': row.name,
                'isActive': row.isActive,
                'ref': row._id
              });
            }
          });
        });
        this.selectedRoles = arr;
      }
    }
  }

  btnClose() {
    if (
      this.name.dirty ||
      this.emailId.dirty ||
      this.role.dirty ||
      this.remarks.dirty
    ) {
      const bottomSheetRef = this.bottomSheet.open(ConfirmDeleteComponent, {
        data: { deleteItem: false },
        disableClose: false,
        hasBackdrop: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  getErrorRole() {
    return this.role.hasError('required') ? 'Role is required' : '';
  }

  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
